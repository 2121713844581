/* eslint-disable max-lines -- Disabled pre-existing violation of max lines rule */
import * as React from 'react';
import Chip from '@material-ui/core/Chip';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

import { cloneDeep, get, isEmpty, values } from 'lodash';

import papaparse from 'papaparse/papaparse';

import EditIcon from '@material-ui/icons/Edit';
import WarningIcon from '@material-ui/icons/Warning';

import TextField from '@material-ui/core/TextField';
import OverlayMessage, { OverlayType } from '../../components/OverlayMessage';

import flexeApi from '../../lib/flexeApi';
import PageLoadSpinner from '../../components/PageLoadSpinner/';
import ErrorMessage from '../../components/ErrorMessage';
import LocationsTable from '../../components/Locations/LocationsTable';
import FindLocationsControls from '../../components/Locations/FindLocationsControls';
import FilterList from '../../components/FilterList';
import SubmitButton from '../../components/SubmitButton';

import {
  FindLocationBy,
  InventoryPackaging,
  LocationInfo,
  LpnCorrection,
  ReservationAndScopeInfo,
} from '../../CommonInterfaces';

import { FLAT_BTN, PACKAGING_TYPE_MAP } from '../../lib/constants';
import { blueDark, white } from '../../styles/flexeColors';
import './styles.css';

const styles = {
  chip: {
    main: {
      width: '100%',
      marginBottom: 20,
    },
    label: {
      width: '100%',
    },
  },
  editModeChip: {
    deleteIcon: {
      fill: blueDark,
    },
  },
  errorChip: {
    deleteIcon: {
      fill: white,
    },
  },
};

interface LocationsState {
  clearEditsDialogContent: string;
  csvErrors: any;
  editMode: boolean;
  editDescription: string;
  editReasonCode: string;
  editRelatedWhPurchaseOrder: string;
  continuationTokens: string[];
  currentPage: number;
  errorMessage?: string;
  fetchingLocations: boolean;
  findLocationsBy: FindLocationBy;
  locations: LocationInfo[];
  locationErrors: any;
  locationErrorsCount: number;
  pendingEdits: LocationInfo[];
  pendingEditsOnly: boolean;
  reservations: ReservationAndScopeInfo[];
  showClearEditsDialog: boolean;
  showCsvDialog: boolean;
  showCsvErrorsDialog: boolean;
  showErrorOverlay: boolean;
  showReviewAndSaveDialog: boolean;
  showSuccessOverlay: boolean;
  showSuccessMessage: string;
  showLpnCorrectionsDialog: boolean;
  skuFilters: string[];
  locationFilters: string[];
  lpnFilters: string[];
  totalEdits: number;
  submittingChanges: boolean;
  submittingLpnCorrections: boolean;
  refreshNotice?: boolean;
  chosenRequest?: ReservationAndScopeInfo;
  showHelpDialog: boolean;
}

class Locations extends React.Component<null, LocationsState> {
  constructor(props) {
    super(props);

    this.state = {
      clearEditsDialogContent: '',
      csvErrors: {},
      editMode: false,
      editDescription: '',
      editReasonCode: 'cycle_count',
      editRelatedWhPurchaseOrder: '',
      errorMessage: null,
      fetchingLocations: false,
      findLocationsBy: FindLocationBy.Reservation,
      locations: [],
      locationErrors: {},
      locationErrorsCount: 0,
      pendingEdits: [],
      pendingEditsOnly: false,
      reservations: [],
      showClearEditsDialog: false,
      showCsvDialog: false,
      showCsvErrorsDialog: false,
      showErrorOverlay: false,
      showReviewAndSaveDialog: false,
      showSuccessOverlay: false,
      showSuccessMessage: '',
      showLpnCorrectionsDialog: false,
      skuFilters: [],
      locationFilters: [],
      lpnFilters: [],
      totalEdits: 0,
      currentPage: 1,
      continuationTokens: [],
      submittingChanges: false,
      submittingLpnCorrections: false,
      chosenRequest: null,
      showHelpDialog: false,
    };
  }

  public componentDidMount() {
    flexeApi.getReservations().then((response) => {
      if (response && response.data && response.data.length) {
        this.setState({ reservations: response.data });
      }
    });
  }

  // eslint-disable-next-line complexity -- Disabled pre-existing violation of complexity rule
  public render() {
    let filteredLocations;
    if (this.state.pendingEditsOnly) {
      // Filter out any locations that don't have the newQuantity prop (not being edited)
      filteredLocations = this.state.locations.filter((location: LocationInfo) => location.newQuantity);
    } else {
      filteredLocations = this.state.locations;
    }

    return (
      <div className="page locations-page">
        <div className="pure-g">
          <div className="pure-u-1-5 side-bar">
            <h2>Filters</h2>
            <Button variant="contained" color="default" onClick={(event) => this.setState({ showHelpDialog: true })}>
              Help
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={this.state.chosenRequest === null}
              onClick={(event) => this.fetchLocations()}
            >
              Apply Filters
            </Button>
            <div className="filter">
              <FindLocationsControls
                reservations={this.state.reservations}
                findLocationsBy={this.state.findLocationsBy}
                handleFindLocationsByChange={(event, findLocationsBy) => this.setState({ findLocationsBy })}
                reservationOrWarehouseSelected={this.reservationOrWarehouseSelected}
              />
            </div>
            <div className="filter">
              <FilterList
                heading="SKU Filters"
                filterKey="sku"
                values={this.state.skuFilters}
                applyFilters={this.applyFilters}
                pendingEditsOnly={this.state.pendingEditsOnly}
                showPendingEditsOnlyToggle={this.state.totalEdits > 0}
                handleShowPendingEditsOnlyToggle={this.toggleShowPendingEditsOnly}
              />
            </div>
            <div className="filter">
              <FilterList
                heading="Location Filters"
                filterKey="location"
                values={this.state.locationFilters}
                applyFilters={this.applyFilters}
                pendingEditsOnly={this.state.pendingEditsOnly}
                showPendingEditsOnlyToggle={this.state.totalEdits > 0}
                handleShowPendingEditsOnlyToggle={this.toggleShowPendingEditsOnly}
              />
            </div>
            <div className="filter">
              <FilterList
                heading="LPN Filters"
                filterKey="lpn"
                values={this.state.lpnFilters}
                applyFilters={this.applyFilters}
                pendingEditsOnly={this.state.pendingEditsOnly}
                showPendingEditsOnlyToggle={this.state.totalEdits > 0}
                handleShowPendingEditsOnlyToggle={this.toggleShowPendingEditsOnly}
              />
            </div>
          </div>

          <div className="pure-u-4-5 main-section">
            {this.state.submittingLpnCorrections ? (
              <div>
                <h3>Submitting LPN Corrections...</h3>
                <PageLoadSpinner />
              </div>
            ) : null}
            {filteredLocations.length && this.state.editMode && this.state.locationErrorsCount === 0 ? (
              <Chip
                avatar={<EditIcon style={{ width: 24, height: 24, margin: 0 }} />}
                onDelete={this.exitEditMode}
                label={
                  <span>
                    You are currently editing locations
                    <b>{this.state.totalEdits} Pending Edits</b>
                  </span>
                }
                style={styles.chip.main}
              />
            ) : null}
            {this.state.locationErrorsCount > 0 ? (
              <Chip
                className="errors-chip"
                avatar={<WarningIcon style={{ width: 24, height: 24, margin: 0 }} />}
                onDelete={this.exitEditMode}
                label={
                  <span>
                    {this.state.refreshNotice
                      ? `Some of the quantities are out of date,
                                    &nbsp;<a href="#" onClick={this.exitEditMode}>click here</a>&nbsp;
                                    to refresh.`
                      : 'There were problems processing the following location edits.'}
                    <b>{this.state.totalEdits} Pending Edits</b>
                  </span>
                }
                style={styles.chip.main}
              />
            ) : null}
            <h1>Locations</h1>
            {filteredLocations.length && !this.state.editMode ? (
              <Button className="pull-right" variant="contained" color="secondary" onClick={this.enterEditMode}>
                Edit Quantities
              </Button>
            ) : null}
            {this.state.findLocationsBy === 'Reservation' && filteredLocations.length && !this.state.editMode ? (
              <Button className="pull-right" variant="contained" color="secondary" onClick={this.showCsvDialog}>
                Edit Quantities (CSV)
              </Button>
            ) : null}
            {!this.state.editMode ? (
              <Button
                className="pull-right"
                variant="contained"
                color="secondary"
                onClick={this.showLpnCorrectionsDialog}
              >
                Correct LPN Inbound (CSV)
              </Button>
            ) : null}
            {filteredLocations.length && this.state.editMode ? (
              <Button
                className="review-and-save-btn"
                variant="contained"
                color="primary"
                onClick={this.reviewAndSaveChanges}
                disabled={this.state.totalEdits === 0}
              >
                Review &amp; Save Changes
              </Button>
            ) : null}
            {this.renderErrorMessage(this.state.errorMessage)}
            {filteredLocations.length ? (
              <div className="pagination pull-right">
                <Button
                  variant="text"
                  onClick={this.handlePagination}
                  data-page={this.state.currentPage - 1}
                  disabled={this.state.currentPage <= 1 || this.state.editMode}
                >
                  Previous Page
                </Button>
                <div className="current-page">{this.state.currentPage}</div>
                <Button
                  variant="text"
                  data-page={this.state.currentPage + 1}
                  onClick={this.handlePagination}
                  disabled={this.state.continuationTokens.length < this.state.currentPage || this.state.editMode}
                >
                  Next Page
                </Button>
              </div>
            ) : null}
            {this.state.fetchingLocations ? (
              <PageLoadSpinner />
            ) : (
              <LocationsTable
                locations={filteredLocations}
                errors={this.state.locationErrors}
                errorsCount={this.state.locationErrorsCount}
                editMode={this.state.editMode}
                updateSkuQty={this.updateSkuQty}
              />
            )}
          </div>
        </div>

        <Dialog open={this.state.showHelpDialog}>
          <DialogTitle>Understanding This Page</DialogTitle>
          <DialogContent>
            <p>
              To view Ops documentation for this page, see this
              <a href="https://drive.google.com/drive/folders/1SjQ_SK4Flm-PHwNSl3uzFarHqEVEIo_9">Google Drive folder</a>
            </p>
            <hr />
            <p>The "Apply Filters" button is disabled until you select either a reservation or a warehouse.</p>
            <hr />
            <p>
              A SKU or Location filter looks for anything that contains the string, regardless of case.
              <br />
              For example, the filter "aa" would match "aa", "baa", "aab", "Aa", or "BaA".
            </p>
            <p>An LPN filter is an exact match. For example "AA" would only match "AA", not "A" or "aa".</p>
            <hr />
            <p>Each row in a filter list is a logical "OR", and separate filter lists are a logical "AND". </p>
            <p>Let's say we have the locations: [(LOC-A, SKU-1), (LOC-A, SKU-2), (LOC-B, SKU-3)].</p>
            <ul>
              <li>
                if our Location filters is empty, and our SKU filters contains [SKU-1, SKU-2], we would return both the
                first and second location, but NOT the third
              </li>
              <li>
                if our Location filters contains [LOC-B], and our SKU filters is empty, we would return ONLY the third
                location.
              </li>
              <li>
                if our Location filters contains [LOC-B], and our SKU filters contains [SKU-1, SKU-2], we would return
                NO locations, because there is no overlap between the two filters.
              </li>
            </ul>
            <hr />
            <p>If you have further questions, contact someone on the WSE team.</p>
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={(event) => this.setState({ showHelpDialog: false })}>
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={this.state.showCsvDialog}>
          <DialogTitle>Edit Quantities from CSV data</DialogTitle>
          <DialogContent>
            <div>
              <p>
                <strong>Important Notes:</strong>
              </p>
              <ul>
                {/* See https://flexe-inc.atlassian.net/browse/INV-2605?focusedCommentId=248499 for notes on
                this component and possible performance improvements */}
                <li>
                  It is recommended that you submit a CSV with no more than 100 rows at at time.
                  <br />
                  If you need to submit more than this, please partition your files into batches.
                  <br />
                  The browser may become unresponsive for a few seconds after uploading.
                </li>
              </ul>
            </div>
            <div>
              <p>Select CSV:</p>
              <input type="file" onChange={this.parseQtyCsv} accept="text/csv" />
            </div>
            <hr />
            <div>
              <p>Supported fields:</p>
              <table className="pure-table">
                <thead>
                  <tr>
                    <th>Header</th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <code>sku</code>
                    </td>
                    <td>
                      <p>SKU for the inventory level being adjusted</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <code>delta</code>
                    </td>
                    <td>
                      <p>Quantity of this SKU to add or remove (no decimals)</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <code>packaging</code>
                    </td>
                    <td>
                      <p>
                        Unit of measure to adjust by (<code>each</code> or <code>carton</code>)
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <code>location</code>
                    </td>
                    <td>
                      <p>
                        <i>(optional)</i>
                        <br />
                        Label name of location for this adjustment. If not provided, first applicable location for this
                        SKU will be selected (this is fine if the reservation is not using mobile locations).
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <code>lpn_barcode</code>
                    </td>
                    <td>
                      <p>
                        <i>(optional)</i>
                        <br />
                        License Plate number for goods in the location for this adjustment. If not provided, goods will
                        be entered as loose goods instead of as LPN goods. Can only be provided if a location is also
                        provided.
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={this.hideCsvDialog}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={this.state.showCsvErrorsDialog}>
          <DialogTitle>Problems with CSV import</DialogTitle>
          <DialogContent>
            <table className="pure-table">
              <thead>
                <tr>
                  <th>Row #</th>
                  <th>{isEmpty(get(this.state, 'csvErrors[0].packaging')) ? 'LPN' : 'Packaging'}</th>
                  <th>SKU</th>
                  <th>Errors</th>
                </tr>
              </thead>
              <tbody>
                {!isEmpty(this.state.csvErrors) &&
                  this.objEntries(this.state.csvErrors).map((error: [string, any]) => {
                    const index: number = parseInt(error[0], 10);
                    const errorHash: any = typeof error[1] === 'object' ? error[1] : {};
                    const messages = errorHash.hasOwnProperty('errors') ? errorHash.errors : [];
                    return (
                      <tr key={'errorRow_' + index}>
                        {/* +1 here to ignore 0 index */}
                        <td>{index + 1}</td>
                        <td>{get(errorHash, 'lpn') || get(errorHash, 'packaging') || 'None specified.'}</td>
                        <td>{get(errorHash, 'sku') || 'None specified.'}</td>
                        <td>
                          {messages.map((msg) => (
                            <p>{msg}</p>
                          ))}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={this.hideCsvErrorsDialog}>
              Got it
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={this.state.showClearEditsDialog}>
          <DialogTitle>Continue without saving?</DialogTitle>
          <DialogContent>{this.state.clearEditsDialogContent}</DialogContent>
          <DialogActions>
            <Button onClick={this.closeClearEditsDialog}>Cancel</Button>
            <Button color="primary" onClick={this.refreshLocations}>
              Continue Without Saving
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog title="Content Change Review" scroll="body" open={this.state.showReviewAndSaveDialog} maxWidth="md">
          <DialogTitle>Content Change Review</DialogTitle>
          <DialogContent>
            <p>Please confirm the following changes that are to be made to warehouse inventory levels by location.</p>
            <table className="pure-table">
              <thead>
                <tr>
                  <th>Location</th>
                  <th>SKU</th>
                  <th>LPN</th>
                  <th>Original Qty</th>
                  <th>Conversion</th>
                  <th>Change</th>
                  <th>New Total</th>
                </tr>
              </thead>
              <tbody>
                {/* eslint-disable-next-line complexity -- Disabled pre-existing violation of complexity rule */}
                {this.state.pendingEdits.map((location: LocationInfo, idx: number) => {
                  const packaging = location.newPackaging || location.packaging;
                  const displayPackaging = packaging ? PACKAGING_TYPE_MAP[packaging] : null;
                  const origDisplayPackaging = location.packaging ? PACKAGING_TYPE_MAP[location.packaging] : null;
                  const origQty: number = parseInt(location.quantity, 10);
                  const newQty: number = parseInt(location.newQuantity, 10);
                  let convertedOrigQty: number = origQty;
                  if (location.newPackaging && location.newPackaging !== location.packaging && location.conversions) {
                    const conversion = location.conversions[location.packaging][location.newPackaging];
                    convertedOrigQty = conversion * origQty;
                  }

                  const conversionDisplay: string =
                    packaging !== location.packaging && convertedOrigQty && displayPackaging
                      ? convertedOrigQty + ' ' + displayPackaging
                      : 'NA';

                  let qtyDiff: number = null;
                  let adjustment: JSX.Element;
                  if (newQty && !Number.isNaN(newQty) && convertedOrigQty && !Number.isNaN(convertedOrigQty)) {
                    qtyDiff = newQty - convertedOrigQty;
                    if (qtyDiff > 0) {
                      adjustment = (
                        <b className="positive">
                          +{qtyDiff} {displayPackaging}
                        </b>
                      );
                    } else if (qtyDiff < 0) {
                      adjustment = (
                        <b className="negative">
                          {qtyDiff} {displayPackaging}
                        </b>
                      );
                    } else {
                      adjustment = <span>NA</span>;
                    }
                  }

                  return (
                    <tr key={idx}>
                      <td>
                        {location.location_id} - {location.location_label}
                      </td>
                      <td>{location.sku}</td>
                      <td>{location.lpn_barcode}</td>
                      <td>
                        {origQty} {origDisplayPackaging}
                      </td>
                      <td>{conversionDisplay}</td>
                      <td>{adjustment}</td>
                      <td>
                        <b>{newQty}</b> {displayPackaging}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="input-layout">
              <div className="edit-description-container">
                <p>Edit notes (required):</p>
                <TextField
                  autoFocus={true}
                  multiline={true}
                  rows={5}
                  value={this.state.editDescription}
                  onChange={this.updateEditDescription}
                  style={{ width: 512 }}
                />
              </div>
              <div className="reason-code-container">
                <FormControl>
                  <InputLabel htmlFor="adjustment-reason">Adjustment reason:</InputLabel>
                  <Select
                    native
                    id="adjustment-reason"
                    value={this.state.editReasonCode}
                    onChange={this.updateEditReasonCode}
                  >
                    <optgroup>
                      <option value="placeholder"></option>
                      <option value="cycle_count">Cycle Count / Inventory Count</option>
                      <option value="factory_error">Factory Error (Concealed Overages or Shortages)</option>
                      <option value="kitting">Kitting</option>
                      <option value="liquidation_exit">Liquidation</option>
                      <option value="manual_order">Manual Order / Shipment</option>
                      <option value="merchandise_transfer_to_rdc">Outbound Warehouse Transfer</option>
                      <option value="product_swap">Product Swap / Changeout</option>
                      <option value="return_to_vendor">Return to Shipper</option>
                      <option value="warehouse_damaged">Warehouse Damaged</option>
                      <option value="warehouse_salvage">Warehouse Salvage</option>
                      <option value="warehouse_theft">Warehouse Theft</option>
                      <option value="quality_disposition">Quality Disposition</option>
                      <option value="flexe_side_inv_adjustment">Flexe internal - one-sided adjustment</option>
                      <option value="vendor_damaged">Vendor Damaged</option>
                      <option value="vendor_overage">Vendor Overage</option>
                      <option value="adjust_past_receipt">Adjust Past Receipt</option>
                    </optgroup>
                    <optgroup>
                      <option value="general">General (Other)</option>
                    </optgroup>
                  </Select>
                </FormControl>
                <div>
                  <p>Purchase order Id</p>
                  <TextField
                    autoFocus={true}
                    multiline={true}
                    rows={1}
                    value={this.state.editRelatedWhPurchaseOrder}
                    onChange={this.updateEditRelatedWhPurchaseOrder}
                  />
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={this.closeReviewAndSaveDialog}>
              Cancel
            </Button>
            <SubmitButton
              type={FLAT_BTN}
              label="Submit"
              showSpinner={this.state.submittingChanges}
              onClick={this.submitChanges}
              disabled={
                this.state.editDescription.length === 0 ||
                this.state.submittingChanges ||
                (['adjust_past_receipt', 'vendor_overage', 'vendor_damaged', 'placeholder'].includes(
                  this.state.editReasonCode,
                ) &&
                  this.state.editRelatedWhPurchaseOrder.length === 0)
              }
            />
          </DialogActions>
        </Dialog>

        <Dialog open={this.state.showLpnCorrectionsDialog}>
          <DialogTitle>Correct a bad LPN Inbound</DialogTitle>
          <DialogContent>
            <div>
              <p>
                <strong>Important Notes:</strong>
              </p>
              <ul>
                <li>The changes will be submitted immediately upon submitting this file.</li>
                <li>It is possible for part of the CSV to succeed.</li>
                <li>
                  It is recommended that you submit no more than 200 changes at at time.
                  <br />
                  If you need to submit more than this, please partition your files into batches.
                </li>
              </ul>
              <p>
                <strong>Common errors and their possible meanings:</strong>
              </p>
              <dl>
                <dt>One or more required headers are missing</dt>
                <dd>The uploaded CSV didn't have reservation, lpn, or sku specified. Did you upload the wrong CSV?</dd>
                <dt>
                  LPN not found - failed to lock: [{'{'}:lpn_barcode=&gt;"47-FX-3b119403"{'}'}]
                </dt>
                <dd>This probably means that the LPN has already been outbounded. Check the movement log.</dd>
                <dt>
                  SKU not found {'{'}:company_id=&gt;123, :sku=&gt;'SKU-123'{'}'}
                </dt>
                <dd>
                  No item master entry was found that corresponds to the reservation's company and SKU. Did you mistype
                  the SKU or specify the wrong reservation?
                </dd>
                <dt>
                  LPN not found {'{'}:reservation_id=&gt;1, :inventory_id=&gt;45, :lpn_barcode=&gt; "47-FX-3b119403"
                  {'}'}
                </dt>
                <dd>
                  No LPN was found with these filters applied. Look up the LPN on this page, and make sure that its
                  reservation and SKU matches what is in the CSV.
                </dd>
              </dl>
            </div>
            <div>
              <p>Select CSV:</p>
              <input type="file" onChange={this.parseLpnCsv} accept="text/csv" />
            </div>
            <hr />
            <div>
              <p>Supported fields:</p>
              <table className="pure-table">
                <thead>
                  <tr>
                    <th>Header</th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <code>reservation</code>
                    </td>
                    <td>
                      <p>The ID of the reservation</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <code>lpn</code>
                    </td>
                    <td>
                      <p>The sequence of letters and numbers on an LPN label (e.g. "25-FX-34fbdac")</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <code>sku</code>
                    </td>
                    <td>
                      <p>The company-specific ID for an entry in the item master. Must be in the specified LPN.</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <code>target_sku</code>
                    </td>
                    <td>
                      <p>
                        <i>(optional)</i>
                        <br />
                        If specified, will change the LPN's SKU to the specified SKU.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <code>target_dropoff_id</code>
                    </td>
                    <td>
                      <p>
                        <i>(optional)</i>
                        <br />
                        If specified, will change the container delivery the LPN came in on. No validation is done on
                        this number.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <code>target_quantity</code>
                    </td>
                    <td>
                      <p>
                        <i>(optional)</i>
                        <br />
                        If specified, will change the LPN quantity to the target value. This updates both audit logs and
                        the actual LPN.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <code>target_expiration_date</code>
                    </td>
                    <td>
                      <p>
                        <i>(optional)</i>
                        <br />
                        If specified, will change the LPN's expiration date to the specified value. Required format:
                        2019-12-31 (ie. YYYY-MM-DD)
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <code>target_lot_code</code>
                    </td>
                    <td>
                      <p>
                        <i>(optional)</i>
                        <br />
                        If specified, will change the LPN's lot code to the specified value.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <code>target_manufacture_date</code>
                    </td>
                    <td>
                      <p>
                        <i>(optional)</i>
                        <br />
                        If specified, will change the LPN's manufacture date to the specified value. Required format:
                        2019-12-31 (ie. YYYY-MM-DD)
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <code>target_asn_number</code>
                    </td>
                    <td>
                      <p>
                        <i>(optional)</i>
                        <br />
                        If specified, will change the LPN's ASN number to the specified value.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <code>target_po_number</code>
                    </td>
                    <td>
                      <p>
                        <i>(optional)</i>
                        <br />
                        If specified, will change the LPN's PO number to the specified value.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <code>target_custom_reference_1</code>
                    </td>
                    <td>
                      <p>
                        <i>(optional)</i>
                        <br />
                        If specified, will change the LPN's first custom reference field to the specified value.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <code>target_custom_reference_2</code>
                    </td>
                    <td>
                      <p>
                        <i>(optional)</i>
                        <br />
                        If specified, will change the LPN's second custom reference field to the specified value.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <code>target_country_of_origin</code>
                    </td>
                    <td>
                      <p>
                        <i>(optional)</i>
                        <br />
                        If specified, will change the LPN's country of origin to the specified value.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <code>target_origin_site</code>
                    </td>
                    <td>
                      <p>
                        <i>(optional)</i>
                        <br />
                        If specified, will change the LPN's origin site to the specified value.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <code>target_eaches_per_inner_pack</code>
                    </td>
                    <td>
                      <p>
                        <i>(optional)</i>
                        <br />
                        If specified, will change the custom UoMs Inner Pack.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <code>target_eaches_per_outer_case</code>
                    </td>
                    <td>
                      <p>
                        <i>(optional)</i>
                        <br />
                        If specified, will change the custom UoMs Outer Case.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <code>target_packaging</code>
                    </td>
                    <td>
                      <p>
                        <i>(optional)</i>
                        <br />
                        If specified, will change the packaging. only each/carton supported
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={this.hideLpnCorrectionsDialog}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        <OverlayMessage
          type={OverlayType.success}
          show={this.state.showSuccessOverlay}
          onHide={this.hideSuccessOverlay}
        >
          {this.state.showSuccessMessage}
        </OverlayMessage>

        <OverlayMessage type={OverlayType.error} show={this.state.showErrorOverlay} onHide={this.hideErrorOverlay}>
          <div>
            <p>There were some problems processing your edits</p>
            <a href="#">View Issues</a>
          </div>
        </OverlayMessage>
      </div>
    );
  }

  // emulating Object.entries because flow does not handle it well currently
  private objEntries = (obj: any) => {
    const ownProps = Object.keys(obj);
    let i = ownProps.length;
    const resArray = new Array(i);
    while (i--) {
      resArray[i] = [ownProps[i], obj[ownProps[i]]];
    }
    return resArray;
  };

  private applyFilters = (filterKey: string, filters: string[]) => {
    if (filterKey === 'sku') {
      this.setState({ skuFilters: filters });
    } else if (filterKey === 'location') {
      this.setState({ locationFilters: filters });
    } else if (filterKey === 'lpn') {
      this.setState({ lpnFilters: filters });
    }
  };

  private reservationOrWarehouseSelected = (chosenRequest: ReservationAndScopeInfo) => {
    // We need chosenRequest later to make another request after submitting the sku quantity updates
    this.setState({ chosenRequest });

    if (this.state.totalEdits > 0) {
      this.setState({
        currentPage: 0,
        showClearEditsDialog: true,
        clearEditsDialogContent: 'Switching location methods will clear all pending edits.',
      });
    }
  };

  private refreshLocations = () => {
    if (this.state.chosenRequest) {
      this.fetchLocations();
    }
  };

  private fetchLocations(continuationToken?: string) {
    const token = continuationToken || null;
    const skuFilters = this.state.skuFilters.length > 0 ? this.state.skuFilters : null;
    const locationFilters = this.state.locationFilters.length > 0 ? this.state.locationFilters : null;
    const lpnFilters = this.state.lpnFilters.length > 0 ? this.state.lpnFilters : null;

    let locationsPromise;

    if (this.state.findLocationsBy === 'Reservation' && this.state.chosenRequest.id) {
      const resId = get(this.state.chosenRequest, 'id');
      locationsPromise = flexeApi.getLocationsByReservation(resId, token, skuFilters, locationFilters, lpnFilters);
    } else {
      const whId = get(this.state.chosenRequest, 'id');
      locationsPromise = flexeApi.getLocationsByWarehouse(whId, token, skuFilters, locationFilters, lpnFilters);
    }

    this.setState({
      fetchingLocations: true,
      totalEdits: 0,
      editMode: false,
      showClearEditsDialog: false,
      locationErrors: {},
      locationErrorsCount: 0,
      pendingEditsOnly: false,
    });

    return locationsPromise
      .then((response) => {
        // NOTE: the node server passes through back-end responses as-is.  They will
        // put a "statusCode" item as a top-level element and then nest error messages
        // like so:
        //
        // { statusCode: 500,
        //   error: { msg: "some message" } }
        //
        // This error handling was tacked onto the existing error handling logic as
        // part of CORE-1659 which led to a blank screen if any problems occurred.
        //
        let locations = [];
        let errorMessage = null;
        const continuationTokens: string[] = this.state.continuationTokens;
        if (response.statusCode && (response.statusCode < 200 || response.statusCode > 299)) {
          if (response.error && response.error.msg) {
            errorMessage = response.error.msg;
          } else {
            errorMessage = 'an unknown error occurred';
          }
        } else if (response.error) {
          errorMessage = response.error;
        } else {
          const returnedLocations = get(response.data, 'locationContents');
          const returnedContinuationToken: string = get(response.data, 'continuationToken');
          if (
            returnedContinuationToken &&
            (continuationTokens.length < 1 ||
              continuationTokens[continuationTokens.length - 1] !== returnedContinuationToken)
          ) {
            continuationTokens.push(returnedContinuationToken);
          }
          locations = returnedLocations.map((location: Location, idx: number) => {
            return {
              ...location,
              locationsArrayIndex: idx,
            };
          });
        }
        this.setState({ locations, continuationTokens, errorMessage, fetchingLocations: false });
      })
      .catch(({ error }) => {
        this.setState({
          errorMessage: error,
          fetchingLocations: false,
        });
      });
  }

  private handlePagination = (event) => {
    const page = parseInt(event.currentTarget.getAttribute('data-page'), 10);

    this.setState({ currentPage: page });
    this.fetchLocations(page > 1 ? this.state.continuationTokens[page - 2] : null);
  };

  private enterEditMode = () => {
    this.setState({ editMode: true });
  };

  private exitEditMode = () => {
    if (this.state.totalEdits > 0) {
      this.setState({
        showClearEditsDialog: true,
        clearEditsDialogContent: 'Are you sure you want to clear all pending edits?',
      });
    } else {
      this.setState({ editMode: false });
      this.removePendingLocationContents();
    }
  };

  private closeClearEditsDialog = () => {
    this.setState({ showClearEditsDialog: false });
  };

  private addLPNCsvError = (index: number, sku: string, lpn: string, msg: string) => {
    const errors = cloneDeep(this.state.csvErrors);
    if (!errors.hasOwnProperty(index)) {
      errors[index] = { sku, lpn, packaging: null, errors: [msg] };
    } else {
      errors[index].errors.push(msg);
    }
    this.setState({ csvErrors: errors });
  };

  private addQtyCsvError = (index: number, sku: string, packaging: string, msg: string) => {
    const errors = cloneDeep(this.state.csvErrors);
    if (!errors.hasOwnProperty(index)) {
      errors[index] = { sku, lpn: null, packaging, errors: [msg] };
    } else {
      errors[index].errors.push(msg);
    }
    this.setState({ csvErrors: errors });
  };

  private parseQtyCsv = (event) => {
    const target = event.target as HTMLInputElement;
    const file = target.files[0];
    let locations;
    if (file) {
      locations = cloneDeep(this.state.locations);
      papaparse.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          const rows = results.data;
          // eslint-disable-next-line complexity -- Disabled pre-existing violation of complexity rule
          rows.forEach((csvRow, index) => {
            // If every value in the row is empty, just skip it.
            if (values(csvRow).every((value) => value === '')) {
              return;
            }
            const requiredColumns = ['sku', 'delta', 'packaging'];
            if (requiredColumns.some((c) => !csvRow.hasOwnProperty(c))) {
              let headerError = 'One or more required headers are missing: ';
              if (csvRow.sku === undefined || !!csvRow.sku) {
                headerError += ' (sku is required)';
              }
              if (csvRow.delta === undefined || !!csvRow.delta) {
                headerError += ' (delta is required)';
              }
              if (csvRow.packaging === undefined || !!csvRow.packaging) {
                headerError += ' (packaging is required)';
              }
              csvRow.sku = 'Not specified.';
              csvRow.packaging = 'Not specified.';
              csvRow.delta = 0;
              this.addQtyCsvError(index, csvRow.sku, csvRow.packaging, headerError);
              return;
            }
            //If optional headers passed in csv are not the one's expected
            Object.keys(csvRow).forEach((key) => {
              if (
                !(
                  key === 'sku' ||
                  key === 'delta' ||
                  key === 'packaging' ||
                  key === 'lpn_barcode' ||
                  key === 'location'
                )
              ) {
                const incorrectHeaderError = 'Optional header cannot be "' + key + '"';
                this.addQtyCsvError(index, csvRow.sku, csvRow.packaging, incorrectHeaderError);
                return;
              }
            });
            if (isEmpty(csvRow.delta.trim())) {
              this.addQtyCsvError(index, csvRow.sku, csvRow.packaging, 'Delta value cannot be empty');
              return;
            }
            const delta = parseInt(csvRow.delta, 10);
            if (isNaN(delta)) {
              this.addQtyCsvError(index, csvRow.sku, csvRow.packaging, 'Delta value was not an integer');
              return;
            }

            csvRow.packaging = csvRow.packaging && csvRow.packaging.toLowerCase();
            const allowedPackaging = [InventoryPackaging.each, InventoryPackaging.carton, InventoryPackaging.pallet];
            if (!allowedPackaging.includes(csvRow.packaging)) {
              this.addQtyCsvError(index, csvRow.sku, csvRow.packaging, 'Packaging must be each, carton, or pallet.');
              return;
            }
            if (csvRow.hasOwnProperty('lpn_barcode') && !csvRow.hasOwnProperty('location')) {
              this.addQtyCsvError(index, csvRow.sku, csvRow.packaging, 'If LPN is provided, location must be provided');
              return;
            }

            let locationContent;
            if (csvRow.location) {
              // if location is explicitly specified...
              const selectLocation = () => {
                locations = cloneDeep(this.state.locations);
                return locations.find((loc) => {
                  return (
                    !loc.is_default_location &&
                    loc.sku &&
                    loc.sku.toLowerCase() === csvRow.sku.toLowerCase() &&
                    loc.location_label.toLowerCase() === csvRow.location.toLowerCase() &&
                    ((!loc.lpn_barcode && !csvRow.lpn_barcode) ||
                      (loc.lpn_barcode &&
                        csvRow.lpn_barcode &&
                        loc.lpn_barcode.toLowerCase() === csvRow.lpn_barcode.toLowerCase())) &&
                    loc.pallet_id === null
                  );
                });
              };
              locationContent = selectLocation();
              if (!locationContent) {
                if (csvRow.delta > 0) {
                  this.addLocationContent(csvRow.location, csvRow.sku, csvRow.lpn_barcode, csvRow.packaging, 0);
                } else {
                  this.addLocationContent(
                    csvRow.location,
                    csvRow.sku,
                    csvRow.lpn_barcode,
                    csvRow.packaging,
                    delta * -1,
                  );
                }
                locationContent = selectLocation();
              }
            } else {
              // ...otherwise make a best effort selection (typical case for automove reservations)
              const locationContents = locations.filter(
                (loc) =>
                  !loc.is_default_location &&
                  loc.sku &&
                  loc.sku.toLowerCase() === csvRow.sku.toLowerCase() &&
                  ((!loc.lpn_barcode && !csvRow.lpn_barcode) ||
                    (loc.lpn_barcode &&
                      csvRow.lpn_barcode &&
                      loc.lpn_barcode.toLowerCase() === csvRow.lpn_barcode.toLowerCase())) &&
                  loc.pallet_id === null,
              );
              if (locationContents.length > 1) {
                this.addQtyCsvError(
                  index,
                  csvRow.sku,
                  csvRow.packaging,
                  `SKU is stored in more than one location - either specify locations to adjust or
                    ensure that SKU only occurs once per file.`,
                );
                return;
              }
              locationContent = locationContents[0];

              const sameSkuRows = rows.filter((row) => row.sku.toLowerCase() === csvRow.sku.toLowerCase());
              if (sameSkuRows.length > 1) {
                this.addQtyCsvError(
                  index,
                  csvRow.sku,
                  csvRow.packaging,
                  'SKU may only appear once per file if no locations specified.',
                );
              }
            }

            // The index of this location in the locations array (for quickly finding and updating)
            const locationsArrayIndex = locationContent.locationsArrayIndex;

            // The original SKU quantity from warehouser
            const origQty = locationContent.quantity;

            // conversions: The SKU's packaging conversions object
            const conversions = locationContent.conversions;
            // origPackaging: The original SKU packaging from warehouser
            const origPackaging = locationContent.packaging;
            // currentPackaging: The current SKU packaging being used in the browser
            const currentPackaging = origPackaging;
            // newPackaging: The SKU packaging currently provided from CSV (not yet applied to the location object)
            const newPackaging = csvRow.packaging;

            // origConversion: The conversion previously applied (if any)
            const origConversion = get(conversions, `${origPackaging}.${newPackaging}`) || 1;
            // currentConversion: The current conversion (if any)
            const currentConversion = get(conversions, `${currentPackaging}.${newPackaging}`) || 1;

            // convertedOrigQty: The previous conversion applied to the original SKU quantity (for display)
            const convertedOrigQty = origQty * origConversion;

            // newSkuQty: The current conversion applied to the current quantity (for updating)
            let newSkuQty = origQty * currentConversion + delta;

            if (newSkuQty < 0) {
              newSkuQty = 0;
            }

            if (!this.state.csvErrors[index]) {
              this.updateSkuQty(locationsArrayIndex, convertedOrigQty, newSkuQty, newPackaging);
            }
          });

          this.setState({ showCsvDialog: false });
          if (isEmpty(this.state.csvErrors)) {
            this.enterEditMode();
            this.reviewAndSaveChanges();
          } else {
            this.showCsvErrorsDialog();
          }
        },
      });
    }
  };

  private updateSkuQty = (locIndex: number, convertedOrigQty: number, newQty: number, newPkg: InventoryPackaging) => {
    const locations = cloneDeep(this.state.locations);
    if (locIndex >= 0) {
      // If the new packaging is the same as the original, delete the newPackaging property
      if (locations[locIndex].packaging === newPkg) {
        delete locations[locIndex].newPackaging;
      } else if (newPkg) {
        locations[locIndex].newPackaging = newPkg;
      }

      // If the new quantity is the same as the original, delete the newQuantity property so it
      // doesn't show up as a pending edit
      if (convertedOrigQty === newQty && locations[locIndex].packaging === newPkg) {
        delete locations[locIndex].newQuantity;
      } else {
        locations[locIndex].newQuantity = newQty;
      }

      // Remove any error messages
      const locationErrors = cloneDeep(this.state.locationErrors);
      delete locationErrors[locIndex];
      const locationErrorsCount = Object.keys(locationErrors).length;

      // Count how many edits there are
      const totalEdits = locations.reduce((editsCount: number, location: LocationInfo) => {
        if (
          (typeof location.newQuantity !== 'undefined' && location.newQuantity !== null && location.newQuantity >= 0) ||
          location.newPackaging
        ) {
          return editsCount + 1;
        }
        return editsCount;
      }, 0);

      // If total skus being edited goes to 0, turn off the pending edits only toggle
      let pendingEditsOnly = this.state.pendingEditsOnly;
      if (totalEdits === 0) {
        pendingEditsOnly = false;
      }

      this.setState({
        locations,
        totalEdits,
        locationErrors,
        locationErrorsCount,
        pendingEditsOnly,
      });
    }
  };

  private addLocationContent = (
    label: string,
    sku: string,
    lpnBarcode: string,
    packaging: InventoryPackaging,
    quantity: number,
  ) => {
    const locations = cloneDeep(this.state.locations);
    const newLocationContent: LocationInfo = {
      pendingCreation: true,
      is_default_location: false,
      locationsArrayIndex: 0,
      location_label: label,
      packaging,
      lpn_barcode: lpnBarcode,
      pallet_id: null,
      quantity,
      // TODO - store selected reservation ID in index state when applying filters
      reservation_id: locations[0].reservation_id,
      sku,
    };

    const sameLocation = locations.find(
      (l) =>
        l.location_label.toLowerCase() === label.toLowerCase() &&
        ((l.lpn_barcode && l.lpn_barcode === lpnBarcode) ||
          (l.lpn_barcode && lpnBarcode && l.lpn_barcode.toLowerCase() === lpnBarcode.toLowerCase())),
    );
    if (sameLocation) {
      locations.splice(sameLocation.locationsArrayIndex, 0, newLocationContent);
    } else {
      locations.push(newLocationContent);
    }

    locations.forEach((l, i) => (l.locationsArrayIndex = i));

    this.setState({ locations });
  };

  private removePendingLocationContents = () => {
    let locations = cloneDeep(this.state.locations);
    locations = locations.filter((l) => !l.pendingCreation);
    this.setState({ locations });
  };

  private updateEditDescription = (event) => {
    this.setState({ editDescription: event.target.value });
  };

  private updateEditReasonCode = (event) => {
    const editReasonCode = get(event, 'target.value');
    this.setState({ editReasonCode });
  };

  private updateEditRelatedWhPurchaseOrder = (event) => {
    this.setState({ editRelatedWhPurchaseOrder: event.target.value });
  };

  private reviewAndSaveChanges = () => {
    this.setState({
      showReviewAndSaveDialog: true,
      editDescription: '',
      editReasonCode: '',
      editRelatedWhPurchaseOrder: '',
      pendingEdits: this.state.locations.filter((location: LocationInfo) => {
        return (
          (typeof location.newQuantity !== 'undefined' && location.newQuantity !== null && location.newQuantity >= 0) ||
          location.newPackaging
        );
      }),
    });
  };

  private closeReviewAndSaveDialog = () => {
    this.setState({ showReviewAndSaveDialog: false });
  };

  private submitChanges = () => {
    this.setState({ submittingChanges: true });
    flexeApi
      .bulkUpdateLocations({
        updates: this.state.pendingEdits.map((edit) => {
          let conversion = 1;
          if (edit.newPackaging) {
            conversion = edit.conversions[edit.packaging][edit.newPackaging];
          }
          return {
            location_id: edit.location_id,
            location_label: edit.location_label,
            inventory_id: edit.inventory_id,
            sku: edit.sku,
            lpn_barcode: edit.lpn_barcode,
            reservation_id: edit.reservation_id,
            delta: edit.newQuantity - edit.quantity * conversion,
            packaging: edit.newPackaging || edit.packaging,
          };
        }),
        description: this.state.editDescription,
        reason_code: this.state.editReasonCode,
        purchase_order_number: this.state.editRelatedWhPurchaseOrder,
      })
      .then((response) => {
        let refreshNotice = false;
        let showSuccessOverlay = false;
        let showErrorOverlay = false;
        if (response.statusCode === 205 && this.state.chosenRequest) {
          showSuccessOverlay = true;
          if (this.state.chosenRequest) {
            this.fetchLocations();
          }
        } else {
          // 428 means the quantity changed on the backend before submission, so we should prompt
          // the user to refresh the locations data
          if (response.statusCode === 428) {
            refreshNotice = true;
          }
          showErrorOverlay = true;
          const errors = get(response, 'data.message.errors');
          const locationErrorsCount = Object.keys(errors).length;
          if (errors && locationErrorsCount) {
            const locationErrors = {};
            this.state.pendingEdits.forEach((edit, idx: number) => {
              const editErrors = errors[`update[${idx}]`];
              if (editErrors) {
                locationErrors[edit.locationsArrayIndex] = editErrors;
              }
            });
            this.setState({ locationErrors, locationErrorsCount });
          }
        }
        this.setState({
          refreshNotice,
          showErrorOverlay,
          showReviewAndSaveDialog: false,
          showSuccessOverlay,
          showSuccessMessage: 'Quantities Successfully Updated!',
          submittingChanges: false,
        });
      })
      .catch(({ error }) => {
        this.setState({
          errorMessage: error,
          showReviewAndSaveDialog: false,
        });
      });
  };

  private parseLpnCsv = (event) => {
    const target = event.target as HTMLInputElement;
    const file = target.files[0];
    if (file) {
      papaparse.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          const rows = results.data;
          const lpnCorrections: LpnCorrection[] = [];
          // eslint-disable-next-line complexity -- Disabled pre-existing violation of complexity rule
          rows.forEach((csvRow, index) => {
            // If every value in the row is empty, just skip it.
            if (values(csvRow).every((value) => value === '')) {
              return;
            }
            const requiredColumns = ['reservation', 'lpn', 'sku'];
            if (requiredColumns.some((c) => !csvRow.hasOwnProperty(c))) {
              let headerError = 'One or more required headers are missing: ';
              if (csvRow.reservation === undefined || !!csvRow.reservation) {
                headerError += ' (reservation is required)';
              }
              if (csvRow.lpn === undefined || !!csvRow.lpn) {
                headerError += ' (lpn is required)';
              }
              if (csvRow.sku === undefined || !!csvRow.sku) {
                headerError += ' (sku is required)';
              }
              this.addLPNCsvError(index, csvRow.sku, csvRow.lpn, headerError);
              return;
            }
            const optionalColumns = [
              'target_sku',
              'target_dropoff_id',
              'target_quantity',
              'target_expiration_date',
              'target_lot_code',
              'target_manufacture_date',
              'target_asn_number',
              'target_po_number',
              'target_custom_reference_1',
              'target_custom_reference_2',
              'target_country_of_origin',
              'target_origin_site',
              'target_eaches_per_inner_pack',
              'target_eaches_per_outer_case',
              'target_packaging',
            ];
            if (!optionalColumns.some((c) => csvRow.hasOwnProperty(c))) {
              this.addLPNCsvError(index, csvRow.sku, csvRow.lpn, 'At least one optional column must be specified');
              return;
            }
            // If optional headers passed in csv are not the one's expected
            // eslint-disable-next-line complexity -- Disabled pre-existing violation of complexity rule
            Object.keys(csvRow).forEach((key) => {
              if (
                !(
                  key === 'reservation' ||
                  key === 'lpn' ||
                  key === 'sku' ||
                  key === 'target_sku' ||
                  key === 'target_dropoff_id' ||
                  key === 'target_quantity' ||
                  key === 'target_expiration_date' ||
                  key === 'target_lot_code' ||
                  key === 'target_manufacture_date' ||
                  key === 'target_asn_number' ||
                  key === 'target_po_number' ||
                  key === 'target_custom_reference_1' ||
                  key === 'target_custom_reference_2' ||
                  key === 'target_country_of_origin' ||
                  key === 'target_origin_site' ||
                  key === 'target_eaches_per_inner_pack' ||
                  key === 'target_eaches_per_outer_case' ||
                  key === 'target_packaging'
                )
              ) {
                const incorrectHeaderError = 'Optional header cannot be "' + key + '"';
                this.addLPNCsvError(index, csvRow.sku, csvRow.lpn, incorrectHeaderError);
                return;
              }
            });

            const parsedLpn = csvRow.lpn.trim();
            const parsedSku = csvRow.sku.trim();

            const parsedReservation = parseInt(csvRow.reservation, 10);
            if (isNaN(parsedReservation)) {
              this.addLPNCsvError(index, csvRow.sku, csvRow.lpn, 'Reservation must be a number');
              return;
            }

            const dropoff = get(csvRow, 'target_dropoff_id');
            const parsedDropoff = dropoff ? parseInt(dropoff, 10) : null;
            if (dropoff && isNaN(parsedDropoff)) {
              this.addLPNCsvError(index, csvRow.sku, csvRow.lpn, 'Dropoff ID must be a number');
              return;
            }

            const quantity = get(csvRow, 'target_quantity');
            const parsedQuantity = quantity ? parseInt(quantity, 10) : null;
            if (quantity && isNaN(parsedQuantity)) {
              this.addLPNCsvError(index, csvRow.sku, csvRow.lpn, 'Quantity must be a number');
              return;
            }

            const expiry = get(csvRow, 'target_expiration_date');
            const parsedExpiry = expiry ? new Date(expiry) : null;
            if (expiry && isNaN(parsedExpiry.getTime())) {
              this.addLPNCsvError(
                index,
                csvRow.sku,
                csvRow.lpn,
                'Expiration date is invalid (valid example: yyyy-mm-dd)',
              );
              return;
            }

            let parsedTargetSku;
            if (csvRow.target_sku && csvRow.target_sku.length > 0) {
              parsedTargetSku = csvRow.target_sku.trim();
            } else {
              parsedTargetSku = null;
            }

            let parsedLotCode;
            if (csvRow.target_lot_code && csvRow.target_lot_code.length > 0) {
              parsedLotCode = csvRow.target_lot_code.trim();
            } else {
              parsedLotCode = null;
            }

            const manufactureDate = get(csvRow, 'target_manufacture_date');
            const parsedManufactureDate = manufactureDate ? new Date(manufactureDate) : null;
            if (manufactureDate && isNaN(parsedManufactureDate.getTime())) {
              this.addLPNCsvError(
                index,
                csvRow.sku,
                csvRow.lpn,
                'Manufacture date is invalid ' + '(valid example: yyyy-mm-dd)',
              );
              return;
            }

            let parsedTargetAsnNumber;
            if (csvRow.target_asn_number && csvRow.target_asn_number.length > 0) {
              parsedTargetAsnNumber = csvRow.target_asn_number.trim();
            } else {
              parsedTargetAsnNumber = null;
            }

            let parsedTargetPoNumber;
            if (csvRow.target_po_number && csvRow.target_po_number.length > 0) {
              parsedTargetPoNumber = csvRow.target_po_number.trim();
            } else {
              parsedTargetPoNumber = null;
            }

            let parsedTargetCustomReference1;
            if (csvRow.target_custom_reference_1 && csvRow.target_custom_reference_1.length > 0) {
              parsedTargetCustomReference1 = csvRow.target_custom_reference_1.trim();
            } else {
              parsedTargetCustomReference1 = null;
            }

            let parsedTargetCustomReference2;
            if (csvRow.target_custom_reference_2 && csvRow.target_custom_reference_2.length > 0) {
              parsedTargetCustomReference2 = csvRow.target_custom_reference_2.trim();
            } else {
              parsedTargetCustomReference2 = null;
            }

            let parsedTargetCountryOfOrigin;
            if (csvRow.target_country_of_origin && csvRow.target_country_of_origin.length > 0) {
              parsedTargetCountryOfOrigin = csvRow.target_country_of_origin.trim();
            } else {
              parsedTargetCountryOfOrigin = null;
            }

            let parsedTargetOriginSite;
            if (csvRow.target_origin_site && csvRow.target_origin_site.length > 0) {
              parsedTargetOriginSite = csvRow.target_origin_site.trim();
            } else {
              parsedTargetOriginSite = null;
            }

            const eachesPerInnerPack = get(csvRow, 'target_eaches_per_inner_pack');
            const parsedEachesPerInnerPack = eachesPerInnerPack ? parseInt(eachesPerInnerPack, 10) : null;
            if (eachesPerInnerPack && isNaN(parsedEachesPerInnerPack)) {
              this.addLPNCsvError(index, csvRow.sku, csvRow.lpn, 'Eaches Per Inner Pack must be a number');
              return;
            }

            const eachesPerOuterCase = get(csvRow, 'target_eaches_per_outer_case');
            const parsedEachesPerOuterCase = eachesPerOuterCase ? parseInt(eachesPerOuterCase, 10) : null;
            if (eachesPerOuterCase && isNaN(parsedEachesPerOuterCase)) {
              this.addLPNCsvError(index, csvRow.sku, csvRow.lpn, 'Eaches Per Outer Case must be a number');
              return;
            }

            const packaging = get(csvRow, 'target_packaging');
            const parsedPackaging = packaging ? packaging.toLowerCase() : null;
            if (packaging && !['carton', 'each'].includes(parsedPackaging)) {
              this.addLPNCsvError(index, csvRow.sku, csvRow.lpn, 'only carton or each supported');
              return;
            }

            const duplicateLpnRows = rows.filter((row) => row.lpn === csvRow.lpn && row.sku === csvRow.sku);
            if (duplicateLpnRows.length > 1) {
              this.addLPNCsvError(index, csvRow.sku, csvRow.lpn, 'Same LPN may only show up once in a file');
              return;
            }

            lpnCorrections.push({
              reservationId: parsedReservation,
              lpn: parsedLpn,
              currentSku: parsedSku,
              targetSku: parsedTargetSku,
              targetShipmentId: parsedDropoff,
              targetQuantity: parsedQuantity,
              targetLotCode: parsedLotCode,
              targetExpirationDate: parsedExpiry,
              targetManufactureDate: parsedManufactureDate,
              targetAsnNumber: parsedTargetAsnNumber,
              targetPoNumber: parsedTargetPoNumber,
              targetCustomReference1: parsedTargetCustomReference1,
              targetCustomReference2: parsedTargetCustomReference2,
              targetCountryOfOrigin: parsedTargetCountryOfOrigin,
              targetOriginSite: parsedTargetOriginSite,
              targetEachesPerInnerPack: parsedEachesPerInnerPack,
              targetEachesPerOuterCase: parsedEachesPerOuterCase,
              targetPackaging: parsedPackaging,
            });
          });

          this.setState({ showLpnCorrectionsDialog: false });
          if (!isEmpty(this.state.csvErrors)) {
            this.showCsvErrorsDialog();
          } else {
            this.submitLpnCorrections(lpnCorrections);
          }
        },
      });
    }
  };

  private submitLpnCorrections = (corrections: LpnCorrection[]) => {
    this.setState({ submittingLpnCorrections: true });
    flexeApi
      .bulkCorrectLpns(corrections)
      .then((response) => {
        this.setState({ submittingLpnCorrections: false });

        const errors = response.flatMap((resp, index) => {
          const err = resp.error || [];
          const fmtErr = err.map((e) => ({ index, message: e.detail }));
          return fmtErr;
        });

        if (errors.length > 0) {
          errors.forEach((err) => {
            const correction = corrections[err.index];
            this.addLPNCsvError(err.index, correction.currentSku, correction.lpn, err.message);
          });
          this.showCsvErrorsDialog();
        } else {
          this.setState({ showSuccessOverlay: true, showSuccessMessage: 'LPNs Successfully Corrected!' });
        }
      })
      .catch(({ error }) => {
        this.setState({ submittingLpnCorrections: false, errorMessage: error });
      });
  };

  private showCsvErrorsDialog = () => {
    this.refreshLocations();
    this.setState({ showCsvErrorsDialog: true });
  };

  private hideCsvErrorsDialog = () => {
    this.setState({ csvErrors: {}, showCsvErrorsDialog: false });
  };

  private showCsvDialog = () => {
    this.setState({ showCsvDialog: true });
  };

  private hideCsvDialog = () => {
    this.setState({ showCsvDialog: false });
  };

  private showLpnCorrectionsDialog = () => {
    this.setState({ showLpnCorrectionsDialog: true });
  };

  private hideLpnCorrectionsDialog = () => {
    this.setState({ showLpnCorrectionsDialog: false });
  };

  private hideSuccessOverlay = () => {
    this.setState({ showSuccessOverlay: false });
  };

  private hideErrorOverlay = () => {
    this.setState({ showErrorOverlay: false });
  };

  private renderErrorMessage(errorMessage?: string) {
    if (errorMessage) {
      return <ErrorMessage>{errorMessage}</ErrorMessage>;
    }
  }

  private toggleShowPendingEditsOnly = (event: Event, inputChecked: boolean) => {
    this.setState({ pendingEditsOnly: inputChecked });
  };
}

export default Locations;
