/* eslint-disable max-lines -- Disabled pre-existing violation of max lines rule */
import * as React from 'react';
import { addDays, format as formatDate, formatISO, parseISO as parseDate, subDays } from 'date-fns';
import AppBar from '@material-ui/core/AppBar';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { cloneDeep, get } from 'lodash';
import flexeApi from '../../lib/flexeApi';
import PageLoadSpinner from '../../components/PageLoadSpinner/';
import ErrorMessage from '../../components/ErrorMessage';
import FilterList from '../../components/FilterList';
import Pagination from '../../components/Pagination';

import { FLAT_BTN, PACKAGING_TYPE_MAP } from '../../lib/constants';
import { blueDark, blueLight, errorRed, white } from '../../styles/flexeColors';
import tabStyles from '../../styles/flexeTabs';
import './styles.css';

const styles = {
  chip: {
    main: {
      width: '100%',
      marginBottom: 20,
    },
    label: {
      width: '100%',
    },
  },
  editModeChip: {
    deleteIcon: {
      fill: blueDark,
    },
  },
  errorChip: {
    deleteIcon: {
      fill: white,
    },
  },
};

const pageSize = 200;

interface DlqMessage {
  id: string;
  messageId: string;
  correlationId: string;
  createdAt: string;
  subscriptionName: string;
  topicName: string;
  isArchived: boolean;
  resent: boolean;
  messageBody: string;
  messageAttributes: object;
}

interface DlqState {
  continuationTokens: string[];
  errors: string[];
  currentTab: string;
  currentPage: number;
  filters: { [key: string]: string[] | boolean };
  loading: boolean;
  messages: DlqMessage[];
  selectedMessages: DlqMessage[];
  messageToEdit: DlqMessage;
  totalCount: number;
  showEditMessageDialog: boolean;
  redrivingMessage: boolean;
}

class Dlq extends React.Component<null, DlqState> {
  constructor(props) {
    super(props);

    this.state = {
      continuationTokens: [],
      errors: null,
      currentTab: 'new',
      currentPage: 1,
      filters: {
        archived: false,
        messageId: null,
        subscriptionId: null,
        topicId: null,
        startDate: null,
        endDate: null,
      },
      loading: true,
      messages: [],
      selectedMessages: [],
      messageToEdit: null,
      totalCount: 0,
      showEditMessageDialog: false,
      redrivingMessage: false,
    };
  }

  public componentDidMount() {
    this.loadMessages();
  }

  // eslint-disable-next-line complexity -- Disabled pre-existing violation of complexity rule
  public render() {
    return (
      <div className="page dlq-page">
        <div className="pure-g">
          <div className="pure-u-1-5 side-bar">
            <FilterList
              heading="Message ID (exact)"
              filterKey="messageId"
              // @ts-ignore
              values={get(this.state.filters, 'messageId')}
              applyFilters={this.applyFilters}
            />
            <FilterList
              heading="Subscription ID"
              filterKey="subscriptionId"
              // @ts-ignore
              values={get(this.state.filters, 'subscriptionId')}
              applyFilters={this.applyFilters}
            />
            <FilterList
              heading="Topic ID"
              filterKey="topicId"
              // @ts-ignore
              values={get(this.state.filters, 'topicId')}
              applyFilters={this.applyFilters}
            />
            <FilterList
              heading="Start Date"
              filterKey="startDate"
              values={
                get(this.state.filters, 'startDate')
                  ? // @ts-ignore
                    formatDate(parseDate(get(this.state.filters, 'startDate')), 'yyyy-MM-dd')
                  : null
              }
              applyFilters={this.applyFilters}
            />
            <FilterList
              heading="End Date"
              filterKey="endDate"
              values={
                get(this.state.filters, 'endDate')
                  ? // @ts-ignore
                    formatDate(subDays(parseDate(get(this.state.filters, 'endDate')), 1), 'yyyy-MM-dd')
                  : null
              }
              applyFilters={this.applyFilters}
            />
          </div>
          <div className="pure-u-4-5 main-section">
            <h4 style={{ color: 'red', fontWeight: 500, marginBottom: 0 }}>
              NOTE: This page is used by developers to review and republish dead letter messages. Please reach out to
              the dev team if you need to interact with any part of this page.
            </h4>
            <h1>PubSub Dead Letter Messages</h1>
            {this.state.errors &&
              this.state.errors.length > 0 &&
              this.state.errors.map((e, i) => <ErrorMessage key={i}>{e}</ErrorMessage>)}
            {this.state.selectedMessages && this.state.selectedMessages.length > 0 && (
              <AppBar position="static" color="default" style={{ marginBottom: '20px' }}>
                {this.state.currentTab === 'new' && (
                  <Toolbar>
                    <Button variant="contained" color="primary" onClick={this.archiveSelectedMessages}>
                      Archive Selected
                    </Button>
                    <Button variant="contained" color="primary" onClick={this.redriveSelectedMessages}>
                      Redrive Selected
                    </Button>
                  </Toolbar>
                )}
                {this.state.currentTab === 'archived' && (
                  <Toolbar>
                    <Button variant="contained" color="primary" onClick={this.unarchiveSelectedMessages}>
                      Unarchive Selected
                    </Button>
                  </Toolbar>
                )}
              </AppBar>
            )}
            <Tabs className="message-tabs" value={this.state.currentTab} onChange={this.changeTabs}>
              <Tab
                value="new"
                label={<span className="tab-label">New</span>}
                style={this.state.currentTab === 'new' ? tabStyles.activeTab : tabStyles.tab}
              />
              <Tab
                value="archived"
                label={<span className="tab-label">Archived</span>}
                style={this.state.currentTab === 'archived' ? tabStyles.activeTab : tabStyles.tab}
              />
            </Tabs>
            {this.state.loading ? <PageLoadSpinner /> : this.messagesTable()}
            {this.state.messageToEdit && this.renderEditMessageDialog()}
          </div>
        </div>
      </div>
    );
  }

  private encodeData = (s: string): string => {
    return encodeURIComponent(s)
      .replace(/\"/g, '%22')
      .replace(/\-/g, '%2D')
      .replace(/\_/g, '%5F')
      .replace(/\./g, '%2E')
      .replace(/\!/g, '%21')
      .replace(/\~/g, '%7E')
      .replace(/\*/g, '%2A')
      .replace(/\'/g, '%27')
      .replace(/\(/g, '%28')
      .replace(/\)/g, '%29');
  };

  private changeTabs = (event, value) => {
    const filters = cloneDeep(this.state.filters);
    filters.archived = value === 'archived';
    this.setState({ filters, currentTab: value, loading: true }, this.loadMessages);
  };

  private getTimeRangeString(time: string) {
    const timeWindow = 15 * 60 * 1000; // 15 minutes
    const dateTime = Date.parse(time);
    const before = new Date(dateTime - timeWindow).toISOString();
    const after = new Date(dateTime + timeWindow).toISOString();
    return this.encodeData(`${before}/${after}`);
  }

  private messagesTable = () => {
    if (!this.state.messages || this.state.messages.length === 0) {
      return <p className="no-messages">No messages matching the current selection.</p>;
    } else {
      return (
        <table className="pure-table locations-table striped">
          <thead>
            <tr>
              <th className="icon">
                <Checkbox
                  checked={this.state.selectedMessages === this.state.messages}
                  onChange={this.selectAllMessages}
                />
              </th>
              <th className="time">Time</th>
              <th className="message-id">ID</th>
              <th className="subscription">Subscription</th>
              <th className="topic">Topic Name</th>
              <th className="body">Body</th>
              <th className="attributes">Attributes</th>
              <th className="actions">
                <Pagination
                  page={this.state.currentPage}
                  pageSize={pageSize}
                  paginationHandler={(page) => this.handlePagination(page)}
                  totalCount={this.state.totalCount}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {this.state.messages.map((msg, idx) => {
              let body;
              let correlationId = '';
              Object.keys(msg.messageAttributes).forEach((key) => {
                if (key.toLowerCase() === 'x-flexe-correlation-id') {
                  correlationId = msg.messageAttributes[key];
                }
              });
              try {
                body = JSON.stringify(JSON.parse(atob(msg.messageBody)), null, 2);
              } catch (err) {
                body = `Invalid JSON: ${atob(msg.messageBody)}`;
              }

              const query = this.encodeData(
                `jsonPayload.message:("${correlationId}" OR "${msg.messageId}") OR\n` +
                  `textPayload:("${correlationId}" OR "${msg.messageId}") OR\n` +
                  `jsonPayload.orderManagerContext.messageId="${msg.messageId}" OR\n` +
                  `jsonPayload.flexe_om_context."com.flexe.oms/X-FLEXE-Correlation-ID":"${correlationId}"`,
              );

              return (
                <tr key={idx}>
                  <td>
                    <Checkbox
                      checked={!!this.state.selectedMessages.find((s) => s.id === msg.id)}
                      value={msg.id}
                      onChange={this.toggleMessageSelected}
                    />
                  </td>
                  <td>{formatDate(parseDate(msg.createdAt), 'M/d/yy hh:mm a')}</td>
                  <td>
                    <a
                      href={
                        'https://console.cloud.google.com/logs/query;' +
                        `query=${query};` +
                        `timeRange=${this.getTimeRangeString(msg.createdAt)}` +
                        `?project=${msg.subscriptionName.split('/')[1]}`
                      }
                      target="_blank"
                    >
                      {msg.messageId}
                    </a>
                  </td>
                  <td>{msg.subscriptionName}</td>
                  <td>{msg.topicName}</td>
                  <td>
                    <pre>
                      <code>{body}</code>
                    </pre>
                  </td>
                  <td>
                    <pre>
                      <code>{JSON.stringify(msg.messageAttributes, null, 2)}</code>
                    </pre>
                  </td>
                  <td>
                    {msg.isArchived ? (
                      <Button onClick={() => this.unarchiveMessage(msg.id)}>Unarchive</Button>
                    ) : (
                      [
                        <Button key="archive" onClick={() => this.archiveMessage(msg.id)}>
                          Archive
                        </Button>,
                        <Button key="redrive" onClick={() => this.handleEditMessage(msg.id)}>
                          Redrive
                        </Button>,
                      ]
                    )}
                  </td>
                  <td></td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    }
  };

  private loadMessages = async (newQuery: boolean = true) => {
    this.setState({ selectedMessages: [] });
    try {
      let continuationToken: string;
      if (!newQuery && this.state.currentPage > 1) {
        continuationToken = this.state.continuationTokens[this.state.currentPage - 2];
      } else {
        continuationToken = null;
      }
      const response = await flexeApi.getDlqMessages(continuationToken, pageSize, this.state.filters);
      const nextToken = get(response, 'data.continuationToken');
      const messages = get(response, 'data.messages');
      const totalCount = get(response, 'data.totalCount');
      this.setState({
        messages,
        totalCount,
      });
      if (newQuery) {
        this.setState({
          continuationTokens: [nextToken],
          currentPage: 1,
        });
      }
    } catch (error) {
      this.setState({ errors: [error] });
    } finally {
      this.setState({ loading: false });
    }
  };

  // eslint-disable-next-line complexity -- Disabled pre-existing violation of complexity rule
  private applyFilters = (filterKey: string, values: string | string[]) => {
    const filters = cloneDeep(this.state.filters);
    if (['startDate', 'endDate'].includes(filterKey)) {
      let date = values && typeof values === 'string' ? parseDate(values) : null;
      if (date && filterKey === 'endDate') {
        date = addDays(date, 1);
      }
      // @ts-ignore
      filters[filterKey] = date ? formatISO(date, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'") : null;
    } else {
      // @ts-ignore
      filters[filterKey] = values === '' ? null : values;
    }
    if (
      (Array.isArray(values) && values.every((v) => v.length > 2)) ||
      (typeof values === 'string' && values.length > 2) ||
      values === '' ||
      values === null
    ) {
      this.setState({ filters, loading: true }, this.loadMessages);
    } else {
      this.setState({ filters });
    }
  };

  private selectAllMessages = (event) => {
    const selectedMessages = event.target.checked ? this.state.messages : [];
    this.setState({ selectedMessages });
  };

  private toggleMessageSelected = (event) => {
    const selectedMessages = cloneDeep(this.state.selectedMessages);
    const msgId = event.target.value;
    if (event.target.checked) {
      const msg = this.state.messages.find((m) => m.id === msgId);
      selectedMessages.push(msg);
      selectedMessages.sort((a, b) => {
        const aId = a.id.toLowerCase();
        const bId = b.id.toLowerCase();
        if (aId < bId) {
          return -1;
        } else if (aId > bId) {
          return 1;
        }
        return 0;
      });
    } else {
      const index = selectedMessages.findIndex((m) => m.id === msgId);
      if (index !== -1) {
        selectedMessages.splice(index, 1);
      }
    }
    this.setState({ selectedMessages });
  };

  private async handlePagination(page: number) {
    this.setState({ loading: true });
    const continuationToken = page > 1 ? this.state.continuationTokens[page - 2] : null;
    const response = await flexeApi.getDlqMessages(continuationToken, pageSize, this.state.filters);
    const messages = get(response, 'data.messages');
    const updatedContinuationTokens = this.state.continuationTokens.slice();
    updatedContinuationTokens.push(get(response, 'data.continuationToken'));
    this.setState({
      continuationTokens: updatedContinuationTokens,
      currentPage: page,
      loading: false,
      messages,
    });
  }

  private handleEditMessage = (messageId: string) => {
    const messages = cloneDeep(this.state.messages);
    const message = messages.find((msg) => messageId === msg.id);
    this.setState({ messageToEdit: message, showEditMessageDialog: true });
  };

  private closeEditMessageDialog = () => {
    this.setState({ messageToEdit: null, showEditMessageDialog: false });
  };

  private renderEditMessageDialog = () => {
    const msg = this.state.messageToEdit;
    const messageBody = get(msg, 'messageBody');
    const messageAttributes = get(msg, 'messageAttributes');
    return (
      <Dialog open={this.state.showEditMessageDialog}>
        <DialogTitle>Redrive PubSub message</DialogTitle>
        <DialogContent>
          <dl className="pure-u-1-1">
            <dt>PubSub Message ID:</dt>
            <dd>{get(msg, 'messageId')}</dd>

            <dt>Correlation ID:</dt>
            <dd>{get(msg, 'correlationId')}</dd>

            <dt>Subscriber:</dt>
            <dd>{get(msg, 'subscriptionName')}</dd>

            <dt>Topic:</dt>
            <dd>
              <input type="text" name="topicName" value={get(msg, 'topicName')} onChange={this.handleUpdateMessage} />
            </dd>
          </dl>
          <p className="pure-u-1-1">
            <b>Body:</b>
            <br />
            <br />
            <textarea
              className="pure-u-1-1"
              name="messageBody"
              style={{ minHeight: '200px' }}
              onChange={this.handleUpdateMessage}
            >
              {messageBody && JSON.stringify(atob(messageBody))}
            </textarea>
          </p>
          <p className="pure-u-1-1">
            <b>Attributes:</b>
            <br />
            <br />
            <textarea
              className="pure-u-1-1"
              name="messageAttributes"
              style={{ minHeight: '200px' }}
              onChange={this.handleUpdateMessage}
            >
              {messageAttributes && JSON.stringify(messageAttributes)}
            </textarea>
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.closeEditMessageDialog}>Cancel</Button>
          <Button color="primary" disabled={this.state.redrivingMessage} onClick={this.redriveMessage}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  private handleUpdateMessage = (event) => {
    const field = event.currentTarget.name;
    const message = cloneDeep(this.state.messageToEdit);
    let value = event.currentTarget.value;
    if (field === 'messageBody') {
      // base64 encode body
      value = btoa(value);
    } else if (field === 'messageAttributes') {
      // convert attributes to JSON
      value = JSON.parse(value);
    }
    message[field] = value;
    this.setState({ messageToEdit: message });
  };

  private redriveMessage = async () => {
    this.setState({ redrivingMessage: true });
    try {
      const result = await flexeApi.redriveDlqMessage(this.state.messageToEdit);
      this.checkErrors(result.response);
      this.closeEditMessageDialog();
    } catch (error) {
      this.setState({ errors: [error] });
    } finally {
      this.setState({ loading: true, redrivingMessage: false }, this.loadMessages);
    }
  };

  private redriveSelectedMessages = async () => {
    this.setState({ loading: true });
    try {
      const result = await flexeApi.bulkRedriveDlqMessages(this.state.selectedMessages);
      this.checkErrors(result.response);
      this.loadMessages(false);
    } catch (error) {
      this.setState({ errors: error });
    }
  };

  private archiveMessage = async (messageId: string) => {
    this.setState({ loading: true });
    try {
      const result = await flexeApi.archiveDlqMessage(messageId);
      this.checkErrors(result.response);
      // refresh the current list
      this.loadMessages(false);
    } catch (error) {
      this.setState({ errors: [error] });
    }
  };

  private archiveSelectedMessages = async () => {
    this.setState({ loading: true });
    try {
      const result = await flexeApi.bulkArchiveDlqMessages(this.state.selectedMessages.map((m) => m.id));
      this.checkErrors(result.response);
      // refresh the current list
      this.loadMessages(false);
    } catch (error) {
      this.setState({ errors: [error] });
    }
  };

  private unarchiveSelectedMessages = async () => {
    this.setState({ loading: true });
    try {
      const result = await flexeApi.bulkUnarchiveDlqMessages(this.state.selectedMessages.map((m) => m.id));
      this.checkErrors(result.response);
      // refresh the current list
      this.loadMessages(false);
    } catch (error) {
      this.setState({ errors: [error] });
    }
  };

  private checkErrors = (response: any) => {
    let errors;
    if (response && response.text) {
      const responseTextJson = JSON.parse(response.text);
      if (responseTextJson.errors && responseTextJson.errors.length) {
        errors = responseTextJson.errors.map((e) => e.detail);
      }
    }
    this.setState({ errors });
  };

  private handleUnarchiveMessage = () => {
    // TODO - warn user if message has previously been redriven
    return;
  };

  private unarchiveMessage = async (messageId: string) => {
    this.setState({ loading: true });
    try {
      await flexeApi.unarchiveDlqMessage(messageId);
      // refresh the current list
      this.loadMessages(false);
    } catch (error) {
      this.setState({ errors: [error] });
    }
  };
}

export default Dlq;
