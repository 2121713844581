import * as React from 'react';
import Form, { FormProps } from '../../Form';
import { FormControl } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { StorageBillingMode } from '../../../CommonInterfaces';
import Radio from '@material-ui/core/Radio';

interface StorageBillingModesProps extends FormProps {
  formData: string;
  storageBillingModeKey: string;
  disableForm: boolean;
  onFieldChange(event);
}

class StorageBillingModes extends Form<StorageBillingModesProps, any> {
  public render() {
    const { formData, storageBillingModeKey, onFieldChange, formErrors, disableForm } = this.props;
    return (
      <fieldset>
        <legend>Storage Billing Mode</legend>

        <FormControl disabled={disableForm}>
          <RadioGroup
            name={storageBillingModeKey}
            data-testid={'storage-billing-mode'}
            defaultValue={formData}
            onChange={onFieldChange}
          >
            <FormControlLabel
              value={StorageBillingMode.nightly_rounded_peak_pallet_equivalents}
              control={<Radio color="primary" />}
              label="Nightly Rounded Peak Pallet Equivalents"
              data-testid={'storage-billing-mode-nightly-round'}
              disabled={disableForm}
            />
            <FormControlLabel
              value={StorageBillingMode.peak_lpn_pallet}
              control={<Radio color="primary" />}
              label="Peak LPN Pallet"
              disabled={disableForm}
            />
            <FormControlLabel
              value={StorageBillingMode.inner_system_none}
              control={<Radio color="primary" />}
              label="Other (through PAT or Manual) *"
              disabled={disableForm}
            />
          </RadioGroup>
          <FormHelperText>
            * Choose a Storage Billing Mode here to override reservation level storage billing mode(selected during
            reservation creation).
          </FormHelperText>
          <FormHelperText> * When 'Other' option is selected, please set price on storage to 0. </FormHelperText>
          {formErrors.storage_billing_mode && (
            <FormHelperText error={true}>{formErrors.storage_billing_mode}</FormHelperText>
          )}
        </FormControl>
      </fieldset>
    );
  }
}

export default StorageBillingModes;
