import * as React from 'react';
import {
  PatPricingConfiguration,
  PatPricingConfigurationWithBillingLever,
} from '../../../services/data/PricingV2Interfaces';
import { PricingCatalog } from '../../../services/data/PricingCatalogInterfaces';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import './PatPricingConfig.css';
import { FC } from 'react';
import Button from '@material-ui/core/Button';
import { IconButton, TextField } from '@material-ui/core';
import { generatePatUid } from '../../../lib/patUidGen';

const styles = {
  infoIconStyle: {
    fontSize: 'medium',
    marginLeft: '2px',
  },
};

interface PatPricingConfigFormProps {
  patPricing?: PatPricingConfigurationWithBillingLever[];
  pricingCatalog: PricingCatalog;
  disabled: boolean;
  onAddPatConfig(PatPricingConfiguration): void;
  onRemovePatConfig(PatPricingConfiguration): void;
  onEditPatConfig(PatPricingConfiguration): void;
}

const PatPricingConfigForm: FC<PatPricingConfigFormProps> = (props) => {
  const [newConfigText, setNewConfigText] = React.useState<string>('');
  const [showTextEntry, setShowTextEntry] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [rowErrorUid, setRowErrorUid] = React.useState<string>('');
  const [rowErrorMessage, setRowErrorMessage] = React.useState<string>('');
  const [editUid, setEditUid] = React.useState<string>('');
  const [newWarehouseRate, setNewWarehouseRate] = React.useState<string>('0.00');
  const [newShipperRate, setNewShipperRate] = React.useState<string>('0.00');

  const pricingByCategory: Map<string, PatPricingConfigurationWithBillingLever[]> = groupByCategory(props.patPricing);

  const pricingCatalog = props.pricingCatalog;

  const removeButtonClicked = (config: PatPricingConfiguration) => {
    props.onRemovePatConfig(config);
  };

  const addButtonClicked = () => {
    setShowTextEntry(true);
  };

  const editButtonClicked = (editConfig: PatPricingConfiguration) => {
    if (editUid !== '') {
      setRowError(
        editConfig.uid,
        `Already editing uid ${editUid}. Please complete or cancel in progress edit before editing another lever`,
      );
      return;
    }
    setNewShipperRate(editConfig.shipperRate);
    setNewWarehouseRate(editConfig.warehouseRate);
    setEditUid(editConfig.uid);
  };

  const saveNewButtonClicked = () => {
    try {
      setErrorMessage('');
      const parsedText = JSON.parse(newConfigText);
      const patConfig = { ...parsedText, uid: generatePatUid() };
      if (isJSONValidPatPricing(patConfig)) {
        props.onAddPatConfig(patConfig);
        setNewConfigText('');
        setShowTextEntry(false);
      } else {
        setErrorMessage(
          'Missing one or more important fields: uid, label, feeCategory, unitOfMeasure, rateDescriptionSuffix',
        );
      }
    } catch (e) {
      setErrorMessage(e.message);
    }
  };

  const saveEditButtonClicked = () => {
    setRowError('', '');
    const editedConfig = props.patPricing.find((config) => config.configuration.uid === editUid);

    if (+newWarehouseRate > +newShipperRate) {
      setRowError(editedConfig.configuration.uid, 'Warehouse rate is not allowed to be greater than shipper rate');
      return;
    }

    try {
      editedConfig.configuration.shipperRate = newShipperRate;
      editedConfig.configuration.warehouseRate = newWarehouseRate;
      props.onEditPatConfig(editedConfig);
      setEditUid('');
    } catch (e) {
      setRowError(editedConfig.configuration.uid, e.message);
    }
  };

  const setRowError = (uid: string, message: string) => {
    setRowErrorUid(uid);
    setRowErrorMessage(message);
  };

  const cancelEditButtonClicked = () => {
    setRowErrorMessage('');
    setEditUid('');
  };

  const isJSONValidPatPricing = (json: object): boolean => {
    return (
      json.hasOwnProperty('uid') &&
      json.hasOwnProperty('label') &&
      json.hasOwnProperty('feeCategory') &&
      json.hasOwnProperty('unitOfMeasure') &&
      json.hasOwnProperty('rateDescriptionSuffix')
    );
  };

  return (
    <fieldset data-testid="pat-pricing-config-form" className={'pat-pricing-form'}>
      {!props.disabled && (
        <div>
          <div data-testid="pat-pricing-error-message" style={{ color: 'red' }}>
            {errorMessage}
          </div>
          {showTextEntry && (
            <TextField
              type="text"
              variant="filled"
              label="New Config JSON"
              multiline={true}
              minRows={8}
              value={newConfigText}
              data-testid="pat-pricing-new-config-text"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setNewConfigText(event.target.value);
              }}
            />
          )}
          {!showTextEntry && (
            <Button
              variant="contained"
              className="pat-pricing-button"
              data-testid="pat-pricing-add-button"
              onClick={addButtonClicked}
            >
              DEV ONLY: Add New Config
            </Button>
          )}
          {showTextEntry && (
            <Button
              variant="contained"
              className="pat-pricing-button"
              data-testid="pat-pricing-save-button"
              onClick={saveNewButtonClicked}
            >
              DEV ONLY: Save Entered Config
            </Button>
          )}
        </div>
      )}
      {Array.from(pricingByCategory.values()).map((pricings) => {
        return (
          <div key={pricings[0].configuration.feeCategory}>
            <h3>{pricingCatalog[pricings[0].billingLever]?.product_name || pricings[0].configuration.feeCategory}</h3>
            {pricings.map((pricingWithLever) => {
              return renderPricing(pricingWithLever);
            })}
          </div>
        );
      })}
      {props.patPricing.length === 0 && (
        <fieldset className={'pat-pricing-form'}>
          <h3>No PAT Levers Configured</h3>
        </fieldset>
      )}
    </fieldset>
  );

  function renderPricing(pricingWithLever: PatPricingConfigurationWithBillingLever) {
    const jsonString = JSON.stringify(pricingWithLever.configuration, null, 2);
    const description = pricingCatalog[pricingWithLever.billingLever]?.description;
    return (
      <div key={pricingWithLever.configuration.uid}>
        <fieldset data-testid={pricingWithLever.configuration.uid}>
          <legend>
            {createPricingTitle(pricingWithLever)}
            {typeof description === 'string' && description.trim().length > 0 && (
              <Tooltip title={description} placement="right">
                <InfoIcon style={styles.infoIconStyle} />
              </Tooltip>
            )}
          </legend>
          {rowErrorUid === pricingWithLever.configuration.uid && (
            <div data-testid={`error.${pricingWithLever.configuration.uid}`} style={{ color: 'red' }}>
              {rowErrorMessage}
            </div>
          )}
          <table>
            <tbody>
              <tr>
                {!props.disabled && (
                  <td>
                    <IconButton
                      aria-label="edit"
                      id={`edit.${pricingWithLever.configuration.uid}`}
                      data-testid={`edit.${pricingWithLever.configuration.uid}`}
                      onClick={() => editButtonClicked(pricingWithLever.configuration)}
                      className="pat-pricing-edit-button"
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      aria-label="delete"
                      id={`delete.${pricingWithLever.configuration.uid}`}
                      data-testid={`delete.${pricingWithLever.configuration.uid}`}
                      onClick={() => removeButtonClicked(pricingWithLever.configuration)}
                      className="pat-pricing-delete-button"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </td>
                )}
                <td>
                  <div className={'pat-pricing-text'}>{jsonString}</div>
                </td>
                {!props.disabled && editUid === pricingWithLever.configuration.uid && (
                  <td className="pat-pricing-edit-cell">
                    <TextField
                      type="number"
                      value={newShipperRate}
                      label="Shipper Rate"
                      data-testid={`edit.shipper-rate.${pricingWithLever.configuration.uid}`}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setNewShipperRate(event.target.value);
                      }}
                    />
                    <TextField
                      type="number"
                      value={newWarehouseRate}
                      label="Warehouse Rate"
                      data-testid={`edit.warehouse-rate.${pricingWithLever.configuration.uid}`}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setNewWarehouseRate(event.target.value);
                      }}
                    />
                    <Button
                      variant="contained"
                      className="pat-pricing-button"
                      id={`edit.save.${pricingWithLever.configuration.uid}`}
                      data-testid={`edit.save.${pricingWithLever.configuration.uid}`}
                      onClick={saveEditButtonClicked}
                    >
                      Save Rates
                    </Button>
                    <Button
                      variant="contained"
                      className="pat-pricing-button"
                      id={`edit.cancel.${pricingWithLever.configuration.uid}`}
                      data-testid={`edit.cancel.${pricingWithLever.configuration.uid}`}
                      onClick={cancelEditButtonClicked}
                    >
                      Cancel
                    </Button>
                  </td>
                )}
              </tr>
            </tbody>
          </table>
        </fieldset>
      </div>
    );
  }

  function groupByCategory(
    pricings: PatPricingConfigurationWithBillingLever[],
  ): Map<string, PatPricingConfigurationWithBillingLever[]> {
    const map = new Map<string, PatPricingConfigurationWithBillingLever[]>();
    pricings.forEach((pricingWithLever) => {
      const group: PatPricingConfigurationWithBillingLever[] = map.get(pricingWithLever.configuration.feeCategory);
      if (group) {
        group.push(pricingWithLever);
      } else {
        map.set(pricingWithLever.configuration.feeCategory, [pricingWithLever]);
      }
    });
    return map;
  }

  function createPricingTitle(pricingWithLever: PatPricingConfigurationWithBillingLever) {
    const pricing = pricingWithLever.configuration;
    const catalogName = pricingCatalog[pricingWithLever.billingLever]?.display_name;
    const capitalizedUnitOfMeasure =
      pricing.unitOfMeasure[0].toUpperCase() + pricing.unitOfMeasure.slice(1).toLowerCase();
    if (catalogName && pricing.rateDescriptionSuffix) {
      return `${catalogName} - ${pricing.rateDescriptionSuffix} (${capitalizedUnitOfMeasure})`;
    } else if (catalogName) {
      return `${catalogName} (${capitalizedUnitOfMeasure})`;
    } else if (pricing.rateDescriptionSuffix) {
      return `${pricing.label} - ${pricing.rateDescriptionSuffix} (${capitalizedUnitOfMeasure})`;
    } else {
      return `${pricing.label} (${capitalizedUnitOfMeasure})`;
    }
  }
};

export default PatPricingConfigForm;
