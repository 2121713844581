/* eslint-disable max-lines -- Disabled pre-existing violation of max lines rule */
import * as React from 'react';
import Form from '../../Form';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import DocumentTable from './DocumentTable';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { ScopeDocument } from '../../../CommonInterfaces';
import flexeApi from '../../../lib/flexeApi';
import { guidish } from '../../../lib/helpers';
import { NON_PLATFORM_ACCOUNT, PLATFORM_ACCOUNT, RESERVATION_NICKNAME_MAX_LENGTH } from '../../../lib/constants';

const styles = {
  addDocument: {
    float: 'right' as const,
  },
  warningText: {
    color: 'coral',
  },
};

interface ReservationSetupProps {
  formData;
  scopeDocuments: ScopeDocument[];
  blockActivityBoxEnabled;
  onStartOnDateChange(event);
  onIntegerFieldChange(event);
  onFieldChange(event);
}

interface ReservationSetupState {
  nonPlatformAccount: string;
  showBillingDetails: boolean;
  showAddDocumentDialog: boolean;
  showDeleteDocumentDialog: boolean;
  deleteDocumentId: number;
  scopeDocuments: ScopeDocument[];
  documentDeleteFail: boolean;
  uploadDocKind: string;
  uploadDocVisibleToShipper: boolean;
  uploadDocVisibleToWarehouse: boolean;
  uploadDocDocument?: any;
  documentUploadFail: boolean;
}

class ReservationSetup extends Form<ReservationSetupProps, ReservationSetupState> {
  constructor(props) {
    super(props);

    this.state = {
      nonPlatformAccount: PLATFORM_ACCOUNT,
      showBillingDetails: false,
      showAddDocumentDialog: false,
      showDeleteDocumentDialog: false,
      deleteDocumentId: -1,
      scopeDocuments: [],
      documentDeleteFail: false,
      uploadDocKind: 'WSA',
      uploadDocVisibleToShipper: false,
      uploadDocVisibleToWarehouse: false,
      uploadDocDocument: null,
      documentUploadFail: false,
    };
  }

  // eslint-disable-next-line complexity -- Disabled pre-existing violation of complexity rule
  public render() {
    const {
      formData,
      scopeDocuments,
      onFieldChange,
      onStartOnDateChange,
      onIntegerFieldChange,
      formErrors,
      disableForm,
      blockActivityBoxEnabled,
    } = this.props;

    const startDate = this.formatDate(formData.start_on);
    const scopeTxnState =
      formData.reservation_scope === null || formData.reservation_scope === undefined
        ? null
        : formData.reservation_scope.txn_state;
    const scopeId =
      formData.reservation_scope === null || formData.reservation_scope === undefined
        ? null
        : formData.reservation_scope.id;
    // const scopeDocumentUnEditable = !blockActivityBoxEnabled && disableForm  || scopeTxnState !== 'new';
    const scopeDocumentUnEditable = !blockActivityBoxEnabled;
    const showScopeDocumentTable = scopeId !== null && scopeId !== 0;
    return (
      <div>
        <fieldset>
          <legend>Details</legend>
          <FormControl disabled={disableForm}>
            <InputLabel htmlFor="depositor-nickname">Nickname visible to shipper</InputLabel>
            <Input
              id="depositor-nickname"
              name="reservation.depositor_reservation_nickname"
              value={formData.depositor_reservation_nickname}
              inputProps={{
                maxLength: RESERVATION_NICKNAME_MAX_LENGTH,
              }}
              onChange={onFieldChange}
            />

            {RESERVATION_NICKNAME_MAX_LENGTH - formData.depositor_reservation_nickname.length <= 5 && (
              <FormHelperText style={styles.warningText}>
                {RESERVATION_NICKNAME_MAX_LENGTH - formData.depositor_reservation_nickname.length} characters left
              </FormHelperText>
            )}
          </FormControl>
          <br />

          <FormControl disabled={disableForm}>
            <InputLabel htmlFor="warehouse-nickname">Nickname visible to warehouse</InputLabel>
            <Input
              id="warehouse-nickname"
              name="reservation.warehouse_reservation_nickname"
              value={formData.warehouse_reservation_nickname}
              inputProps={{
                maxLength: RESERVATION_NICKNAME_MAX_LENGTH,
              }}
              onChange={onFieldChange}
            />

            {RESERVATION_NICKNAME_MAX_LENGTH - formData.warehouse_reservation_nickname.length <= 5 && (
              <FormHelperText style={styles.warningText}>
                {RESERVATION_NICKNAME_MAX_LENGTH - formData.warehouse_reservation_nickname.length} characters left
              </FormHelperText>
            )}
          </FormControl>
          <br />
          <br />

          <FormControl disabled={disableForm}>
            <InputLabel shrink={true} htmlFor="start-date">
              Start on Date *
            </InputLabel>
            <Input id="start-date" type="date" value={startDate} onChange={onStartOnDateChange} />
            <FormHelperText style={styles.warningText}>* Not Used For Billing</FormHelperText>
            {formErrors.start_on && <FormHelperText error={true}>{formErrors.start_on}</FormHelperText>}
          </FormControl>
          <br />

          <FormControl disabled={disableForm}>
            <InputLabel htmlFor="fee-currency">Currency *</InputLabel>
            <Select
              native
              id="fee-currency"
              name="reservation.fee_currency"
              value={formData.fee_currency}
              onChange={onFieldChange}
            >
              <option value="USD">USD</option>
              <option value="CAD">CAD</option>
              <option value="MXN">MXN</option>
            </Select>
            {formErrors.fee_currency && <FormHelperText error={true}>{formErrors.fee_currency}</FormHelperText>}
          </FormControl>
          <br />

          <FormControl disabled={disableForm}>
            <InputLabel htmlFor="description">Description *</InputLabel>
            <Input
              id="description"
              name="reservation.description"
              value={formData.description}
              onChange={onFieldChange}
              multiline={true}
              rows={1}
              required
              disabled={disableForm}
              fullWidth={true}
            />
            <FormHelperText>i.e. Short description for easy identification</FormHelperText>
            {formErrors.description && <FormHelperText error={true}>{formErrors.description}</FormHelperText>}
          </FormControl>
          <br />

          <FormControlLabel
            disabled={disableForm}
            control={
              <Checkbox
                className="checkbox"
                checked={this.state.showBillingDetails}
                onChange={this.toggleBillingDetails}
              />
            }
            label="Set Billing Details"
          />
          <br />

          <FormControlLabel
            label="Non-Platform"
            data-testid="account_type"
            control={
              <Checkbox
                className="checkbox"
                name="reservation.account_type"
                checked={this.state.nonPlatformAccount === NON_PLATFORM_ACCOUNT ? true : false}
                onChange={(_, checked: boolean) => {
                  this.setState({ nonPlatformAccount: checked ? NON_PLATFORM_ACCOUNT : PLATFORM_ACCOUNT }, () => {
                    formData.account_type = this.state.nonPlatformAccount;
                  });
                }}
              />
            }
          />
        </fieldset>
        {this.renderBillingDetails(formData, onFieldChange, disableForm)}

        {showScopeDocumentTable && (
          <fieldset>
            <legend>Scope Document</legend>
            <Button
              className="add-document"
              style={styles.addDocument}
              aria-label="add"
              onClick={this.handleClickAddDocument}
              disabled={scopeDocumentUnEditable}
            >
              <AddIcon />
              Add a new document
            </Button>
            <DocumentTable
              rows={scopeDocuments}
              onClickDelete={(index) => this.handleClickDeleteDocument(index)}
              scopeDocumentUnEditable={scopeDocumentUnEditable}
            />
          </fieldset>
        )}
        <Dialog open={this.state.showAddDocumentDialog}>
          <DialogTitle>Upload a Scope Document</DialogTitle>
          <DialogContent>
            <div>
              <FormControl>
                <label htmlFor="upload-document-type">Upload Document type</label>
                <Select
                  native
                  id="upload-document-kind"
                  name="uploadDocKind"
                  value={this.state.uploadDocKind}
                  onChange={this.uploadDocKindChange}
                >
                  <option value="WSA">WSA</option>
                  <option value="WSA_AMENDMENT">Amendments to WSA</option>
                  <option value="NON_SITE_SERVICE_PRICING_TERMS">Non-Site Service Pricing Terms</option>
                </Select>
              </FormControl>
              <br />
              <FormControlLabel
                control={
                  <Checkbox
                    className="checkbox"
                    name="show_to_shipper"
                    checked={this.state.uploadDocVisibleToShipper}
                    onChange={this.uploadDocVisibleToShipperChange}
                  />
                }
                label="The Depositor can view this document"
              />
              <br />
              <FormControlLabel
                control={
                  <Checkbox
                    className="checkbox"
                    name="show_to_warehouse"
                    checked={this.state.uploadDocVisibleToWarehouse}
                    onChange={this.uploadDocVisibleToWarehouseChange}
                  />
                }
                label="The Warehouse can view this document"
              />
              <br />
              <FormControl>
                <input id="wsa" type="file" onChange={this.uploadDocDocumentChange} />
              </FormControl>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.hideAddDialog} color="primary">
              close
            </Button>
            <Button
              onClick={this.uploadDocument}
              disabled={this.state.uploadDocDocument == null}
              color="primary"
              autoFocus
            >
              upload
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.showDeleteDocumentDialog}>
          <DialogTitle>Delete a Scope Document</DialogTitle>
          <DialogContent>
            <p> Are you sure you want to delete this scope document? </p>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.hideDeleteDialog} color="primary">
              close
            </Button>
            <Button onClick={this.deleteDocument} color="primary" autoFocus>
              delete
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.documentDeleteFail}>
          <DialogTitle>Error</DialogTitle>
          <DialogContent>
            <p> Delete scope document fail! </p>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.hideDeleteErrorDialog} color="primary">
              close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.documentUploadFail}>
          <DialogTitle>Error</DialogTitle>
          <DialogContent>
            <p> Upload scope document fail! </p>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.hideUploadErrorDialog} color="primary">
              close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  private toggleBillingDetails = (event, isChecked: boolean) => {
    this.setState({ showBillingDetails: isChecked });
  };

  private renderBillingDetails(formData: any, onFormUpdate, disableForm: boolean) {
    if (this.state.showBillingDetails) {
      return (
        <div>
          <h3>Billing Details (appears on invoice)</h3>

          <p className="note">
            Note: The address fields are "all or nothing". If you set any of street, city, state or zip, all those
            fields in company will be ignored.
          </p>

          <FormControl disabled={disableForm}>
            <InputLabel htmlFor="bill-to-name">Bill to name</InputLabel>
            <Input
              id="bill-to-name"
              name="reservation.bill_to.name"
              value={formData.bill_to.name}
              onChange={onFormUpdate}
            />
          </FormControl>

          <FormControl disabled={disableForm}>
            <InputLabel htmlFor="bill-to-street">Bill to street</InputLabel>
            <Input
              id="bill-to-street"
              name="reservation.bill_to.address_1"
              value={formData.bill_to.address_1}
              onChange={onFormUpdate}
            />
          </FormControl>

          <FormControl disabled={disableForm}>
            <InputLabel htmlFor="bill-to-city">Bill to city</InputLabel>
            <Input
              id="bill-to-city"
              name="reservation.bill_to.locality"
              value={formData.bill_to.locality}
              onChange={onFormUpdate}
            />
          </FormControl>

          <FormControl disabled={disableForm}>
            <InputLabel htmlFor="bill-to-state">Bill to state</InputLabel>
            <Input
              id="bill-to-state"
              name="reservation.bill_to.region"
              value={formData.bill_to.region}
              onChange={onFormUpdate}
            />
          </FormControl>

          <FormControl disabled={disableForm}>
            <InputLabel htmlFor="bill-to-zip">Bill to zip</InputLabel>
            <Input
              id="bill-to-zip"
              name="reservation.bill_to.postal_code"
              value={formData.bill_to.postal_code}
              onChange={onFormUpdate}
            />
          </FormControl>

          <FormControl disabled={disableForm}>
            <InputLabel htmlFor="bill-to-phone">Bill to phone</InputLabel>
            <Input
              id="bill-to-phone"
              name="reservation.bill_to.phone"
              value={formData.bill_to.phone}
              onChange={onFormUpdate}
            />
          </FormControl>

          <FormControl disabled={disableForm}>
            <InputLabel htmlFor="vendor-id">Customer Vendor ID</InputLabel>
            <Input
              id="vendor-id"
              name="reservation.customer_vendor_id"
              value={formData.customer_vendor_id}
              onChange={onFormUpdate}
            />
          </FormControl>
        </div>
      );
    }
  }

  private handleClickAddDocument = () => {
    this.setState({ showAddDocumentDialog: true });
  };

  private hideAddDialog = () => {
    this.setState({ showAddDocumentDialog: false });
  };

  private uploadDocument = () => {
    const fileKey = `scopes-${guidish()}`;
    const companyIds = [this.props.formData.depositor.id, this.props.formData.warehouse.company.id];
    const labels = {
      reservationId: this.props.formData.id,
      scopeId: this.props.formData.reservation_scope.id,
      documentKind: this.state.uploadDocKind,
      documentVisibleToShipper: this.state.uploadDocVisibleToShipper,
      documentVisibleToWarehouse: this.state.uploadDocVisibleToShipper,
    };
    flexeApi
      .uploadFile(companyIds, labels, fileKey, this.state.uploadDocDocument)
      .then((response) => {
        const documentList: ScopeDocument[] = this.props.formData.reservation_scope_documents;
        const newDocument: ScopeDocument = {
          fileKey,
          fileName: this.state.uploadDocDocument.name,
          kind: this.state.uploadDocKind,
          visibleToShipper: this.state.uploadDocVisibleToShipper,
          visibleToWarehouse: this.state.uploadDocVisibleToWarehouse,
        };
        documentList.push(newDocument);
        this.setState({
          uploadDocKind: 'WSA',
          uploadDocVisibleToWarehouse: false,
          uploadDocVisibleToShipper: false,
          uploadDocDocument: null,
          showAddDocumentDialog: false,
          scopeDocuments: documentList,
        });
      })
      .catch((error) => {
        this.uploadDocumentFailSetting();
      });
  };

  private handleClickDeleteDocument = (index) => {
    this.setState({ showDeleteDocumentDialog: true, deleteDocumentId: index });
  };

  private hideDeleteDialog = () => {
    this.setState({ showDeleteDocumentDialog: false });
  };

  private deleteDocument = () => {
    const documentList = this.props.formData.reservation_scope_documents;
    documentList.splice(
      documentList.findIndex((item) => item.id === this.state.deleteDocumentId),
      1,
    );
    this.setState({ deleteDocumentId: -1, showDeleteDocumentDialog: false, scopeDocuments: documentList });
  };

  private hideDeleteErrorDialog = () => {
    this.setState({ documentDeleteFail: false });
  };

  private hideUploadErrorDialog = () => {
    this.setState({ documentUploadFail: false });
  };

  private uploadDocKindChange = (event) => {
    this.setState({ uploadDocKind: event.target.value });
  };

  private uploadDocVisibleToShipperChange = (event) => {
    this.setState({ uploadDocVisibleToShipper: event.target.checked });
  };

  private uploadDocVisibleToWarehouseChange = (event) => {
    this.setState({ uploadDocVisibleToWarehouse: event.target.checked });
  };

  private uploadDocDocumentChange = (event) => {
    this.setState({ uploadDocDocument: event.target.files[0] });
  };

  // Return the date in the string of format yyyy-mm-dd;
  // or as-is if it's already in this format
  private formatDate(date): string {
    if (date.length === 10) {
      return date;
    }

    return date.toISOString().slice(0, 10);
  }

  private uploadDocumentFailSetting = () => {
    this.setState({
      documentUploadFail: true,
      showAddDocumentDialog: false,
      uploadDocKind: 'WSA',
      uploadDocVisibleToWarehouse: false,
      uploadDocVisibleToShipper: false,
      uploadDocDocument: null,
    });
  };
}

export default ReservationSetup;
