import * as React from 'react';
import { FEE_HINT_TEXT } from '../../lib/constants';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';

const styles = {
  textFieldStyles: {
    display: 'block',
    fontSize: 14,
    maxWidth: 64,
  },
  errorIconStyles: {
    width: 18,
    height: 18,
    right: 0,
    top: -31,
  },
};

interface PriceFieldProps {
  'key': any;
  'name': string;
  'value': string;
  'data-error-key': string;
  'errorText': string;
  'style'?: any;
  'labelText'?: string;
  'disabled': boolean;
  onChange(event);
}

interface PriceFieldState {
  showErrorTooltip: boolean;
}

class PriceField extends React.Component<PriceFieldProps, PriceFieldState> {
  constructor(props) {
    super(props);

    this.state = {
      showErrorTooltip: false,
    };
  }

  public render() {
    const { style, labelText, disabled, errorText, name, value, ...otherProps } = this.props;

    return (
      <div style={{ position: 'relative' }}>
        <FormControl disabled={disabled}>
          <InputLabel htmlFor={name}>{labelText}</InputLabel>
          <Input
            id={name}
            name={name}
            data-testid={name}
            value={value}
            onChange={otherProps.onChange}
            type="number"
            onWheel={(event) => {
              const target = event.target as HTMLInputElement;
              target.blur();
            }}
            inputProps={{ min: 0, step: 'any' }}
          />
          <FormHelperText>{FEE_HINT_TEXT}</FormHelperText>
          {errorText && <FormHelperText error={true}>{errorText}</FormHelperText>}
        </FormControl>
      </div>
    );
  }

  private toggleErrorTooltip = (event) => {
    this.setState({ showErrorTooltip: event.type === 'mouseover' });
  };
}

export default PriceField;
