import * as React from 'react';
import { cloneDeep } from 'lodash';
import { FlexeApiResponse, FulfillmentInventoryGroupPricing, InventoryGroup } from '../CommonInterfaces';
import { nullPricingValues, usingPricingGroup } from '../lib/helpers';
import flexeApi from '../lib//flexeApi';

class InventoryGroupsService {
  /**
   *
   * @TODO [BLNG-6620] ---- ADD TESTS BEFORE MAKING ANY CHANGES ----
   *
   * Please don't make changes or add any new code to this service
   * before adding tests to the existing code.
   *
   * There are currently no automated tests for this service.
   *
   * This is an explicit tech debt callout, and needs to be remedied
   * before any future work can be done here.
   *
   */

  // eslint-disable-next-line complexity -- Disabled pre-existing violation of complexity rule
  public processInventoryGroup = (inventoryGroup: InventoryGroup, pricingOptions) => {
    const {
      use_packaging_groups: usePackagingGroups,
      container_pricing_scope: supportsContainerPricing,
      cpd_fulfillment_pricing_scope: supportsCartonPick,
    } = pricingOptions;
    if (!usePackagingGroups) {
      return [this.processPackagingGroup('default', inventoryGroup, pricingOptions)];
    } else {
      const inventoryGroupData = [];
      for (const packagingGroupKey in inventoryGroup.pricing) {
        if (packagingGroupKey !== 'default') {
          const packagingGroupData = this.processPackagingGroup(packagingGroupKey, inventoryGroup, pricingOptions);
          inventoryGroupData.push(packagingGroupData);
        }
      }
      return inventoryGroupData;
    }
  };

  // eslint-disable-next-line complexity -- Disabled pre-existing violation of complexity rule
  private processPackagingGroup = (packagingGroupKey: string, inventoryGroup: InventoryGroup, pricingOptions: any) => {
    const pricingScopes = cloneDeep(inventoryGroup.pricing[packagingGroupKey]);
    const { container_pricing_scope: supportsContainerPricing, cpd_fulfillment_pricing_scope: supportsCartonPick } =
      pricingOptions;

    // If some fees are being used in a pricing scope, null the empty values
    for (const pricingScopeKey in pricingScopes) {
      if (pricingOptions[pricingScopeKey] && usingPricingGroup(pricingScopes[pricingScopeKey])) {
        pricingScopes[pricingScopeKey] = nullPricingValues(pricingScopes[pricingScopeKey]);
      }
    }

    const pricingAxes = {
      inventory_group: null,
      packaging: null,
    };
    if (!inventoryGroup.isDefault) {
      pricingAxes.inventory_group = inventoryGroup.id;
    }
    if (packagingGroupKey !== 'default') {
      pricingAxes.packaging = packagingGroupKey;
    }

    // The name and type properties are metadata and don't need to be sent to the backend
    delete pricingScopes.name;
    delete pricingScopes.type;

    return {
      pricing_axes: pricingAxes,
      pricing_scopes: pricingScopes,
    };
  };

  public proccessInventoryGroups = (state, inventoryGroups: InventoryGroup[], pricingTemplate) => {
    const pricing = cloneDeep(state.pricing);

    pricing.inventoryGroups = inventoryGroups
      .map((invGroup: any): InventoryGroup => {
        const oldInvGroup = state.pricing.inventoryGroups.find((iGroup: InventoryGroup) => iGroup.id === invGroup.id);
        return this.mergeInventoryGroup(oldInvGroup, invGroup, pricingTemplate);
      })
      .sort((invGroupA: InventoryGroup, invGroupB: InventoryGroup): number => {
        return invGroupA.id - invGroupB.id;
      });

    return { pricing, pricingFormErrors: {}, reservationErrorMessage: '' };
  };

  private mergeInventoryGroup = (
    oldInvGroup: InventoryGroup,
    newOrUpdatedInvGroup: InventoryGroup,
    pricingTemplate,
  ) => {
    const enabled = oldInvGroup ? oldInvGroup.enabled : false;
    const pricing = oldInvGroup ? oldInvGroup.pricing : cloneDeep(pricingTemplate);
    return {
      ...newOrUpdatedInvGroup,
      enabled,
      saved: true,
      tempDescription: newOrUpdatedInvGroup.description,
      pricing,
    };
  };

  public mergeInventoryGroupState = (state, invGroupIdx, newOrUpdatedInvGroup: InventoryGroup, pricingTemplate) => {
    const pricingState = cloneDeep(state.pricing);
    const oldInvGroup = state.pricing.inventoryGroups[invGroupIdx];
    pricingState.inventoryGroups[invGroupIdx] = this.mergeInventoryGroup(
      oldInvGroup,
      newOrUpdatedInvGroup,
      pricingTemplate,
    );
    return { pricing: pricingState };
  };

  public addInventoryGroup = (state, pricingTemplate) => {
    const pricing = cloneDeep(state.pricing);

    pricing.inventoryGroups.push({
      saved: false,
      enabled: false,
      description: '',
      tempDescription: '',
      pricing: cloneDeep(pricingTemplate),
    });

    return { pricing, pricingFormErrors: {} };
  };

  public cancelEditInventoryGroup = (state, invGroupIdx: number) => {
    const pricing = cloneDeep(state.pricing);
    const invGroup = pricing.inventoryGroups[invGroupIdx];
    if (invGroup.saved) {
      invGroup.tempDescription = invGroup.description;
    } else {
      pricing.inventoryGroups.splice(invGroupIdx, 1);
    }
    return { pricing, pricingFormErrors: {} };
  };

  public async createOrUpdateInventoryGroup(state, companyId: number, invGroupIdx: number) {
    const invGroup = cloneDeep(state.pricing.inventoryGroups[invGroupIdx]);
    const body = {
      data: {
        description: invGroup.tempDescription,
      },
    };

    let response: FlexeApiResponse;
    if (invGroup.saved) {
      response = await flexeApi.updateInventoryGroup(companyId, invGroup.id, body);
    } else {
      response = await flexeApi.createInventoryGroup(companyId, body);
    }

    const { data, statusCode, error } = response;

    if ((statusCode === 200 || statusCode === 201) && data) {
      return data;
    } else if (error && typeof error !== 'string') {
      throw new Error(error.msg);
    }
  }
}

export default InventoryGroupsService;
