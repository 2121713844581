export const env = process.env.FLEXE_ENV || 'development';
export const isCloudEnv = ['staging', 'sandbox', 'production'].includes(env);
export const gcpProject = process.env.FLEXE_GOOGLE_PROJECT_ID;
export const nodeRoot = process.env.FLEXE_APP_ROOT || '';
// The web uri is used to compose href links to warehouser.  The weburi should be accessible from the internet
export const warehouserWebUri = process.env.FLEXE_WAREHOUSER_WEB_URI || 'http://localhost:3000';
// The base uri is used for api communication to warehouser.  Does not have to be accessible from the internet
export const flexeInternalApiBaseUri = process.env.FLEXE_INTERNAL_API_BASE_URI || 'http://localhost:3000';
// The base uri is used for api communication to dlq api.  Does not have to be accessible from the internet
export const dlqBaseUri = process.env.FLEXE_DLQ_API_BASE_URI || 'http://localhost:3005';
export const featureHubUrl = process.env.FEATURE_HUB_URL;
export const featureHubApiKey = process.env.WAREHOUSER_FEATURE_HUB_API_KEY;
export const warehouserApiUri = flexeInternalApiBaseUri + '/api/v1/admin';
export const warehouserTokenUri = warehouserApiUri + '/token/retrieve';
export const warehouserSessionInvalidationUri = warehouserApiUri + '/logout';
export const v2AuthUri =
  (process.env.FLEXE_V2_AUTH_URI || 'http://localhost:3000') + '/api/v2/token/generate?admin=true&frontend=true';
export const newAdminSessionCookieName = '_new_admin_warehouser_session';
export const v2AuthCookieName = 'flexe_v2_jwt';

export const kratosSessionCookieName = 'ory_kratos_session';
export const redirectUriParamName = 'redirect';

// SSO:
export const refreshTokenCookieMaxAge = 1800000; //30 mins in milliseconds
export const publicKratosBaseUrl = process.env.PUBLIC_KRATOS_BASE_URL ?? 'http://127.0.0.1:4433';
export const internalKratosBaseUrl = process.env.INTERNAL_KRATOS_BASE_URL ?? 'http://127.0.0.1:4433';
export const adminKratosBaseUrl = process.env.ADMIN_KRATOS_BASE_URL ?? 'http://127.0.0.1:4434';
export const ssoEnabled = process.env.SSO_ENABLED === 'true';
export const ssoCookieDomain = process.env.SSO_COOKIE_DOMAIN ?? 'localhost';
export const ssoCookieSameSite = process.env.SSO_SAME_SITE ?? 'Lax';
