import * as React from 'react';
import { get, isEqual, uniqBy } from 'lodash';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { FindLocationBy, ReservationAndScopeInfo } from '../../CommonInterfaces';
import Reservations from '../../pages/Reservations';

const reservationDataSourceConfig = {
  text: 'reservationText',
  value: 'reservationValue',
};
const warehouseDataSourceConfig = {
  text: 'warehouseText',
  value: 'warehouseValue',
};

interface FindLocationsControlsProps {
  reservations: ReservationAndScopeInfo[];
  findLocationsBy: string;
  handleFindLocationsByChange(event: Event, value: FindLocationBy);
  reservationOrWarehouseSelected(chosenRequest: ReservationAndScopeInfo);
}

interface FindLocationsControlsState {
  searchText: string;
  reservations: ReservationAndScopeInfo[];
}

class FindLocationsControls extends React.Component<FindLocationsControlsProps, FindLocationsControlsState> {
  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      reservations: [],
    };
  }

  public render() {
    const { findLocationsBy } = this.props;

    // Sort the reservations based on what we're looking for (Reservations ID or Warehouse ID)
    let sortedReservations = this.state.reservations.sort(
      (resA: ReservationAndScopeInfo, resB: ReservationAndScopeInfo) => {
        if (findLocationsBy === 'Reservation') {
          return resA.id - resB.id;
        } else {
          return resA.warehouse.id - resB.warehouse.id;
        }
      },
    );

    // If we're looking for warehouses, remove duplicates (since the array is actually of reservations)
    if (findLocationsBy === 'Warehouse') {
      sortedReservations = uniqBy(sortedReservations, (res) => res.warehouse.id);
    }

    return (
      <div className="find-locations-by">
        <h3>Find Locations By:</h3>

        <fieldset>
          <RadioGroup value={findLocationsBy} onChange={this.handleFindLocationsByChange}>
            <FormControlLabel
              value="Reservation"
              control={<Radio color="primary" />}
              label="Reservation"
              labelPlacement="end"
            />
            <FormControlLabel
              value="Warehouse"
              control={<Radio color="primary" />}
              label="Warehouse"
              labelPlacement="end"
            />
          </RadioGroup>
        </fieldset>

        <label>
          <input
            type="text"
            list="findLocationsAutoComplete"
            value={this.state.searchText}
            onChange={this.updateSearchText}
          />
          <datalist id="findLocationsAutoComplete" style={{ width: 512 }}>
            {sortedReservations.map((r) => {
              return findLocationsBy === 'Reservation' ? (
                <option key={r.reservationValue} value={r.reservationValue}>
                  {r.reservationText}
                </option>
              ) : (
                <option key={r.warehouseValue} value={r.warehouseValue}>
                  {r.warehouseText}
                </option>
              );
            })}
          </datalist>
        </label>
      </div>
    );
  }

  public componentWillReceiveProps(nextProps) {
    if (!isEqual(this.props.reservations, nextProps.reservations)) {
      this.setState({
        reservations: nextProps.reservations.map(this.formatReservationForAutoComplete),
      });
    }
  }

  private formatReservationForAutoComplete(reservation: ReservationAndScopeInfo): ReservationAndScopeInfo {
    const resId = reservation.id || '';
    const resDepName = get(reservation, 'depositor.name');
    const whId = get(reservation, 'warehouse.id');
    const whName = get(reservation, 'warehouse.name');
    const whCoName = get(reservation, 'warehouse.company.name');
    return {
      ...reservation,
      reservationText: `#${resId} ${resDepName} -> ${whName}`,
      reservationValue: parseInt(resId, 10),
      warehouseText: `#${whId} ${whCoName} - ${whName}`,
      warehouseValue: parseInt(whId, 10),
    };
  }

  private updateSearchText = (event) => {
    const searchText = get(event, 'target.value');
    this.setState({ searchText });
    const chosenRequest = this.props.reservations.find((r) => {
      return this.props.findLocationsBy === 'Reservation'
        ? r.id === parseInt(searchText, 10)
        : r.warehouse.id === parseInt(searchText, 10);
    });
    if (chosenRequest) {
      this.props.reservationOrWarehouseSelected(chosenRequest);
    }
    //add a default request to enable filtering without needing to wait for all reservations to be loaded.
    const defaultRequest: ReservationAndScopeInfo = {
      id: searchText,
      client_labeled: null,
      customer_vendor_id: null,
      depositor_reservation_nickname: '',
      warehouse_reservation_nickname: '',
      fee_currency: null,
      monthly_minimum: null,
      monthly_warehouse_minimum: null,
      reservation_scope_documents: null,
      pallet_label_prefix: null,
      allow_partial_receiving: null,
      allow_same_day_delivery: null,
      block_activity: true,
      additional_scope_notes: null,
      bill_to: null,
      pricingOptions: null,
    };
    if (defaultRequest) {
      this.props.reservationOrWarehouseSelected(defaultRequest);
    }
  };

  private clearSearchText = () => {
    this.setState({ searchText: '' });
  };

  private handleFindLocationsByChange = (event, value: FindLocationBy) => {
    this.clearSearchText();
    this.props.handleFindLocationsByChange(event, value);
  };
}

export default FindLocationsControls;
