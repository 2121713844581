import { createTheme } from '@material-ui/core/styles';
import { blueLight, ctaOrange, errorRed, flexeBlue, grayDark, grayMedDark, grayMedLight, white } from './flexeColors';

const flexeTheme = createTheme({
  palette: {
    primary: {
      main: flexeBlue,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        marginRight: '15px',
      },
    },
    MuiDialogContent: {
      root: {
        overflowY: 'auto',
      },
    },
    MuiFormControl: {
      root: {
        width: '100%',
        margin: '5px 0',
      },
    },
    MuiFormControlLabel: {
      root: {
        width: '100%',
        margin: '5px 0',
      },
    },
    MuiToolbar: {
      root: {
        minHeight: 56,
        backgroundColor: grayDark,
        justifyContent: 'space-between',
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: grayMedDark,
        display: 'block',
        height: 32,
        marginLeft: 24,
        width: 1,
      },
    },
    MuiRadio: {
      root: {
        padding: 0,
        marginRight: 16,
      },
    },
    MuiDialog: {
      paper: {
        padding: 24,
      },
    },
  },
});

export default flexeTheme;
