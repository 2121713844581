import { FlexeApiResponse } from '../CommonInterfaces';
import { apiRequestJSON } from '../lib/flexeApi';
import { PricingCatalog } from './data/PricingCatalogInterfaces';

export class PricingCatalogService {
  public async retrievePricingCatalog(): Promise<PricingCatalog> {
    const response: FlexeApiResponse = await apiRequestJSON('GET', '/api/pricing_catalog');

    const { data, statusCode, error } = response;

    if ((statusCode === 200 || statusCode === 201) && data) {
      return data;
    } else if (error && typeof error !== 'string') {
      throw new Error(error.msg);
    }
  }
}
