import * as React from 'react';
import { isEmpty } from 'lodash';
import { usingPricingGroup } from '../lib/helpers';
import {
  BillingUom,
  flatFeeScopes,
  PricingOptions,
  PricingScope,
  PricingScopeData,
  StorageBillingMode,
  warehouseExpenseScopes,
} from '../CommonInterfaces';

class PricingService {
  public handlePricingValidationError = (responseError: any) => {
    window.scrollTo(0, 0);
    const pricingFormErrors = {};
    let reservationErrorMessage;
    if (typeof responseError !== 'string') {
      // eslint-disable-next-line complexity -- Disabled pre-existing violation of complexity rule
      responseError.forEach((errorObj) => {
        const invGroupKey =
          errorObj.inventory_group && errorObj.inventory_group >= 0 ? errorObj.inventory_group : 'default';
        pricingFormErrors[invGroupKey] = pricingFormErrors[invGroupKey] || {};
        const packagingGroupKey = errorObj.packaging ? errorObj.packaging : 'default';

        pricingFormErrors[invGroupKey][packagingGroupKey] = pricingFormErrors[invGroupKey][packagingGroupKey] || {};
        pricingFormErrors[invGroupKey][packagingGroupKey][errorObj.type] =
          pricingFormErrors[invGroupKey][packagingGroupKey][errorObj.type] || {};

        const errors = errorObj.error;
        for (const errorKey in errors) {
          if (errors.hasOwnProperty(errorKey)) {
            if (errors[errorKey].hasOwnProperty('rownum')) {
              pricingFormErrors[invGroupKey][packagingGroupKey][errorObj.type][errorKey] =
                pricingFormErrors[invGroupKey][packagingGroupKey][errorObj.type][errorKey] || {};
              const rownum = 'rownum';
              const error = 'error';
              pricingFormErrors[invGroupKey][packagingGroupKey][errorObj.type][errorKey][errors[errorKey][rownum]] =
                errors[errorKey][error].join(', ');
            } else {
              pricingFormErrors[invGroupKey][packagingGroupKey][errorObj.type][errorKey] = errors[errorKey].join(', ');
            }
          }
        }
      });
      reservationErrorMessage = <p>Please fix the form errors below.</p>;
    } else {
      reservationErrorMessage = responseError;
    }
    return {
      reservationErrorMessage,
      pricingFormErrors,
    };
  };

  public checkForIncorrectConfiguration = (
    pricingScopeData: PricingScopeData,
    pricingOptions: PricingOptions,
    metaData: any,
  ) => {
    const configErrors = {};

    // Next, if any fees on a supported service are not set, then it's not ok
    pricingScopeData.forEach((pricingScope: PricingScope) => {
      Object.keys(pricingScope.pricing_scopes).forEach((scopeKey) => {
        if (
          (flatFeeScopes.includes(scopeKey) ||
            (pricingOptions.warehouse_expense_pricing_scope && warehouseExpenseScopes.includes(scopeKey))) &&
          (pricingScope.pricing_axes.packaging === undefined ||
            pricingScope.pricing_axes.packaging === null ||
            this.hasUndefinedFee(pricingScope.pricing_scopes[scopeKey]) ||
            this.notUsingPricingGroup(pricingScope.pricing_scopes[scopeKey]))
        ) {
          configErrors[scopeKey] = metaData[scopeKey].name;
        } else if (pricingOptions[scopeKey] && this.notUsingPricingGroup(pricingScope.pricing_scopes[scopeKey])) {
          configErrors[scopeKey] = metaData[scopeKey].name;
        }
      });
    });

    return configErrors;
  };

  public removeEmptyServices = (pricingScopeData: PricingScopeData, pricingOptions: PricingOptions) => {
    return pricingScopeData.map((pricingScope: PricingScope) => {
      for (const scopeKey in pricingScope.pricing_scopes) {
        if (
          !warehouseExpenseScopes.includes(scopeKey) &&
          !flatFeeScopes.includes(scopeKey) &&
          !pricingOptions[scopeKey]
        ) {
          delete pricingScope.pricing_scopes[scopeKey];
        }
      }
      if (!isEmpty(pricingScope.pricing_scopes)) {
        return pricingScope;
      }
    });
  };

  public processReservationConfigErrors = (configErrors: any) => {
    const reservationErrorMessage = (
      <ul>
        {Object.keys(configErrors).map((errorKey: any, idx: number) => {
          if (typeof configErrors[errorKey] === 'string') {
            const suffix = configErrors[errorKey] === 'Storage' ? '' : ' or remove the service.';
            return (
              <li key={idx}>
                Please either enter all fees for <b>{configErrors[errorKey]}</b>
                {suffix}
              </li>
            );
          }
        })}
      </ul>
    );
    return reservationErrorMessage;
  };

  private notUsingPricingGroup = (data: any) => {
    if (data instanceof Array) {
      for (const obj of data) {
        if (obj === undefined) {
          continue;
        }
        if (!usingPricingGroup(obj)) {
          return true;
        }
      }
    } else {
      return !usingPricingGroup(data);
    }
  };

  private hasUndefinedFee = (data: any) => {
    if (data instanceof Array) {
      for (const obj of data) {
        if (obj === undefined) {
          continue;
        }
        if (Object.keys(obj).includes('depositor_undefined_fee')) {
          return true;
        }
      }
    } else {
      return Object.keys(data).includes('depositor_undefined_fee');
    }
  };
}

export default PricingService;
