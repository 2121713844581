import * as React from 'react';
import { useContext, createContext, useState } from 'react';
import { RequiredError } from '@ory/client/dist/base';
import { Configuration as OryConfig, FrontendApi, Identity, LogoutFlow, Session } from '@ory/client';
import Configuration from './config';
import { AxiosError } from 'axios';

// Reference implementations:
// https://v5.reactrouter.com/web/example/auth-workflow
// https://github.com/ory/elements/tree/main/examples/react-spa
//
// server-side rendering:
// https://github.com/ory/kratos-selfservice-ui-node/blob/master/src/pkg/middleware.ts

const ourBasePath = `${window.location.protocol}//${window.location.host}`;

class OryClient {
  private static instance: FrontendApi;
  static async getInstance(): Promise<FrontendApi> {
    if (!OryClient.instance) {
      const config = await Configuration.getInstance().ready();
      OryClient.instance = new FrontendApi(
        new OryConfig({
          basePath: config.getSetting('publicKratosBaseUrl'),
          baseOptions: {
            withCredentials: true,
          },
        }),
      );
    }
    return OryClient.instance;
  }
}

const ssoAuthenticator = {
  logoutUrl: null,
  async signin() {
    // OK. So I don't think this is actually the exact right thing to do, with the existence of the sso-web-app
    // I **believe** that we actually should be redirecting the user to sso-web-app and let it create the flow without
    // demanding the user use flexe-okta. However, considering our admin users /must/ always use flexe-okta to log in
    // this is fine to leave here for now. More research to be done in DEVEX-1895
    const ory = await OryClient.getInstance();
    ory.createBrowserLoginFlow({ returnTo: ourBasePath }).then(({ data: flow }) => {
      const flowId = flow.id;
      ory
        .updateLoginFlow({ flow: flowId, updateLoginFlowBody: { method: 'oidc', provider: 'flexe-okta' } })
        .then(() => {
          // we successfully submitted the login flow, so lets redirect to the dashboard
          window.location.replace('/');
        })

        .catch((error: AxiosError) => {
          const responseData: any = error.response?.data || {};

          // Catching the response code to redirect the browser in an SPA, per:
          // https://github.com/ory/elements/blob/87f0f2478687700837b8e0fa1a28e5d64c5fb57a/examples/react-spa/src/sdk.ts#L120
          switch (error.response?.status) {
            case 422: {
              if (responseData.redirect_browser_to !== undefined) {
                window.location = responseData.redirect_browser_to;
                return Promise.resolve();
              }
            }
          }

          console.error(error);

          throw error;
        });
    });
  },

  async getSession(cb: (user: Session) => void) {
    const ory = await OryClient.getInstance();
    ory
      .toSession() // calls /sessions/whoami
      .then(({ data: session }: { data: Session }) => {
        ory
          .createBrowserLogoutFlow({ returnTo: `${ourBasePath}` })
          .then(({ data: logoutFlow }: { data: LogoutFlow }) => {
            // Get also the logout url
            ssoAuthenticator.logoutUrl = logoutFlow.logout_url;
            cb(session);
          })
          .catch((err: RequiredError) => {
            console.warn('ssoAuthenticator: logout unavailable:', err.name);
            cb(null);
          });
      })
      .catch((err: RequiredError) => {
        console.warn('ssoAuthenticator: session data unavailable:', err.name);
        cb(null);
      });
  },
  signout(cb: Function) {
    // window.location.assign(ssoAuthenticator.logoutUrl);
    cb(ssoAuthenticator.logoutUrl);
  },
};

type SsoAuth = {
  user: string;
  getSession: (cb: (session: Session | null) => void) => void;
  signin: () => void;
  signout: () => void;
};

/** For more details on
 * `authContext`, `ProvideAuth`, `useAuth` and `useProvideAuth`
 * refer to: https://hooks.reactivers.com/use-auth
 */
export const authContext = createContext<SsoAuth>({
  user: null,
  getSession: () => {},
  signin: () => {},
  signout: () => {},
});

export function useAuthContext() {
  return useContext(authContext);
}

function useAuthState(): SsoAuth {
  const [user, setUser] = useState(null);
  // @ts-ignore
  const getUserName = (identity?: Identity) => identity?.metadata_public.email;

  const signin = () => {
    ssoAuthenticator.signin();
  };

  const getSession = (cb: (session: Session | null) => void) => {
    ssoAuthenticator.getSession((session) => {
      if (session) {
        setUser(getUserName(session.identity));
      }
      cb(session);
    });
  };

  const signout = () => {
    ssoAuthenticator.signout((logouturl: string) => {
      setUser(null);
      window.location.assign(logouturl);
    });
  };

  return {
    user,
    getSession,
    signin,
    signout,
  };
}

export function ProvideAuth({ children }) {
  const auth = useAuthState();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}
