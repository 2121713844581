import { cloneDeep } from 'lodash';
import { StorageBillingMode } from '../CommonInterfaces';
import { BillingLeverDetails, PricingCatalog } from '../services/data/PricingCatalogInterfaces';

export interface PricingData {
  name: string;
  key: string;
  billing_lever?: string;
  description?: string;
}

export interface ScopeInformation {
  name: string;
  key: string;
  pricingData?: PricingData[];
}

export interface Metadata {
  [scope: string]: ScopeInformation;
}

const defaultPricingMetaData: Metadata = {
  storage_pricing_scope: {
    name: 'Storage',
    key: 'storage_pricing_scope',
  },
  container_pricing_scope: {
    name: 'Container Unload',
    key: 'container_pricing_scope',
    pricingData: [
      { name: 'Palletize Labor', key: '_labor_fee', billing_lever: 'container_receipt' },
      {
        name: 'Per Pallet Materials',
        key: '_material_fee',
        billing_lever: 'receiving_and_putaway_per_pallet_materials',
      },
      { name: 'Per Item Unload', key: '_per_item_labor_fee', billing_lever: 'legacy_item_receipt_floor_loaded_parcel' },
      {
        name: 'Per Item Equivalent Unload',
        key: '_per_palletized_item_labor_fee',
        billing_lever: 'legacy_item_equivalent_receipt_palletized',
      },
      { name: 'Per LPN', key: '_inbound_lpn_complete_fee', billing_lever: 'lpn_receipt_palletized' },
    ],
  },
  consumer_fulfillment_pricing_scope: {
    name: 'eCommerce Fulfillment',
    key: 'consumer_fulfillment_pricing_scope',
    pricingData: [
      { name: 'Per eCommerce Shipment', key: '_flat_fee', billing_lever: 'per_ecomm_shipment' },
      {
        name: 'Per eCommerce Shipment Packing Slip',
        key: '_packing_slip_fee',
        billing_lever: 'per_ecomm_shipment_packing_slip',
      },
      { name: 'Per Unit Picked', key: '_labor_fee', billing_lever: 'line_quantity_pick' },
    ],
  },
  retail_fulfillment_pricing_scope: {
    name: 'Retail Fulfillment',
    key: 'retail_fulfillment_pricing_scope',
    pricingData: [
      { name: 'Per Order', key: '_flat_fee', billing_lever: 'shipment_fee' },
      { name: 'Per Unit Picked', key: '_per_unit_pick_fee', billing_lever: 'line_quantity_pick_freight' },
      { name: 'Per Unit VAS', key: '_per_unit_value_added_service_fee', billing_lever: 'unit_vas' },
      { name: 'Per Pallet VAS', key: '_per_pallet_value_added_service_fee', billing_lever: 'pallet_vas' },
      { name: 'Per Pallet Materials', key: '_per_pallet_materials_fee', billing_lever: 'picking_per_pallet_materials' },
      { name: 'Per LPN', key: '_outbound_lpn_complete_fee', billing_lever: 'pallet_pick_lpn' },
    ],
  },
  returns_pricing_scope: {
    name: 'Returns',
    key: 'returns_pricing_scope',
    pricingData: [
      { name: 'Per Unit', key: '_flat_fee', billing_lever: 'return_unit_received' },
      { name: 'Per Unit Refurbished', key: '_refurbish_fee', billing_lever: 'return_unit_refurbished' },
      { name: 'Per Unit Re-Stocked', key: '_restock_fee', billing_lever: 'return_unit_restocked' },
      { name: 'Per Unit Damaged', key: '_removal_fee', billing_lever: 'return_unit_damaged' },
    ],
  },
  sort_center_pricing_scope: {
    name: 'Sortation',
    key: 'sort_center_pricing_scope',
    pricingData: [{ name: 'Per Unit Shipped', key: '_sortation_fee', billing_lever: 'sortation' }],
  },
  handling_expense_pricing_scope: {
    name: 'Handling Services Expense',
    key: 'handling_expense_pricing_scope',
  },
  labor_expense_pricing_scope: {
    name: 'Labor Expense',
    key: 'labor_expense_pricing_scope',
  },
  storage_expense_pricing_scope: {
    name: 'Storage Services Expense',
    key: 'storage_expense_pricing_scope',
  },
  supplies_expense_pricing_scope: {
    name: 'Supplies Expense',
    key: 'supplies_expense_pricing_scope',
  },
  transportation_expense_pricing_scope: {
    name: 'Transportation Expense',
    key: 'transportation_expense_pricing_scope',
  },
  other_expense_pricing_scope: {
    name: 'Other Expense',
    key: 'other_expense_pricing_scope',
  },
  flat_fee_storage_pricing_scope: {
    name: 'Storage Flat Fee',
    key: 'flat_fee_storage_pricing_scope',
  },
  flat_fee_inboud_pricing_scope: {
    name: 'Inbound Flat Fee',
    key: 'flat_fee_inbound_pricing_scope',
  },
  flat_fee_outbound_pricing_scope: {
    name: 'Outbound Flat Fee',
    key: 'flat_fee_outbound_pricing_scope',
  },
  flat_fee_other_pricing_scope: {
    name: 'Other Flat Fee',
    key: 'flat_fee_other_pricing_scope',
  },
};

function newStorageFeeKeyFromBillingMode(storageBillingMode: String) {
  if (storageBillingMode === StorageBillingMode.nightly_rounded_peak_pallet_equivalents) {
    return {
      name: 'Rounded Pallet Equivalent Storage',
      key: '_rounded_pallet_equivalent_fee',
      billing_lever: 'rounded_pallet_equivalent_storage',
    };
  } else if (storageBillingMode === StorageBillingMode.peak_lpn_pallet) {
    return {
      name: 'LPN Max Daily Count Storage',
      key: '_lpn_daily_maximum_fee',
      billing_lever: 'lpn_max_daily_count_storage',
    };
  } else if (storageBillingMode === StorageBillingMode.inner_system_none) {
    return { name: 'Storage Fee (N/A)', key: '_none_fee', billng_lever: '' };
  } else {
    return { name: 'Storage Fee', key: '_storage_fee', billing_lever: '' }; // Read support for deprecated storage billing modes on old reservations
  }
}

function populatePricingCatalogNames(metadata: Metadata, pricingCatalog: PricingCatalog) {
  const metaDataToUpdate = cloneDeep(metadata);
  for (const scopeKey in metaDataToUpdate) {
    if (!metaDataToUpdate.hasOwnProperty(scopeKey)) {
      continue;
    }
    const pricingData = metaDataToUpdate[scopeKey].pricingData;
    if (pricingData == null) {
      continue;
    }
    pricingData.map((pricingDatum: PricingData) => {
      const billing_lever = pricingDatum.billing_lever;
      if (billing_lever == null) {
        return pricingDatum;
      }
      const billingLeverDetails: BillingLeverDetails = pricingCatalog[billing_lever];
      if (billingLeverDetails == null) {
        return pricingDatum;
      }
      pricingDatum.name = billingLeverDetails.display_name;
      pricingDatum.description = billingLeverDetails.description;
      return pricingDatum;
    });
  }
  return metaDataToUpdate;
}

function fetchPricingMetaData(storageBillingMode: String, pricingCatalog: PricingCatalog) {
  let metadata = cloneDeep(defaultPricingMetaData);
  metadata.storage_pricing_scope.pricingData = [newStorageFeeKeyFromBillingMode(storageBillingMode)];
  metadata = populatePricingCatalogNames(metadata, pricingCatalog);
  return metadata;
}

export { fetchPricingMetaData };
