import * as React from 'react';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const styles = {
  cell: {
    paddingRight: '10px',
    paddingLeft: '20px',
    textAlign: 'center' as const,
  },
  fileNameCell: {
    paddingRight: '4px',
    paddingLeft: '10px',
    wordBreak: 'break-all' as const,
  },
  checkboxCell: {
    paddingRight: '10px',
    paddingLeft: '40px',
  },
  documentTable: {
    tableLayout: 'fixed' as const,
  },
};

function renderDocumentRow(index: number, row: any, onClickDelete, scopeDocumentUnEditable) {
  return (
    <TableRow key={index}>
      <TableCell style={styles.fileNameCell}>
        <a href={`//${window.location.host}/v2/file-storage/content/${row.fileKey}`} target="_blank">
          {row.fileName}
        </a>
      </TableCell>
      <TableCell style={styles.cell}>
        {row.kind === 'WSA' && <p>WSA</p>}
        {row.kind === 'WSA_AMENDMENT' && <p>Amendment to WSA</p>}
        {row.kind === 'NON_SITE_SERVICE_PRICING_TERMS' && <p>Non-site service pricing terms</p>}
      </TableCell>
      <TableCell style={styles.checkboxCell}>
        <FormControlLabel
          disabled={true}
          control={<Checkbox className="checkbox" name="depositor" checked={row.visibleToShipper} />}
          label="Depositor"
        />
        <FormControlLabel
          disabled={true}
          control={<Checkbox className="checkbox" name="warehouse" checked={row.visibleToWarehouse} />}
          label="Warehouse"
        />
      </TableCell>
      <TableCell style={styles.cell}>
        <Button aria-label="delete" onClick={() => onClickDelete(index)} disabled={scopeDocumentUnEditable}>
          <DeleteIcon />
        </Button>
      </TableCell>
    </TableRow>
  );
}

export default function DocumentTable(props) {
  return (
    <div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={styles.fileNameCell}>Name</TableCell>
            <TableCell style={styles.cell}>Type</TableCell>
            <TableCell style={styles.checkboxCell}>Show to who</TableCell>
            <TableCell style={styles.cell}>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map((row, index) => {
            return renderDocumentRow(index, row, props.onClickDelete, props.scopeDocumentUnEditable);
          })}
        </TableBody>
      </Table>
    </div>
  );
}
