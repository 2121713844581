import * as React from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FLAT_BTN, RAISED_BTN } from '../../lib/constants';
import { PropTypes } from '@material-ui/core';
import Color = PropTypes.Color;

interface Props {
  showSpinner: boolean;
  type: typeof RAISED_BTN | typeof FLAT_BTN;
  label?: string;
  color?: Color;
  disabled?: boolean;
  onClick(event);
}

const SubmitButton = ({ showSpinner = false, type = RAISED_BTN, ...otherProps }: Props) => {
  const spinner = <CircularProgress size={24} thickness={2.5} />;
  return (
    <Button {...otherProps} variant={type === RAISED_BTN ? 'contained' : 'text'}>
      {showSpinner ? spinner : null}
      {otherProps.label ? otherProps.label : 'Submit'}
    </Button>
  );
};

export default SubmitButton;
