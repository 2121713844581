import { PricingScopeData, warehouseExpenseScopes } from '../../CommonInterfaces';

class WarehouseExpenseValidator {
  public static validateWarehouseExpenses(pricingScopeData: PricingScopeData): string[] {
    const errors: string[] = [];
    for (const pricingData of pricingScopeData) {
      for (const key in pricingData.pricing_scopes) {
        if (warehouseExpenseScopes.includes(key)) {
          if (pricingData.pricing_axes.packaging === undefined) {
            errors.push('At least one expense has no UOM selected');
          }
          for (const index in pricingData.pricing_scopes[key]) {
            if (pricingData.pricing_scopes[key].hasOwnProperty(index)) {
              for (const eventKey in pricingData.pricing_scopes[key][index]) {
                if (pricingData.pricing_scopes[key][index].hasOwnProperty(eventKey)) {
                  const eventValue = pricingData.pricing_scopes[key][index][eventKey];
                  this.validateFee(eventKey, eventValue, errors);
                }
              }
            }
          }
        }
      }
    }
    // Deduplicate errors before returning
    return errors.filter((error, index) => errors.indexOf(error) === index);
  }

  private static validateFee(eventKey, eventValue, errors: string[]) {
    if (eventKey.endsWith('_fee')) {
      const fee = eventKey.replace('depositor_', '').replace('warehouse_', '').replace('_fee', '');
      if (fee === 'undefined' || fee === '') {
        errors.push('At least one expense has no type selected');
      }
      if (eventValue === undefined || eventValue === '') {
        const feeDisplay = fee === 'undefined' || fee === '' ? 'UNSELECTED TYPE' : fee;
        if (eventKey.includes('depositor_')) {
          errors.push(`${feeDisplay}: No shipper pricing entered`);
        } else {
          errors.push(`${feeDisplay}: No warehouse pricing entered`);
        }
      }
    }
  }
}

export default WarehouseExpenseValidator;
