import * as React from 'react';
import Form from '../../Form';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import ContentClear from '@material-ui/icons/Clear';
import ActionDone from '@material-ui/icons/Done';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { InventoryGroup } from '../../../CommonInterfaces';
import './inventoryGroupsManagement.css';

const styles = {
  inventoryGroupDescription: {
    width: 'calc(100% - 96px)',
    maxWidth: 216,
  },
};

interface InventoryGroupsManagementProps {
  inventoryGroups: InventoryGroup[];
  onInventoryGroupsReturned?();
  onAddInventoryGroup();
  onCancelEditInventoryGroup(event: React.MouseEvent<HTMLElement>);
  onCreateOrUpdateInventoryGroup(event: React.MouseEvent<HTMLElement>);
}

interface InventoryGroupsManagementState {
  addAgroupHover: boolean;
}

class InventoryGroupsManagement extends Form<InventoryGroupsManagementProps, InventoryGroupsManagementState> {
  private saved: boolean;

  constructor(props) {
    super(props);

    this.state = {
      addAgroupHover: false,
    };
  }

  public render() {
    const {
      inventoryGroups,
      onCancelEditInventoryGroup,
      onCreateOrUpdateInventoryGroup,
      onAddInventoryGroup,
      disableForm,
    } = this.props;

    return (
      <fieldset>
        <legend>Inventory Groups</legend>
        {inventoryGroups.length ? (
          <table className="inventory-groups">
            <thead>
              <tr>
                <th>Enable</th>
                <th>Group</th>
              </tr>
            </thead>
            <tbody>
              {inventoryGroups.map((invGroup: InventoryGroup, idx: number) => {
                const keyPrefix = `pricing.inventoryGroups[${idx}]`;
                const disableCheckbox =
                  invGroup.description.trim() === '' || invGroup.description !== invGroup.tempDescription;
                return (
                  <tr key={idx}>
                    <td>
                      <Checkbox
                        name={`${keyPrefix}.enabled`}
                        checked={invGroup.enabled}
                        onChange={this.updateFieldValue}
                        disabled={disableCheckbox || disableForm}
                      />
                    </td>
                    <td>
                      <TextField
                        name={`${keyPrefix}.tempDescription`}
                        autoFocus={!this.saved}
                        value={invGroup.tempDescription}
                        onChange={this.updateFieldValue}
                        style={styles.inventoryGroupDescription}
                        disabled={disableForm}
                      />
                      {!invGroup.saved || invGroup.tempDescription !== invGroup.description ? (
                        <div className="cancel-or-submit-buttons">
                          <IconButton data-inv-group-idx={idx} onClick={onCancelEditInventoryGroup}>
                            <ContentClear color="error" />
                          </IconButton>
                          <IconButton data-inv-group-idx={idx} onClick={onCreateOrUpdateInventoryGroup}>
                            <ActionDone color="primary" />
                          </IconButton>
                        </div>
                      ) : null}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : null}

        <Button
          onMouseEnter={this.onAddAgroupMouseEnter}
          onMouseLeave={this.onAddAgroupMouseLeave}
          onClick={onAddInventoryGroup}
        >
          <AddCircleIcon />
          Add a Group
        </Button>
      </fieldset>
    );
  }

  private onAddAgroupMouseEnter = () => {
    this.setState({ addAgroupHover: true });
  };

  private onAddAgroupMouseLeave = () => {
    this.setState({ addAgroupHover: false });
  };
}

export default InventoryGroupsManagement;
