import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import { errorRed } from '../../styles/flexeColors';

const styles = {
  filterTextField: {
    width: 'calc(100% - 48px)',
  },
  filterIconButton: {
    verticalAlign: 'middle',
  },
};

interface Props {
  index: number;
  value: string;
  type?: string;
  disabled: boolean;
  updateFilter(event);
  removeFilter(event);
}

const Filter = ({ index, value, type, updateFilter, removeFilter, disabled }: Props) => {
  return (
    <li>
      <TextField
        id={'filter_' + index}
        name={'filter_' + index}
        type={type || 'text'}
        autoFocus
        inputProps={{ 'data-index': index }}
        value={value}
        onChange={updateFilter}
        style={styles.filterTextField}
        disabled={disabled}
      />
      <IconButton style={styles.filterIconButton} data-index={index} onClick={removeFilter} disabled={disabled}>
        <ClearIcon color="error" />
      </IconButton>
    </li>
  );
};

export default Filter;
