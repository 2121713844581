import * as React from 'react';

class Finance extends React.Component {
  public render() {
    return (
      <div className="page">
        <h3>Generate SKU Equivalents Report</h3>
        <p>This page has been moved to old admin, under the Finance & Legal menu.</p>
      </div>
    );
  }
}

export default Finance;
