import { isEmpty } from 'lodash';
import { PricingGroup } from '../CommonInterfaces';

function usingPricingGroup(pricingGroup: PricingGroup): boolean {
  if (!pricingGroup) {
    return false;
  }

  for (const price in pricingGroup) {
    if (pricingGroup.hasOwnProperty(price)) {
      const priceInt = parseInt(pricingGroup[price], 10);

      // Can not delete warehouse expense pricing issue: https://flexe-inc.atlassian.net/browse/BLNG-3147
      // exclude to check the price which end at '_description' is not an long term fix.
      // TO DO: we will investigate what usingPricingGroup() try to do, and find a good way to solve the problem.
      if (price.endsWith('_description') || (price !== 'id' && !Number.isNaN(priceInt))) {
        return true;
      }
    }
  }

  return false;
}

function nullPricingValues(pricingGroup: PricingGroup) {
  const nulledPricingGroup = {};
  for (const feeKey in pricingGroup) {
    if (pricingGroup.hasOwnProperty(feeKey)) {
      const feeValue = pricingGroup[feeKey];
      nulledPricingGroup[feeKey] = feeValue === '' ? null : feeValue;
    }
  }
  return nulledPricingGroup;
}

function capitalize(str: string) {
  if (typeof str !== 'string' || str.length === 0) {
    return;
  }
  return str[0].toUpperCase() + str.slice(1);
}

function guidish(): string {
  // TODO: this is used in only one place, so it can likely be dug into and resolved
  // tslint:disable-next-line:insecure-random
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}

function isWarehouseExpense(scope) {
  return [
    'handling_expense_pricing_scope',
    'labor_expense_pricing_scope',
    'storage_expense_pricing_scope',
    'supplies_expense_pricing_scope',
    'transportation_expense_pricing_scope',
    'other_expense_pricing_scope',
  ].includes(scope);
}

function isFlatFee(scope) {
  return [
    'flat_fee_inbound_pricing_scope',
    'flat_fee_outbound_pricing_scope',
    'flat_fee_other_pricing_scope',
    'flat_fee_storage_pricing_scope',
  ].includes(scope);
}

function inventoryGroupHasErrors(formErrors) {
  return tabHasErrors(formErrors, (scopeKey) => isWarehouseExpense(scopeKey) || isFlatFee(scopeKey));
}

function warehouseExpenseHasErrors(formErrors) {
  return tabHasErrors(formErrors, (scopeKey) => !isWarehouseExpense(scopeKey));
}

function flatFeeHasErrors(formErrors) {
  return tabHasErrors(formErrors, (scopeKey) => !isFlatFee(scopeKey));
}

function tabHasErrors(formErrors, exclusionCallBack) {
  if (!formErrors || isEmpty(formErrors)) {
    return false;
  }
  for (const packageType in formErrors) {
    if (formErrors.hasOwnProperty(packageType)) {
      for (const scopeKey in formErrors[packageType]) {
        if (exclusionCallBack(scopeKey)) {
          continue;
        }
        if (formErrors[packageType].hasOwnProperty(scopeKey)) {
          for (const feeKey in formErrors[packageType][scopeKey]) {
            if (formErrors[packageType][scopeKey].hasOwnProperty(feeKey)) {
              return true;
            }
          }
        }
      }
    }
  }
}

function hasOwnProperty<X extends {}, Y extends PropertyKey>(obj: X, prop: Y): obj is X & Record<Y, unknown> {
  return obj.hasOwnProperty(prop);
}

// IE-compatible parsing from:
// https://stackoverflow.com/questions/979975/how-to-get-the-value-from-the-get-parameters
function parseQueryString(query: string): object {
  const vars = query.split('&');
  const queryString = {};
  for (const queryVar of vars) {
    const pair = queryVar.split('=');
    const key = decodeURIComponent(pair[0]);
    const value = decodeURIComponent(pair[1]);
    // If first entry with this name
    if (typeof queryString[key] === 'undefined') {
      queryString[key] = decodeURIComponent(value);
      // If second entry with this name
    } else if (typeof queryString[key] === 'string') {
      const arr = [queryString[key], decodeURIComponent(value)];
      queryString[key] = arr;
      // If third or later entry with this name
    } else {
      queryString[key].push(decodeURIComponent(value));
    }
  }
  return queryString;
}

export {
  usingPricingGroup,
  nullPricingValues,
  capitalize,
  guidish,
  inventoryGroupHasErrors,
  warehouseExpenseHasErrors,
  flatFeeHasErrors,
  hasOwnProperty,
  parseQueryString,
};
