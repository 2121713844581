/* eslint-disable max-lines -- Disabled pre-existing violation of max lines rule */
import * as React from 'react';
import { get } from 'lodash';
import { compose, filter, map, sortBy, uniqBy } from 'lodash/fp';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Popover from '@material-ui/core/Popover';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

import AlertWarning from '@material-ui/icons/Warning';
import ContentAdd from '@material-ui/icons/AddCircle';
import ContentRemove from '@material-ui/icons/RemoveCircle';
import Edit from '@material-ui/icons/Edit';
import Cancel from '@material-ui/icons/Cancel';
import { errorRed, flexeBlue, white } from '../../styles/flexeColors';
import { PACKAGING_TYPE_MAP } from '../../lib/constants';
import { InventoryPackaging, LocationInfo } from '../../CommonInterfaces';
import './locationsTable.css';

const styles = {
  skuQtyTextField: {
    width: 64,
  },
  adjSkuQtyTextField: {
    width: 64,
    verticalAlign: 'middle',
    marginRight: 20,
  },
  adjSkuQtySelectField: {
    width: 'auto',
    verticalAlign: 'middle',
    marginRight: 20,
  },
  addRemoveButtons: {
    backgroundColor: flexeBlue,
    borderRadius: 3,
    verticalAlign: 'middle',
    width: 38,
    height: 38,
    padding: 0,
  },
};

interface LocationsTableProps {
  locations: LocationInfo[];
  errors: any;
  errorsCount: number;
  editMode: boolean;
  updateSkuQty(
    locationsArrayIndex: number,
    convertedOrigQty: number,
    newSkuQty?: number,
    newPackaging?: InventoryPackaging,
  );
}

interface LocationsTableState {
  hasPallets: boolean;
  hasLpns: boolean;
  showAdjSkuQtyPopover: boolean;
  showEditWarningDialog: boolean;
  tempSkuQtyAdj?: number;
  tempSkuPackaging?: InventoryPackaging;
  selectedSkuTextField?: HTMLInputElement;
  selectedLocation?: LocationInfo;
  packageSelectLocIndex?: number;
  skuQtyErrorMsg: string;
  editWarningContinueMethod?();
}

class LocationsTable extends React.Component<LocationsTableProps, LocationsTableState> {
  private pendingPackageSelecLocIndex?: number;

  constructor(props) {
    super(props);

    const hasPallets = props.locations.some((location: LocationInfo) => {
      return location.pallet_id;
    });

    const hasLpns = props.locations.some((location: LocationInfo) => {
      return location.lpn_barcode;
    });

    this.state = {
      hasPallets,
      hasLpns,
      showAdjSkuQtyPopover: false,
      showEditWarningDialog: false,
      editWarningContinueMethod: null,
      tempSkuQtyAdj: null,
      tempSkuPackaging: null,
      selectedSkuTextField: null,
      selectedLocation: null,
      packageSelectLocIndex: null,
      skuQtyErrorMsg: '',
    };
  }

  public render() {
    if (!this.props.locations || this.props.locations.length === 0) {
      return (
        <p className="no-locations">
          There are no locations loaded. Please select a reservation or warehouse to see the associated locations.
        </p>
      );
    } else {
      let firstNonDefaultLocFound = false;
      return (
        <div>
          <table className="pure-table locations-table">
            <thead>
              <tr>
                <th className="icon"></th>
                <th className="location-id">ID</th>
                <th className="label">Label</th>
                <th className="reservation-id">Reservation ID</th>
                <th className="sku">SKU</th>
                <th className="quantity">Quantity</th>
                <th className="adjustment">Adj.</th>
                <th className="packaging">Packaging</th>
                {this.props.errorsCount > 0 ? (
                  <th className="issue-type">
                    <AlertWarning color="error" /> Issue Type
                  </th>
                ) : null}
                {this.state.hasPallets ? <th className="palletId">Pallet ID</th> : null}
                {this.state.hasLpns ? <th className="lpnBarcode">LPN</th> : null}
                {this.state.hasLpns ? <th className="inventoryTrackingData">Inventory Tracking Data ID</th> : null}
              </tr>
            </thead>
            {this.groupAndSortLocations(this.props.locations).map((locs) => {
              const classes = [];
              if (locs[0].is_default_location) {
                classes.push('default-location');
              }
              if (!firstNonDefaultLocFound && !locs[0].is_default_location) {
                firstNonDefaultLocFound = true;
                classes.push('first-non-default-location');
              }

              return (
                <tbody key={locs[0].location_id} className={classes.join(' ')}>
                  {locs.map(this.renderSkuRow)}
                </tbody>
              );
            })}
          </table>

          {this.renderAdjSkuQtyPopover()}
          {this.renderLocationEditWarningDialog()}
        </div>
      );
    }
  }

  private groupAndSortLocations(locations: LocationInfo[]): LocationInfo[][] {
    // @ts-ignore
    return compose(
      // 3. Finally, sort each location by several criteria
      sortBy([
        (locs) => !locs[0].is_default_location, // a. Default locations go first
        // @ts-ignore
        (locs) => locs.every((loc) => loc.quantity === ''), // b. Locations with no content go last
        (locs) => locs[0].sku, // c. Sort locations by their first SKU
        (locs) => locs[0].location_id, // d. Sort locations by their location ID
      ]),
      // 2. For each unique location id, create an array of skus that share that id
      map((uniqueLoc) => {
        return compose(
          // Within each location sort skus:
          // a. by existence of contents
          // b. then reservation_id
          // c. and then sku name
          // @ts-ignore
          sortBy([(loc) => loc.quantity === '', 'reservation_id', 'sku']),
          // @ts-ignore
          filter((loc) => loc.location_id === uniqueLoc.location_id),
        )(locations);
      }),
      // 1. Create an array of unique location ids
      uniqBy('location_id'),
      // @ts-ignore
    )(locations);
  }

  private showAdjSkuQtyPopover = (event) => {
    const locIndex = parseInt(event.target.dataset.locationsArrayIndex, 10);
    const selectedLocation = this.props.locations.find((loc: LocationInfo) => {
      return loc.locationsArrayIndex === locIndex;
    });
    if (selectedLocation) {
      const selectedSkuTextField = event.target;
      const tempSkuPackaging = event.target.dataset.packaging as InventoryPackaging;
      let showAdjSkuQtyPopover = false;
      let showEditWarningDialog = false;
      let editWarningContinueMethod = null;

      if (selectedLocation.is_default_location) {
        showEditWarningDialog = true;
        editWarningContinueMethod = this.okToEditStagingLocationQuantity;
      } else {
        showAdjSkuQtyPopover = true;
      }

      this.setState({
        showAdjSkuQtyPopover,
        showEditWarningDialog,
        editWarningContinueMethod,
        selectedSkuTextField,
        selectedLocation,
        tempSkuPackaging,
      });
    }
  };

  private okToEditStagingLocationQuantity = () => {
    this.setState({
      showAdjSkuQtyPopover: true,
      showEditWarningDialog: false,
    });
  };

  private hideAdjSkuQtyPopover = () => {
    this.setState({
      showAdjSkuQtyPopover: false,
      selectedSkuTextField: null,
      selectedLocation: null,
      tempSkuQtyAdj: null,
      skuQtyErrorMsg: '',
    });
  };

  private updateTempSkuQtyAdj = (event) => {
    this.setState({ tempSkuQtyAdj: parseInt(event.target.value, 10) });
  };

  private updateTempSkuPackaging = (event) => {
    const tempSkuPackaging = get(event, 'target.value');
    this.setState({ tempSkuPackaging });
  };

  private updateAndSetTempSkuPackaging = (event) => {
    const tempSkuPackaging = get(event, 'target.value');
    this.setState({ tempSkuPackaging });
    this.updateSkuQty(0, tempSkuPackaging);
  };

  private editPackaging = (event) => {
    const locIndex = parseInt(event.currentTarget.dataset.locationsArrayIndex, 10);
    const selectedLocation = this.props.locations.find((loc: LocationInfo) => {
      return loc.locationsArrayIndex === locIndex;
    });
    if (selectedLocation) {
      const tempSkuPackaging = selectedLocation.packaging;
      let packageSelectLocIndex = null;
      let showEditWarningDialog = false;
      let editWarningContinueMethod = null;

      if (selectedLocation.is_default_location) {
        showEditWarningDialog = true;
        this.pendingPackageSelecLocIndex = locIndex;
        editWarningContinueMethod = this.okToEditStagingLocationPackaging;
      } else {
        packageSelectLocIndex = locIndex;
      }

      this.setState({
        selectedLocation,
        tempSkuPackaging,
        packageSelectLocIndex,
        showEditWarningDialog,
        editWarningContinueMethod,
      });
    }
  };

  private okToEditStagingLocationPackaging = () => {
    this.setState({
      showEditWarningDialog: false,
      packageSelectLocIndex: this.pendingPackageSelecLocIndex,
    });
  };

  private cancelEditPackaging = (event) => {
    const locIndex = parseInt(event.currentTarget.dataset.locationsArrayIndex, 10);
    const selectedLocation = this.props.locations.find((loc: LocationInfo) => {
      return loc.locationsArrayIndex === locIndex;
    });
    if (selectedLocation) {
      this.setState({
        selectedLocation: null,
        tempSkuPackaging: selectedLocation.packaging,
        packageSelectLocIndex: null,
      });
      this.props.updateSkuQty(
        selectedLocation.locationsArrayIndex,
        selectedLocation.quantity,
        selectedLocation.quantity,
        selectedLocation.packaging,
      );
    }
  };

  private hideEditWarningDialog = () => {
    this.setState({ showEditWarningDialog: false });
  };

  /**
   * Calculates the new SKU quantity and passes it up to Locations page component via
   * this.props.updateSkuQty()
   * @param {number} skuQtyDelta - The delta for the new SKU quantity. Can be positive or negative.
   */
  private updateSkuQty(skuQtyDelta: number, tempSkuPackaging?: InventoryPackaging) {
    if (skuQtyDelta === null || typeof skuQtyDelta === 'undefined') {
      return;
    }
    // The original SKU quantity from warehouser
    const origQty = get(this.state, 'selectedLocation.quantity');
    // skuInputQty: The current calculated SKU quantity in the selected input field
    const skuInputQty = parseInt(get(this.state, 'selectedSkuTextField.value', origQty), 10);
    // locationsArrayIndex: The index of this location in the locations array (for quickly finding
    //    and updating)
    const locationsArrayIndex = get(this.state, 'selectedLocation.locationsArrayIndex');
    // origPackaging: The original SKU packaging from warehouser
    const origPackaging = get(this.state, 'selectedLocation.packaging');
    // currentPackaging: The current SKU packaging being used in the browser
    const currentPackaging = get(this.state, 'selectedLocation.newPackaging', origPackaging);
    // newPackaging: The SKU packaging currently selected in the adjust quantity popover (not yet
    //    applied to the location object)
    const newPackaging = tempSkuPackaging || this.state.tempSkuPackaging;
    // conversions: The SKU's packaging conversions object
    const conversions = get(this.state, 'selectedLocation.conversions', {});

    if (skuInputQty && locationsArrayIndex >= 0) {
      // origConversion: The conversion previously applied (if any)
      const origConversion = conversions[origPackaging][newPackaging] || 1;
      // currentConversion: The current conversion (if any)
      const currentConversion = conversions[currentPackaging][newPackaging] || 1;
      // convertedOrigQty: The previous conversion applied to the original SKU quantity (for display)
      const convertedOrigQty = origQty * origConversion;
      // newSkuQty: The current conversion applied to the current quantity (for updating)
      const newSkuQty = skuInputQty * currentConversion + skuQtyDelta;

      if (newSkuQty >= 0) {
        this.props.updateSkuQty(locationsArrayIndex, convertedOrigQty, newSkuQty, newPackaging);
        this.hideAdjSkuQtyPopover();
      } else {
        this.setState({
          skuQtyErrorMsg: 'Resulting SKU quantity must be 0 or greater',
        });
      }
    }
  }

  private onSubmitAdjSkuQtyForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    this.updateSkuQty(this.state.tempSkuQtyAdj);
  };

  private increaseSkuQty = (event) => {
    const form = get(event, 'currentTarget.form');
    if (form) {
      const isValid = form.reportValidity();
      if (isValid) {
        this.updateSkuQty(this.state.tempSkuQtyAdj);
      }
    }
  };

  private decreaseSkuQty = (event) => {
    const form = get(event, 'currentTarget.form');
    if (form) {
      const isValid = form.reportValidity();
      if (isValid && this.state.tempSkuQtyAdj) {
        this.updateSkuQty(-this.state.tempSkuQtyAdj);
      }
    }
  };

  // eslint-disable-next-line complexity -- Disabled pre-existing violation of complexity rule
  private renderAdjSkuQtyPopover() {
    if (this.state.selectedSkuTextField && this.state.selectedLocation) {
      // Disable the package select field if the original packaging is Eaches (we can't down-convert
      // further than that) or the `newQuantity` property is set (quantity has been adjusted)
      const selectedPackaging = get(this.state, 'selectedLocation.packaging');
      const conversions = get(this.state, 'selectedLocation.conversions');
      const newQty = get(this.state, 'selectedLocation.newQuantity');
      const convertToCartonDisabled = !conversions.carton;
      const convertToEachDisabled = !conversions.each;

      const packagingSelectDisabled =
        !!newQty ||
        !conversions ||
        selectedPackaging === 'each' ||
        (selectedPackaging === 'carton' && convertToEachDisabled) ||
        (selectedPackaging === 'pallet' && convertToEachDisabled && convertToCartonDisabled);

      return (
        <Popover
          className="adjust-sku-quantity-popover"
          open={this.state.showAdjSkuQtyPopover}
          anchorEl={this.state.selectedSkuTextField}
          onClose={this.hideAdjSkuQtyPopover}
          style={{ padding: 20 }}
        >
          <form onSubmit={this.onSubmitAdjSkuQtyForm}>
            <h3 className="h4">Adjust Quantity</h3>
            <TextField
              id="adjust_sku_quantity_textfield"
              name="adjust_sku_quantity_textfield"
              value={this.state.tempSkuQtyAdj || ''}
              type="number"
              inputProps={{ min: 1, step: 1 }}
              autoFocus={true}
              onChange={this.updateTempSkuQtyAdj}
              style={styles.adjSkuQtyTextField}
            />
            <Select
              native
              disabled={packagingSelectDisabled}
              value={this.state.tempSkuPackaging}
              onChange={this.updateTempSkuPackaging}
              style={styles.adjSkuQtySelectField}
            >
              <option value="carton" disabled={convertToCartonDisabled}>
                Cartons
              </option>
              <option value="each" disabled={convertToEachDisabled}>
                Eaches
              </option>
            </Select>
            <IconButton
              onClick={this.increaseSkuQty}
              disabled={!this.state.tempSkuQtyAdj}
              style={{ ...styles.addRemoveButtons, marginRight: 10 }}
            >
              <ContentAdd />
            </IconButton>
            <IconButton
              onClick={this.decreaseSkuQty}
              disabled={!this.state.tempSkuQtyAdj}
              style={styles.addRemoveButtons}
            >
              <ContentRemove />
            </IconButton>
          </form>
          {this.state.skuQtyErrorMsg ? <p className="error">{this.state.skuQtyErrorMsg}</p> : null}
        </Popover>
      );
    }
  }

  private renderLocationEditWarningDialog = () => {
    return (
      <Dialog open={this.state.showEditWarningDialog}>
        <DialogTitle id="alert-dialog-title">Edit Staging Location?</DialogTitle>
        <DialogContent>
          <p>Staging location contents are generally not changed directly, are you sure you want to proceed?</p>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={this.hideEditWarningDialog}>
            Cancel
          </Button>
          <Button variant="text" color="primary" onClick={this.state.editWarningContinueMethod}>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  // eslint-disable-next-line complexity -- Disabled pre-existing violation of complexity rule
  private renderSkuRow = (content: LocationInfo, idx: number) => {
    let displayPackaging;
    const origPackaging = content.packaging;
    const newPackaging = content.newPackaging;
    const packaging = newPackaging || origPackaging;

    if (packaging === 'carton' && content.pallet_id) {
      displayPackaging = 'Palletized Cartons';
    } else if (packaging) {
      displayPackaging = PACKAGING_TYPE_MAP[packaging];
    }

    const origQty: number = parseInt(content.quantity, 10);
    const newQty: number = parseInt(content.newQuantity, 10);
    const inputQty = !Number.isNaN(newQty) ? newQty : origQty;

    // Calculate the difference between the new quantity and the old quantity,
    // and adjust it's display according to whether it's positive or negative
    let displayOrigQty: number = origQty;
    let qtyDiff: number = null;
    let adjustment: JSX.Element = null;
    let qtyPackaging: string = null;
    let conversionDisplay: JSX.Element = null;
    if (packaging !== origPackaging && content.conversions) {
      const conversion = content.conversions[origPackaging][newPackaging];
      displayOrigQty = origQty * conversion;
      conversionDisplay = (
        <small className="conversion">
          ({conversion} {displayPackaging} per {PACKAGING_TYPE_MAP[origPackaging]})
        </small>
      );
    }
    if (displayPackaging && displayPackaging !== 'Each') {
      qtyPackaging = ' ' + displayPackaging;
    }
    if (
      typeof newQty !== 'undefined' &&
      newQty !== null &&
      !Number.isNaN(newQty) &&
      (newQty !== displayOrigQty || conversionDisplay)
    ) {
      qtyDiff = newQty - displayOrigQty;
      if (qtyDiff > 0) {
        adjustment = (
          <span>
            <b className="positive">
              +{qtyDiff}
              {qtyPackaging}
            </b>{' '}
            ({displayOrigQty}){conversionDisplay}
          </span>
        );
      } else if (qtyDiff < 0) {
        adjustment = (
          <span>
            <b className="negative">
              {qtyDiff}
              {qtyPackaging}
            </b>{' '}
            ({displayOrigQty}){conversionDisplay}
          </span>
        );
      } else {
        adjustment = <span>{conversionDisplay}</span>;
      }
    }

    const disableEdit =
      !content.sku ||
      !content.packaging ||
      (content.quantity === 0 && content.packaging === 'pallet') ||
      (content.packaging === 'carton' && !!content.pallet_id);

    const isEditing =
      this.state.selectedLocation && this.state.selectedLocation.locationsArrayIndex === content.locationsArrayIndex;

    const editPackaging = this.state.packageSelectLocIndex === content.locationsArrayIndex;
    const showPackagingEditIcon =
      !disableEdit && this.props.editMode && !!content.packaging && content.packaging !== 'each' && !editPackaging;

    const errors = this.props.errors[content.locationsArrayIndex];
    let errorsStr;
    if (errors && errors.length) {
      errorsStr = errors.join(', ');
    }

    return (
      <tr
        key={idx}
        className={[
          disableEdit ? 'disabled' : null,
          isEditing ? 'editing' : null,
          qtyDiff || conversionDisplay ? 'edited' : null,
          errorsStr ? 'update-error' : null,
        ]
          .join(' ')
          .trim()}
      >
        <td className="icon">
          {isEditing || qtyDiff || conversionDisplay ? <Edit color={errorsStr ? 'error' : 'primary'} /> : null}
        </td>
        <td className="location-id">{idx === 0 ? content.location_id : null}</td>
        <td className="label">{idx === 0 ? content.location_label : null}</td>
        <td className="reservation-id">{content.reservation_id ? content.reservation_id : <span>&ndash;</span>}</td>
        <td className="sku">{content.sku ? content.sku : <span>&ndash;</span>}</td>
        <td className="quantity">
          {this.props.editMode ? (
            <TextField
              id={'qty_' + content.locationsArrayIndex}
              inputProps={{
                'data-locations-array-index': content.locationsArrayIndex,
                'data-packaging': packaging,
              }}
              value={inputQty}
              type="number"
              fullWidth={true}
              disabled={disableEdit}
              onClick={this.showAdjSkuQtyPopover}
              style={styles.skuQtyTextField}
            />
          ) : (
            origQty.toString()
          )}
        </td>
        <td className="adjustment">{adjustment}</td>
        <td className="packaging">
          {editPackaging ? (
            <Select
              native
              value={this.state.tempSkuPackaging}
              onChange={this.updateAndSetTempSkuPackaging}
              style={styles.adjSkuQtySelectField}
            >
              <option value="carton">Cartons</option>
              <option value="each">Eaches</option>
            </Select>
          ) : (
            displayPackaging
          )}
          {showPackagingEditIcon ? (
            <IconButton
              data-locations-array-index={content.locationsArrayIndex}
              onClick={this.editPackaging}
              style={{ verticalAlign: 'middle' }}
            >
              <Edit color="primary" style={{ width: 18, height: 18 }} />
            </IconButton>
          ) : null}
          {adjustment ? (
            <IconButton
              data-locations-array-index={content.locationsArrayIndex}
              onClick={this.cancelEditPackaging}
              style={{ verticalAlign: 'middle' }}
            >
              <Cancel color="error" style={{ width: 18, height: 18 }} />
            </IconButton>
          ) : null}
        </td>
        {this.props.errorsCount > 0 ? <td className="issue-type">{errorsStr}</td> : null}
        {this.state.hasPallets ? <td className="palletId">{content.pallet_id}</td> : null}
        {this.state.hasLpns ? <td className="lpnBarcode">{content.lpn_barcode}</td> : null}
        {this.state.hasLpns ? (
          <td className="inventoryTrackingData">
            <a href={`${window.warehouserWebUri}/admin/inventory_tracking_data/${content.inventory_tracking_data_id}`}>
              {content.inventory_tracking_data_id}
            </a>
          </td>
        ) : null}
      </tr>
    );
  };
}

export default LocationsTable;
