import * as React from 'react';
import { cloneDeep, get } from 'lodash';
import Select from '@material-ui/core/Select';
import flexeApi from '../../../lib/flexeApi';
import PriceField from '../../PriceField';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import PercentageField from '../../PercentageField';
import TextField from '@material-ui/core/TextField';
import { PricingCatalog } from '../../../services/data/PricingCatalogInterfaces';
import {
  groupExpenseRowsByCategory,
  WarehouseExpenseRow,
  warehouseExpenseRowComparator,
} from '../../../models/pricing/WarehouseExpenseRow';

interface WarehouseExpenseConfigFormProps {
  expenseRows: WarehouseExpenseRow[];
  formErrors: any;
  disabled: boolean;
  pricingCatalog: PricingCatalog;
  onAddExpenseRow(category: string): void;
  onRemoveExpenseRow(category: string, uom: string, rownum: string): void;
  onExpenseFormShipperRateChange(expenseRow: WarehouseExpenseRow, newRate: any): void;
  onExpenseFormWarehouseRateChange(expenseRow: WarehouseExpenseRow, newRate: any): void;
  onExpenseFormDescriptionChange(expenseRow: WarehouseExpenseRow, newDescription: any): void;
  onChangeExpenseEventName(expenseRow: WarehouseExpenseRow, newEventName: string): void;
  onChangeExpenseUnit(expenseRow: WarehouseExpenseRow, newUnit: string): void;
}

interface WarehouseExpenseConfigFormState {
  warehouseExpenseScopes: any;
  warehouseExpenseUoms: any;
}

enum RateAttribute {
  Description = 'description',
  DepositorRate = 'depositorRate',
  WarehouseRate = 'warehouseRate',
}

enum DropdownName {
  EventName = 'eventName',
  Unit = 'uom',
}

class WarehouseExpenseConfigForm extends React.Component<
  WarehouseExpenseConfigFormProps,
  WarehouseExpenseConfigFormState
> {
  constructor(props) {
    super(props);
    this.state = {
      warehouseExpenseScopes: {},
      warehouseExpenseUoms: {},
    };
  }

  public componentDidMount() {
    flexeApi.getWarehouseExpenseScopes().then((response) => {
      if (response && response.config && response.config.length) {
        this.setState({ warehouseExpenseScopes: response.config[0].data });
      }
    });

    flexeApi.getWarehouseExpenseUoms().then((response) => {
      if (response && response.config && response.config.length) {
        this.setState({ warehouseExpenseUoms: response.config[0].data });
      }
    });
  }

  public render() {
    const { disabled } = this.props;
    const scopes = this.state.warehouseExpenseScopes;
    const sortedExpenseRows = this.props.expenseRows.sort(warehouseExpenseRowComparator);
    const allExpenseRowsByCategory = groupExpenseRowsByCategory(sortedExpenseRows);

    return (
      <fieldset data-testid="warehouse-expense-config-form">
        <h3>Warehouse Expenses Config</h3>
        <table className="pure-table pricing-table">
          <tbody>
            <tr>
              <td>Expense category</td>
              <td>Type</td>
              <td>Description</td>
              <td>UOM</td>
              <td key="shipper.header">Shipper</td>
              <td key="warehouse.header">Warehouse</td>
            </tr>
            {Object.keys(scopes).map((categoryKey: string, Idx: number) => {
              const category = scopes[categoryKey];
              const expenseRows: WarehouseExpenseRow[] = allExpenseRowsByCategory[categoryKey] || [];
              const renderedRows = expenseRows.map((row) => this.renderRow(row));
              const emptyRow = <tr key={categoryKey}></tr>;
              return [
                <tr className="expense-types" key={category.display_string}>
                  <td colSpan={6}>{category.display_string}</td>
                </tr>,
                renderedRows.length ? renderedRows : emptyRow,
                <tr key={Idx}>
                  <td>
                    <IconButton
                      key={`addbutton.${categoryKey}`}
                      data-testid={`addbutton.${categoryKey}`}
                      aria-label="add"
                      disabled={disabled}
                      onClick={() => this.addEmptyRow(categoryKey)}
                    >
                      <AddIcon />
                    </IconButton>
                  </td>
                </tr>,
              ];
            })}
          </tbody>
        </table>
      </fieldset>
    );
  }

  private updateDropdown = (newValue: any, dropdownName: DropdownName, modifiedRowId: any) => {
    const allExpenseRows = this.props.expenseRows;
    const expenseRowIndex = allExpenseRows.findIndex((row) => row.id === modifiedRowId);

    // This should not happen
    if (expenseRowIndex < 0) {
      return { allExpenseRows };
    }
    const originalExpenseRow: WarehouseExpenseRow = cloneDeep(allExpenseRows[expenseRowIndex]);

    if (dropdownName === DropdownName.EventName) {
      this.props.onChangeExpenseEventName(originalExpenseRow, newValue);
    } else if (dropdownName === DropdownName.Unit) {
      this.props.onChangeExpenseUnit(originalExpenseRow, newValue);
    } else {
      throw new Error(`Did not understand dropdownName`);
    }
  };

  private updateRateAttribute = (newValue: any, category: string, rateAttribute: RateAttribute, id: any) => {
    const newValueFormatted =
      rateAttribute !== RateAttribute.Description && category === 'supplies_expense_pricing_scope'
        ? (newValue / 100 + 1).toFixed(2)
        : newValue;
    const allExpenseRows = this.props.expenseRows;
    const expenseRowIndex = allExpenseRows.findIndex((row) => row.id === id);

    // This should not happen
    if (expenseRowIndex < 0) {
      return { allExpenseRows };
    }

    const expenseRow = allExpenseRows[expenseRowIndex];

    if (rateAttribute === RateAttribute.Description) {
      this.props.onExpenseFormDescriptionChange(expenseRow, newValueFormatted);
    } else if (rateAttribute === RateAttribute.DepositorRate) {
      this.props.onExpenseFormShipperRateChange(expenseRow, newValueFormatted);
    } else if (rateAttribute === RateAttribute.WarehouseRate) {
      this.props.onExpenseFormWarehouseRateChange(expenseRow, newValueFormatted);
    } else {
      throw new Error(`Did not understand rateAttribute`);
    }
  };

  private renderRow(row: WarehouseExpenseRow) {
    const category = row.category;
    const { formErrors, disabled } = this.props;
    const scopes = this.state.warehouseExpenseScopes;
    const billingLever = scopes[category].fees[row.eventName]?.billing_lever;
    const allValidUoms = this.state.warehouseExpenseUoms;
    const billingLeverSupportedUnits = this.props.pricingCatalog[billingLever]?.units || [];
    const errorKeyPrefix = `${row.uom}.${category}`;
    const shipperErrorKey = `${errorKeyPrefix}.depositor_${row.eventName}_fee.${row.rownum}`;
    const warehouseErrorKey = `${errorKeyPrefix}.warehouse_${row.eventName}_fee.${row.rownum}`;
    const shipperErrorText = get(formErrors, shipperErrorKey);
    const warehouseErrorText = get(formErrors, warehouseErrorKey);

    const depositorRateCommonProps = {
      'key': `depositorRate.${row.id}`,
      'name': `input.depositor_fee.${row.id}`,
      'errorText': shipperErrorText || '',
      'onChange': (event) =>
        this.updateRateAttribute(event.target.value, category, RateAttribute.DepositorRate, row.id),
      'data-error-key': `${shipperErrorKey}`,
      disabled,
    };

    const warehouseRateCommonProps = {
      'key': `warehouseRate.${row.id}`,
      'name': `input.warehouse_fee.${row.id}`,
      'errorText': warehouseErrorText || '',
      'onChange': (event) =>
        this.updateRateAttribute(event.target.value, category, RateAttribute.WarehouseRate, row.id),
      'data-error-key': `${warehouseErrorKey}`,
      disabled,
    };

    return (
      <tr key={`${category}.${row.id}`}>
        <td>
          <IconButton
            aria-label="delete"
            disabled={disabled}
            id={row.id}
            data-testid={`delete.${row.id}`}
            onClick={(event) => this.deleteRow(category, row.id)}
          >
            <DeleteIcon />
          </IconButton>
        </td>
        <td>
          <Select
            native
            id={row.id}
            data-testid={`select.${row.id}`}
            value={row.eventName}
            disabled={disabled}
            onChange={(event) => this.updateDropdown(event.target.value, DropdownName.EventName, row.id)}
          >
            <option value={''}>Select</option>
            {Object.keys(scopes[category].fees).map((outer) => (
              <option key={outer} value={outer}>
                {scopes[category].fees[outer].display_string}
              </option>
            ))}
          </Select>
        </td>
        <td>
          <TextField
            id={row.id}
            data-testid={`input.description.${row.id}`}
            value={row.description}
            onChange={(event) =>
              this.updateRateAttribute(event.target.value, category, RateAttribute.Description, row.id)
            }
            disabled={disabled}
          />
        </td>
        <td>
          <Select
            native
            id={row.id}
            data-testid={`select.uom.${row.id}`}
            value={row.uom}
            disabled={disabled}
            onChange={(event) => this.updateDropdown(event.target.value, DropdownName.Unit, row.id)}
          >
            <option value={''}>Select</option>
            {billingLeverSupportedUnits.map(
              (unitKey) =>
                allValidUoms[unitKey] && (
                  <option key={unitKey} value={unitKey}>
                    {allValidUoms[unitKey]}
                  </option>
                ),
            )}
          </Select>
        </td>
        <td>
          {category === 'supplies_expense_pricing_scope' ? (
            <PercentageField
              value={row.depositorRate !== '' ? row.depositorRate * 100 - 100 : 0}
              {...depositorRateCommonProps}
            />
          ) : (
            <PriceField value={row.depositorRate} {...depositorRateCommonProps} />
          )}
        </td>
        <td>
          {category === 'supplies_expense_pricing_scope' ? (
            <PercentageField
              value={row.warehouseRate !== '' ? row.warehouseRate * 100 - 100 : 0} // string? number? typing <_<
              {...warehouseRateCommonProps}
            />
          ) : (
            <PriceField value={row.warehouseRate} {...warehouseRateCommonProps} />
          )}
        </td>
      </tr>
    );
  }

  private addEmptyRow = (category) => {
    this.props.onAddExpenseRow(category);
  };

  private deleteRow = (category, deletedRowId) => {
    const allExpenseRows = this.props.expenseRows;

    const expenseRowIndex = allExpenseRows.findIndex((row) => row.id === deletedRowId);

    if (expenseRowIndex > -1) {
      const expenseRow: WarehouseExpenseRow = allExpenseRows[expenseRowIndex];
      this.props.onRemoveExpenseRow(category, expenseRow.uom, expenseRow.rownum);
    }
  };
}

export default WarehouseExpenseConfigForm;
