import * as React from 'react';

import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { LoginPage, PrivateRoute } from './pages/Login/';
import { ProvideAuth } from './lib/sso';
import App from './App';

const loginComponent = LoginPage;
const Root = ({ history }) => (
  <ProvideAuth>
    <Router history={history}>
      <Switch>
        <Route path="/login" exact component={loginComponent} />
        <PrivateRoute path="/">
          <App />
        </PrivateRoute>
      </Switch>
    </Router>
  </ProvideAuth>
);

export default Root;
