export interface WarehouseExpenseRow {
  id: string;
  category: string;
  eventName: string;
  uom: string;
  warehouseRate: any; // The rate fields are being treated alternatively as strings or numbers
  depositorRate: any;
  description: string;
  depositorWarning: string;
  warehouseWarning: string;
  rownum: string;
  databaseId: any;
}

// Utility method for grouping expense rows by category
export const groupExpenseRowsByCategory = (expenseRows: WarehouseExpenseRow[]) => {
  const expenseRowsByCategory = {};
  expenseRows.forEach((row: WarehouseExpenseRow) => {
    if (!expenseRowsByCategory[row.category]) {
      expenseRowsByCategory[row.category] = [];
    }
    expenseRowsByCategory[row.category].push(row);
  });

  return expenseRowsByCategory;
};

// Comparator for sorting expense rows
// Orders by databaseID; non-persisted rows conventionally have an ID starting 'new-' which will lex sort after others
export const warehouseExpenseRowComparator = (
  expenseRow1: WarehouseExpenseRow,
  expenseRow2: WarehouseExpenseRow,
): number => {
  if (expenseRow1.databaseId < expenseRow2.databaseId) {
    return -1;
  } else if (expenseRow1.databaseId > expenseRow2.databaseId) {
    return 1;
  } else {
    return 0;
  }
};
