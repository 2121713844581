import * as React from 'react';

class Dashboard extends React.Component {
  public render() {
    return (
      <div className="page dashboard-page">
        <h2>Main Dashboard</h2>
      </div>
    );
  }
}

export default Dashboard;
