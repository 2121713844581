export const FEE_HINT_TEXT = '$X.XX';

export const PERCENTAGE_HINT_TEXT = 'percentage';

export const SORT_ASC: 'SORT_ASC' = 'SORT_ASC';
export const SORT_DESC: 'SORT_DESC' = 'SORT_DESC';
export const PACKAGING_TYPE_MAP = {
  each: 'Eaches',
  carton: 'Cartons',
  pallet: 'Pallets',
};
export const RAISED_BTN = 'RAISED_BTN';
export const FLAT_BTN = 'FLAT_BTN';

export const DEFAULT_TRANSITION_MS = 300;

//Excluded Carriers
export const EXCLUDED_CARRIER_NAMES = ['roadie', 'purolator', 'axlehire', 'shipstation', 'swyft'];

//Reservation Metadata Consts
export const PLATFORM_ACCOUNT = 'Platform';
export const NON_PLATFORM_ACCOUNT = 'Non-Platform';

//Form Validations
export const RESERVATION_NICKNAME_MAX_LENGTH = 25;

//Default timeout
export const DEFAULT_LOCAL_ENV_SESSION_TIMEOUT = 86400; // 1 day
export const DEFAULT_CLOUD_ENV_SESSION_TIMEOUT = 1800; // 30 minutes
