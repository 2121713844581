import * as React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import InboundMethods from './InboundMethods';

interface PricingOptionsProps {
  formData: any;
  optionsKey: string;
  disableForm?: boolean;
  handleToggleChange(event, value: any);
}

const PricingOptions = ({ formData, handleToggleChange, optionsKey, disableForm }: PricingOptionsProps) => {
  return (
    <div className="pricing-options">
      <fieldset>
        <legend>Pricing By</legend>
        <FormControlLabel
          control={
            <Switch
              name={`${optionsKey}.use_inventory_groups`}
              checked={formData.use_inventory_groups}
              onChange={handleToggleChange}
              disabled={disableForm}
            />
          }
          disabled={disableForm || formData.sort_center_pricing_scope}
          labelPlacement="end"
          label="Inventory Groups"
        />
        <FormControlLabel
          control={
            <Switch
              name={`${optionsKey}.use_packaging_groups`}
              checked={formData.use_packaging_groups}
              onChange={handleToggleChange}
            />
          }
          disabled={disableForm}
          labelPlacement="end"
          label="Packaging"
        />
      </fieldset>
      <InboundMethods
        formData={formData}
        optionsKey={optionsKey}
        handleToggleChange={handleToggleChange}
        disableForm={disableForm}
      />
      <fieldset>
        <React.Fragment>
          <legend>Outbound Method(s) - Select at least 1</legend>
          <div>
            <FormControlLabel
              control={
                <Switch
                  name={`${optionsKey}.consumer_fulfillment_pricing_scope`}
                  checked={formData.consumer_fulfillment_pricing_scope}
                  onChange={handleToggleChange}
                />
              }
              disabled={disableForm}
              labelPlacement="end"
              label="eCommerce Fulfillment"
            />
            <FormControlLabel
              control={
                <Switch
                  name={`${optionsKey}.retail_fulfillment_pricing_scope`}
                  checked={formData.retail_fulfillment_pricing_scope}
                  onChange={handleToggleChange}
                />
              }
              disabled={disableForm}
              labelPlacement="end"
              label="Retail Fulfillment"
            />
            <FormControlLabel
              control={
                <Switch
                  name={`${optionsKey}.sort_center_pricing_scope`}
                  checked={formData.sort_center_pricing_scope}
                  onChange={handleToggleChange}
                />
              }
              disabled={disableForm || formData.use_inventory_groups}
              labelPlacement="end"
              label="Sort Center"
            />
          </div>
        </React.Fragment>
      </fieldset>
      <fieldset>
        <legend>Additional Services</legend>
        <FormControlLabel
          control={
            <Switch
              name={`${optionsKey}.returns_pricing_scope`}
              checked={formData.returns_pricing_scope}
              onChange={handleToggleChange}
            />
          }
          disabled={disableForm}
          labelPlacement="end"
          label="Returns"
        />
      </fieldset>
    </div>
  );
};

export default PricingOptions;
