import * as React from 'react';
import { get } from 'lodash';

import flexeApi from '../../lib/flexeApi';
import './infoFormStyles.css';

import Form, { FormProps } from '../Form';
import ReservationSettings from './SubForms/ReservationSettings';
import ReservationSetup from './SubForms/ReservationSetup';
import PageLoadSpinner from '../PageLoadSpinner/';
import ErrorMessage from '../ErrorMessage';
import ReservationMinimums from './SubForms/ReservationMinimums';
import StorageBillingModes from './SubForms/StorageBillingModes';

interface InfoFormState {
  shippingCompanies: any[];
  warehouses: any[];
  shippingCompanySearchText: string;
  warehouseSearchText: string;
  formErrors: any;
  errorMessage?: string;
  loadingCompaniesAndWarehouses: boolean;
}

class InfoForm extends Form<FormProps, InfoFormState> {
  constructor(props) {
    super(props);

    this.state = {
      shippingCompanies: [],
      warehouses: [],
      shippingCompanySearchText: '',
      warehouseSearchText: '',
      formErrors: {},
      errorMessage: null,
      loadingCompaniesAndWarehouses: true,
    };

    // Fetch the shippers and warehouses
    flexeApi
      .getShippingCompaniesAndWarehouses()
      .then(({ companies, warehouses }) => {
        const errorMessages = [];
        if (companies.length === 0) {
          errorMessages.push('No shipping companies were returned.');
        }
        if (warehouses.length === 0) {
          errorMessages.push('No warehouses were returned.');
        }
        this.setState({
          shippingCompanies: companies.map((co) => {
            return {
              ...co,
              searchText: `#${co.id} - ${co.name}`,
            };
          }),
          warehouses: warehouses.map((wh) => {
            return {
              ...wh,
              searchText: `#${wh.id} - ${wh.name}`,
            };
          }),
          loadingCompaniesAndWarehouses: false,
          errorMessage: errorMessages.join(' '),
        });
        const companyId = this.props.formData.company_id;
        const warehouseId = this.props.formData.warehouse_id;
        this.setCompanyAndWarehouseNames(companyId, warehouseId);
      })
      .catch((error: Error) => {
        this.setState({
          loadingCompaniesAndWarehouses: false,
          errorMessage: "We're sorry, there was an issue loading the form",
        });
      });
  }

  public render() {
    if (this.state.loadingCompaniesAndWarehouses) {
      return <PageLoadSpinner />;
    } else if (this.state.errorMessage) {
      return <ErrorMessage>{this.state.errorMessage}</ErrorMessage>;
    } else {
      const { formData, formErrors } = this.props;

      return (
        <form>
          <h1>Reservation Information</h1>
          <div className="pure-g">
            <div className="fieldset-wrapper pure-u-1-2">
              <fieldset>
                <legend>Details</legend>
                <label>
                  Choose a Shipping Company *
                  <br />
                  <input type="text" list="shippingCompany" onChange={this.setShippingCompanyId} />
                  <datalist id="shippingCompany">
                    {this.state.shippingCompanies.map((c) => {
                      return (
                        <option key={c.id} value={c.id}>
                          {c.name}
                        </option>
                      );
                    })}
                  </datalist>
                </label>
                <br />
                <br />

                <label>
                  Choose a Warehouse *
                  <br />
                  <input type="text" list="warehouseCompany" onChange={this.setWarehouseId} />
                  <datalist id="warehouseCompany">
                    {this.state.warehouses.map((wh) => {
                      return (
                        <option key={wh.id} value={wh.id}>
                          {wh.name}
                        </option>
                      );
                    })}
                  </datalist>
                </label>
                <br />

                <ReservationSetup
                  formData={formData}
                  scopeDocuments={formData.reservation_scope_documents}
                  onFieldChange={this.updateFieldValue}
                  onStartOnDateChange={this.updateStartOnDate}
                  onIntegerFieldChange={this.updateIntegerFieldValue}
                  formErrors={formErrors}
                  disableForm={false}
                  blockActivityBoxEnabled={false}
                />
              </fieldset>
            </div>

            <div className="fieldset-wrapper pure-u-1-2">
              <ReservationMinimums
                shipperMinimum={formData.monthly_minimum}
                shipperMinimumKey="reservation.monthly_minimum"
                warehouseMinimum={formData.monthly_warehouse_minimum}
                warehouseMinimumKey="reservation.monthly_warehouse_minimum"
                onFieldChange={this.updateFieldValue}
                formErrors={formErrors}
                disableForm={false}
              />
              <br />
              <StorageBillingModes
                formData={formData.storage_billing_mode}
                storageBillingModeKey="reservation.storage_billing_mode"
                onFieldChange={this.updateFieldValue}
                formErrors={formErrors}
                disableForm={false}
              />
              <ReservationSettings
                formData={formData}
                blockActivity={formData.block_activity}
                blockActivityKey="reservation.block_activity"
                onFieldChange={this.updateFieldValue}
                formErrors={formErrors}
                disableForm={false}
                blockActivityBoxEnabled={false}
              />
            </div>
          </div>
        </form>
      );
    }
  }

  private updateStartOnDate = (event: Event) => {
    const date = get(event, 'target.value');
    this.props.onFormUpdate('reservation.start_on', date);
  };

  private setCompanyAndWarehouseNames(companyId: number, warehouseId: number) {
    let shippingCompanySearchText = '';
    let warehouseSearchText = '';

    if (companyId) {
      const shippingCompany = this.state.shippingCompanies.find((company) => {
        return company.id === companyId;
      });
      if (shippingCompany) {
        shippingCompanySearchText = shippingCompany.searchText;
      }
    } else {
      shippingCompanySearchText = this.state.shippingCompanySearchText;
    }

    if (warehouseId) {
      const warehouse = this.state.warehouses.find((wh) => {
        return wh.id === warehouseId;
      });
      if (warehouse) {
        warehouseSearchText = warehouse.searchText;
      }
    } else {
      warehouseSearchText = this.state.warehouseSearchText;
    }

    this.setState({ shippingCompanySearchText, warehouseSearchText });
  }

  private setShippingCompanyId = async (event) => {
    const chosenCompanyId = parseInt(event.target.value, 10);
    await this.props.onFormUpdate('reservation.company_id', chosenCompanyId);
    this.setCompanyAndWarehouseNames(chosenCompanyId, null);
  };

  private setWarehouseId = async (event) => {
    const chosenWarehouseId = parseInt(event.target.value, 10);
    if (!chosenWarehouseId) {
      return;
    }
    await this.props.onFormUpdate('reservation.warehouse_id', chosenWarehouseId);
    this.setCompanyAndWarehouseNames(null, chosenWarehouseId);
  };
}

export default InfoForm;
