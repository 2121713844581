import * as React from 'react';
import { get } from 'lodash';
import Form from '../../Form';
import Select from '@material-ui/core/Select';
import flexeApi from '../../../lib/flexeApi';
import PriceField from '../../PriceField';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import TextField from '@material-ui/core/TextField';

interface FlatFeeConfigFormProps {
  pricingData: any;
  formErrors: any;
  disabled: boolean;
  onFormUpdateAndRemove?(
    oldKey: string,
    newKey: string,
    newEvent: string,
    depositorRate: any,
    warehouseRate: any,
    description: any,
    rownum: any,
  );
}

interface FlatFeeConfigFormState {
  flatFeeScopes: any;
  formData: any;
}

class FlatFeeConfigForm extends Form<FlatFeeConfigFormProps, FlatFeeConfigFormState> {
  private counter = {
    flat_fee_inbound_pricing_scope: 0,
    flat_fee_outbound_pricing_scope: 0,
    flat_fee_other_pricing_scope: 0,
    flat_fee_storage_pricing_scope: 0,
  };
  constructor(props) {
    super(props);
    const data = {};
    const pricing = props.pricingData.pricing || {};
    Object.keys(pricing).forEach((uom) => {
      Object.keys(pricing[uom]).forEach((category) => {
        data[category] = data[category] || [];
        const categoryFees = pricing[uom][category];
        for (let i = 0; i < categoryFees.length; i++) {
          if (categoryFees[i] === undefined) {
            continue;
          }
          const warehouseKeys = Object.keys(categoryFees[i]).filter(
            (e) => e.startsWith('warehouse_') && e.endsWith('_fee'),
          );
          warehouseKeys.forEach((key) => {
            const eventName = key.slice(10).slice(0, -4);
            const row = {
              id: `${uom}.${category}.${eventName}.${i}`,
              eventName,
              uom,
              warehouseRate: categoryFees[i][key],
              depositorRate: categoryFees[i]['depositor_' + eventName + '_fee'],
              description: categoryFees[i][eventName + '_description'],
              depositorWarning: '',
              warehouseWarning: '',
              rownum: `${i}`,
            };
            data[category].push(row);
            this.counter[category] = i + 1;
          });
        }
      });
    });

    this.state = {
      flatFeeScopes: {},
      formData: data,
    };
  }

  public componentDidMount() {
    flexeApi.getFlatFeeScopes().then((response) => {
      if (response && response.config && response.config.length) {
        this.setState({ flatFeeScopes: response.config[0].data });
      }
    });
  }

  public render() {
    const { disabled } = this.props;
    const scopes = this.state.flatFeeScopes;
    return (
      <fieldset>
        <h3>Flat Fee Config</h3>
        <table className="pure-table pricing-table">
          <tbody>
            <tr>
              <td>Category</td>
              <td>Type</td>
              <td>Description</td>
              <td key="shipper.header">Shipper</td>
              <td key="warehouse.header">Warehouse</td>
            </tr>
            {Object.keys(scopes).map((key: string, Idx: number) => {
              const category = scopes[key];
              return [
                <tr className="expense-types" key={category.display_string}>
                  <td colSpan={5}>{category.display_string}</td>
                </tr>,
                this.renderRows(key),
                <tr key={Idx}>
                  <td>
                    <IconButton
                      key={`addbutton.${key}`}
                      aria-label="add"
                      data-testid={`addbutton.${key}`}
                      disabled={disabled}
                      onClick={() => this.addRow(key)}
                    >
                      <AddIcon />
                    </IconButton>
                  </td>
                </tr>,
              ];
            })}
          </tbody>
        </table>
      </fieldset>
    );
  }

  private updateDropdown = (event, category, dropdownName, modifiedRowId) => {
    const newDropDownValue = event.target.value;

    this.setState((prevState, prevProps) => {
      const formData = prevState.formData;
      const index = (formData[category] || []).findIndex((row) => row.id === modifiedRowId);

      if (index > -1) {
        let r = formData[category][index];
        const priceKeyPrefix = 'pricing.flatFeeGroup.pricing';
        const oldEvent = r.eventName;
        const oldKey = `${priceKeyPrefix}.month.${category}`;
        formData[category][index][dropdownName] = newDropDownValue;

        r = formData[category][index];
        const newEvent = r.eventName;
        const newKey = `${priceKeyPrefix}.month.${category}`;
        const depositorRate = r.depositorRate;
        const warehouseRate = r.warehouseRate;
        const description = r.description;
        prevProps.onFormUpdateAndRemove(oldKey, newKey, newEvent, depositorRate, warehouseRate, description, r.rownum);
      }

      return { formData };
    });
  };

  private updateRate = (event, category, rateName, rec) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    let errorKey;
    if (target instanceof HTMLInputElement) {
      errorKey = target.dataset.errorKey;
    }

    this.setState((prevState, prevProps) => {
      const formData = prevState.formData;
      const index = (formData[category] || []).findIndex((row) => row.id === rec.id);
      if (index > -1) {
        formData[category][index][rateName] = value;
        if (parseFloat(formData[category][index].warehouseRate) > parseFloat(formData[category][index].depositorRate)) {
          formData[category][index].depositorWarning = 'Shipper rate is less than warehouse rate warning.';
          formData[category][index].warehouseWarning = 'Shipper rate is less than warehouse rate warning.';
        } else {
          formData[category][index].depositorWarning = '';
          formData[category][index].warehouseWarning = '';
        }
      }
      prevProps.onFormUpdate(name, value.toString(), errorKey);

      return { formData };
    });
  };

  private renderRow(row, category) {
    const { formErrors, disabled } = this.props;
    const scopes = this.state.flatFeeScopes;
    const priceKeyPrefix = 'pricing.flatFeeGroup.pricing';
    const errorKeyPrefix = `month.${category}`;
    const shipperErrorKey = `${errorKeyPrefix}.depositor_${row.eventName}_fee.${row.rownum}`;
    const warehouseErrorKey = `${errorKeyPrefix}.warehouse_${row.eventName}_fee.${row.rownum}`;
    const shipperErrorText = get(formErrors, shipperErrorKey);
    const warehouseErrorText = get(formErrors, warehouseErrorKey);
    if (shipperErrorText !== undefined) {
      row.depositorWarning = shipperErrorText;
    } else {
      row.depositorWarning = '';
    }
    if (warehouseErrorText !== undefined) {
      row.warehouseWarning = warehouseErrorText;
    } else {
      row.warehouseWarning = '';
    }
    return (
      <tr key={`${category}.${row.id}`}>
        <td>
          <IconButton
            aria-label="delete"
            disabled={disabled}
            id={row.id}
            data-testid={`delete.${category}.${row.rownum}`}
            onClick={(event) => this.deleteRow(event, category, row.id)}
          >
            <DeleteIcon />
          </IconButton>
        </td>
        <td>
          <Select
            native
            id={row.id}
            data-testid={`select.${category}.${row.rownum}`}
            value={row.eventName}
            disabled={disabled}
            onChange={(event) => this.updateDropdown(event, category, 'eventName', row.id)}
          >
            <option value={''}>Select</option>
            {Object.keys(scopes[category].fees)
              .filter((outer) => scopes[category].fees[outer].enabled || row.eventName === outer)
              .map((outer) => (
                <option key={outer} value={outer}>
                  {scopes[category].fees[outer].display_string}
                </option>
              ))}
          </Select>
        </td>
        <td>
          <TextField
            id={row.id}
            data-testid={`${priceKeyPrefix}.month.${category}.${row.rownum}.${row.eventName}_description`}
            name={`${priceKeyPrefix}.month.${category}.${row.rownum}.${row.eventName}_description`}
            value={row.description}
            onChange={(event) => this.updateRate(event, category, 'description', row)}
            disabled={disabled}
          />
        </td>
        <td>
          <PriceField
            key={`depositorRate.${row.id}`}
            name={`${priceKeyPrefix}.month.${category}.${row.rownum}.depositor_${row.eventName}_fee`}
            value={row.depositorRate}
            data-error-key={`${shipperErrorKey}`}
            errorText={row.depositorWarning}
            onChange={(event) => this.updateRate(event, category, 'depositorRate', row)}
            disabled={disabled}
          />
        </td>
        <td>
          <PriceField
            key={`warehouseRate.${row.id}`}
            name={`${priceKeyPrefix}.month.${category}.${row.rownum}.warehouse_${row.eventName}_fee`}
            value={row.warehouseRate}
            data-error-key={`${shipperErrorKey}`}
            errorText={row.warehouseWarning}
            onChange={(event) => this.updateRate(event, category, 'warehouseRate', row)}
            disabled={disabled}
          />
        </td>
      </tr>
    );
  }

  private renderRows(category) {
    if (this.state.formData[category] === undefined || this.state.formData[category] === null) {
      return <tr key={category}></tr>;
    }
    return this.state.formData[category].map((row) => this.renderRow(row, category));
  }

  private addRow = (category) => {
    this.setState((prevState, prevProps) => {
      const formData = prevState.formData;
      formData[category] = formData[category] || [];
      const rownum = `${this.counter[category]}`;
      formData[category].push({
        id: rownum,
        depositorRate: '',
        warehouseRate: '',
        description: '',
        rownum,
      });
      this.counter[category] = this.counter[category] + 1;
      const newKey = `pricing.flatFeeGroup.pricing.month.${category}`;
      prevProps.onFormUpdateAndRemove(null, newKey, undefined, '', '', '', rownum);

      return { formData };
    });
  };

  private deleteRow = (event, category, deletedRowId) => {
    this.setState((prevState, prevProps) => {
      const formData = prevState.formData;
      const index = (formData[category] || []).findIndex((row) => row.id === deletedRowId);
      if (index > -1) {
        const r = formData[category][index];
        const oldKey = `pricing.flatFeeGroup.pricing.month.${category}`;
        formData[category].splice(index, 1);
        prevProps.onFormUpdateAndRemove(oldKey, null, null, null, null, '', r.rownum);
      }

      return { formData };
    });
  };
}

export default FlatFeeConfigForm;
