import * as React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import Form from '../../Form';
import ErrorIcon from '../../ErrorIcon';
import { get } from 'lodash';
import PriceField from '../../PriceField';
import { capitalize } from '../../../lib/helpers';
import { InventoryGroup } from '../../../CommonInterfaces';
import './inventoryPricingGroup.css';

const styles = {
  errorIconStyles: {
    width: 18,
    height: 18,
  },
  infoIconStyle: {
    fontSize: 'medium',
    marginLeft: '2px',
  },
};

interface Props {
  idx?: number;
  supportsReturns?: boolean;
  usePackagingGroups?: boolean;
  inventoryGroup: InventoryGroup;
  pricingOptions: any;
  metaData: any;
}

interface State {
  showPackagingErrorTooltips: {
    each: boolean;
    carton: boolean;
    pallet: boolean;
  };
}

class InventoryPricingGroup extends Form<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      showPackagingErrorTooltips: {
        each: false,
        carton: false,
        pallet: false,
      },
    };
  }

  // eslint-disable-next-line complexity -- Disabled pre-existing violation of complexity rule
  public render() {
    const { idx, inventoryGroup, pricingOptions, metaData, disableForm, formErrors } = this.props;

    const {
      use_packaging_groups: usePackagingGroups,
      storage_pricing_scope: supportsStoragePricing,
      container_pricing_scope: supportsContainerPricing,
      cpd_fulfillment_pricing_scope: supportsCartonPick,
      consumer_fulfillment_pricing_scope: supportsConsumerFulfillmentPricing,
      retail_fulfillment_pricing_scope: supportsRetailFulfillmentPricing,
      returns_pricing_scope: supportsReturnsPricing,
      sort_center_pricing_scope: supportsSortationPricing,
    } = pricingOptions;

    let priceKeyPrefix;
    let groupDescription;
    if (inventoryGroup.isDefault) {
      priceKeyPrefix = 'pricing.defaultInventoryGroup.pricing';
      groupDescription = 'Pricing Group';
    } else {
      priceKeyPrefix = `pricing.inventoryGroups[${idx}].pricing`;
      groupDescription = 'Inventory Group';
    }

    let packagingTypes;
    if (usePackagingGroups) {
      packagingTypes = ['each', 'carton', 'pallet'];
    } else {
      packagingTypes = ['default'];
    }

    if (
      supportsStoragePricing ||
      supportsContainerPricing ||
      supportsConsumerFulfillmentPricing ||
      supportsRetailFulfillmentPricing ||
      supportsReturnsPricing
    ) {
      return (
        <fieldset>
          <h3>
            {inventoryGroup.description} <small>&ndash; {groupDescription}</small>
          </h3>
          <table className="pure-table pricing-table">
            <thead className="packaging-types">
              <tr>
                <th></th>
                {packagingTypes.map((packagingType: string, ptIdx) => {
                  const packagingErrorText = get(
                    formErrors,
                    `${packagingType}.reservation_pricing_scope.pricing_scopes`,
                  );
                  return [
                    <th colSpan={2} key={`${packagingType}.header`}>
                      {packagingType === 'default' ? 'All Packagings' : capitalize(packagingType)}
                      {packagingErrorText ? (
                        <Tooltip
                          title={packagingErrorText}
                          open={this.state.showPackagingErrorTooltips[packagingType]}
                          placement="top-end"
                        >
                          <ErrorIcon
                            style={styles.errorIconStyles}
                            data-packaging={packagingType}
                            onMouseOver={this.togglePackagingErrorTooltip}
                            onMouseOut={this.togglePackagingErrorTooltip}
                          />
                        </Tooltip>
                      ) : null}
                    </th>,
                  ];
                })}
              </tr>
            </thead>
            {/* eslint-disable-next-line complexity -- Disabled pre-existing violation of complexity rule */}
            {Object.keys(metaData).map((pgKey: string, pgIdx: number) => {
              const pgMetaData = metaData[pgKey];
              if (
                (pgMetaData.key === 'storage_pricing_scope' && supportsStoragePricing) ||
                (pgMetaData.key === 'container_pricing_scope' && supportsContainerPricing) ||
                (pgMetaData.key === 'consumer_fulfillment_pricing_scope' && supportsConsumerFulfillmentPricing) ||
                (pgMetaData.key === 'retail_fulfillment_pricing_scope' && supportsRetailFulfillmentPricing) ||
                (pgMetaData.key === 'returns_pricing_scope' && supportsReturnsPricing) ||
                (pgMetaData.key === 'sort_center_pricing_scope' && supportsSortationPricing)
              ) {
                return [
                  <thead key={`thead_${pgIdx}`} className="company-types">
                    <tr>
                      <th>{pgMetaData.name}</th>
                      {packagingTypes.map((packagingType: string, ptIdx) => {
                        return [<th key="shipper.header">Shipper</th>, <th key="warehouse.header">Warehouse</th>];
                      })}
                    </tr>
                  </thead>,
                  <tbody key={`tbody_${pgKey}`}>
                    {pgMetaData.pricingData.map((fee: any, feeIdx: number) => {
                      const fullWarehouseKey = `warehouse${fee.key}`;
                      const fullShipperKey = `depositor${fee.key}`;
                      return (
                        <tr key={feeIdx}>
                          <td>
                            {fee.name}
                            {typeof fee.description === 'string' && fee.description.trim().length > 0 && (
                              <Tooltip title={fee.description} placement="right">
                                <InfoIcon style={styles.infoIconStyle} />
                              </Tooltip>
                            )}
                          </td>
                          {packagingTypes.map((packagingType: string, ptIdx) => {
                            const invGroupId = inventoryGroup.id || 'default';
                            const packagingGroupPrices = inventoryGroup.pricing[packagingType][pgMetaData.key];
                            const errorKeyPrefix = `${packagingType}.${pgMetaData.key}`;
                            const shipperErrorKey = `${errorKeyPrefix}.${fullShipperKey}`;
                            const warehouseErrorKey = `${errorKeyPrefix}.${fullWarehouseKey}`;
                            const shipperErrorText = get(formErrors, shipperErrorKey);
                            const warehouseErrorText = get(formErrors, warehouseErrorKey);

                            if (
                              packagingGroupPrices &&
                              packagingGroupPrices.hasOwnProperty(fullShipperKey) &&
                              packagingGroupPrices.hasOwnProperty(fullWarehouseKey)
                            ) {
                              return [
                                <td key={`${packagingType}.shipper.fee`}>
                                  <PriceField
                                    key={feeIdx}
                                    name={`${priceKeyPrefix}.${packagingType}.${pgMetaData.key}.${fullShipperKey}`}
                                    value={packagingGroupPrices[fullShipperKey]}
                                    data-error-key={`${invGroupId}.${shipperErrorKey}`}
                                    errorText={shipperErrorText}
                                    onChange={this.updateFieldValue}
                                    disabled={disableForm}
                                  />
                                </td>,
                                <td key={`${packagingType}.warehouse.fee`}>
                                  <PriceField
                                    key={feeIdx}
                                    name={`${priceKeyPrefix}.${packagingType}.${pgMetaData.key}.${fullWarehouseKey}`}
                                    value={packagingGroupPrices[fullWarehouseKey]}
                                    data-error-key={`${invGroupId}.${warehouseErrorKey}`}
                                    errorText={warehouseErrorText}
                                    onChange={this.updateFieldValue}
                                    disabled={disableForm}
                                  />
                                </td>,
                              ];
                            } else {
                              return [
                                <td key={`${packagingType}.warehouse.fee`}>&ndash;</td>,
                                <td key={`${packagingType}.shipper.fee`}>&ndash;</td>,
                              ];
                            }
                          })}
                        </tr>
                      );
                    })}
                  </tbody>,
                ];
              } else {
                return null;
              }
            })}
          </table>
        </fieldset>
      );
    } else {
      return <p>No optional services selected</p>;
    }
  }

  private togglePackagingErrorTooltip = (event: Event) => {
    const errorIcon = event.currentTarget;
    if (errorIcon instanceof SVGElement) {
      const packagingType = errorIcon.getAttribute('data-packaging');
      this.setState({
        showPackagingErrorTooltips: {
          ...this.state.showPackagingErrorTooltips,
          [packagingType]: event.type === 'mouseover',
        },
      });
    }
  };
}

export default InventoryPricingGroup;
