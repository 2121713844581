import * as React from 'react';
import { clone, get, isNumber } from 'lodash';
import Filter from './Filter';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import './styles.css';

const styles = {
  addFilterButton: {
    marginTop: 16,
  },
};

interface FilterListProps {
  heading?: string | JSX.Element; // Text or React element to use as the heading
  filterKey: string; // param name of the filter being applied
  values: string | string[]; // string or array of strings representing the filter values
  pendingEditsOnly?: boolean;
  showPendingEditsOnlyToggle?: boolean;
  handleShowPendingEditsOnlyToggle?(event, inputChecked: boolean);
  applyFilters(key: string, values: string[] | string); // The callback that passes the changed filters to the parent
}

class FilterList extends React.Component<FilterListProps, null> {
  private static defaultProps = {
    heading: 'Filters',
    pendingEditsOnly: false,
    showPendingEditsOnlyToggle: false,
  };

  public render() {
    return (
      <fieldset>
        <h3>{this.props.heading}</h3>

        {Array.isArray(this.props.values) && (
          <Button
            className="add-a-filter-btn"
            variant="contained"
            style={styles.addFilterButton}
            onClick={this.addFilter}
          >
            <AddIcon color="primary" />
            Add a Filter
          </Button>
        )}

        {this.props.showPendingEditsOnlyToggle ? (
          <div className="pending-edits-only">
            <FormGroup row>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.props.pendingEditsOnly}
                    onChange={this.props.handleShowPendingEditsOnlyToggle}
                  />
                }
                label="Pending Edits Only"
              />
            </FormGroup>
          </div>
        ) : null}

        <ul className="filters">
          {Array.isArray(this.props.values) ? (
            this.props.values.map((val, index) => {
              return (
                <Filter
                  key={'filter_' + index}
                  index={index}
                  value={val}
                  updateFilter={this.updateFilter}
                  removeFilter={this.removeFilter}
                  disabled={this.props.pendingEditsOnly}
                />
              );
            })
          ) : (
            <Filter
              key={'filter_' + this.props.filterKey}
              index={0}
              type={['startDate', 'endDate'].includes(this.props.filterKey) ? 'date' : 'text'}
              value={this.props.values || ''}
              updateFilter={this.updateFilter}
              removeFilter={this.removeFilter}
              disabled={this.props.pendingEditsOnly}
            />
          )}
        </ul>
      </fieldset>
    );
  }

  private addFilter = () => {
    if (Array.isArray(this.props.values)) {
      this.props.applyFilters(this.props.filterKey, [...this.props.values, '']);
    }
  };

  private updateFilter = ({ target }: { target: HTMLInputElement }) => {
    const filterIndex = parseInt(target.getAttribute('data-index'), 10);
    if (isNumber(filterIndex)) {
      let filters = clone(this.props.values);
      if (Array.isArray(filters)) {
        filters[filterIndex] = target.value;
      } else {
        filters = target.value;
      }
      this.props.applyFilters(this.props.filterKey, filters);
    }
  };

  private removeFilter = ({ currentTarget }: { currentTarget: HTMLElement }) => {
    const filterIndex = parseInt(currentTarget.getAttribute('data-index'), 10);
    if (isNumber(filterIndex)) {
      let filters = clone(this.props.values);
      if (Array.isArray(filters)) {
        filters.splice(filterIndex, 1);
      } else {
        filters = null;
      }
      this.props.applyFilters(this.props.filterKey, filters);
    }
  };
}

export default FilterList;
