import * as React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const infoIconStyle = {
  fontSize: 'medium',
  marginLeft: '2px',
};

const containerTooltip =
  'This enables mobile inbounding process, ' + 'must be selected to receive goods on this reservation';
const palletTooltip =
  'Based on legacy overflow workflow, this ' + 'toggle enables palletized delivery for any reservation';

interface InboundMethodsProps {
  formData: any;
  optionsKey: string;
  disableForm?: boolean;
  handleToggleChange(event, value: any);
}

export default class InboundMethods extends React.Component<InboundMethodsProps, any> {
  constructor(props) {
    super(props);
  }

  public render() {
    return (
      <fieldset>
        <legend>Inbound Method - Required</legend>
        <FormControlLabel
          control={
            <Switch
              name={`${this.props.optionsKey}.container_pricing_scope`}
              checked={this.props.formData.container_pricing_scope}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                this.props.handleToggleChange(event, event.target.checked);
              }}
            />
          }
          disabled={this.props.disableForm}
          labelPlacement="end"
          label={
            <div>
              Container Unload
              <Tooltip title={containerTooltip} placement="right">
                <InfoIcon style={infoIconStyle} />
              </Tooltip>
            </div>
          }
        />
      </fieldset>
    );
  }
}
