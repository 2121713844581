import * as React from 'react';
import flexeApi from '../../lib/flexeApi';
import { isString } from 'lodash';
import Form from '../Form';
import Tabs from '@material-ui/core/Tabs';
import ErrorMessage from '../ErrorMessage/';
import { FlexeApiResponse, ReservationPricing } from '../../CommonInterfaces';
import './pricingFormStyles.css';

interface PricingFormProps {
  formErrors: any;
  pricing: ReservationPricing;
  metaData: any;
  cancelEditInventoryGroup: any;
  createOrUpdateInventoryGroup: any;
  onAddInventoryGroup();
  onInventoryGroupsReturned(data: any);
  setPricingFormErrors(msg: string);
}

interface PricingFormState {
  currentTab: any;
}

class PricingForm<Props, State> extends Form<Props & PricingFormProps, State & PricingFormState> {
  public componentDidMount() {
    flexeApi
      .getInventoryGroups(this.props.formData.company_id)
      .then(({ data, statusCode, error }: FlexeApiResponse) => {
        if (statusCode === 200) {
          if (Array.isArray(data) && data.length) {
            this.props.onInventoryGroupsReturned(data);
          }
        } else if (error && typeof error !== 'string') {
          this.props.setPricingFormErrors(error.msg);
        }
      });
  }

  public render() {
    const { formErrors } = this.props;
    let errorMessage;

    if (isString(formErrors)) {
      errorMessage = (
        <div className="pure-u-1-1">
          <ErrorMessage>{formErrors}</ErrorMessage>
        </div>
      );
    }

    return (
      <div className="pricing-form">
        <h1>Reservation Scope Pricing</h1>

        {errorMessage}

        <Tabs className="pricing-tabs" value={this.state.currentTab} onChange={this.changeTabs}>
          {this.renderTabs()}
        </Tabs>

        {this.renderPanes()}
      </div>
    );
  }

  protected renderTabs() {
    // no-op, this method to be defined by subclasses
    return null;
  }

  protected renderPanes() {
    // no-op, this method to be defined by subclasses
    return null;
  }

  private changeTabs = (event, value) => {
    this.setState({ currentTab: value });
  };
}

export default PricingForm;
