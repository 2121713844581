import * as React from 'react';
import { CSSTransition } from 'react-transition-group';
import Avatar from '@material-ui/core/Avatar';
import DoneIcon from '@material-ui/icons/Done';
import WarningIcon from '@material-ui/icons/Warning';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import { DEFAULT_TRANSITION_MS } from '../../lib/constants';
import './styles.css';

const styles = {
  avatar: {
    display: 'inline-block',
  },
};

export enum OverlayType {
  success,
  error,
}

interface OverlayMessageProps {
  children: JSX.Element | string;
  type?: OverlayType;
  show: boolean;
  autoHide?: boolean;
  hideDelay?: number;
  onHide(event: React.MouseEvent<HTMLDivElement>);
}

class OverlayMessage extends React.Component<OverlayMessageProps, null> {
  private static defaultProps = {
    type: OverlayType.success,
    autoHide: true,
    hideDelay: 2000,
  };
  private hideOverlayTimer: number;

  constructor(props) {
    super(props);
    this.hideOverlayTimer = null;
  }

  public render() {
    const { show, type, children } = this.props;
    return (
      <CSSTransition
        in={show}
        classNames="overlay"
        enter={false}
        exit={true}
        unmountOnExit={true}
        timeout={DEFAULT_TRANSITION_MS}
      >
        <div className="overlay" onClick={this.hideOverlay}>
          <div className="content">
            {type === OverlayType.success ? (
              <Avatar className="icon" style={{ ...styles.avatar, backgroundColor: green['500'] }}>
                <DoneIcon />
              </Avatar>
            ) : (
              <Avatar className="icon" style={{ ...styles.avatar, backgroundColor: red['500'] }}>
                <WarningIcon />
              </Avatar>
            )}
            <div className="message">{children}</div>
          </div>
        </div>
      </CSSTransition>
    );
  }

  public componentDidMount() {
    if (this.props.autoHide) {
      this.setAutoHide(this.props.hideDelay);
    }
  }

  public componentWillReceiveProps(nextProps) {
    if (!this.props.show && nextProps.show && nextProps.autoHide) {
      this.setAutoHide(nextProps.hideDelay);
    }
  }

  private setAutoHide = (hideDelay: number) => {
    this.hideOverlayTimer = window.setTimeout(this.hideOverlay, hideDelay);
  };

  private hideOverlay = (event: React.MouseEvent<HTMLDivElement>) => {
    clearTimeout(this.hideOverlayTimer);
    this.props.onHide(event);
  };
}

export default OverlayMessage;
