import { grayLight, grayLighter, textColor, white } from './flexeColors';

const tabStyles = {
  tabItemContainer: {
    backgroundColor: 'transparent',
    borderBottom: `1px solid ${grayLight}`,
    padding: '0 .5rem',
  },
  tabContentContainer: {
    margin: '1.4rem 0',
  },
  tabsInkBar: {
    display: 'none',
  },
  tab: {
    color: textColor,
    fontSize: '16px',
    textTransform: 'none',
    width: '14rem',
    padding: '4px 10px',
    margin: '8px 2px 0',
    backgroundColor: grayLighter,
    borderRadius: '4px 4px 0 0',
  },
  activeTab: {},
};

tabStyles.activeTab = {
  ...tabStyles.tab,
  backgroundColor: white,
  border: `1px solid ${grayLight}`,
  borderBottom: 'none',
  top: '1px',
  margin: '0 2px 0',
};

export default tabStyles;
