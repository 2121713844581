import 'whatwg-fetch';
import 'promise-polyfill';

import { FlexeApiResponse, LpnCorrection, ReservationMetadata, ScopeDocument } from '../CommonInterfaces';
import { PatPricingConfiguration } from '../services/data/PricingV2Interfaces';

export function apiRequestJSON(method: string, apiPath: string, body?: any) {
  const requestOptions: any = {
    method,
    credentials: 'same-origin',
  };

  if (method === 'GET' && body) {
    const queryKeys = Object.keys(body).filter((key) => body[key] !== null && body[key] !== undefined);
    const queryVars = queryKeys.map((key) => `${key}=${body[key]}`);
    apiPath = `${apiPath}?${queryVars.join('&')}`;
  } else if (body) {
    requestOptions.body = JSON.stringify(body);
    requestOptions.headers = {
      'Content-Type': 'application/json',
    };
  }

  return window.fetch(`//${window.location.host}${apiPath}`, requestOptions).then((response) => {
    if (response.status === 200) {
      return response.json();
    } else {
      throw new Error('Server returned status: ' + response.status);
    }
  });
}

function filesApiUpload(companyIds: number[], labels: object, fileKey: string, file: File) {
  const requestOptions = {
    method: 'PUT',
    body: file,
  };

  addLocalHeaders(requestOptions);

  const queryParts = companyIds
    .map((companyId: number) => `companyIds=${companyId}`)
    .concat([
      `labels=${Object.keys(labels)
        .map((key) => `${key}%2C${labels[key]}`)
        .join('%2C')}`,
    ]);
  const query = queryParts.join('&');
  const url = buildUrl(`/v2/file-storage/${fileKey}?${query}`);

  return window
    .fetch(url, requestOptions)
    .then((response: Response) => {
      if (response.status === 200) {
        return response.json();
      } else {
        throw new Error('Server returned status: ' + response.status);
      }
    })
    .catch((reason) => {
      throw new Error('Server returned error: ' + reason);
    });
}

function scopeApiRequestJson(method: string, path: string) {
  const scopeApiPath = buildUrl(path);
  const requestOptions: any = { method };
  return window.fetch(scopeApiPath, requestOptions);
}

function buildUrl(apiPath) {
  return `//${window.location.host}${apiPath}`;
}

function addLocalHeaders(requestOptions) {
  if (window.location.href.startsWith('http://localhost')) {
    requestOptions.headers = {
      'X-FLEXE-user-id': '2',
      'X-FLEXE-user-type': 'AdminUser',
      'X-FLEXE-company-id': '0',
    };
  }
}

/**
 * This works largely like apiRequestJSON above, with a couple of differences. First, it does not send a request with a
 * same-origin policy header, which aids in local testing using the nginx setup described in the README. Second, it
 * doesn't do any processing of the response, that's entirely up to the consumer of the method
 * @param method [string] HTTP verb to use
 * @param apiPath [string] api path to the endpoint
 * @param body [object?] optional request body
 * @returns [Promise<Response>]
 */
export function crossOriginRequestJson(method: string, apiPath: string, body?: any) {
  const requestOptions: any = {
    method,
    headers: {},
  };

  addLocalHeaders(requestOptions);

  if (body) {
    requestOptions.body = JSON.stringify(body);
    requestOptions.headers['Content-Type'] = 'application/json';
  }
  const apiUrl = buildUrl(apiPath);

  return window.fetch(apiUrl, requestOptions);
}

const flexeApi = {
  getShippingCompaniesAndWarehouses() {
    return apiRequestJSON('GET', '/api/reservations/companies-and-warehouses');
  },

  async getFeatureFlag(flagName: string, defaultVal: string = 'false') {
    const result = await apiRequestJSON('GET', `/api/feature-flags/?flag=${flagName}&default=${defaultVal}`);

    return result.flag;
  },

  getWarehouseExpenseScopes() {
    return apiRequestJSON('GET', '/api/pricing/scopes');
  },

  getFlatFeeScopes() {
    return apiRequestJSON('GET', '/api/pricing/flat_fee_scopes');
  },

  getWarehouseExpenseUoms() {
    return apiRequestJSON('GET', '/api/pricing/uoms');
  },

  getReservations() {
    return apiRequestJSON('GET', '/api/reservations');
  },

  getReservationCutoffs(reservationId: number) {
    return crossOriginRequestJson('GET', `/v2/reservation-cutoffs/${reservationId}/`)
      .then((resp: Response) => resp.json())
      .then((json) => ({ reservationCutoffs: json.data.reservationCutoffs, errors: json.errors }))
      .catch((reason) => new Error(reason));
  },

  upsertReservationCutoffs(cutoff: any) {
    return crossOriginRequestJson('PUT', '/v2/reservation-cutoffs/upsert/', { meta: {}, data: cutoff })
      .then((resp: Response) => resp.json())
      .then((json) => ({ reservationCutoffs: json.data.reservationCutoffs, errors: json.errors }))
      .catch((reason) => new Error(reason));
  },

  deleteReservationCutoffs(cutoffKeys: any) {
    return crossOriginRequestJson('DELETE', '/v2/reservation-cutoffs/delete/', { meta: {}, data: cutoffKeys })
      .then((resp: Response) => resp.json())
      .then((json) => ({ reservationCutoffs: json.data.reservationCutoffs, errors: json.errors }))
      .catch((reason) => new Error(reason));
  },

  getCarrierServiceLevels() {
    return crossOriginRequestJson('POST', '/carrierManagement/v1/findServiceLevels/', { meta: {}, data: {} })
      .then((resp: any) => resp.json())
      .then((json) => ({ serviceLevels: json.data.serviceLevels, errors: json.errors }));
  },

  getReservationAndPricing(reservationId: number) {
    return apiRequestJSON('GET', `/api/reservations/${reservationId}?includePricing=true`);
  },

  createReservationAndScope(payload) {
    return apiRequestJSON('POST', '/api/reservations', payload);
  },

  updateReservationAndPricing(
    reservationId: number,
    pricingScopeData,
    patPricingConfigurations: PatPricingConfiguration[],
    blockActivity: boolean,
    paymentTerm: number,
    storageBillingMode: string,
    shipperMinimum: number,
    warehouseMinimum: number,
    documents: ScopeDocument[],
  ) {
    return apiRequestJSON('PUT', `/api/reservations/${reservationId}`, {
      pricing_scope_data: pricingScopeData,
      pat_pricing_configurations: patPricingConfigurations,
      block_activity: blockActivity,
      payment_term: paymentTerm,
      storage_billing_mode: storageBillingMode,
      documents,
      shipper_minimum: shipperMinimum,
      warehouse_minimum: warehouseMinimum,
    });
  },

  createReservationMetadataVersion(reservationId: number, config: ReservationMetadata) {
    // add format to pass parse_input_format check in warehouser side.
    const data = {
      data: config,
      meta: {},
    };
    return crossOriginRequestJson('POST', `/api/v2/reservations/${reservationId}/metadata`, { ...data });
  },

  submitReservationScopeForApproval(scopeId: number, scopeApprovalPolicy: string, scopeManagerApprovalEmail: string) {
    return apiRequestJSON('PUT', `/api/reservation_scopes/${scopeId}/submit_for_approval`, {
      scope_approval_policy: scopeApprovalPolicy,
      scope_manager_approval_email: scopeManagerApprovalEmail,
    });
  },

  cancelReservationScope(scopeId: number) {
    return apiRequestJSON('PUT', `/api/reservation_scopes/${scopeId}/cancel`);
  },

  uploadFile(companyIds: number[], labels: object, fileKey: string, file: File) {
    return filesApiUpload(companyIds, labels, fileKey, file);
  },

  getScope(scopeId: number) {
    return scopeApiRequestJson('GET', `/v2/scopes/${scopeId}`);
  },

  getDlqMessages(continuationToken: string, pageSize: number, filters: object) {
    return apiRequestJSON('POST', '/api/dlq/messages', { continuationToken, pageSize, filters });
  },

  redriveDlqMessage(message) {
    return apiRequestJSON('POST', '/api/dlq/republish', { message });
  },

  bulkRedriveDlqMessages(messages) {
    return apiRequestJSON('POST', '/api/dlq/bulkRepublishMessages', { messages });
  },

  archiveDlqMessage(messageId: string) {
    return apiRequestJSON('PATCH', '/api/dlq/updateArchiveInfo', { messageId, isArchived: true });
  },

  unarchiveDlqMessage(messageId: string) {
    return apiRequestJSON('PATCH', '/api/dlq/updateArchiveInfo', { messageId, isArchived: false });
  },

  bulkArchiveDlqMessages(messageIds: string[]) {
    return apiRequestJSON('PATCH', '/api/dlq/bulkUpdateArchiveInfo', { messageIds, isArchived: true });
  },

  bulkUnarchiveDlqMessages(messageIds: string[]) {
    return apiRequestJSON('PATCH', '/api/dlq/bulkUpdateArchiveInfo', { messageIds, isArchived: false });
  },

  getLocationsByReservation(
    reservationId: number,
    continuationToken?: string,
    skus?: string[],
    locations?: string[],
    lpns?: string[],
  ) {
    return apiRequestJSON('GET', '/api/locations/index', { reservationId, continuationToken, skus, locations, lpns });
  },

  getLocationsByWarehouse(
    warehouseId: number,
    continuationToken?: string,
    skus?: string[],
    locations?: string[],
    lpns?: string[],
  ) {
    return apiRequestJSON('GET', '/api/locations/index', { warehouseId, continuationToken, skus, locations, lpns });
  },

  bulkUpdateLocations(locationUpdates: {
    updates: any[];
    description: string;
    reason_code: string;
    purchase_order_number: string;
  }) {
    return apiRequestJSON('POST', '/api/locations/bulk_update', locationUpdates);
  },

  bulkCorrectLpns(lpnCorrections: LpnCorrection[]) {
    return apiRequestJSON('POST', '/api/locations/bulk_lpn_corrections', lpnCorrections);
  },

  createPricingScopes(pricingScopeData) {
    return apiRequestJSON('POST', '/api/pricing_scopes', pricingScopeData);
  },

  async getInventoryGroups(shippingCompanyId: number): Promise<FlexeApiResponse> {
    const apiPath = `/api/v1/admin/inventory_groups?company_id=${shippingCompanyId}`;
    const response = await crossOriginRequestJson('GET', apiPath);
    const responseBody = await response.json();

    if (responseBody.hasOwnProperty('data')) {
      return {
        statusCode: response.status,
        data: responseBody.data,
      };
    } else {
      return {
        statusCode: response.status,
        data: null,
        error: { msg: responseBody?.errors[0]?.detail },
      };
    }
  },

  async createInventoryGroup(companyId: number, postBody): Promise<FlexeApiResponse> {
    const apiPath = `/api/v1/admin/company/${companyId}/inventory_groups`;
    const response = await crossOriginRequestJson('POST', apiPath, postBody);
    const responseBody = await response.json();

    if (responseBody.hasOwnProperty('data')) {
      return {
        statusCode: response.status,
        data: responseBody.data,
      };
    } else {
      return {
        statusCode: response.status,
        data: null,
        error: { msg: responseBody?.errors[0]?.detail },
      };
    }
  },

  async updateInventoryGroup(companyId: number, invGroupId: number, postBody): Promise<FlexeApiResponse> {
    const apiPath = `/api/v1/admin/company/${companyId}/inventory_groups/${invGroupId}`;
    const response = await crossOriginRequestJson('PUT', apiPath, postBody);
    const responseBody = await response.json();

    if (responseBody.hasOwnProperty('data')) {
      return {
        statusCode: response.status,
        data: responseBody.data,
      };
    } else {
      return {
        statusCode: response.status,
        data: null,
        error: { msg: responseBody?.errors[0]?.detail },
      };
    }

    return apiRequestJSON('PUT', `/api/company/${companyId}/inventory_groups/${invGroupId}`, postBody);
  },

  getReservationSlaMetrics(reservationId: number): Promise<any> {
    return crossOriginRequestJson('GET', `/v2/reservation-sla-metrics?reservationIds[]=${reservationId}`);
  },

  upsertReservationSlaMetrics(reservationId, metrics): Promise<any> {
    return crossOriginRequestJson('PUT', `/v2/reservation-sla-metrics/${reservationId}`, {
      meta: {},
      data: metrics,
    });
  },
};

export default flexeApi;
