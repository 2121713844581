import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import Root from './Root';
import { MuiThemeProvider } from '@material-ui/core/styles'; // material UI v1+
import flexeTheme from './styles/flexeTheme';
import './css/main.css';

// These properties are set on window on the server side. TypeScript needs to know that we can access it.
declare global {
  interface Window {
    warehouserWebUri: string;
    gitCommitSha: string;
    environment: string;
  }
}
(async () => {
  const history = createBrowserHistory();

  ReactDOM.render(
    <MuiThemeProvider theme={flexeTheme}>
      <Root history={history} />
    </MuiThemeProvider>,
    document.getElementById('root'),
  );
})();
