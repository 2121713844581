/* eslint-disable max-lines -- Disabled pre-existing violation of max lines rule */
import { SORT_ASC, SORT_DESC } from './lib/constants';

export type Price = string;

export type SortDir = typeof SORT_ASC | typeof SORT_DESC | null;

export enum InventoryPackaging {
  carton = 'carton',
  each = 'each',
  pallet = 'pallet',
}

export enum BillingUom {
  carton = 'carton',
  each = 'each',
  pallet = 'pallet',
  order = 'order',
  hour = 'hour',
  feet = 'feet',
  sq_feet = 'sq_feet',
  cu_feet = 'cu_feet',
  lbs = 'lbs',
  case = 'case',
  item = 'item',
  shipment = 'shipment',
  day = 'day',
  half_month = 'half_month',
  month = 'month',
  inbound = 'inbound',
  container = 'container',
  carton_pickup = 'carton_pickup',
  pickup = 'pickup',
  pallet_day = 'pallet_day',
  money = 'money',
  usd = 'usd',
  cad = 'cad',
  trip = 'trip',
  truck_load = 'truck_load',
  shunt = 'shunt',
  inner_pack = 'inner_pack',
  parcel = 'parcel',
}

export enum StorageType {
  rack = 'rack',
  stacked = 'stacked',
  floor = 'floor',
}

export enum ReservationTxnState {
  new = 'new',
  confirmed = 'confirmed',
  canceled = 'canceled',
}

export enum ScopeTxnState {
  new = 'new',
  awaiting_approval = 'awaiting_approval',
  approved = 'approved',
  completed = 'completed',
  cancelled = 'cancelled',
}

export enum ScopeApprovalPolicy {
  two_side = 'TWO_SIDES',
  one_side_shipper = 'ONE_SIDE_SHIPPER',
  one_side_warehouse = 'ONE_SIDE_WAREHOUSE',
  manager_approval = 'MANAGER',
  admin_approval = 'ADMIN',
}

export enum FeeCurrency {
  USD = 'USD',
  CAD = 'CAD',
  MXN = 'MXN',
}

export interface FlexeApiResponse {
  statusCode: number;
  data: any;
  error?: string | { msg: string };
}

export interface FlexeApiV2Response {
  statusCode: number;
  data: any;
}

export interface Match {
  url: string;
  params: {
    resId: string;
  };
  path: string;
  isExact: boolean;
}

export interface CompanyInfo {
  id: any;
  name: string;
}

export interface WarehouseInfo {
  id: any;
  city: string;
  company: CompanyInfo;
  name?: string;
}

export interface PricingOptions {
  use_inventory_groups: boolean;
  use_packaging_groups: boolean;
  flat_fee_pricing_scope?: boolean;
  storage_pricing_scope: boolean;
  container_pricing_scope: boolean;
  warehouse_expense_pricing_scope?: boolean;
  cpd_fulfillment_pricing_scope?: boolean;
  consumer_fulfillment_pricing_scope?: boolean;
  retail_fulfillment_pricing_scope?: boolean;
  sort_center_pricing_scope?: boolean;
  returns_pricing_scope?: boolean;
  storage_billing_mode?: string;
}

export interface ScopeDocument {
  fileKey: string;
  fileName: string;
  kind: string;
  visibleToShipper: boolean;
  visibleToWarehouse: boolean;
}

export interface ReservationMetadata {
  id: number;
  primary_oc: string;
  primary_last_name: string;
  secondary_oc: string;
  secondary_last_name: string;
  ops_team_name: string;
  // set complexity_score and commercial_planned_margin to be any instead of number
  // it will return '' if we set it to number and we do not put any number in this section
  complexity_score: any;
  commercial_owner: string;
  program_group: string;
  program: string;
  commercial_planned_margin: any;
  version: number;
  created_by_name: string;
  created_by_id: number;
  created_by_type: string;
  created_at: string;
  account_type: string;
}

export enum SlaMetric {
  ibContainerUnloadOnTime = 'IB, Container Unload on Time',
  ibDockToStock = 'IB, Dock to Stock',
  ibPutawayOnTime = 'IB, Putaway on Time',
  obReadyToLoad = 'OB, Ready to Load',
  obParcelShipOnTime = 'OB, Parcel Ship on Time',
  obFreightShipOnTime = 'OB, Freight Ship on Time',
  locationAccuracy = 'Location Accuracy',
  unitAccuracy = 'Unit Accuracy',
  shrinkage = 'Shrinkage',
  fillRate = 'Fill Rate',
}

export enum SlaMetricSubtype {
  slaStandard = 'SLA Standard',
  targetPercentage = 'Target Percentage',
}

export interface ReservationAndScopeInfo {
  id?: any;
  company_id?: number;
  capacity?: string;
  client_labeled: boolean;
  customer_vendor_id: string;
  depositor_reservation_nickname: string;
  warehouse_reservation_nickname: string;
  description?: string;
  fee_currency: FeeCurrency;
  monthly_minimum: string;
  monthly_warehouse_minimum: string;
  storage_billing_mode?: string;
  reservation_scope_documents: ScopeDocument[];
  pallet_label_prefix: string;
  start_on?: Date;
  created_at?: string;
  shipper_signed_on?: string;
  warehouse_signed_on?: string;
  set_billing_details?: boolean;
  allow_partial_receiving: boolean;
  allow_same_day_delivery: boolean;
  block_activity: boolean;
  additional_scope_notes?: string;
  default_storage_type?: StorageType;
  cpd_fulfillment_pricing_scope?: boolean;
  txn_state?: ReservationTxnState;
  depositor?: CompanyInfo;
  warehouse?: WarehouseInfo;
  warehouse_id?: number;
  account_type?: string;
  reservation_scope?: {
    id: number;
    block_activity: boolean;
    txn_state: ScopeTxnState;
    created_at: string;
    shipper_signed_on: string;
    warehouse_signed_on: string;
    payment_term: number;
    storage_billing_mode?: string;
    shipper_minimum: string;
    warehouse_minimum: string;
    approval_type?: string;
    approval_info?: {
      approval_time?: string;
      shipper_side_signed_on?: string;
      warehouser_side_signed_on?: string;
    };
  };
  bill_to: {
    name: string;
    address_1: string;
    address_2?: string;
    locality: string;
    postal_code: string;
    region: string;
    country?: string;
    phone: string;
  };
  pricingOptions: PricingOptions;
  // Properties used in the FindLocationsControls component
  reservationText?: string;
  reservationValue?: number;
  warehouseText?: string;
  warehouseValue?: number;
}

interface PalletEquivalentStoragePricing {
  warehouse_rounded_pallet_equivalent_fee: Price;
  depositor_rounded_pallet_equivalent_fee: Price;
}

interface PeakLpnStoragePricing {
  warehouse_lpn_daily_maximum_fee: Price;
  depositor_lpn_daily_maximum_fee: Price;
}

interface NoneStoragePricing {
  warehouse_none_fee: Price;
  depositor_none_fee: Price;
}

interface PalletPricing {
  warehouse_in_out_fee: Price;
  depositor_in_out_fee: Price;
}

interface ContainerPricing {
  warehouse_labor_fee: Price;
  warehouse_material_fee: Price;
  depositor_labor_fee: Price;
  depositor_material_fee: Price;
  warehouse_inbound_lpn_complete_fee: Price;
  depositor_inbound_lpn_complete_fee: Price;
}

interface ContainerWithoutMaterialFeesPricing {
  warehouse_labor_fee: Price;
  warehouse_per_item_labor_fee: Price;
  depositor_labor_fee: Price;
  depositor_per_item_labor_fee: Price;
}

interface CpdFulfillmentPricing {
  warehouse_carton_labor_fee: Price;
  warehouse_carton_material_fee: Price;
  warehouse_pallet_labor_fee: Price;
  warehouse_pallet_material_fee: Price;
  warehouse_flat_fee: Price;
  depositor_carton_labor_fee: Price;
  depositor_carton_material_fee: Price;
  depositor_pallet_labor_fee: Price;
  depositor_pallet_material_fee: Price;
  depositor_flat_fee: Price;
}

interface ConsumerFulfillmentPricing {
  warehouse_flat_fee: Price;
  warehouse_labor_fee: Price;
  depositor_flat_fee: Price;
  depositor_labor_fee: Price;
}

interface RetailFulfillmentPricing {
  warehouse_flat_fee: Price;
  warehouse_per_unit_pick_fee: Price;
  warehouse_per_unit_value_added_service_fee: Price;
  warehouse_per_pallet_value_added_service_fee?: Price;
  warehouse_per_pallet_materials_fee?: Price;
  depositor_flat_fee: Price;
  depositor_per_unit_pick_fee: Price;
  depositor_per_unit_value_added_service_fee: Price;
  depositor_per_pallet_value_added_service_fee?: Price;
  depositor_per_pallet_materials_fee?: Price;
}

interface ReturnsPricing {
  warehouse_flat_fee: Price;
  warehouse_refurbish_fee: Price;
  warehouse_restock_fee: Price;
  warehouse_removal_fee: Price;
  depositor_flat_fee: Price;
  depositor_refurbish_fee: Price;
  depositor_restock_fee: Price;
  depositor_removal_fee: Price;
}

interface HandlingExpensePricing {
  warehouse_barcode_label_applied_fee: Price;
  depositor_barcode_label_applied_fee: Price;
  warehosue_overpack_small_pill_bottles_ecomm_fee: Price;
  depositor_overpack_small_pill_bottles_ecomm_fee: Price;
  warehouse_overbox_ecomm_fee: Price;
  depositor_overbox_ecomm_fee: Price;
}

interface LaborExpensePricing {
  warehouse_sunday_or_holiday_fee: Price;
  depositor_sunday_or_holiday_fee: Price;
  warehouse_vas_labor_fee: Price;
  depositor_vas_labor_fee: Price;
  warehouse_saturday_opening_fee_fee: Price;
  depositor_saturday_opening_fee_fee: Price;
  warehosue_canceled_orders_fee: Price;
  depositor_canceled_orders_fee: Price;
  warehouse_warehouse_labor_fee: Price;
  depositor_warehouse_labor_fee: Price;
  warehosue_overtime_or_saturday_fee: Price;
  depositor_overtime_or_saturday_fee: Price;
  warehouse_customer_service_fee: Price;
  depositor_customer_service_fee: Price;
}

interface StorageExpensePricing {
  warehouse_pallets_in_trailers_fee: Price;
  depositor_pallets_in_trailers_fee: Price;
  warehouse_pallets_out_of_system_fee: Price;
  depositor_pallets_out_of_system_fee: Price;
}

interface SuppliesExpensePricing {
  warehouse_heat_treated_pallets_fee: Price;
  warehouse_tape_fee: Price;
  warehouse_stretch_wrap_fee: Price;
  warehouse_slip_sheets_fee: Price;
  warehouse_shrink_wrap_fee: Price;
  warehouse_number_2_pallets_fee: Price;
  warehouse_miscellaneous_fee: Price;
  warehouse_boxes_fee: Price;
  warehouse_pallets_fee: Price;
  warehouse_number_1_pallets_fee: Price;
  depositor_heat_treated_pallets_fee: Price;
  depositor_tape_fee: Price;
  depositor_stretch_wrap_fee: Price;
  depositor_slip_sheets_fee: Price;
  depositor_shrink_wrap_fee: Price;
  depositor_number_2_pallets_fee: Price;
  depositor_miscellaneous_fee: Price;
  depositor_boxes_fee: Price;
  depositor_pallets_fee: Price;
  depositor_number_1_pallets_fee: Price;
}

interface TransportationExpensePricing {
  warehouse_warehouse_provided_transportation_fee: Price;
  depositor_warehouse_provided_transportation_fee: Price;
}

interface OtherExpensePricing {
  warehouse_garbage_disposal_fee: Price;
  depositor_garbage_disposal_fee: Price;
}

export const warehouseExpenseScopes = [
  'handling_expense_pricing_scope',
  'labor_expense_pricing_scope',
  'storage_expense_pricing_scope',
  'supplies_expense_pricing_scope',
  'transportation_expense_pricing_scope',
  'other_expense_pricing_scope',
];

export const flatFeeScopes = [
  'flat_fee_inbound_pricing_scope',
  'flat_fee_outbound_pricing_scope',
  'flat_fee_storage_pricing_scope',
  'flat_fee_other_pricing_scope',
];

export type PricingGroup =
  | PalletEquivalentStoragePricing
  | PeakLpnStoragePricing
  | NoneStoragePricing
  | PalletPricing
  | ContainerPricing
  | CpdFulfillmentPricing
  | ConsumerFulfillmentPricing
  | RetailFulfillmentPricing
  | ReturnsPricing
  | HandlingExpensePricing
  | LaborExpensePricing
  | StorageExpensePricing
  | SuppliesExpensePricing
  | TransportationExpensePricing
  | OtherExpensePricing
  | {};

export interface FulfillmentDefaultPricingGroup {
  storage_pricing_scope?: PalletEquivalentStoragePricing | PeakLpnStoragePricing | NoneStoragePricing;
  container_pricing_scope?: ContainerPricing;
  consumer_fulfillment_pricing_scope?: ConsumerFulfillmentPricing;
  retail_fulfillment_pricing_scope?: RetailFulfillmentPricing;
  returns_pricing_scope?: ReturnsPricing;
}
export interface FulfillmentEachPackagingGroup {
  container_pricing_scope?: ContainerWithoutMaterialFeesPricing;
  consumer_fulfillment_pricing_scope?: ConsumerFulfillmentPricing;
  returns_pricing_scope?: ReturnsPricing;
}
export interface FulfillmentCartonPackagingGroup {
  consumer_fulfillment_pricing_scope?: ConsumerFulfillmentPricing;
  retail_fulfillment_pricing_scope?: RetailFulfillmentPricing;
  returns_pricing_scope?: ReturnsPricing;
}
export interface FulfillmentPalletPackagingGroup {
  storage_pricing_scope?: PalletEquivalentStoragePricing | PeakLpnStoragePricing | NoneStoragePricing;
}

export interface FulfillmentInventoryGroupPricing {
  default: FulfillmentDefaultPricingGroup;
  each: FulfillmentEachPackagingGroup;
  carton: FulfillmentCartonPackagingGroup;
  pallet: FulfillmentPalletPackagingGroup;
}

export interface InventoryGroup {
  id: number;
  company_id?: number;
  description: string;
  tempDescription: string;
  saved: boolean;
  enabled?: boolean;
  isDefault?: boolean;
  pricing: FulfillmentInventoryGroupPricing;
}

export interface ReservationPricing {
  defaultInventoryGroup?: InventoryGroup;
  warehouseExpenseGroup?: any;
  flatFeeGroup?: any;
  inventoryGroups: InventoryGroup[];
}

export interface AllPricing {
  storage_pricing: PalletEquivalentStoragePricing | PeakLpnStoragePricing | NoneStoragePricing;
  consumer_fulfillment_pricing: ConsumerFulfillmentPricing;
  container_pricing: ContainerPricing;
  cpd_fulfillment_pricing: CpdFulfillmentPricing;
  inventory_group?: any;
  location_group?: any;
  packaging: InventoryPackaging;
  retail_fulfillment_pricing: RetailFulfillmentPricing;
  returns_pricing: ReturnsPricing;
  handling_expense_pricing: HandlingExpensePricing;
  labor_expense_pricing: LaborExpensePricing;
  storage_expense_pricing: StorageExpensePricing;
  supplies_expense_pricing: SuppliesExpensePricing;
  transportation_expense_pricing: TransportationExpensePricing;
  other_expense_pricing_scope: OtherExpensePricing;
}

export interface PricingGroupScopes {
  [key: string]: PricingGroup;
}

export interface PricingScope {
  pricing_axes: {
    inventory_group?: number;
    packaging?: BillingUom;
  };
  pricing_scopes: PricingGroupScopes;
}

export type PricingScopeData = PricingScope[];

export enum StorageBillingMode {
  disabled = 'disabled',
  nightly_rounded_peak_pallet_equivalents = 'nightly_rounded_pallet_equivalents',
  nightly_fractional_peak_pallet_equivalents = 'nightly_fractional_pallet_equivalents',
  peak_lpn_pallet = 'peak_lpn_pallet',
  average_lpn_pallet = 'average_lpn_pallet',
  inner_system_none = 'inner_system_none',
}

export interface LocationInfo {
  pendingCreation?: boolean;
  conversions?: any;
  inventory_id?: number;
  is_default_location: boolean;
  locationsArrayIndex: number;
  location_id?: number;
  location_label: string;
  lpn_barcode?: string;
  inventory_tracking_data_id?: number;
  newPackaging?: InventoryPackaging;
  newQuantity?: any;
  packaging: InventoryPackaging;
  pallet_id: number;
  quantity: any;
  reservation_id: number;
  sku: string;
}

export enum FindLocationBy {
  Reservation = 'Reservation',
  Warehouse = 'Warehouse',
}

export interface LpnCorrection {
  reservationId: number;
  lpn: string;
  currentSku: string;
  targetSku?: string;
  targetShipmentId?: number;
  targetQuantity?: number;
  targetExpirationDate?: Date;
  targetLotCode?: string;
  targetManufactureDate?: Date;
  targetAsnNumber?: string;
  targetPoNumber?: string;
  targetCustomReference1?: string;
  targetCustomReference2?: string;
  targetCountryOfOrigin?: string;
  targetOriginSite?: string;
  targetEachesPerInnerPack?: number;
  targetEachesPerOuterCase?: number;
  targetPackaging?: string;
}
