import { v4 as uuidv4 } from 'uuid';
import { sample } from 'lodash';
function generatePatUid(): string {
  const normalUuid = uuidv4();
  const withUnderscores = normalUuid.replace(/-/g, '_');
  const leadingAlpha = sample(['a', 'b', 'c', 'd', 'e', 'f']);

  // Replace first char with random hex alpha to be compliant with PAT UUID schema
  return leadingAlpha + withUnderscores.substring(1);
}
export { generatePatUid };
