/* eslint-disable max-lines -- Pre-existing code complexity issue that needs attention  */
import * as React from 'react';
import { cloneDeep, get, isEmpty, omit, set } from 'lodash';
import LegacyFrontendAuth from '../../lib/authentication';
import flexeApi from '../../lib/flexeApi';
import InventoryGroupsService from '../../services/InventoryGroupsService';
import PricingService from '../../services/PricingService';
import ErrorMessage from '../../components/ErrorMessage';
import {
  FeeCurrency,
  FlexeApiResponse,
  InventoryGroup,
  ReservationAndScopeInfo,
  ReservationMetadata,
  ReservationPricing,
  ScopeTxnState,
  StorageBillingMode,
  StorageType,
} from '../../CommonInterfaces';
import { fetchPricingMetaData } from '../../lib/metaData';

import './styles.css';
import Button from '@material-ui/core/Button';
import SubmitButton from '../../components/SubmitButton';
import { PLATFORM_ACCOUNT, RAISED_BTN } from '../../lib/constants';
import { PropTypes } from '@material-ui/core';
import Color = PropTypes.Color;
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import { fetchPricingTemplate } from '../../lib/pricingTemplates';
import { PricingCatalog } from '../../services/data/PricingCatalogInterfaces';
import { PricingCatalogService } from '../../services/PricingCatalogService';
import { Link } from 'react-router-dom';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import InfoForm from '../../components/Forms/InfoForm';
import FulfillmentPricingForm from '../../components/Forms/FulfillmentPricingForm';

export interface NewReservationWizardProps {
  history: any;
}

export interface NewReservationWizardState {
  stepIndex: number;
  waitingForBackend: boolean;
  navDisabled: boolean;
  creatingNewRevision: boolean;
  reservation: ReservationAndScopeInfo;
  reservationId: number;
  scopeId: number;
  infoFormErrors: any;
  newReservationSuccess: boolean;
  configFormErrors?: any;
  pricing: ReservationPricing;
  reservationErrorMessage?: JSX.Element | string;
  pricingFormErrors: any;
  showCreateReservationModal: boolean;
  pendingReservationMetadata: ReservationMetadata;
  pricingTemplate: any;
  pricingMetadata: any;
  pricingCatalog: PricingCatalog;
}

const stepTitles = ['Reservation Info', 'Define Pricing'];

class NewReservationWizard extends React.Component<NewReservationWizardProps, NewReservationWizardState> {
  private inventoryGroupsService: InventoryGroupsService;
  private pricingService: PricingService;
  private pricingCatalogService: PricingCatalogService;

  constructor(props) {
    super(props);

    this.inventoryGroupsService = new InventoryGroupsService();
    this.pricingService = new PricingService();
    this.pricingCatalogService = new PricingCatalogService();

    this.state = {
      stepIndex: 0,
      navDisabled: false,
      reservationErrorMessage: null,
      newReservationSuccess: false,
      infoFormErrors: {},
      pricingFormErrors: {},
      configFormErrors: {},
      waitingForBackend: false,
      creatingNewRevision: false,
      showCreateReservationModal: false,
      reservationId: null,
      scopeId: null,
      reservation: {
        company_id: null,
        warehouse_id: null,
        start_on: new Date(),
        fee_currency: FeeCurrency.USD,
        capacity: '0',
        description: '',
        depositor_reservation_nickname: '',
        warehouse_reservation_nickname: '',
        set_billing_details: false,
        bill_to: {
          name: '',
          address_1: '',
          locality: '',
          postal_code: '',
          region: '',
          phone: '',
        },
        customer_vendor_id: '',
        default_storage_type: StorageType.rack,
        monthly_minimum: '0',
        monthly_warehouse_minimum: '0',
        storage_billing_mode: StorageBillingMode.nightly_rounded_peak_pallet_equivalents,
        reservation_scope_documents: [],
        allow_partial_receiving: false,
        allow_same_day_delivery: false,
        block_activity: false,
        client_labeled: false,
        pallet_label_prefix: '',
        account_type: PLATFORM_ACCOUNT,
        reservation_scope: {
          id: 0,
          block_activity: false,
          txn_state: ScopeTxnState.new,
          created_at: '',
          shipper_signed_on: '',
          warehouse_signed_on: '',
          payment_term: null,
          shipper_minimum: '',
          warehouse_minimum: '',
        },
        pricingOptions: {
          use_inventory_groups: false,
          use_packaging_groups: false,
          storage_pricing_scope: true,
          container_pricing_scope: true,
          consumer_fulfillment_pricing_scope: false,
          warehouse_expense_pricing_scope: false,
          retail_fulfillment_pricing_scope: false,
          sort_center_pricing_scope: false,
          returns_pricing_scope: false,
        },
      },
      pendingReservationMetadata: {
        id: null,
        primary_oc: '',
        primary_last_name: '',
        secondary_oc: '',
        secondary_last_name: '',
        ops_team_name: '',
        complexity_score: null,
        commercial_owner: '',
        program_group: '',
        program: '',
        commercial_planned_margin: null,
        version: null,
        created_by_name: '',
        created_by_id: null,
        created_by_type: '',
        created_at: '',
        account_type: '',
      },
      pricing: {
        defaultInventoryGroup: {
          id: 0,
          isDefault: true,
          saved: true,
          description: 'Default',
          tempDescription: 'Default',
          pricing: cloneDeep(fetchPricingTemplate(StorageBillingMode.nightly_rounded_peak_pallet_equivalents)),
        },
        inventoryGroups: [],
      },
      pricingMetadata: fetchPricingMetaData(StorageBillingMode.nightly_rounded_peak_pallet_equivalents, {}),
      pricingTemplate: fetchPricingTemplate(StorageBillingMode.nightly_rounded_peak_pallet_equivalents),
      pricingCatalog: {},
    };
  }

  public async componentDidMount() {
    let pricingCatalog = {};
    pricingCatalog = await this.pricingCatalogService.retrievePricingCatalog();
    const template = fetchPricingTemplate(this.state.reservation.storage_billing_mode);
    const metadata = fetchPricingMetaData(this.state.reservation.storage_billing_mode, pricingCatalog);
    this.setState((prevState) => ({
      pricingCatalog,
      pricingTemplate: template,
      pricingMetadata: metadata,
      pricing: {
        ...prevState.pricing,
        defaultInventoryGroup: {
          ...prevState.pricing.defaultInventoryGroup,
          pricing: cloneDeep(template),
        },
      },
    }));
  }

  public render() {
    return (
      <div className="page new-reservation-page">
        <div className="bread-crumbs">
          <Link to="/reservations">Reservations &gt;</Link> <span>New Fulfillment Reservation</span>
        </div>

        <Stepper activeStep={this.state.stepIndex}>
          {stepTitles.map((stepTitle, idx) => {
            return (
              <Step key={idx}>
                <StepLabel>{stepTitle}</StepLabel>
              </Step>
            );
          })}
        </Stepper>

        {this.renderErrorMessage(this.state.reservationErrorMessage)}
        {this.renderCurrentStep()}
        {this.setupNavButtons()}

        {this.renderCreateReservationModal()}
      </div>
    );
  }

  public componentDidUpdate(prevProps, prevState) {
    this.checkNavDisable();
  }

  private checkNavDisable() {
    if (this.state.stepIndex === 1) {
      if (!this.state.reservation.pricingOptions.container_pricing_scope) {
        this.disableNavButton();
      } else if (this.state.reservation.pricingOptions.use_inventory_groups && this.allInvGroupsDisabled()) {
        this.disableNavButton();
      } else {
        this.enableNavButton();
      }
    }
  }

  private allInvGroupsDisabled(): boolean {
    let invGroupsDisabled = true;
    this.state.pricing.inventoryGroups.forEach((invGroup: InventoryGroup) => {
      if (invGroup.enabled) {
        invGroupsDisabled = false;
      }
    });
    return invGroupsDisabled;
  }

  private disableNavButton = () => {
    if (!this.state.navDisabled) {
      this.setState({ navDisabled: true });
    }
  };

  private enableNavButton = () => {
    if (this.state.navDisabled) {
      this.setState({ navDisabled: false });
    }
  };

  private setupNavButtons = () => {
    switch (this.state.stepIndex) {
      case 0:
        return this.renderNavButtons('Create new reservation', this.showCreateReservationModal);
      case 1:
        return this.renderNavButtons('Finish', this.createPricingScopes, 'primary');
    }
  };

  private renderCurrentStep = () => {
    switch (this.state.stepIndex) {
      case 0: {
        return (
          <InfoForm
            formData={this.state.reservation}
            formErrors={this.state.infoFormErrors}
            onFormUpdate={this.handleReservationUpdate}
          />
        );
      }
      case 1: {
        return (
          <FulfillmentPricingForm
            formData={this.state.reservation}
            pricing={this.state.pricing}
            onInventoryGroupsReturned={this.setInventoryGroups}
            onAddInventoryGroup={this.handleAddInventoryGroup}
            cancelEditInventoryGroup={this.handleCancelEditInventoryGroup}
            createOrUpdateInventoryGroup={this.handleCreateOrUpdateInventoryGroup}
            metaData={this.state.pricingMetadata}
            formErrors={this.state.pricingFormErrors}
            setPricingFormErrors={this.handleSetPricingFormErrors}
            onFormUpdate={this.handleReservationUpdate}
            disableNavigation={this.disableNavButton}
            enableNavigation={this.enableNavButton}
          />
        );
      }
    }
  };

  protected renderErrorMessage(reservationErrorMessage?: JSX.Element | string) {
    if (reservationErrorMessage) {
      return <ErrorMessage>{reservationErrorMessage}</ErrorMessage>;
    }
  }

  protected renderCreateReservationModal = () => {
    return (
      <Dialog open={this.state.showCreateReservationModal}>
        <DialogTitle>Create New Reservation?</DialogTitle>
        <DialogContent>
          <p>This will create a new reservation. Do you wish to proceed?</p>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={this.hideCreateReservationModal}>
            No
          </Button>
          <SubmitButton
            type={RAISED_BTN}
            label="Yes"
            color="primary"
            onClick={this.createReservationAndScope}
            showSpinner={this.state.creatingNewRevision}
            disabled={this.state.creatingNewRevision}
          />
        </DialogActions>
      </Dialog>
    );
  };

  protected showCreateReservationModal = () => {
    this.setState({
      showCreateReservationModal: true,
    });
  };

  protected hideCreateReservationModal = () => {
    this.setState({
      showCreateReservationModal: false,
    });
  };

  protected goToPrevStep = () => {
    this.setState({
      stepIndex: this.state.stepIndex - 1,
      waitingForBackend: false,
      reservationErrorMessage: null,
      newReservationSuccess: false,
      infoFormErrors: {},
      pricingFormErrors: {},
      configFormErrors: {},
    });
  };

  protected goToNextStep = () => {
    this.setState({
      stepIndex: this.state.stepIndex + 1,
      waitingForBackend: false,
      reservationErrorMessage: null,
      newReservationSuccess: false,
      infoFormErrors: {},
      pricingFormErrors: {},
      configFormErrors: {},
    });
  };

  // eslint-disable-next-line complexity -- Disabled pre-existing violation of complexity rule
  protected createReservationAndScope = () => {
    this.hideCreateReservationModal();
    this.setState({ waitingForBackend: true });

    // First check if the company_id and warehouse_id are set. They are needed to verify the
    // reservation on the backend
    if (!this.state.reservation.company_id || !this.state.reservation.warehouse_id) {
      let noCompanySelectedMessage = '';
      let noWarehouseSelectedMessage = '';
      if (!this.state.reservation.company_id) {
        noCompanySelectedMessage = 'Please choose a shipping company';
      }
      if (!this.state.reservation.warehouse_id) {
        noWarehouseSelectedMessage = 'Please choose a warehouse';
      }
      this.setState({
        waitingForBackend: false,
        infoFormErrors: {
          company_id: noCompanySelectedMessage,
          warehouse_id: noWarehouseSelectedMessage,
        },
      });
      return;
    }

    // Check that description is entered
    if (!this.state.reservation.description) {
      this.setState({
        waitingForBackend: false,
        infoFormErrors: {
          description: 'Please enter a description',
        },
      });
      return;
    }

    const payload = {
      reservation: this.state.reservation,
    };

    return flexeApi.createReservationAndScope(payload).then((response) => {
      const infoFormErrors = get(response, 'error.msg');
      let reservationId;
      let scopeId;
      if (infoFormErrors) {
        this.handleValidationError('infoFormErrors', infoFormErrors);
        return false;
      } else {
        const responseData = get(response, 'data', {});
        reservationId = responseData.reservationId;
        scopeId = responseData.scopeId;
      }

      // Create Reservation Metadata containing account_type information.
      this.createReservationMetadata(reservationId);

      this.setState({
        waitingForBackend: false,
        reservationId,
        scopeId,
        infoFormErrors: infoFormErrors || {},
        pricingMetadata: fetchPricingMetaData(this.state.reservation.storage_billing_mode, this.state.pricingCatalog),
        pricingTemplate: fetchPricingTemplate(this.state.reservation.storage_billing_mode),
        pricing: {
          ...this.state.pricing,
          defaultInventoryGroup: {
            ...this.state.pricing.defaultInventoryGroup,
            pricing: cloneDeep(fetchPricingTemplate(this.state.reservation.storage_billing_mode)),
          },
        },
      });
      this.goToNextStep();
      return true;
    });
  };

  protected createReservationMetadata = (reservationId) => {
    LegacyFrontendAuth.authenticateWithCookie().then((response) => {
      response.json().then((responseJSON) => {
        this.state.pendingReservationMetadata.created_by_name = responseJSON.email;
        this.state.pendingReservationMetadata.account_type = this.state.reservation.account_type;
        flexeApi.createReservationMetadataVersion(reservationId, this.state.pendingReservationMetadata);
      });
    });
  };

  // eslint-disable-next-line complexity -- Disabled pre-existing violation of complexity rule
  protected createPricingScopes = () => {
    // Disable "NEXT" button and add a processing spinner
    this.setState({ waitingForBackend: true });

    const pricingScopeData = [];
    const { pricingOptions } = this.state.reservation;
    const { use_inventory_groups, use_packaging_groups } = pricingOptions;

    if (!use_inventory_groups) {
      const inventoryGroupData = this.inventoryGroupsService.processInventoryGroup(
        this.state.pricing.defaultInventoryGroup,
        pricingOptions,
      );
      inventoryGroupData.forEach((packagingGroup) => {
        if (!isEmpty(packagingGroup.pricing_scopes)) {
          pricingScopeData.push(packagingGroup);
        }
      });
    } else {
      this.state.pricing.inventoryGroups.forEach((invGroup: InventoryGroup) => {
        if (invGroup.enabled) {
          const inventoryGroupData = this.inventoryGroupsService.processInventoryGroup(invGroup, pricingOptions);

          inventoryGroupData.forEach((packagingGroup) => {
            if (!isEmpty(packagingGroup.pricing_scopes)) {
              pricingScopeData.push(packagingGroup);
            }
          });
        }
      });
    }

    // Only for Fulfillment type reservation pricing
    if (
      !this.state.reservation.pricingOptions.consumer_fulfillment_pricing_scope &&
      !this.state.reservation.pricingOptions.retail_fulfillment_pricing_scope &&
      !this.state.reservation.pricingOptions.sort_center_pricing_scope
    ) {
      this.setState({
        waitingForBackend: false,
        reservationErrorMessage: <p>Outbound pricing must be set.</p>,
      });
      window.scrollTo(0, 0);
      return;
    }

    // Check if any fees of a selected service are not set
    const configErrors = this.pricingService.checkForIncorrectConfiguration(
      pricingScopeData,
      pricingOptions,
      this.state.pricingMetadata,
    );
    if (Object.keys(configErrors).length) {
      // Display config errors
      const reservationErrorMessage = this.pricingService.processReservationConfigErrors(configErrors);
      this.setState({
        waitingForBackend: false,
        creatingNewRevision: false,
        reservationErrorMessage,
      });
      return;
    }

    const processedPricingScopeData = this.pricingService.removeEmptyServices(pricingScopeData, pricingOptions);

    const warehouseMinimum = this.state.reservation.monthly_warehouse_minimum;
    const shipperMinimum = this.state.reservation.monthly_minimum;

    flexeApi
      .createPricingScopes({
        reservation_scope_id: this.state.scopeId,
        pricing_scope_data: processedPricingScopeData,
        warehouse_minimum: warehouseMinimum,
        shipper_minimum: shipperMinimum,
      })
      .then((response) => {
        const responseError = get(response, 'error.msg');
        if (responseError) {
          const stateUpdate = this.pricingService.handlePricingValidationError(responseError);
          this.setState({ ...stateUpdate, waitingForBackend: false });
        } else {
          this.loadReservationInfoPage();
        }
      });
  };

  protected handleReservationUpdate = (key: string, value: any, errorKey?: string) => {
    const state: NewReservationWizardState = cloneDeep(this.state);
    set(state, key, value);
    state.reservationErrorMessage = null; // Clear error message(s) when the form is updated
    if (errorKey) {
      state.pricingFormErrors = omit(state.pricingFormErrors, [errorKey]);
    }
    this.setState(state);
  };

  protected setInventoryGroups = (inventoryGroups: InventoryGroup[]) => {
    const updatedState = this.inventoryGroupsService.proccessInventoryGroups(
      this.state,
      inventoryGroups,
      this.state.pricingTemplate,
    );
    this.setState(updatedState);
  };

  protected handleAddInventoryGroup = () => {
    const updatedState = this.inventoryGroupsService.addInventoryGroup(this.state, this.state.pricingTemplate);
    this.setState(updatedState);
  };

  protected handleCancelEditInventoryGroup = ({ currentTarget }: { currentTarget: HTMLElement }) => {
    const invGroupIdx = parseInt(currentTarget.dataset.invGroupIdx, 10);
    const updatedState = this.inventoryGroupsService.cancelEditInventoryGroup(this.state, invGroupIdx);
    this.setState(updatedState);
  };

  protected handleCreateOrUpdateInventoryGroup = ({ currentTarget }: { currentTarget: HTMLElement }) => {
    const invGroupIdx = parseInt(currentTarget.dataset.invGroupIdx, 10);
    this.createOrUpdateInventoryGroup(this.state.reservation.company_id, invGroupIdx);
  };

  protected handleSetPricingFormErrors = (errors: any) => {
    this.setState({ pricingFormErrors: errors });
    window.scrollTo(0, 0);
  };

  protected handleValidationError(errorsObjKey: string, responseError: any) {
    if (typeof responseError !== 'string') {
      // responseError is expected to be an object map of field name -> array of error strings
      // join all the error strings and field names together into a single top-level error message
      const combinedErrorMessages = [];
      for (const errorKey in responseError) {
        if (responseError.hasOwnProperty(errorKey)) {
          combinedErrorMessages.push(errorKey + ': ' + responseError[errorKey].join(', '));
        }
      }
      const errorMessages = combinedErrorMessages.map((msg) => <p key={msg}>{msg}</p>);
      this.setState({
        waitingForBackend: false,
        reservationErrorMessage: <React.Fragment>{errorMessages}</React.Fragment>,
      });
    } else {
      // The responseError property points to a string, not an object, indicating that
      // there was a backend error, not form validation errors. Set
      // state.reservationErrorMessage so a general error message is displayed instead.
      this.setState({
        waitingForBackend: false,
        reservationErrorMessage: <p>{responseError}</p>,
      });
    }
    window.scrollTo(0, 0);
  }

  protected loadReservationInfoPage = () => {
    if (this.state.reservationId) {
      this.props.history.push(`/reservations/${this.state.reservationId}/#new`);
    }
  };

  protected renderNavButtons = (label, handleNextFn, color: Color = 'default') => {
    return (
      <div className="nav-buttons">
        <Button variant="contained" color={color ? color : 'default'} onClick={this.goToPrevStep} disabled={true}>
          Back
        </Button>
        <SubmitButton
          data-testid="res-create-next-step"
          type={RAISED_BTN}
          label={label}
          color={color ? color : 'default'}
          onClick={handleNextFn}
          disabled={this.state.waitingForBackend || this.state.navDisabled}
          showSpinner={this.state.waitingForBackend}
        />
      </div>
    );
  };

  private createOrUpdateInventoryGroup = (companyId: number, invGroupIdx: number) => {
    const invGroup = cloneDeep(this.state.pricing.inventoryGroups[invGroupIdx]);
    const body = {
      data: {
        description: invGroup.tempDescription,
      },
    };
    const promise = invGroup.saved
      ? flexeApi.updateInventoryGroup(companyId, invGroup.id, body)
      : flexeApi.createInventoryGroup(companyId, body);

    promise.then(({ data, statusCode, error }: FlexeApiResponse) => {
      if ((statusCode === 200 || statusCode === 201) && data) {
        const updatedState = this.inventoryGroupsService.mergeInventoryGroupState(
          this.state,
          invGroupIdx,
          data,
          this.state.pricingTemplate,
        );
        this.setState(updatedState);
      } else if (error && typeof error !== 'string') {
        this.setReservationErrorMessage(error.msg);
      }
    });
  };

  private setReservationErrorMessage = (reservationErrorMessage: JSX.Element | string) => {
    this.setState({ reservationErrorMessage });
    window.scrollTo(0, 0);
  };
}

export default NewReservationWizard;
