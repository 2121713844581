import * as React from 'react';
import flexeApi from '../../lib/flexeApi';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ContentCopyIcon from '@material-ui/icons/FileCopyRounded';
import { Link } from 'react-router-dom';
import { EXCLUDED_CARRIER_NAMES } from '../../lib/constants';

interface Carrier {
  id: number;
  name: string;
}

interface ServiceLevel {
  id: number;
  name: string;
}

interface CutoffTime {
  dayOfWeek: string;
  timeOfDay: string;
}

interface ReservationCutoff {
  reservationId: number;
  carrier: Carrier;
  serviceLevel: ServiceLevel;
  cutoffTimes: CutoffTime[];
}

interface ReservationCutoffsState {
  reservationCutoffs: ReservationCutoff[];
  showDialog: boolean;
  editing: boolean;
  selectedCarrierId: number;
  carriers: Carrier[];
  selectedServiceLevelId: number;
  serviceLevelOptions: ServiceLevel[];
  carrierServiceLevels: { [carrierId: number]: ServiceLevel[] };
  cutoffTimes: CutoffTime[];
  error: string;
}

interface ReservationCutoffsProps {
  match: {
    params: {
      id: string;
    };
  };
}

const defaultCarrier: Carrier = { id: 0, name: 'default' };
const defaultServiceLevel: ServiceLevel = { id: 0, name: 'default' };
const dayNames = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

class ReservationCutoffs extends React.Component<ReservationCutoffsProps, ReservationCutoffsState> {
  constructor(props) {
    super(props);
    this.state = {
      reservationCutoffs: [],
      selectedCarrierId: defaultCarrier.id,
      selectedServiceLevelId: defaultServiceLevel.id,
      carriers: [],
      serviceLevelOptions: [],
      carrierServiceLevels: {},
      cutoffTimes: [],
      showDialog: false,
      editing: false,
      error: null,
    };
    this.getCarrierServiceLevels();
  }

  public componentDidMount(): void {
    this.getReservationCutoffs();
  }

  public render() {
    return (
      <div className="page">
        <div>
          <div className="bread-crumbs">
            <Link to="/reservations">Reservations &#62; &nbsp;</Link>
            <Link to={`/reservations/${this.props.match.params.id}`}>
              Reservation {this.props.match.params.id} &#62; &nbsp;
            </Link>
            <span>Reservation {this.props.match.params.id} Cutoffs</span>
          </div>
        </div>
        <div className="header pure-g">
          {this.state.error && (
            <Snackbar
              open={this.state.error != null}
              autoHideDuration={3000}
              message={this.state.error}
              onClose={() => this.setState({ error: null })}
            />
          )}
          <div className="pure-u-3-4">
            <h1>Reservation Cutoffs for Reservation {this.props.match.params.id}</h1>
          </div>
          <div className="pure-u-1-4">
            <Button className="pull-right" variant="contained" onClick={() => this.setState({ showDialog: true })}>
              Create a New Cutoff
            </Button>
          </div>
        </div>

        <Table>
          <TableHead>
            <TableRow key="header-row">
              <TableCell>Carrier</TableCell>
              <TableCell>Service Level</TableCell>
              <TableCell>Monday</TableCell>
              <TableCell>Tuesday</TableCell>
              <TableCell>Wednesday</TableCell>
              <TableCell>Thursday</TableCell>
              <TableCell>Friday</TableCell>
              <TableCell>Saturday</TableCell>
              <TableCell>Sunday</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.reservationCutoffs.map((cutoff) => {
              return (
                <TableRow key={`${cutoff.carrier.id}-${cutoff.serviceLevel.id}`}>
                  <TableCell>{cutoff.carrier.name}</TableCell>
                  <TableCell>{cutoff.serviceLevel.name}</TableCell>
                  {dayNames.map((dayOfWeek) => {
                    const timeEntry = cutoff.cutoffTimes.find((c) => c.dayOfWeek === dayOfWeek);
                    const cutoffTime = timeEntry ? timeEntry.timeOfDay : '';
                    return (
                      <TableCell>
                        <Input type="time" value={cutoffTime} disabled={true} />
                      </TableCell>
                    );
                  })}
                  <TableCell>
                    <IconButton aria-label="edit-button" onClick={() => this.editCutoff(cutoff)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      disabled={
                        this.state.reservationCutoffs.length === 1 &&
                        this.state.reservationCutoffs[0].carrier.name === 'default' &&
                        this.state.reservationCutoffs[0].serviceLevel.name === 'default' &&
                        this.state.reservationCutoffs[0].cutoffTimes.every(
                          (selectedCutoff) => selectedCutoff.timeOfDay === '12:00',
                        )
                      }
                      aria-label="delete-button"
                      onClick={() => this.deleteCutoffs(cutoff)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <Dialog open={this.state.showDialog} maxWidth="md" fullWidth={true} aria-label="dialog" title="dialog">
          <DialogTitle>
            {this.state.editing ? 'Update Reservation Cutoff' : 'Create a new Reservation Cutoff'}
          </DialogTitle>
          <DialogContent>
            <FormControl>
              <InputLabel htmlFor="carrier">Carrier</InputLabel>
              <Select
                value={this.state.selectedCarrierId}
                disabled={this.state.editing}
                onChange={(e) =>
                  this.setState({
                    selectedCarrierId: parseInt(e.target.value as string, 10),
                    serviceLevelOptions: this.state.carrierServiceLevels[parseInt(e.target.value as string, 10)],
                  })
                }
                name="carrier"
              >
                {this.state.carriers.map((carrier) => (
                  <MenuItem value={carrier.id}>{carrier.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel htmlFor="service-level">Service Level</InputLabel>
              <Select
                value={this.state.selectedServiceLevelId}
                disabled={this.state.editing}
                onChange={(e) => this.setState({ selectedServiceLevelId: parseInt(e.target.value as string, 10) })}
                name="service-level"
              >
                {this.state.serviceLevelOptions.map((serviceLevel) => (
                  <MenuItem key={serviceLevel.id} value={serviceLevel.id}>
                    {serviceLevel.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {dayNames.map((dayOfWeek, index) => {
              const cutoffTimeEntry = this.state.cutoffTimes.find((c) => c.dayOfWeek === dayOfWeek);
              const cutoffTime = cutoffTimeEntry ? cutoffTimeEntry.timeOfDay : '';
              return (
                <Grid container spacing={3} style={{ marginTop: '10px' }}>
                  <Grid item sm={10} md={10} lg={10}>
                    <FormControl style={{ margin: '10px' }}>
                      <Input
                        onChange={this.updateCutoffTime}
                        type="time"
                        title={dayOfWeek}
                        value={cutoffTime}
                        id={dayOfWeek}
                        startAdornment={<InputAdornment position="start">{dayOfWeek}</InputAdornment>}
                      />
                    </FormControl>
                  </Grid>
                  {index === 0 && (
                    <Grid item sm={2} md={2} lg={2}>
                      <IconButton color="primary" title="copy to all" onClick={this.copyToAll}>
                        <ContentCopyIcon />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              );
            })}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ showDialog: false })} color="primary">
              Cancel
            </Button>
            <Button onClick={this.upsertCutoffs} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  private copyToAll = () => {
    const mondayCutoff = this.state.cutoffTimes.find((c) => c.dayOfWeek === 'monday');
    if (!mondayCutoff || mondayCutoff.timeOfDay === null || mondayCutoff.timeOfDay.length <= 0) {
      return;
    }

    this.setState({
      cutoffTimes: dayNames.map((d) => ({ dayOfWeek: d, timeOfDay: mondayCutoff.timeOfDay })),
    });
  };

  private updateCutoffTime = (e) => {
    const cutoffEntry = this.state.cutoffTimes.find((c) => c.dayOfWeek === e.target.id);
    let newCutoffTimes;
    if (cutoffEntry) {
      newCutoffTimes = this.state.cutoffTimes.map((c) => {
        if (c.dayOfWeek === e.target.id) {
          c.timeOfDay = e.target.value;
        }
        return c;
      });
    } else {
      newCutoffTimes = this.state.cutoffTimes.concat([{ dayOfWeek: e.target.id, timeOfDay: e.target.value }]);
    }
    this.setState({
      cutoffTimes: newCutoffTimes,
    });
  };

  private editCutoff = (cutoff: ReservationCutoff) => {
    this.setState({
      selectedCarrierId: cutoff.carrier.id,
      serviceLevelOptions: this.state.carrierServiceLevels[cutoff.carrier.id],
      selectedServiceLevelId: cutoff.serviceLevel.id,
      cutoffTimes: cutoff.cutoffTimes.map((c) => Object.assign({}, c)),
      showDialog: true,
      editing: true,
    });
  };

  private upsertCutoffs = async () => {
    let cutoffTimesToDelete;
    const newCutoff = {
      reservationId: this.props.match.params.id,
      carrierId: this.state.selectedCarrierId,
      serviceLevelId: this.state.selectedServiceLevelId,
      cutoffTimes: this.state.cutoffTimes.filter((c) => c.timeOfDay !== null && c.timeOfDay.length > 0),
    };

    if (this.state.editing) {
      cutoffTimesToDelete = this.state.cutoffTimes
        .filter((c) => !c.timeOfDay || c.timeOfDay === '')
        .map((c) => {
          return {
            reservationId: this.props.match.params.id,
            carrierId: this.state.selectedCarrierId,
            serviceLevelId: this.state.selectedServiceLevelId,
            dayOfWeek: c.dayOfWeek,
          };
        });
    }

    try {
      if (cutoffTimesToDelete && cutoffTimesToDelete.length) {
        await flexeApi.deleteReservationCutoffs(cutoffTimesToDelete);
      }
      await flexeApi.upsertReservationCutoffs(newCutoff);
    } catch (e) {
      this.setState({ error: e.toString() });
    }

    this.getReservationCutoffs();
    this.setState({
      showDialog: false,
      editing: false,
      selectedCarrierId: defaultCarrier.id,
      selectedServiceLevelId: defaultServiceLevel.id,
      cutoffTimes: [],
    });
  };

  private getReservationCutoffs = () => {
    const reservationId = parseInt(this.props.match.params.id, 10);
    return flexeApi
      .getReservationCutoffs(reservationId)
      .then((resp: any) => {
        let reservationCutoffs = resp.reservationCutoffs;
        const error = resp.errors.map((e) => e.title).join(', ');
        if (error && error.length) {
          reservationCutoffs = [];
        }
        this.setState({
          reservationCutoffs,
          error,
        });
      })
      .catch((error) => {
        this.setState({
          reservationCutoffs: [],
          error,
        });
      });
  };

  private deleteCutoffs = (cutoff: ReservationCutoff) => {
    const cutoffKeys = cutoff.cutoffTimes.map((cutoffTime) => {
      return {
        reservationId: this.props.match.params.id,
        carrierId: cutoff.carrier.id,
        serviceLevelId: cutoff.serviceLevel.id,
        dayOfWeek: cutoffTime.dayOfWeek,
      };
    });

    flexeApi
      .deleteReservationCutoffs(cutoffKeys)
      .then((resp: any) => {
        if (resp.errors && resp.errors.length) {
          this.setState({
            error: resp.errors[0].title,
          });
        } else {
          this.getReservationCutoffs();
        }
      })
      .catch((error) => this.setState({ error }));
  };

  private getCarrierServiceLevels = () => {
    flexeApi.getCarrierServiceLevels().then((resp: any) => {
      const carrierServiceLevels = resp.serviceLevels;
      const { carriers, serviceLevels } = carrierServiceLevels.reduce(
        (result, carrierServiceLevel) => {
          if (!EXCLUDED_CARRIER_NAMES.includes(carrierServiceLevel.carrier.name)) {
            result.carriers[carrierServiceLevel.carrier.id] = carrierServiceLevel.carrier;
          }

          if (result.serviceLevels[carrierServiceLevel.carrier.id] != null) {
            result.serviceLevels[carrierServiceLevel.carrier.id].push({
              id: carrierServiceLevel.id,
              name: carrierServiceLevel.name,
            });
          } else {
            result.serviceLevels[carrierServiceLevel.carrier.id] = [
              { id: carrierServiceLevel.id, name: carrierServiceLevel.name },
            ];
          }
          return result;
        },
        { carriers: { 0: defaultCarrier }, serviceLevels: { 0: [] } },
      );
      Object.keys(serviceLevels).forEach((key) => serviceLevels[key].push(defaultServiceLevel));
      this.setState({
        carriers: Object.values(carriers),
        carrierServiceLevels: serviceLevels,
        serviceLevelOptions: serviceLevels[this.state.selectedCarrierId],
      });
    });
  };
}

export default ReservationCutoffs;
