import * as React from 'react';
import Avatar from '@material-ui/core/Avatar';
import AlertError from '@material-ui/icons/Error';
import { errorRed, redHighlight } from '../../styles/flexeColors';
import './styles.css';

const avatarStyles = {
  marginRight: 10,
  width: 28,
  height: 28,
  display: 'inline-block',
  verticalAlign: 'top',
};

const iconStyles = {
  margin: 2,
};

interface ErrorMessageProps {
  children: JSX.Element | string;
}

const ErrorMessage = ({ children }: ErrorMessageProps) => {
  return (
    <div className="error-message">
      <Avatar color="error" style={avatarStyles}>
        <AlertError style={iconStyles} />
      </Avatar>
      <div className="content">{children}</div>
    </div>
  );
};

export default ErrorMessage;
