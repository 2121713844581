import * as React from 'react';
import { Link, Redirect, Route, Switch } from 'react-router-dom';
import Dashboard from './pages/Dashboard/';
import Dlq from './pages/Dlq/';
import Locations from './pages/Locations/';
import Finance from './pages/Finance/Finance';
import Reservations from './pages/Reservations/';
import ReservationDetail from './pages/ReservationDetail';
import NewReservationWizard from './pages/NewReservation/NewReservationWizard';
import NotFound404 from './pages/NotFound404';
import LegacyFrontendAuth from './lib/authentication';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import IdleMonitor from './auth/IdleMonitor';
import ReservationCutoffs from './pages/ReservationCutoffs';
import { DEFAULT_CLOUD_ENV_SESSION_TIMEOUT, DEFAULT_LOCAL_ENV_SESSION_TIMEOUT } from './lib/constants';
import { authContext } from './lib/sso';
import Configuration from './lib/config';
const styles = {
  logoutBtn: {
    color: '#FFF',
    margin: '10px 24px',
  },
};

interface AppState {
  authenticated?: boolean; // tracks legacy authentication
  userEmail?: string;
  configReady: boolean;
}

const config = Configuration.getInstance();

class App extends React.Component<any, AppState> {
  static contextType = authContext;
  private today: Date;

  constructor(props) {
    super(props);

    this.today = new Date();

    this.state = {
      authenticated: null,
      configReady: false,
    };
  }

  public logout = (event) => {
    if (event) {
      event.preventDefault();
    }
    this.context.signout();
  };

  public componentDidMount() {
    config.ready().then((cfg: Configuration) => {
      this.setState({ configReady: true });
      if (cfg.getSetting('ssoEnabled') !== 'enabled') {
        LegacyFrontendAuth.authenticateWithCookie()
          .then((response) => {
            response.json().then((responseJSON) => {
              this.setState({ authenticated: true, userEmail: responseJSON.email });
            });
          })
          .catch(() => {
            this.setState({ authenticated: false });
          });
      }
    });
  }

  // eslint-disable-next-line complexity -- TODO: should fix this but right now need to focus on getting this auth stuff to work
  public render() {
    const ssoAuth = this.context;

    if (!this.state.configReady) {
      return null;
    }
    const ssoEnabled = config.getSetting('ssoEnabled');

    if (!ssoEnabled && this.state.authenticated === null) {
      return null;
    } else if (!ssoEnabled && this.state.authenticated === false) {
      return <Redirect to="/login" />;
    } else {
      return (
        <div className="app">
          <Toolbar className="tool-bar" variant="dense">
            <div className="toolbar-group toolbar-group-first">
              <div className="ops-dashboard">
                <Link to="/">Ops Dashboard</Link>
              </div>
            </div>
            <div className="toolbar-group">
              <Divider />
              <div className="nav_new">
                <Link to="/locations">WMS Locations</Link>
              </div>
              <div className="nav_new">
                <Link to="/reservations">Reservations</Link>
              </div>
              <div className="nav_new">
                <Link to="/finance">Finance</Link>
              </div>
              <Divider />
              <div className="nav_new">
                <Link to="/dlq">DLQ</Link>
              </div>
            </div>
            <div className="toolbar-group">
              {ssoEnabled ? ssoAuth.user : this.state.userEmail}
              <Button variant="text" onClick={this.logout} style={styles.logoutBtn}>
                Logout
              </Button>
            </div>
          </Toolbar>

          <Switch>
            <Route path="/" component={Dashboard} exact />
            <Route path="/dlq" component={Dlq} exact />
            <Route
              path="/reservations/new"
              exact
              render={({ location, history }) => {
                if (location.hash === '#fulfillment') {
                  return <NewReservationWizard history={history} />;
                }
                return <NotFound404 />;
              }}
            />
            <Route path="/locations" exact component={Locations} />

            <Route
              path="/reservations"
              exact
              render={(props) => {
                return <Reservations history={props.history} />;
              }}
            />

            <Route
              path="/reservations/:id(\d+)"
              exact
              render={(props) => {
                return <ReservationDetail flags={{}} {...props} />;
              }}
            />
            <Route path="/reservations/:id(\d+)/cutoffs" exact render={(props) => <ReservationCutoffs {...props} />} />
            <Route path="/finance" exact component={Finance} />
            <Route component={NotFound404} />
          </Switch>

          <footer>
            <div className="build-info">
              <span className="environment">{window.environment}</span>
              <span className="pipe">&nbsp;|&nbsp;</span>
              <a href={`https://gitlab.com/flexe/admin_api/tree/${window.gitCommitSha}`} target="_blank">
                {window.gitCommitSha}
              </a>
            </div>
            <div>
              <small className="copyright">&copy; {this.today.getFullYear()} Flexe, Inc.</small>
              <img className="logo" src="/FLEXE-logo-footer.png" alt="Flexe Logo" />
            </div>
          </footer>

          <IdleMonitor
            sessionTimeoutInSeconds={
              window.environment === 'development'
                ? DEFAULT_LOCAL_ENV_SESSION_TIMEOUT
                : DEFAULT_CLOUD_ENV_SESSION_TIMEOUT
            }
            logout={(event) => this.logout(event)}
          />
        </div>
      );
    }
  }
}

export default App;
