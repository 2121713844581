import * as React from 'react';
import * as PropTypes from 'prop-types';
import Form from '../../Form';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

interface ReservationSettingsProps {
  blockActivity: boolean;
  blockActivityKey: string;
  blockActivityBoxEnabled: boolean;
  onFieldChange(event);
}

class ReservationSettings extends Form<ReservationSettingsProps, null> {
  public render() {
    const {
      formData,
      blockActivity,
      blockActivityKey,
      onFieldChange,
      formErrors,
      disableForm,
      blockActivityBoxEnabled,
    } = this.props;

    return (
      <div>
        <fieldset>
          <legend>Settings</legend>

          <FormControlLabel
            disabled={disableForm}
            control={
              <Checkbox
                className="checkbox"
                name="reservation.allow_same_day_delivery"
                checked={formData.allow_same_day_delivery}
                onChange={onFieldChange}
              />
            }
            label="Same Day Delivery"
          />
          <br />

          <FormControlLabel
            disabled={disableForm}
            control={
              <Checkbox
                className="checkbox"
                name="reservation.allow_partial_receiving"
                checked={formData.allow_partial_receiving}
                onChange={onFieldChange}
              />
            }
            label="Allow Partial Container Receipt"
          />
          <br />

          <FormControlLabel
            disabled={disableForm}
            control={
              <Checkbox
                className="checkbox"
                name="reservation.client_labeled"
                checked={formData.client_labeled}
                onChange={onFieldChange}
              />
            }
            label="Client Labeled"
          />
          <br />

          <FormControl disabled={disableForm}>
            <InputLabel htmlFor="pallet-label-prefix">Pallet Label Prefix</InputLabel>
            <Input
              id="pallet-label-prefix"
              name="reservation.pallet_label_prefix"
              value={formData.pallet_label_prefix}
              onChange={onFieldChange}
            />
            {formErrors.pallet_label_prefix && (
              <FormHelperText error={true}>{formErrors.pallet_label_prefix}</FormHelperText>
            )}
          </FormControl>
        </fieldset>

        {/* This is currently unused, but we're not removing yet, so for now we hide and default to OFF */}
        <fieldset hidden>
          <legend>Block Until Approved?</legend>

          <FormControlLabel
            disabled={!blockActivityBoxEnabled && disableForm}
            control={
              <Checkbox className="checkbox" name={blockActivityKey} checked={blockActivity} onChange={onFieldChange} />
            }
            label="Block all activity"
          />
          <br />
        </fieldset>
      </div>
    );
  }
}

export default ReservationSettings;
