import * as React from 'react';
import Form from '../../Form';
import { FormControl } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';
import './ReservationSetupCurrency.css';
interface ReservationMinimumsProps {
  shipperMinimum: string;
  shipperMinimumKey: string;
  warehouseMinimum: string;
  warehouseMinimumKey: string;
  onFieldChange(event);
}

class ReservationMinimums extends Form<ReservationMinimumsProps, any> {
  public render() {
    const {
      shipperMinimum,
      shipperMinimumKey,
      warehouseMinimum,
      warehouseMinimumKey,
      onFieldChange,
      formErrors,
      disableForm,
    } = this.props;
    return (
      <fieldset>
        <legend>Monthly Minimums</legend>

        <FormControl disabled={disableForm}>
          <InputLabel htmlFor="monthly-minimum">Shipper Minimum *</InputLabel>
          <Input
            id="monthly-minimum"
            name={shipperMinimumKey}
            value={shipperMinimum}
            onChange={onFieldChange}
            type="number"
            inputProps={{ min: 0, step: 1 }}
            required
          />
          <FormHelperText>i.e. 100</FormHelperText>
          <span className="dollar-sign">$</span>
          {formErrors.monthly_minimum && <FormHelperText error={true}>{formErrors.monthly_minimum}</FormHelperText>}
        </FormControl>

        <FormControl disabled={disableForm}>
          <InputLabel htmlFor="monthly-warehouse-minimum">Warehouse Minimum *</InputLabel>
          <Input
            id="monthly-warehouse-minimum"
            name={warehouseMinimumKey}
            value={warehouseMinimum}
            onChange={onFieldChange}
            type="number"
            inputProps={{ min: 0, step: 1 }}
            required
          />
          <FormHelperText>i.e. 100</FormHelperText>
          <span className="dollar-sign">$</span>
          {formErrors.monthly_warehouse_minimum && (
            <FormHelperText error={true}>{formErrors.monthly_warehouse_minimum}</FormHelperText>
          )}
        </FormControl>
      </fieldset>
    );
  }
}

export default ReservationMinimums;
