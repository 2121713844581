import 'whatwg-fetch';
import 'promise-polyfill';
import cookies from 'browser-cookies';
import { newAdminSessionCookieName, redirectUriParamName, v2AuthCookieName } from '../../server/config';
import { parseQueryString } from '../../client/lib/helpers';

const LegacyFrontendAuth = {
  authenticateWithCreds(email: string, password: string) {
    const buildPromise = (path: string) => {
      return new Promise((resolve, reject) => {
        return window
          .fetch(`//${window.location.host}/${path}`, {
            method: 'POST',
            credentials: 'same-origin',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email, password }),
          })
          .then((response) => {
            if (response.status === 200) {
              // In the case where we got a 200, assume everything is great and move on.
              resolve(true);
            } else {
              return response.json().then((data) => {
                // We need to read the body (which is a promise), and then return a value that has both the now-read
                // body as JSON and the original response object for context, so a downstream 'then' can make a smart
                // decision on what to do when the request isn't a 200.
                return new Promise((innerResolve) => {
                  innerResolve({ response, body: data });
                });
              });
            }
          })
          .then((responseData: any) => {
            const response = responseData.response;
            if (response.status === 401) {
              this.authenticationFailed(reject, new Error(responseData.body.error));
            } else {
              this.authenticationFailed(reject, new Error('Unable to authenticate: ' + response.statusText));
            }
          })
          .catch((error) => {
            this.authenticationFailed(reject, new Error(error));
          });
      });
    };

    return Promise.all([buildPromise('api/login'), buildPromise('api/login/v2')]);
  },

  authenticateWithCookie(): Promise<any> {
    const sessionToken = cookies.get(newAdminSessionCookieName);

    const promise = new Promise((resolve, reject) => {
      if (sessionToken) {
        window
          .fetch(`//${window.location.host}/api/login/cookie`, {
            method: 'POST',
            credentials: 'same-origin',
          })
          .then((response) => {
            if (response.status === 200) {
              resolve(response);
            } else {
              this.authenticationFailed(reject, new Error("Server couldn't authenticate"));
            }
          })
          .catch((error) => {
            this.authenticationFailed(reject, new Error(error));
          });
      } else {
        this.authenticationFailed(reject, new Error('Session cookie not present'));
      }
    });

    return promise;
  },

  getPreAuthUrl(): string {
    const windowLocation = this.getWindowLocation();
    const qs = parseQueryString(windowLocation.search.substring(1));
    const uriParam = qs[redirectUriParamName];
    return uriParam || '/';
  },

  // function getters make testing easier
  getWindowLocation(): Location {
    return window.location;
  },

  authenticationFailed(reject, error: Error) {
    if (reject) {
      reject(error);
    }
  },

  /**
   * Need to invalidate session server-side on logout.
   * Regardless if it succeeds or not we clear the tokens in the browser.
   */
  logout() {
    window
      .fetch(`//${window.location.host}/api/logout`, {
        method: 'POST',
        credentials: 'same-origin',
      })
      .then((response) => {
        this.clearAuthenticated();
        window.location.assign('/login');
      })
      .catch((error) => {
        this.clearAuthenticated();
        window.location.assign('/login');
      });
  },

  clearAuthenticated() {
    cookies.erase(newAdminSessionCookieName, { domain: window.location.hostname.replace('admin.', '') });
    cookies.erase(v2AuthCookieName);
    window.localStorage.removeItem('authenticated');
  },

  setAuthenticated() {
    window.localStorage.setItem('authenticated', 'true');
  },

  isAuthenticated(): boolean {
    return window.localStorage.getItem('authenticated') === 'true';
  },
};

export default LegacyFrontendAuth;
