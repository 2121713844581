import * as React from 'react';
import Avatar from '@material-ui/core/Avatar';
import ActionInfo from '@material-ui/icons/Info';
import Chip from '@material-ui/core/Chip';
import red from '@material-ui/core/colors/red';

const styles = {
  avatar: { display: 'block' },
  backgroundColor: red['500'],
  infoIcon: {
    width: 38,
    height: 38,
    margin: '0 0 0',
  },
};

interface AlertMessageProps {
  children: JSX.Element | string;
}

const AlertMessage = ({ children }: AlertMessageProps) => {
  return (
    <Chip
      color="default"
      label={children}
      avatar={
        <Avatar
          style={{
            width: 32,
            height: 32,
            margin: '0 0 0',
          }}
          children={<ActionInfo style={styles.infoIcon} />}
        />
      }
    />
  );
};

export default AlertMessage;
