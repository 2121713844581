import * as React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { SlaMetric, SlaMetricSubtype } from '../../../CommonInterfaces';

const styles = {
  metricName: {
    paddingRight: '10px',
    paddingLeft: '20px',
    textAlign: 'left' as const,
  },
  metricValue: {
    paddingRight: '10px',
    paddingLeft: '20px',
    textAlign: 'center' as const,
  },
  header: {
    paddingRight: '10px',
    paddingLeft: '20px',
    textAlign: 'left' as const,
    fontWeight: '600',
  },
};

const SLA_DROPDOWN_OPTIONS = ['N/A', '24', '48', '72'];

const slaMetricsWithDropdown = [
  SlaMetric.ibContainerUnloadOnTime,
  SlaMetric.ibPutawayOnTime,
  SlaMetric.ibDockToStock,
  SlaMetric.obReadyToLoad,
  SlaMetric.obParcelShipOnTime,
  SlaMetric.obFreightShipOnTime,
];

const slaDescriptionForMetric = {
  [SlaMetric.ibContainerUnloadOnTime]:
    'Measures whether inventory was unloaded from the truck into inbound staging within {Reservation SLA} of truck arrival time',
  [SlaMetric.ibDockToStock]:
    'Measures the delta between container unload and last scan to putaway, clock starts at first receive scan, ends at completion of last putaway',
  [SlaMetric.ibPutawayOnTime]:
    'Measures whether inventory was put away in an allocatable location, or QA, within {Reservation SLA} since the first receive into inbound staging',
  [SlaMetric.obReadyToLoad]:
    'Percent of Distribution shipments scheduled for a day that are in the outbound staging location before or on the pick up window end date. Percent of eCommerce shipments scheduled for a day that are staged (scanned at pack station, therefore ready to load) before the ship by time',
  [SlaMetric.obParcelShipOnTime]:
    'Percent of Parcel shipments scheduled for a day that have departed the warehouse on an outbound truck or trailer before the ship by time',
  [SlaMetric.obFreightShipOnTime]:
    'Percent of Freight shipments scheduled for a day that are marked "completed" (loaded onto the truck/trailer) in the system before or on the pick up window end date',
  [SlaMetric.locationAccuracy]:
    'Percent of "accurate" cycle counts, where accurate is defined as actual = expected. This calculation is not weighted by the size of the error of the cycle count',
  [SlaMetric.unitAccuracy]:
    'Measures accuracy of cycle count results (actual unit quantity) compared with expected unit quantity. Calculation: lesser of expected and actual / greater of expected and actual',
  [SlaMetric.shrinkage]:
    'Net adjustments as a percentage of throughput. Calculation: Net Adjustments/((inbounds + outbounds) / 2)',
  [SlaMetric.fillRate]:
    'Percent of all shipments that were fulfilled because WH had enough allocatable inventory on hand. Calculation: WH cancelled shipments/(WH cancelled shipments+completed shipments)',
};

function renderSlaMetricsRow(setMetrics, metric: SlaMetric, data: any) {
  const nameCell = (
    <TableCell style={styles.metricName}>
      <p>{metric}</p>
    </TableCell>
  );

  let slaStandardInput = null;
  if (slaMetricsWithDropdown.includes(metric)) {
    slaStandardInput = (
      // parsing the value as an int so we get a whole number that matches the options.
      <Select
        value={parseInt(data[SlaMetricSubtype.slaStandard], 10) || 'N/A'}
        onChange={(e) => setMetrics(metric, SlaMetricSubtype.slaStandard, e.target.value)}
      >
        {SLA_DROPDOWN_OPTIONS.map((opt) =>
          opt === 'N/A' ? (
            <MenuItem key={opt} value={opt}>
              {opt}
            </MenuItem>
          ) : (
            <MenuItem key={opt} value={opt}>
              {opt}hr
            </MenuItem>
          ),
        )}
      </Select>
    );
  } else {
    slaStandardInput = (
      <TextField
        defaultValue={data[SlaMetricSubtype.slaStandard] || ''}
        onChange={(e) => setMetrics(metric, SlaMetricSubtype.slaStandard, e.target.value)}
        type="number"
        inputProps={{ min: 0, max: 100, step: 0.1 }}
      />
    );
  }

  const slaStandardCell = <TableCell style={styles.metricValue}>{slaStandardInput}</TableCell>;

  const descriptionCell = <TableCell>{slaDescriptionForMetric[metric]}</TableCell>;

  const targetPercentageCell = (
    <TableCell>
      <TextField
        defaultValue={data[SlaMetricSubtype.targetPercentage] || ''}
        onChange={(e) => setMetrics(metric, SlaMetricSubtype.targetPercentage, e.target.value)}
        type="number"
        inputProps={{ min: 0, max: 100, step: 0.1 }}
      />
    </TableCell>
  );

  return (
    <TableRow key={metric}>
      {nameCell}
      {descriptionCell}
      {slaStandardCell}
      {targetPercentageCell}
    </TableRow>
  );
}

export default function ReservationSlaMetricsTable(props) {
  return (
    <div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={styles.header}>SLA Metric</TableCell>
            <TableCell style={styles.header}>Description</TableCell>
            <TableCell style={styles.header}>Reservation SLA</TableCell>
            <TableCell style={styles.header}>Target Percentage</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(props.rows).map((pair) => {
            return renderSlaMetricsRow(props.stateSetter, pair[0] as SlaMetric, pair[1]);
          })}
        </TableBody>
      </Table>
    </div>
  );
}
