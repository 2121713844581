import * as React from 'react';

interface PaginationProps {
  page: number;
  pageSize: number;
  paginationHandler: any;
  totalCount: number;
}
class Pagination extends React.Component<PaginationProps> {
  public render() {
    const { page, pageSize, totalCount } = this.props;
    return (
      <div className="pagination-component">
        <div>
          {page * pageSize - (pageSize - 1)}&mdash;
          {Math.min(page * pageSize, totalCount)} of {totalCount}
        </div>
        {/* TODO - style these properly after material UI update */}
        <div>
          {page > 1 && (
            <a data-page={page - 1} onClick={this.paginate} className="prev-page">
              &lt;
            </a>
          )}
          {page < Math.ceil(totalCount / pageSize) && (
            <a data-page={page + 1} onClick={this.paginate} className="next-page">
              &gt;
            </a>
          )}
        </div>
      </div>
    );
  }

  private paginate = (event) => {
    const page = parseInt(event.currentTarget.getAttribute('data-page'), 10);
    this.props.paginationHandler(page);
  };
}

export default Pagination;
