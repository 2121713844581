import { crossOriginRequestJson } from '../lib/flexeApi';
import { PatPricingConfigurationWithBillingLever } from './data/PricingV2Interfaces';

export class PricingV2Service {
  public async retrievePatPricing(scopeId: number): Promise<PatPricingConfigurationWithBillingLever[]> {
    return crossOriginRequestJson('GET', `/api/v2/admin/pricing/pat/${scopeId}`)
      .then((resp: Response) => resp.json())
      .then((json) => json.data.pricing_configurations as PatPricingConfigurationWithBillingLever[])
      .catch((reason) => {
        throw new Error(reason);
      });
  }
}
