import * as React from 'react';
import AlertError from '@material-ui/icons/Error';
import { errorRed } from '../../styles/flexeColors';

interface ErrorIconProps {
  style?: any;
  otherProps?: any;
  onMouseOver?(event);
  onMouseOut?(event);
}

const ErrorIcon = ({ style, ...otherProps }: ErrorIconProps) => {
  return (
    <AlertError
      {...otherProps}
      color="error"
      style={{
        position: 'absolute' as const,
        right: 6,
        top: 6,
        ...(style || {}),
      }}
    />
  );
};

export default ErrorIcon;
