import * as React from 'react';
import { useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import LegacyFrontendAuth from '../../lib/authentication';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ErrorMessage from '../../components/ErrorMessage/';
import isEmail from 'validator/lib/isEmail';
import { useAuthContext } from '../../lib/sso';
import { Redirect, Route, useHistory, useLocation } from 'react-router-dom';
import Configuration from '../../lib/config';

const cardStyles = {
  width: 446,
  padding: '24px 36px',
  position: 'absolute' as const,
  left: '50%',
  top: '33%',
  transform: 'translate(-50%, -50%)',
};

interface LoginPageState {
  email: string;
  password: string;
  errorMessages: {
    [key: string]: string;
  };
}

export class LegacyLoginPage extends React.Component<{}, LoginPageState> {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      errorMessages: {
        loginErrorMessage: '',
        emailErrorMessage: '',
        passwordErrorMessage: '',
      },
    };
  }

  public render() {
    return (
      <Card style={cardStyles}>
        <CardHeader title="Log In" />
        <CardContent>
          {this.renderErrorMessage(this.state.errorMessages.loginErrorMessage)}
          <form onSubmit={this.requestLogin}>
            <TextField
              required
              helperText={this.state.errorMessages.emailErrorMessage}
              label="Email"
              fullWidth
              type="email"
              value={this.state.email}
              onChange={this.handleEmailChange}
              onBlur={this.validateEmail}
              onFocus={this.clearErrorMessage.bind(this, 'emailErrorMessage')}
            />
            <br />
            <TextField
              required
              helperText={this.state.errorMessages.passwordErrorMessage}
              label="Password"
              fullWidth
              type="password"
              value={this.state.password}
              onChange={this.handlePasswordChange}
              onBlur={this.validatePassword}
              onFocus={this.clearErrorMessage.bind(this, 'passwordErrorMessage')}
            />
            <Button className="form-button" type="submit" color="primary">
              Submit
            </Button>
          </form>
        </CardContent>
      </Card>
    );
  }

  private handleEmailChange = (event) => {
    const target = event.target as HTMLInputElement;
    this.setState({ email: target.value });
  };

  private handlePasswordChange = (event) => {
    const target = event.target as HTMLInputElement;
    this.setState({ password: target.value });
  };

  private hideLoginError = () => {
    this.setState({
      errorMessages: {
        ...this.state.errorMessages,
        loginErrorMessage: '',
      },
    });
  };

  private validateEmail = () => {
    if (!isEmail(this.state.email)) {
      this.setState({
        errorMessages: {
          ...this.state.errorMessages,
          emailErrorMessage: 'Please enter a valid email address',
        },
      });
    }
  };

  private validatePassword = () => {
    if (!this.state.password.length) {
      this.setState({
        errorMessages: {
          ...this.state.errorMessages,
          passwordErrorMessage: 'Please enter your password',
        },
      });
    }
  };

  private clearErrorMessage = (messageKey: string) => {
    this.setState({
      errorMessages: {
        ...this.state.errorMessages,
        [messageKey]: '',
      },
    });
  };

  private requestLogin = (event) => {
    event.preventDefault();

    LegacyFrontendAuth.authenticateWithCreds(this.state.email, this.state.password)
      .then((authenticated) => {
        if (authenticated.every((a) => a === true)) {
          LegacyFrontendAuth.setAuthenticated();
          window.location.assign(LegacyFrontendAuth.getPreAuthUrl());
        } else {
          LegacyFrontendAuth.clearAuthenticated();
          this.setState({
            errorMessages: {
              ...this.state.errorMessages,
              loginErrorMessage: 'Your email and/or password were incorrect.',
            },
          });
        }
      })
      .catch((error: Error) => {
        LegacyFrontendAuth.clearAuthenticated();
        this.setState({
          password: '',
          errorMessages: {
            ...this.state.errorMessages,
            loginErrorMessage: error.message,
          },
        });
      });
  };

  private renderErrorMessage(loginErrorMessage: string) {
    if (loginErrorMessage) {
      return <ErrorMessage>{loginErrorMessage}</ErrorMessage>;
    }
  }
}

export function LoginPage(props) {
  const [sessionPresent, setSessionPresent] = useState<boolean | null>(false);
  const [ssoEnabled, setSsoEnabled] = useState<boolean | null>(null);

  const auth = useAuthContext();

  const location = useLocation();
  const { from } = location.state || { from: { pathname: '/' } };

  useEffect(() => {
    auth.getSession((session) => {
      if (session) {
        setSessionPresent(true);
      } else {
        setSessionPresent(false);
      }
    });
    Configuration.getInstance()
      .ready()
      .then((config) => {
        if (config.getSetting('ssoEnabled') === 'enabled') {
          setSsoEnabled(true);
        } else {
          setSsoEnabled(false);
        }
      });
  }, []);

  const login = (event) => {
    if (event) {
      event.preventDefault();
    }
    auth.signin();
  };

  if (sessionPresent === null || ssoEnabled === null) {
    // loading...
    return <></>;
  }

  if (!ssoEnabled) {
    return <LegacyLoginPage />;
  }

  if (!sessionPresent) {
    return (
      <Card style={cardStyles}>
        <CardHeader title="Welcome to Flexe Admin" />
        <CardContent>
          <Button className="form-button" onClick={login} color="primary">
            Log in with SSO
          </Button>
        </CardContent>
      </Card>
    );
  } else {
    return (
      <Redirect
        to={{
          state: {
            ...from.state,
            authUser: auth.user,
          },
          ...from,
        }}
      />
    );
  }
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
export function PrivateRoute({ children, ...rest }) {
  const auth = useAuthContext();
  const isLegacyAuthenticated = LegacyFrontendAuth.isAuthenticated();

  const history = useHistory();
  const componentLocation = useLocation();
  const { from } = componentLocation.state || { from: { pathname: '/' } };

  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.user || isLegacyAuthenticated ? (
          children
        ) : (
          // Ideally we'd use a Redirect to /login here, but requires moving to
          // React Router's BrowserRouter first.
          <Redirect to={{ pathname: '/login', state: { from: location } }} />
        )
      }
    />
  );
}
