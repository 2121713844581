import * as React from 'react';
import Form from '../../Form';
import { FormControl } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';

interface BillingSettingsProps {
  paymentTerm: string;
  paymentTermKey: string;
  onFieldChange(event);
}

class BillingSettings extends Form<BillingSettingsProps, any> {
  public render() {
    const { paymentTerm, paymentTermKey, onFieldChange, formErrors, disableForm } = this.props;
    return (
      <fieldset>
        <legend>Billing Settings</legend>

        <FormControl disabled={disableForm}>
          <InputLabel htmlFor="payment-term">Payment Term</InputLabel>
          <Input
            id="payment-term"
            name={paymentTermKey}
            value={paymentTerm}
            onChange={onFieldChange}
            type="number"
            inputProps={{ min: 0, step: 1 }}
          />
          <FormHelperText>
            If payment term field is not empty, it will override the company payment term value
          </FormHelperText>
          {formErrors.payment_term && <FormHelperText error={true}>{formErrors.payment_term}</FormHelperText>}
        </FormControl>
      </fieldset>
    );
  }
}

export default BillingSettings;
