import * as React from 'react';
import Avatar from '@material-ui/core/Avatar';
import DoneIcon from '@material-ui/icons/Done';
import Chip from '@material-ui/core/Chip';
import green from '@material-ui/core/colors/green';

interface SuccessMessageProps {
  children?: JSX.Element | string;
  onDeleteClick?();
}

const SuccessMessage = ({ onDeleteClick, children }: SuccessMessageProps) => {
  return (
    <Chip
      avatar={
        <Avatar style={{ backgroundColor: green['500'] }}>
          <DoneIcon />
        </Avatar>
      }
      color="primary"
      label={children}
      onDelete={onDeleteClick}
    />
  );
};

export default SuccessMessage;
