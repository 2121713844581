/* eslint-disable max-lines -- Disabled pre-existing violation of max lines rule */
import * as React from 'react';
import { cloneDeep, get, isEmpty, omit, set, unset } from 'lodash';
import { parseISO as parseDate } from 'date-fns';
import AlertMessage from '../../components/AlertMessage/';
import ErrorIcon from '../../components/ErrorIcon/';
import ErrorMessage from '../../components/ErrorMessage';
import Form from '../../components/Form';
import InventoryPricingGroup from '../../components/Forms/SubForms/InventoryPricingGroup';
import InventoryGroupsManagement from '../../components/Forms/SubForms/InventoryGroupsManagement';
import PricingOptions from '../../components/Forms/SubForms/PricingOptions';
import PatPricingConfigForm from '../../components/Forms/SubForms/PatPricingConfig';
import WarehouseExpenseConfigForm from '../../components/Forms/SubForms/WarehouseExpenseConfig';
import { Link } from 'react-router-dom';
import OverlayMessage, { OverlayType } from '../../components/OverlayMessage';
import PageLoadSpinner from '../../components/PageLoadSpinner/';
import ReservationSettings from '../../components/Forms/SubForms/ReservationSettings';
import ReservationSetup from '../../components/Forms/SubForms/ReservationSetup';
import ReservationMinimums from '../../components/Forms/SubForms/ReservationMinimums';
import SubmitButton from '../../components/SubmitButton/';
import SuccessMessage from '../../components/SuccessMessage/';

import ActionLockIcon from '@material-ui/icons/Lock';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Avatar from '@material-ui/core/Avatar';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import EditIcon from '@material-ui/icons/Edit';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { ListItemText } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Popover from '@material-ui/core/Popover';
import PowerIcon from '@material-ui/icons/PowerSettingsNew';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import flexeApi from '../../lib/flexeApi';
import InventoryGroupsService from '../../services/InventoryGroupsService';
import PricingService from '../../services/PricingService';
import { flatFeeHasErrors, inventoryGroupHasErrors, warehouseExpenseHasErrors } from '../../lib/helpers';
import { fetchPricingMetaData } from '../../lib/metaData';
import { FLAT_BTN, RAISED_BTN } from '../../lib/constants';
import {
  AllPricing,
  BillingUom,
  FeeCurrency,
  flatFeeScopes,
  FlexeApiResponse,
  InventoryGroup,
  PricingGroup,
  PricingScope,
  PricingScopeData,
  ReservationAndScopeInfo,
  ReservationPricing,
  ReservationTxnState,
  ScopeApprovalPolicy,
  ScopeTxnState,
  SlaMetric,
  SlaMetricSubtype,
  warehouseExpenseScopes,
} from '../../CommonInterfaces';

import tabStyles from '../../styles/flexeTabs';
import './styles.css';
import Grid from '@material-ui/core/Grid/Grid';
import ReservationSlaMetricsTable from '../../components/Forms/SubForms/ReservationSlaMetricsTable';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FlatFeeConfigForm from '../../components/Forms/SubForms/FlatFeeConfig';
import BillingSettings from '../../components/Forms/SubForms/BillingSettings';
import StorageBillingModes from '../../components/Forms/SubForms/StorageBillingModes';
import WarehouseExpenseValidator from '../../components/WarehouseExpense/WarehouseExpenseValidator';
import { fetchPricingTemplate, fetchStorageScope } from '../../lib/pricingTemplates';
import { PricingCatalog } from '../../services/data/PricingCatalogInterfaces';
import { PricingCatalogService } from '../../services/PricingCatalogService';
import { PricingV2Service } from '../../services/PricingV2Service';
import { groupExpenseRowsByCategory, WarehouseExpenseRow } from '../../models/pricing/WarehouseExpenseRow';
import {
  PatPricingConfiguration,
  PatPricingConfigurationWithBillingLever,
} from '../../services/data/PricingV2Interfaces';

const styles = {
  backButton: {
    display: 'inline-block',
    cursor: 'pointer',
  },
  setupTab: {
    ...tabStyles.tab,
    margin: '8px 28px 0 2px',
  },
  setupTabActive: {
    ...tabStyles.activeTab,
    margin: '0 28px 0 2px',
  },
  history: {
    float: 'right' as const,
  },
  documentList: {
    listStyleType: 'none',
  },
  redWarning: {
    color: 'red',
  },
  dataHeader: {
    margin: '5px 0px',
  },
  dataValue: {
    margin: '5px 0px',
  },
  dataBlock: {
    margin: '10px 0px',
  },
};

interface ReservationDetailProps {
  match: any;
  location: any;
  history: any;
  flags: any;
}

interface ReservationDetailState {
  ldClientReady: boolean;
  changeStatusMenuAnchor?: HTMLElement;
  reservationDetails: ReservationAndScopeInfo;
  pricing: ReservationPricing;
  warehouseExpenseConfigEnabled: boolean;
  reservationLoaded: boolean;
  showChangeStatusMenu: boolean;
  showSubmitForApprovalModal: boolean;
  submitingForApproval: boolean;
  scopeApprovalPolicy: string;
  scopeManagerApprovalEmail: string;
  showCancelScopeModal: boolean;
  cancellingScope: boolean;
  showSuccessOverlay: boolean;
  showWarningModal: boolean;
  showNegativeMarginWarning: boolean;
  showDuplicateFeeTypesError: boolean;
  showInvalidExpenseError: boolean;
  invalidExpenseErrors?: string[];
  successOverlayMessage: string;
  currentTab: string;
  reservationErrorMessage?: JSX.Element | string;
  formErrors: any;
  pricingFormErrors: any;
  disablePricingEdits: boolean;
  creatingNewRevision: boolean;
  waitingForBackend: boolean;
  scopeDocsChecked: boolean[];
  showSlaGoalsModal: boolean;
  reservationSlaMetrics: any;
  reservationSlaMetricError?: string;
  pricingTemplate: any;
  pricingMetadata: any;
  pricingCatalog: PricingCatalog;
  patPricing: PatPricingConfigurationWithBillingLever[];
}

class ReservationDetail extends Form<ReservationDetailProps, ReservationDetailState> {
  private inventoryGroupsService: InventoryGroupsService;
  private pricingService: PricingService;
  private pricingV2Service: PricingV2Service;
  private pricingCatalogService: PricingCatalogService;
  private today: Date;
  private storedReservationSlaMetrics: any;
  private storageBillingModeKey = 'reservationDetails.reservation_scope.storage_billing_mode';

  constructor(props) {
    super(props);

    this.inventoryGroupsService = new InventoryGroupsService();
    this.pricingService = new PricingService();
    this.pricingV2Service = new PricingV2Service();
    this.pricingCatalogService = new PricingCatalogService();
    this.today = new Date();
    this.storedReservationSlaMetrics = null;

    this.state = {
      ldClientReady: false,
      changeStatusMenuAnchor: null,
      reservationDetails: {
        id: '',
        depositor: {
          id: '',
          name: '',
        },
        warehouse: {
          id: '',
          city: '',
          company: {
            id: '',
            name: '',
          },
        },
        depositor_reservation_nickname: '',
        warehouse_reservation_nickname: '',
        start_on: new Date(),
        fee_currency: FeeCurrency.USD,
        description: '',
        bill_to: {
          name: '',
          address_1: '',
          address_2: '',
          locality: '',
          region: '',
          postal_code: '',
          country: '',
          phone: '',
        },
        customer_vendor_id: '',
        monthly_minimum: '',
        monthly_warehouse_minimum: '',
        allow_partial_receiving: false,
        allow_same_day_delivery: false,
        client_labeled: false,
        pallet_label_prefix: '',
        default_storage_type: null,
        created_at: '',
        shipper_signed_on: '',
        txn_state: ReservationTxnState.new,
        warehouse_signed_on: '',
        block_activity: true,
        reservation_scope_documents: [],
        reservation_scope: {
          id: 0,
          block_activity: true,
          txn_state: ScopeTxnState.new,
          created_at: '',
          shipper_signed_on: '',
          warehouse_signed_on: '',
          payment_term: null,
          storage_billing_mode: null,
          shipper_minimum: '',
          warehouse_minimum: '',
          approval_type: '',
          approval_info: {},
        },
        pricingOptions: {
          use_inventory_groups: false,
          use_packaging_groups: false,
          flat_fee_pricing_scope: false,
          storage_pricing_scope: true,
          container_pricing_scope: false,
          cpd_fulfillment_pricing_scope: false,
          consumer_fulfillment_pricing_scope: false,
          warehouse_expense_pricing_scope: false,
          retail_fulfillment_pricing_scope: false,
          sort_center_pricing_scope: false,
          returns_pricing_scope: false,
        },
      },
      pricing: {
        defaultInventoryGroup: null,
        inventoryGroups: [],
      },
      warehouseExpenseConfigEnabled: false,
      reservationLoaded: false,
      showChangeStatusMenu: false,
      showSubmitForApprovalModal: false,
      submitingForApproval: false,
      scopeApprovalPolicy: ScopeApprovalPolicy.manager_approval,
      scopeManagerApprovalEmail: '',
      showCancelScopeModal: false,
      cancellingScope: false,
      showSuccessOverlay: false,
      showWarningModal: false,
      successOverlayMessage: '',
      currentTab: 'setup',
      reservationErrorMessage: null,
      formErrors: {},
      pricingFormErrors: null,
      disablePricingEdits: true,
      creatingNewRevision: false,
      waitingForBackend: false,
      scopeDocsChecked: [],
      showNegativeMarginWarning: false,
      showDuplicateFeeTypesError: false,
      showInvalidExpenseError: false,
      invalidExpenseErrors: [],
      showSlaGoalsModal: false,
      reservationSlaMetrics: null,
      pricingTemplate: fetchPricingTemplate(''),
      pricingMetadata: fetchPricingMetaData('', {}),
      pricingCatalog: {},
      patPricing: [],
    };
  }

  public async componentDidMount() {
    // If the reservation is newly created (it has the URL hash '#new'), show the successfull
    // creation overlay and auto-hide it 3 seconds later
    if (this.props.location.hash === '#new') {
      this.showSuccessOverlay('Reservation Successfully Created!');
    }

    let pricingCatalog = {};
    pricingCatalog = await this.pricingCatalogService.retrievePricingCatalog();

    this.setState({ pricingCatalog }, () => {
      this.getAllReservationData();
    });
  }

  // eslint-disable-next-line complexity -- Disabled pre-existing violation of complexity rule
  public render() {
    const txnState: ReservationTxnState = get(this.state, 'reservationDetails.txn_state');
    const scopeTxnState: ScopeTxnState = get(this.state, 'reservationDetails.reservation_scope.txn_state');

    return (
      <div className="page reservation-detail-page">
        {this.renderBreadCrumbs()}

        {this.renderErrorMessage(this.state.reservationErrorMessage)}

        {this.renderReservationDetails(this.state.reservationDetails)}

        {this.renderTabs(
          this.state.reservationDetails,
          this.state.pricing.defaultInventoryGroup,
          this.state.pricing.inventoryGroups,
        )}

        {this.renderMainButtons(txnState)}

        <Dialog open={this.state.showWarningModal}>
          <DialogTitle>Create a new scope?</DialogTitle>
          <DialogContent>
            <p>This will cancel the current scope. Do you wish to continue?</p>
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={this.hideWarningModal}>
              Cancel
            </Button>
            <Button variant="text" color="primary" onClick={this.continueEditingScope}>
              Continue
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={this.state.showNegativeMarginWarning}>
          <DialogTitle>Submit with Negative Margin?</DialogTitle>
          <DialogContent>
            <div>
              <p>Flat fee shipper rate is less than warehouse rate, it may cause negative margin.</p>
              <p>Do you wish to continue?</p>
            </div>
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={this.hideNegativeMarginWarning}>
              Cancel
            </Button>
            <Button variant="text" color="primary" onClick={this.submitWithNegativeMargin}>
              Submit
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={this.state.showDuplicateFeeTypesError}>
          <DialogTitle>Duplicate Fee Type and Description</DialogTitle>
          <DialogContent>
            <div>
              <p>Combination of fee type and description must be unique.</p>
            </div>
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={this.hideDuplicateFeeTypesError}>
              Ok
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={this.state.showInvalidExpenseError}>
          <DialogTitle>Invalid Expense Configured</DialogTitle>
          <DialogContent>
            <div>
              <p>Please review the expense configuration.</p>
              <ul>
                {this.state.invalidExpenseErrors.map((error) => {
                  return <li>{error}</li>;
                })}
              </ul>
            </div>
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={this.hideInvalidExpenseError}>
              Ok
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={this.state.showSubmitForApprovalModal}>
          <DialogTitle>Submit Scope for Approval?</DialogTitle>
          <DialogContent>
            {this.state.reservationDetails.reservation_scope_documents &&
              this.state.reservationDetails.reservation_scope_documents.length !== 0 &&
              this.renderScopeDocsFinalCheck(this.state.reservationDetails.reservation_scope_documents)}
            {this.renderApprovalMethodOption()}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.hideSubmitForApprovalModal} disabled={this.state.submitingForApproval}>
              Cancel
            </Button>
            <SubmitButton
              type={FLAT_BTN}
              label="Submit"
              onClick={this.submitForApproval}
              showSpinner={this.state.submitingForApproval}
              disabled={
                this.state.submitingForApproval ||
                (this.state.scopeDocsChecked &&
                  this.state.scopeDocsChecked.length !== 0 &&
                  !this.state.scopeDocsChecked.every((v) => v === true))
              }
            />
          </DialogActions>
        </Dialog>

        <Dialog open={this.state.showCancelScopeModal}>
          <DialogTitle>Cancel Scope?</DialogTitle>
          <DialogContent>
            {scopeTxnState === 'new' && <p>This will cancel the reservation scope. Do you wish to proceed?</p>}
            {scopeTxnState === 'awaiting_approval' && (
              <p>
                The shipper and warehouse may have seen and / or signed this scope. Are you sure you want to cancel this
                scope?
              </p>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.hideCancelModal} disabled={this.state.cancellingScope}>
              No
            </Button>
            <SubmitButton
              type={FLAT_BTN}
              label="Yes"
              onClick={this.cancelScope}
              showSpinner={this.state.cancellingScope}
              disabled={this.state.cancellingScope}
            />
          </DialogActions>
        </Dialog>

        <Dialog open={this.state.showSlaGoalsModal} maxWidth={false}>
          <DialogTitle>SLA Metrics</DialogTitle>
          <DialogContent>
            {this.state.reservationSlaMetricError !== null && (
              <div>
                <br />
                <br />
                <p className="negative">{this.state.reservationSlaMetricError}</p>
              </div>
            )}
            <ReservationSlaMetricsTable
              rows={this.state.reservationSlaMetrics}
              stateSetter={(metric, subtype, value) => {
                if (this.state.reservationSlaMetrics[metric][subtype] !== value) {
                  const newState = { ...this.state.reservationSlaMetrics };
                  newState[metric][subtype] = value;
                  this.setState({ reservationSlaMetrics: newState });
                }
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="text"
              onClick={() => {
                this.setState({ reservationSlaMetrics: cloneDeep(this.storedReservationSlaMetrics) });
                this.hideSlaGoalsModal();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="text"
              onClick={() => {
                this.upsertReservationSlaMetrics();
              }}
            >
              Update
            </Button>
          </DialogActions>
        </Dialog>

        <OverlayMessage
          type={OverlayType.success}
          show={this.state.showSuccessOverlay}
          onHide={this.hideSuccessOverlay}
        >
          {this.state.successOverlayMessage}
        </OverlayMessage>
      </div>
    );
  }

  private showSlaGoalsModal = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    this.storedReservationSlaMetrics = cloneDeep(this.state.reservationSlaMetrics);
    this.setState({ showSlaGoalsModal: true });
  };

  private hideSlaGoalsModal = () => {
    this.setState({
      showSlaGoalsModal: false,
      reservationSlaMetricError: null,
    });
  };

  private getAllReservationData() {
    return this.getReservationAndPricing()
      .then((companyId) => {
        if (companyId) {
          return flexeApi.getInventoryGroups(companyId).then(({ data, statusCode, error }: FlexeApiResponse) => {
            if (statusCode === 200) {
              if (Array.isArray(data) && data.length) {
                const state = this.inventoryGroupsService.proccessInventoryGroups(
                  this.state,
                  data,
                  this.state.pricingTemplate,
                );
                this.setState(state);
              }
            } else if (error && typeof error !== 'string') {
              this.setState({ reservationErrorMessage: <p>{error.msg}</p> });
            }
          });
        }
      })
      .catch((error) => {
        this.setState({ reservationErrorMessage: <p>{error.message}</p> });
      });
  }
  private getReservationAndPricing() {
    const reservationId = this.props.match.params.id;
    return (
      flexeApi
        .getReservationAndPricing(reservationId)
        // eslint-disable-next-line complexity -- Disabled pre-existing violation of complexity rule
        .then(({ reservation, pricing }) => {
          if (!reservation && !pricing) {
            this.setState({ reservationLoaded: true });
            return;
          }
          const reservationDetails = reservation || {};
          const reservationScope = reservationDetails.reservation_scope;

          this.setState({
            pricingTemplate: fetchPricingTemplate(reservationScope.storage_billing_mode),
            pricingMetadata: fetchPricingMetaData(reservationScope.storage_billing_mode, this.state.pricingCatalog),
          });

          // If scope is missing pricings create new pricing with empty storage
          if (pricing.length === 0) {
            pricing.push({
              storage_pricing: cloneDeep(fetchStorageScope(reservationScope.storage_billing_mode)),
            });
          }
          this.pricingV2Service
            .retrievePatPricing(reservationScope.id)
            .then((patPricing: PatPricingConfigurationWithBillingLever[]) => {
              this.setState({ patPricing });
            });
          flexeApi
            .getScope(reservationScope.id)
            .then((response) => {
              if (response.status === 200) {
                return response.json();
              } else {
                const msg = {
                  responseUrl: response.url,
                  responseCode: response.status,
                  responseErr: response.text(),
                };
                return { error: response.status };
              }
            })
            .then((response: any) => {
              if (response !== undefined && !response.error) {
                const documents = response.data.documents.map((d) => {
                  let shipper = null;
                  let warehouse = null;
                  if (d.visibleTo === 'NONE') {
                    shipper = false;
                    warehouse = false;
                  } else if (d.visibleTo === 'SHIPPER') {
                    shipper = true;
                    warehouse = false;
                  } else if (d.visibleTo === 'WAREHOUSE') {
                    shipper = false;
                    warehouse = true;
                  } else if (d.visibleTo === 'ALL') {
                    shipper = true;
                    warehouse = true;
                  } else {
                    throw new Error('Invalid response from server ' + d.visibleTo);
                  }
                  return {
                    fileKey: d.fileKey,
                    fileName: d.fileName,
                    kind: d.kind,
                    visibleToShipper: shipper,
                    visibleToWarehouse: warehouse,
                  };
                });
                reservationDetails.reservation_scope_documents = documents;
                this.setState({
                  reservationDetails: {
                    ...this.state.reservationDetails,
                    ...reservationDetails,
                  },
                });
              }
            });

          flexeApi
            .getReservationSlaMetrics(reservationId)
            .then((response: any) => {
              if (response.status === 200) {
                return response.json();
              } else {
                return { error: response.status };
              }
            })
            .then((response) => {
              if (response !== undefined && !response.error) {
                // Data arrives in a flat list with possibly multiple metrics with the same name but different subtypes
                // and values. Reorganize into a hierarchy so we can assign multiple subtypes to the same metric name.
                const organizedResponse = {};
                response.data.metrics.forEach((data) => {
                  // Make sure we use the enum values, not strings.
                  const slaMetric = data.metric as SlaMetric;
                  const slaSubtype = data.metric_subtype as SlaMetricSubtype;
                  organizedResponse[slaMetric] ||= {};
                  organizedResponse[slaMetric][slaSubtype] = data.value;
                });

                // Data may not be present for all possible metrics. Fill in the gaps with empty values so the user can
                // create what's missing if they wish.
                const metrics = {};
                Object.values(SlaMetric).forEach((metric) => {
                  metrics[metric] = organizedResponse[metric] || {};
                  Object.values(SlaMetricSubtype).forEach((subtype) => {
                    metrics[metric][subtype] ||= null; // this ensures the key is present
                  });
                });
                this.setState({ reservationSlaMetrics: metrics });
              }
            });

          // Set any null values to an empty string so React doesn't complain
          if (!reservationDetails.depositor_reservation_nickname) {
            reservationDetails.depositor_reservation_nickname = '';
          }

          if (!reservationDetails.warehouse_reservation_nickname) {
            reservationDetails.warehouse_reservation_nickname = '';
          }

          if (!reservationDetails.customer_vendor_id) {
            reservationDetails.customer_vendor_id = '';
          }

          if (!reservationDetails.pallet_label_prefix) {
            reservationDetails.pallet_label_prefix = '';
          }

          /* Convert pricing group keys to have '_scope' at the end so we can use the same
           * FulfillmentPricingGroup component. */
          const { pricingGroups, pricingOptions } = this.processPricingGroups(pricing);

          reservationDetails.pricingOptions = pricingOptions || {};

          // Group pricing by inventory group and then packaging group
          const {
            inventoryGroups: inventoryGroupsArr,
            defaultInventoryGroup,
            usePackagingGroups,
            flatFeeGroup,
            warehouseExpenseGroup,
          } = this.groupByInventoryGroups(pricingGroups);

          reservationDetails.pricingOptions.warehouse_expense_pricing_scope = !isEmpty(warehouseExpenseGroup.pricing);
          reservationDetails.pricingOptions.flat_fee_pricing_scope = !isEmpty(flatFeeGroup.pricing);
          reservationDetails.pricingOptions.use_packaging_groups = usePackagingGroups;

          // Convert inventoryGroups from an Object to an Array
          const inventoryGroupsObj = Object.keys(inventoryGroupsArr).map((invId: any) => inventoryGroupsArr[invId]);

          if (inventoryGroupsObj.length) {
            reservationDetails.pricingOptions.use_inventory_groups = true;
          }

          this.setState({
            reservationLoaded: true,
            reservationDetails: {
              ...this.state.reservationDetails,
              ...reservationDetails,
            },
            pricing: {
              ...this.state.pricing,
              defaultInventoryGroup,
              warehouseExpenseGroup,
              flatFeeGroup,
              inventoryGroups: inventoryGroupsObj,
            },
          });

          return reservationDetails.depositor.id;
        })
    );
  }

  private processPricingGroups = (
    pricing: AllPricing[],
  ): {
    pricingGroups?: any[];
    pricingOptions?: any;
  } => {
    if (pricing && pricing.length) {
      const pricingOptions = {};
      const pricingGroups = pricing.map((pricingGroup: AllPricing) => {
        const renamedPricingGroup: PricingGroup = {};

        for (const key in pricingGroup) {
          if (pricingGroup.hasOwnProperty(key)) {
            let newKey = key;
            if (
              [
                'storage_pricing',
                'consumer_fulfillment_pricing',
                'container_pricing',
                'cpd_fulfillment_pricing',
                'retail_fulfillment_pricing',
                'returns_pricing',
                'handling_expense_pricing',
                'labor_expense_pricing',
                'storage_expense_pricing',
                'supplies_expense_pricing',
                'transportation_expense_pricing',
                'other_expense_pricing',
                'flat_fee_storage_pricing',
                'flat_fee_inbound_pricing',
                'flat_fee_outbound_pricing',
                'flat_fee_other_pricing',
                'sort_center_pricing',
              ].includes(key)
            ) {
              newKey += '_scope';
              if (pricingGroup[key]) {
                pricingOptions[newKey] = true;
              }
            }

            renamedPricingGroup[newKey] = pricingGroup[key];
          }
        }

        return renamedPricingGroup;
      });

      return { pricingGroups, pricingOptions };
    }
    return {};
  };

  private groupByInventoryGroups = (pricingGroups: any[]) => {
    const inventoryGroups = { default: null };
    const warehouseExpenseGroup = { pricing: {} };
    const flatFeeGroup = { pricing: {} };
    let usePackagingGroups = true;
    if (pricingGroups) {
      pricingGroups.forEach((pGroup) => {
        const inventoryId = get(pGroup, 'inventory_group.id') || 'default';
        const packagingId = pGroup.packaging || 'default';
        const inventoryDesc = get(pGroup, 'inventory_group.name') || 'Default';

        // If the packaging is not set, then we are not using packaging groups
        if (packagingId === 'default') {
          usePackagingGroups = false;
        }
        // Add it to the inventoryGroups object
        inventoryGroups[inventoryId] = inventoryGroups[inventoryId] || {
          id: parseInt(inventoryId, 10),
          description: inventoryDesc,
          tempDescription: inventoryDesc,
          saved: true,
          enabled: true,
          isDefault: inventoryId === 'default',
          pricing: cloneDeep(this.state.pricingTemplate),
        };

        // eslint-disable-next-line complexity -- Disabled pre-existing violation of complexity rule
        Object.keys(pGroup).forEach((priceScopeKey) => {
          if (
            pGroup[priceScopeKey] &&
            [
              'storage_pricing_scope',
              'consumer_fulfillment_pricing_scope',
              'container_pricing_scope',
              'cpd_fulfillment_pricing_scope',
              'retail_fulfillment_pricing_scope',
              'sort_center_pricing_scope',
              'returns_pricing_scope',
            ].includes(priceScopeKey)
          ) {
            inventoryGroups[inventoryId].pricing[packagingId][priceScopeKey] = pGroup[priceScopeKey];
          }
          if (pGroup[priceScopeKey] && warehouseExpenseScopes.includes(priceScopeKey)) {
            warehouseExpenseGroup.pricing[packagingId] = warehouseExpenseGroup.pricing[packagingId] || {};
            warehouseExpenseGroup.pricing[packagingId][priceScopeKey] =
              warehouseExpenseGroup.pricing[packagingId][priceScopeKey] || {};
            warehouseExpenseGroup.pricing[packagingId][priceScopeKey] = pGroup[priceScopeKey];
          }
          if (pGroup[priceScopeKey] && flatFeeScopes.includes(priceScopeKey)) {
            flatFeeGroup.pricing[packagingId] = flatFeeGroup.pricing[packagingId] || {};
            flatFeeGroup.pricing[packagingId][priceScopeKey] = flatFeeGroup.pricing[packagingId][priceScopeKey] || {};
            flatFeeGroup.pricing[packagingId][priceScopeKey] = pGroup[priceScopeKey];
          }
          if (pGroup.packaging === 'pallet' || pGroup.packaging === null) {
            if (pGroup.retail_fulfillment_pricing_scope) {
              pGroup.retail_fulfillment_pricing_scope.warehouse_outbound_lpn_complete_fee =
                pGroup.retail_fulfillment_pricing_scope.warehouse_outbound_lpn_complete_fee || '0.00';
              pGroup.retail_fulfillment_pricing_scope.depositor_outbound_lpn_complete_fee =
                pGroup.retail_fulfillment_pricing_scope.depositor_outbound_lpn_complete_fee || '0.00';
            }
            if (pGroup.container_pricing_scope) {
              pGroup.container_pricing_scope.warehouse_inbound_lpn_complete_fee =
                pGroup.container_pricing_scope.warehouse_inbound_lpn_complete_fee || '0.00';
              pGroup.container_pricing_scope.depositor_inbound_lpn_complete_fee =
                pGroup.container_pricing_scope.depositor_inbound_lpn_complete_fee || '0.00';
            }
          }
        });
      });
    } else {
      usePackagingGroups = false;
    }
    const defaultInventoryGroup = inventoryGroups.default || {
      id: 0,
      isDefault: true,
      saved: true,
      description: 'Default',
      tempDescription: 'Default',
      pricing: cloneDeep(this.state.pricingTemplate),
    };
    delete inventoryGroups.default;
    return { inventoryGroups, defaultInventoryGroup, usePackagingGroups, flatFeeGroup, warehouseExpenseGroup };
  };

  private showSuccessOverlay = (message: string) => {
    this.setState({
      showSuccessOverlay: true,
      successOverlayMessage: message,
    });
  };

  private hideSuccessOverlay = () => {
    this.setState({
      showSuccessOverlay: false,
      successOverlayMessage: '',
    });
    if (window.history.pushState) {
      window.history.pushState('', '/', window.location.pathname);
    } else {
      window.location.hash = '';
    }
  };

  private handleBackButtonClick = () => {
    this.props.history.push('/reservations');
  };

  private changeTabs = (event, value) => {
    this.setState({ currentTab: value });
  };

  private handleToggleChange = ({ target }: { target: HTMLInputElement }, value: any) => {
    this.onFormUpdate(target.name, value);
  };

  private onConfigUpdate = (
    oldKey: string,
    newKey: string,
    newEvent: string,
    value1: any,
    value2: any,
    value3: any,
    rownum: string,
  ) => {
    this.setState((prevState) => {
      const state = cloneDeep(prevState);
      if (oldKey !== null) {
        unset(state, `${oldKey}.${rownum}`);
        const oldCategory = get(state, oldKey);
        const nulls = Object.values(oldCategory).filter((p) => !isEmpty(p));
        if (nulls.length === 0) {
          unset(state, oldKey);
        }
      }
      if (newKey !== null) {
        set(state, `${newKey}.${rownum}.depositor_${newEvent}_fee`, value1);
        set(state, `${newKey}.${rownum}.warehouse_${newEvent}_fee`, value2);
        set(state, `${newKey}.${rownum}.${newEvent}_description`, value3);
        set(state, `${newKey}.${rownum}.rownum`, rownum);
      }

      return state;
    });
  };

  private onAddPatConfig = (newConfig: PatPricingConfiguration) => {
    const newConfigWithLever: PatPricingConfigurationWithBillingLever = {
      billingLever: 'New Lever',
      configuration: newConfig,
    };

    this.setState((prevState) => {
      // add new config first so it will be at top of its category and easier to find
      return { patPricing: [newConfigWithLever, ...prevState.patPricing] };
    });
  };

  private onRemovePatConfig = (removeConfig: PatPricingConfiguration) => {
    this.setState((prevState) => {
      const updatedPatPricing = prevState.patPricing.filter((config) => config.configuration.uid !== removeConfig.uid);

      return { patPricing: updatedPatPricing };
    });
  };

  private onEditPatConfig = (editConfig: PatPricingConfigurationWithBillingLever) => {
    this.setState((prevState) => {
      // Update the specifc index so things don't get shoved around during updates
      const editConfigIndex = prevState.patPricing.findIndex(
        (config) => config.configuration.uid === editConfig.configuration.uid,
      );

      const updatedPatPricing = [...prevState.patPricing];
      updatedPatPricing[editConfigIndex] = editConfig;

      return { patPricing: updatedPatPricing };
    });
  };

  private onChangeExpenseEventName = (originalExpenseRow: WarehouseExpenseRow, newEventName: string) => {
    const newUnit = undefined; // reset unit when event name changes as the valid options will be different
    this.onChangeExpenseUnitOrEventName(originalExpenseRow, newUnit, newEventName);
  };

  private onChangeExpenseUnit = (originalExpenseRow: WarehouseExpenseRow, newUnit: string) => {
    const newEventName = originalExpenseRow.eventName; // Not actually changing here; re-use existing one
    this.onChangeExpenseUnitOrEventName(originalExpenseRow, newUnit, newEventName);
  };

  private onChangeExpenseUnitOrEventName = (
    originalExpenseRow: WarehouseExpenseRow,
    newUnit: string,
    newEventName: string,
  ) => {
    const { uom, category, rownum, eventName } = originalExpenseRow;
    const oldKeyPrefix = `pricing.warehouseExpenseGroup.pricing.${uom}.${category}`;
    const newKeyPrefix = `pricing.warehouseExpenseGroup.pricing.${newUnit}.${category}`;

    const oldDepositorFeeKey = `${oldKeyPrefix}.${rownum}.depositor_${eventName}_fee`;
    const oldWarehouseFeeKey = `${oldKeyPrefix}.${rownum}.warehouse_${eventName}_fee`;
    const oldDescriptionKey = `${oldKeyPrefix}.${rownum}.${eventName}_description`;
    const oldIdKey = `${oldKeyPrefix}.${rownum}.${eventName}_id`;

    this.setState((prevState) => {
      const state = cloneDeep(prevState);

      // rownum is only unique per UOM; we need to re-calculate rownum here to avoid clobbering an existing row in new UOM
      const currentExpenseRows = this.expenseRowsFromPricing(state.pricing.warehouseExpenseGroup.pricing);
      const newRownum = this.getNextRownumForExpenseCategory(category, currentExpenseRows);

      const newDepositorFeeKey = `${newKeyPrefix}.${newRownum}.depositor_${newEventName}_fee`;
      const newWarehouseFeeKey = `${newKeyPrefix}.${newRownum}.warehouse_${newEventName}_fee`;
      const newDescriptionKey = `${newKeyPrefix}.${newRownum}.${newEventName}_description`;
      const newIdKey = `${newKeyPrefix}.${newRownum}.${newEventName}_id`;
      const newRowNumKey = `${newKeyPrefix}.${newRownum}.rownum`;

      // Copy values at old keys
      const oldDepositorFee = get(state, oldDepositorFeeKey);
      const oldWarehouseFee = get(state, oldWarehouseFeeKey);
      const oldDescription = get(state, oldDescriptionKey);
      const oldId = get(state, oldIdKey);

      // Remove old keys (and clear the whole unit+category if this was the last thing in it)
      unset(state, `${oldKeyPrefix}.${rownum}`);
      const oldCategory = get(state, oldKeyPrefix);
      const nonEmptyRowsInCategory = Object.values(oldCategory).filter((p) => !isEmpty(p));
      if (nonEmptyRowsInCategory.length === 0) {
        unset(state, oldKeyPrefix);
      }

      // Save copy at new keys, with re-calculated rowNum
      set(state, newDepositorFeeKey, oldDepositorFee);
      set(state, newWarehouseFeeKey, oldWarehouseFee);
      set(state, newDescriptionKey, oldDescription);
      set(state, newRowNumKey, `${newRownum}`);
      set(state, newIdKey, oldId);

      return state;
    });
  };

  private getNextRownumForExpenseCategory = (category: string, currentExpenseRows: WarehouseExpenseRow[]) => {
    const currentExpenseRowsByCategory = groupExpenseRowsByCategory(currentExpenseRows);
    const currentExpenseRowsForCategory = currentExpenseRowsByCategory[category] || [];

    if (currentExpenseRowsForCategory.length === 0) {
      return 0;
    }

    const existingRownumsForCategory = currentExpenseRowsForCategory.map((row) => row.rownum);
    const maxRownum = existingRownumsForCategory.reduce((a, b) => Math.max(a, b), -Infinity);

    return maxRownum + 1;
  };

  private onAddExpenseRow = (category: string) => {
    this.setState((prevState) => {
      const state = cloneDeep(prevState);
      const key = `pricing.warehouseExpenseGroup.pricing.undefined.${category}`;

      const currentExpenseRows = this.expenseRowsFromPricing(prevState.pricing.warehouseExpenseGroup.pricing);
      const rownum = this.getNextRownumForExpenseCategory(category, currentExpenseRows);

      // Is this weird?  Yes, but it was also the status quo behavior for onConfigUpdate
      set(state, `${key}.${rownum}.depositor_${undefined}_fee`, '');
      set(state, `${key}.${rownum}.warehouse_${undefined}_fee`, '');
      set(state, `${key}.${rownum}.${undefined}_description`, '');
      set(state, `${key}.${rownum}.${undefined}_id`, `new-${rownum}`);
      set(state, `${key}.${rownum}.rownum`, `${rownum}`);

      return state;
    });
  };

  private onRemoveExpenseRow = (category: string, uom: string, rownum: string) => {
    this.onRemoveConfigRow('warehouseExpenseGroup', category, uom, rownum);
  };

  private onRemoveConfigRow = (groupName: string, category: string, uom: string, rownum: string) => {
    this.setState((prevState) => {
      const state = cloneDeep(prevState);
      const key = `pricing.${groupName}.pricing.${uom}.${category}`;

      unset(state, `${key}.${rownum}`);
      const oldCategory = get(state, key);
      const nulls = Object.values(oldCategory).filter((p) => !isEmpty(p));
      if (nulls.length === 0) {
        unset(state, key);
      }

      return state;
    });
  };

  private onExpenseFormShipperRateChange = (expenseRow: WarehouseExpenseRow, newRate: any) => {
    this.onFormShipperRateChange('warehouseExpenseGroup', expenseRow, newRate);
  };

  private onExpenseFormWarehouseRateChange = (expenseRow: WarehouseExpenseRow, newRate: any) => {
    this.onFormWarehouseRateChange('warehouseExpenseGroup', expenseRow, newRate);
  };

  private onExpenseFormDescriptionChange = (expenseRow: WarehouseExpenseRow, newDescription: any) => {
    this.onFormDescriptionRateChange('warehouseExpenseGroup', expenseRow, newDescription);
  };

  private onFormShipperRateChange = (groupName: string, expenseRow: WarehouseExpenseRow, newRate: any) => {
    const { category, eventName, uom, rownum } = expenseRow;
    const key = `pricing.${groupName}.pricing.${uom}.${category}.${rownum}.depositor_${eventName}_fee`;
    this.onFormUpdate(key, newRate);
  };
  private onFormWarehouseRateChange = (groupName: string, expenseRow: WarehouseExpenseRow, newRate: any) => {
    const { category, eventName, uom, rownum } = expenseRow;
    const key = `pricing.${groupName}.pricing.${uom}.${category}.${rownum}.warehouse_${eventName}_fee`;
    this.onFormUpdate(key, newRate);
  };

  private onFormDescriptionRateChange = (groupName: string, expenseRow: WarehouseExpenseRow, newDescription: any) => {
    const { category, eventName, uom, rownum } = expenseRow;
    const key = `pricing.${groupName}.pricing.${uom}.${category}.${rownum}.${eventName}_description`;
    this.onFormUpdate(key, newDescription);
  };

  private onFormUpdate = (key: string, value: any, errorKey?: string) => {
    this.setState((prevState) => {
      const state: ReservationDetailState = cloneDeep(prevState);
      set(state, key, value);
      if (key === this.storageBillingModeKey) {
        this.onStorageBillingModeUpdate(state, value);
      }

      // Clear the error message(s) when there is an update
      state.reservationErrorMessage = null;
      if (errorKey) {
        state.pricingFormErrors = omit(state.pricingFormErrors, [errorKey]);
      }

      return state;
    });
  };

  private onStorageBillingModeUpdate(state: ReservationDetailState, newStorageBillingMode: string) {
    // Update dependant pricing display options
    state.pricingMetadata = fetchPricingMetaData(newStorageBillingMode, state.pricingCatalog);
    state.pricingTemplate = fetchPricingTemplate(newStorageBillingMode);

    const newStorageScope = fetchStorageScope(newStorageBillingMode);

    state.pricing.inventoryGroups.forEach((inventoryGroup: InventoryGroup) => {
      inventoryGroup.pricing.pallet.storage_pricing_scope = cloneDeep(newStorageScope);
      inventoryGroup.pricing.default.storage_pricing_scope = cloneDeep(newStorageScope);
    });

    state.pricing.defaultInventoryGroup.pricing.pallet.storage_pricing_scope = cloneDeep(newStorageScope);
    state.pricing.defaultInventoryGroup.pricing.default.storage_pricing_scope = cloneDeep(newStorageScope);
  }

  private getScopeState(scopeTxnState: ScopeTxnState) {
    const scopeStates = {
      new: 'New',
      awaiting_approval: 'Awaiting Approval',
      approved: 'Approved',
      completed: 'Completed',
      cancelled: 'Cancelled',
    };
    return scopeStates[scopeTxnState];
  }

  private hideWarningModal = () => {
    this.setState({ showWarningModal: false });
  };

  private editScope = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    const scopeTxnState = get(this.state, 'reservationDetails.reservation_scope.txn_state');
    if (scopeTxnState === 'awaiting_approval') {
      // Warn the user with a modal
      this.setState({ showWarningModal: true });
    } else {
      this.continueEditingScope();
    }
  };

  private continueEditingScope = () => {
    this.setState({
      showWarningModal: false,
      disablePricingEdits: false,
    });
  };

  private hideNegativeMarginWarning = () => {
    this.setState({ creatingNewRevision: false, showNegativeMarginWarning: false });
  };

  private hideDuplicateFeeTypesError = () => {
    this.setState({ creatingNewRevision: false, showDuplicateFeeTypesError: false });
  };

  private hideInvalidExpenseError = () => {
    this.setState({ creatingNewRevision: false, showInvalidExpenseError: false });
  };

  private submitWithNegativeMargin = () => {
    this.setState({
      showNegativeMarginWarning: false,
    });
    this.createNewScope(false);
  };

  private cancelEditScope = () => {
    // Switch back to the setup tab in case the current tab disappears
    this.setState({ disablePricingEdits: true, currentTab: 'setup' });
    this.getReservationAndPricing();
  };

  private preparePricingScopeData = (): {
    pricingScopeData: PricingScopeData;
    reservationErrorMessage?: JSX.Element;
  } => {
    const pricingScopeData: PricingScopeData = [];
    const pricingOptions = this.state.reservationDetails.pricingOptions;

    if (pricingOptions.use_inventory_groups) {
      this.state.pricing.inventoryGroups.forEach((invGroup: InventoryGroup) => {
        if (invGroup.enabled) {
          const inventoryGroupData = this.inventoryGroupsService.processInventoryGroup(invGroup, pricingOptions);

          inventoryGroupData.forEach((pricingScope: PricingScope) => {
            if (!isEmpty(pricingScope.pricing_scopes)) {
              pricingScopeData.push(pricingScope);
            }
          });
        }
      });
    } else {
      const defaultInvGroup = this.state.pricing.defaultInventoryGroup;
      const defaultInvGroupData = this.inventoryGroupsService.processInventoryGroup(defaultInvGroup, pricingOptions);

      defaultInvGroupData.forEach((pricingScope: PricingScope) => {
        if (!isEmpty(pricingScope.pricing_scopes)) {
          pricingScopeData.push(pricingScope);
        }
      });
    }

    const warehouseExpensePricing = this.state.pricing.warehouseExpenseGroup.pricing;
    Object.keys(warehouseExpensePricing).forEach((uom) => {
      const index = pricingScopeData.findIndex(
        (data) => data.pricing_axes.inventory_group === null && data.pricing_axes.packaging === BillingUom[uom],
      );
      if (index >= 0) {
        pricingScopeData[index].pricing_scopes = Object.assign(
          pricingScopeData[index].pricing_scopes,
          warehouseExpensePricing[uom],
        );
      } else if (!isEmpty(warehouseExpensePricing[uom])) {
        pricingScopeData.push({
          pricing_axes: {
            inventory_group: null,
            packaging: BillingUom[uom],
          },
          pricing_scopes: warehouseExpensePricing[uom],
        });
      }
    });

    const flatFeePricing = this.state.pricing.flatFeeGroup.pricing;
    Object.keys(flatFeePricing).forEach((uom) => {
      const index = pricingScopeData.findIndex(
        (data) => data.pricing_axes.inventory_group === null && data.pricing_axes.packaging === BillingUom[uom],
      );
      if (index >= 0) {
        pricingScopeData[index].pricing_scopes = Object.assign(
          pricingScopeData[index].pricing_scopes,
          flatFeePricing[uom],
        );
      } else if (!isEmpty(flatFeePricing[uom])) {
        pricingScopeData.push({
          pricing_axes: {
            inventory_group: null,
            packaging: BillingUom[uom],
          },
          pricing_scopes: flatFeePricing[uom],
        });
      }
    });
    // Outbound Method(s)
    if (
      !this.state.reservationDetails.pricingOptions.consumer_fulfillment_pricing_scope &&
      !this.state.reservationDetails.pricingOptions.retail_fulfillment_pricing_scope &&
      !this.state.reservationDetails.pricingOptions.sort_center_pricing_scope
    ) {
      return {
        pricingScopeData,
        reservationErrorMessage: <p>Outbound pricing must be set.</p>,
      };
    }

    // Inbound Method(s)
    if (!this.state.reservationDetails.pricingOptions.container_pricing_scope) {
      return {
        pricingScopeData,
        reservationErrorMessage: <p>Container Unload Inbound Method must be set.</p>,
      };
    }

    return { pricingScopeData };
  };

  // eslint-disable-next-line complexity -- Disabled pre-existing violation of complexity rule
  private createNewScope = (validateNegativeMargin = true) => {
    this.setState({
      creatingNewRevision: true,
      formErrors: {},
    });

    const blockActivity = this.state.reservationDetails.reservation_scope.block_activity;
    const { pricingScopeData, reservationErrorMessage } = this.preparePricingScopeData();
    const pricingOptions = this.state.reservationDetails.pricingOptions;
    const paymentTerm = this.state.reservationDetails.reservation_scope.payment_term;
    const storageBillingMode = this.state.reservationDetails.reservation_scope.storage_billing_mode;
    const documents = this.state.reservationDetails.reservation_scope_documents;

    if (reservationErrorMessage) {
      this.setState({
        creatingNewRevision: false,
        reservationErrorMessage,
      });
      return;
    }

    if (
      (validateNegativeMargin && this.hasNegativeMargin(pricingScopeData)) ||
      this.hasDuplicateFeeTypes(pricingScopeData) ||
      this.hasInvalidWarehouseExpense(pricingScopeData)
    ) {
      return;
    }

    // checks if there are duplicate events set up for same category
    // TO DO this is temporary validation, we will support it in the future release
    if (warehouseExpenseScopes.some((category) => this.state.pricing.warehouseExpenseGroup[category])) {
      this.setState({
        waitingForBackend: false,
        creatingNewRevision: false,
        reservationErrorMessage: 'Duplicate event set up in Warehouse Expense Pricings.',
      });
      window.scrollTo(0, 0);
      return;
    }
    if (flatFeeScopes.some((category) => this.state.pricing.flatFeeGroup[category])) {
      this.setState({
        waitingForBackend: false,
        creatingNewRevision: false,
        reservationErrorMessage: 'Duplicate event set up in Flat Fee Pricings.',
      });
      window.scrollTo(0, 0);
      return;
    }
    // Check if any fees of a selected service are not set
    const configErrors = this.pricingService.checkForIncorrectConfiguration(
      pricingScopeData,
      pricingOptions,
      this.state.pricingMetadata,
    );
    if (configErrors && Object.keys(configErrors).length) {
      // Display config errors
      const errorMessages = this.pricingService.processReservationConfigErrors(configErrors);
      this.setState({
        waitingForBackend: false,
        creatingNewRevision: false,
        reservationErrorMessage: errorMessages,
      });
      window.scrollTo(0, 0);
      return;
    }

    const processedPricingScopeData = this.pricingService.removeEmptyServices(pricingScopeData, pricingOptions);

    flexeApi
      .updateReservationAndPricing(
        parseInt(this.state.reservationDetails.id, 10),
        processedPricingScopeData,
        this.state.patPricing.map((config) => config.configuration),
        blockActivity,
        paymentTerm,
        storageBillingMode,
        parseFloat(this.state.reservationDetails.reservation_scope.shipper_minimum),
        parseFloat(this.state.reservationDetails.reservation_scope.warehouse_minimum),
        documents,
      )
      .then(this.checkForValidationErrors)
      .then(this.checkForValidationErrors)
      .then(this.scopeRevisionSuccessfull)
      .catch(this.scopeRevisionUnsuccessfull);
  };

  private checkForValidationErrors = (response) => {
    const responseError = get(response, 'error.msg');
    if (responseError) {
      const stateUpdate = this.pricingService.handlePricingValidationError(responseError);
      this.setState(stateUpdate);
      throw new Error();
    } else {
      return true;
    }
  };

  private scopeRevisionSuccessfull = () => {
    this.showSuccessOverlay('Scope Successfully Revised!');
    this.getAllReservationData();
    this.setState({
      creatingNewRevision: false,
      disablePricingEdits: true,
      reservationErrorMessage: null,
      pricingFormErrors: null,
    });
  };

  private scopeRevisionUnsuccessfull = (error: Error) => {
    let reservationErrorMessage = this.state.reservationErrorMessage;
    let disablePricingEdits = false;
    if (error.message) {
      disablePricingEdits = true;
      reservationErrorMessage = <p>{error.message}</p>;
    }
    this.setState({
      creatingNewRevision: false,
      disablePricingEdits,
      reservationErrorMessage,
    });
    window.scrollTo(0, 0);
  };

  private revealChangeStatusMenu = (event) => {
    this.setState({
      showChangeStatusMenu: !this.state.showChangeStatusMenu,
      changeStatusMenuAnchor: event.currentTarget as HTMLElement,
    });
  };

  private hideChangeStatusMenu = () => {
    this.setState({ showChangeStatusMenu: false });
  };

  private showSubmitForApprovalModal = () => {
    const scopeDocs = this.state.reservationDetails.reservation_scope_documents;
    const scopeDocsChecked = scopeDocs ? new Array(scopeDocs.length).fill(false) : [];
    this.setState({
      showSubmitForApprovalModal: true,
      showChangeStatusMenu: false,
      scopeDocsChecked,
    });
  };

  private hideSubmitForApprovalModal = () => {
    this.setState({ showSubmitForApprovalModal: false });
  };

  private submitForApproval = () => {
    const scopeId = get(this.state, 'reservationDetails.reservation_scope.id');
    const scopeApprovalPolicy = this.state.scopeApprovalPolicy;
    const scopeManagerApprovalEmail = this.state.scopeManagerApprovalEmail;
    this.setState({ submitingForApproval: true });
    flexeApi
      .submitReservationScopeForApproval(scopeId, scopeApprovalPolicy, scopeManagerApprovalEmail)
      .then((resp) => {
        if (resp.statusCode === 200) {
          // Update scope status to "Awaiting Approval" and hide the modal
          const state: ReservationDetailState = cloneDeep(this.state);
          if (scopeApprovalPolicy === ScopeApprovalPolicy.admin_approval) {
            state.reservationDetails.reservation_scope.txn_state = ScopeTxnState.approved;
            state.reservationDetails.reservation_scope.approval_info = { approval_time: this.today.toLocaleString() };
          } else {
            state.reservationDetails.reservation_scope.txn_state = ScopeTxnState.awaiting_approval;
          }
          state.reservationDetails.reservation_scope.approval_type = scopeApprovalPolicy;
          state.showSubmitForApprovalModal = false;
          state.submitingForApproval = false;
          state.scopeDocsChecked.fill(false);
          this.setState(state);
        } else {
          const errorMsg = get(resp, 'error.msg');
          this.handleSubmitForApprovalError(errorMsg);
        }
      })
      .catch((error) => {
        this.handleSubmitForApprovalError(error.message);
      });
  };

  private handleSubmitForApprovalError = (errorMsg: string) => {
    this.setState({
      reservationErrorMessage: <p>{errorMsg}</p>,
      showSubmitForApprovalModal: false,
      submitingForApproval: false,
    });
  };

  private upsertReservationSlaMetrics = () => {
    const data = [];
    const reservationId = this.props.match.params.id;
    Object.entries(this.state.reservationSlaMetrics).forEach((pair) => {
      const metric = pair[0];
      const subtypes = pair[1];
      Object.entries(subtypes).forEach((innerPair) => {
        const subtype = innerPair[0];
        const value = innerPair[1];
        if (value && value !== 'N/A') {
          data.push({ metric, value, metric_subtype: subtype, reservation_id: parseInt(reservationId, 10) });
        }
      });
    });

    if (data.length > 0) {
      flexeApi.upsertReservationSlaMetrics(reservationId, { metrics: data }).then((response) => {
        if (response.status === 204) {
          this.hideSlaGoalsModal();
        } else {
          let errorMessage = 'An unknown error occurred while attempting to save metric data.';
          if (response.error && response.error.msg) {
            errorMessage = response.error.msg;
          }
          this.setState({
            reservationSlaMetricError: errorMessage,
          });
        }
      });
    }
  };

  private showCancelScopeModal = () => {
    this.setState({
      showCancelScopeModal: true,
      showChangeStatusMenu: false,
    });
  };

  private hideCancelModal = () => {
    this.setState({ showCancelScopeModal: false });
  };

  private cancelScope = () => {
    const scopeId = get(this.state, 'reservationDetails.reservation_scope.id');
    this.setState({ cancellingScope: true });
    flexeApi
      .cancelReservationScope(scopeId)
      .then((resp) => {
        if (resp.statusCode === 200) {
          // Update scope status to "Cancelled"
          const state: ReservationDetailState = cloneDeep(this.state);
          state.reservationDetails.reservation_scope.txn_state = ScopeTxnState.cancelled;
          state.showCancelScopeModal = false;
          state.cancellingScope = false;
          this.setState(state);
        } else {
          const errorMsg = get(resp, 'error.msg');
          this.handleCancelScopeError(errorMsg);
        }
      })
      .catch((error) => {
        this.handleCancelScopeError(error.message);
      });
  };

  private handleCancelScopeError = (errorMsg: string) => {
    this.setState({
      reservationErrorMessage: <p>{errorMsg}</p>,
      showCancelScopeModal: false,
      cancellingScope: false,
    });
  };

  private renderBreadCrumbs() {
    if (this.state.reservationLoaded) {
      return (
        <div>
          <div className="bread-crumbs">
            <Link to="/reservations">Reservations &#62;</Link>
            <span> Res ID #{this.state.reservationDetails.id}</span>
          </div>
        </div>
      );
    }
  }

  private renderErrorMessage(reservationErrorMessage: JSX.Element | string) {
    if (reservationErrorMessage) {
      return <ErrorMessage data-testid="reservation-error-message">{reservationErrorMessage}</ErrorMessage>;
    }
  }

  private getanchor() {
    return window.document.getElementById('change-status-menu');
  }

  private renderStatus(txnState: ReservationTxnState, scopeTxnState: ScopeTxnState, blocked: boolean) {
    const changeStatusMenu = (
      <div style={{ display: 'inline-block' }}>
        <IconButton
          id="change-status-menu"
          onClick={this.revealChangeStatusMenu}
          style={{ verticalAlign: 'middle', position: 'relative' }}
        >
          <ExpandMoreIcon />
        </IconButton>
        <Popover
          style={{ right: '16px' }}
          open={this.state.showChangeStatusMenu}
          anchorEl={this.getanchor()}
          anchorReference="anchorEl"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          onClose={this.hideChangeStatusMenu}
        >
          <List style={{ width: 'max-content' }}>
            <ListItem button={true} onClick={this.showSubmitForApprovalModal} disabled={scopeTxnState !== 'new'}>
              <ListItemText primary="Submit For Approval" />
            </ListItem>
            <ListItem
              button={true}
              onClick={this.showCancelScopeModal}
              disabled={scopeTxnState !== 'new' && scopeTxnState !== 'awaiting_approval'}
            >
              <ListItemText primary="Cancel" />
            </ListItem>
          </List>
        </Popover>
      </div>
    );
    if (txnState === 'confirmed') {
      return (
        <div className="status">
          <PowerIcon className="status-icon power-icon" color="action" />
          <div className="text">
            <h3 className="h5">Active</h3>
          </div>
          {changeStatusMenu}
        </div>
      );
    } else {
      return (
        <div className="status">
          <ActionLockIcon className="status-icon lock-icon" color="secondary" />
          <div className="text">
            <h3 className="h5">Inactive</h3>
            {blocked ? (
              <small>
                <i>Activity Blocked</i>
              </small>
            ) : null}
          </div>
          {changeStatusMenu}
        </div>
      );
    }
  }

  // eslint-disable-next-line complexity -- Disabled pre-existing violation of complexity rule
  private renderReservationDetails(reservationDetails: ReservationAndScopeInfo) {
    const { depositor: company, warehouse, reservation_scope: reservationScope } = reservationDetails;
    if (!this.state.reservationLoaded) {
      return <PageLoadSpinner />;
    } else if (!isEmpty(reservationDetails) && !isEmpty(reservationScope)) {
      const reservationId = get(reservationDetails, 'id');
      const txnState = get(reservationDetails, 'txn_state');
      const shipperCompanyName = get(company, 'name');
      const warehouseCompanyName = get(warehouse, 'company.name');
      const warehouseName = get(warehouse, 'name');
      const warehouseCity = get(warehouse, 'city');

      const scopeId = get(reservationScope, 'id');
      const scopeCreatedAt = get(reservationScope, 'created_at');
      const scopeBlockActivity = get(reservationScope, 'block_activity');
      const scopeTxnState = get(reservationScope, 'txn_state');
      const reservationCardTitle = `#${reservationId.toString()} ${warehouseName} - ${warehouseCity}`;
      const scopeApprovalType =
        get(reservationScope, 'approval_type') || (scopeTxnState !== 'new' ? ScopeApprovalPolicy.two_side : '');
      const scopeApprovalInfo = get(reservationScope, 'approval_info');
      const shipperSignedOn =
        (scopeApprovalInfo !== null && scopeApprovalInfo !== undefined && scopeApprovalInfo.shipper_side_signed_on) ||
        get(reservationScope, 'shipper_signed_on');
      const warehouseSignedOn =
        (scopeApprovalInfo !== null &&
          scopeApprovalInfo !== undefined &&
          scopeApprovalInfo.warehouser_side_signed_on) ||
        get(reservationScope, 'warehouse_signed_on');
      // delete them, when we delete the m2 refactor feature flag
      const oldShipperSignedOn = get(reservationScope, 'shipper_signed_on');
      const oldWarehouseSignedOn = get(reservationScope, 'warehouse_signed_on');

      return (
        <Paper className="reservation-info">
          <header>
            <Avatar
              onClick={this.handleBackButtonClick}
              className="back-button"
              color="primary"
              style={styles.backButton}
            >
              <ArrowBackIcon style={{ margin: '8px' }} />
            </Avatar>
            <div className="title">
              <h2 className="h1">{reservationCardTitle}</h2>
              <div className="sub-title">{shipperCompanyName}</div>
            </div>
            {this.renderStatus(txnState, scopeTxnState, scopeBlockActivity)}
          </header>
          <div className="pure-g">
            <div className="pure-u-3-24 scope">
              <h3 className="h4">
                <span>Scope</span>
              </h3>
              <h4 className="h5">{this.getScopeState(scopeTxnState)}</h4>
              <a href="#">Scope_{scopeId}</a>
              <br />
              <small>Created on {parseDate(scopeCreatedAt).toLocaleDateString()}</small>
            </div>
            <div className="pure-u-1-24">
              <div className="vertical-line"></div>
            </div>
            <div className="pure-u-6-24 approval">
              <h3 className="h4">
                <span>Approval Info</span>
              </h3>
              <div className="pure-u-1-2">
                {scopeApprovalType !== '' && scopeApprovalType !== undefined && (
                  <h4 className="h5">
                    <span>Approval Type</span>
                  </h4>
                )}
                {scopeApprovalType}
                <p></p>
                {scopeApprovalType === ScopeApprovalPolicy.two_side &&
                  scopeApprovalInfo !== null &&
                  scopeApprovalInfo !== undefined &&
                  this.renderSignedStatus('Approve Time', scopeApprovalInfo.approval_time)}
              </div>
              <div className="pure-u-1-2">
                {scopeApprovalType === ScopeApprovalPolicy.two_side &&
                  this.renderSignedStatus('Shipper', shipperSignedOn)}
                {scopeApprovalType === ScopeApprovalPolicy.two_side &&
                  this.renderSignedStatus('Warehouse', warehouseSignedOn)}
                {(scopeApprovalType === ScopeApprovalPolicy.manager_approval ||
                  scopeApprovalType === ScopeApprovalPolicy.one_side_warehouse ||
                  scopeApprovalType === ScopeApprovalPolicy.one_side_shipper ||
                  scopeApprovalType === ScopeApprovalPolicy.admin_approval) &&
                  scopeApprovalInfo !== undefined &&
                  this.renderSignedStatus('Approve Time', scopeApprovalInfo.approval_time)}
              </div>
            </div>
            <div className="pure-u-1-24">
              <div className="vertical-line"></div>
            </div>
            <div className="pure-u-6-24 shipper">
              <h3 className="h4">
                <span>Shipper</span>
              </h3>
              <div className="pure-g">
                <div className="pure-u-1-2">
                  <h4 className="h5">Company</h4>
                  {shipperCompanyName}
                </div>
                <div className="pure-u-1-2">
                  <h4 className="h5">Log in as Company</h4>
                  {company.id && (
                    <a
                      target="_blank"
                      href={`${window.warehouserWebUri}/admin/admin_login/${company.id}/login_as_admin`}
                    >
                      Company Login Link
                    </a>
                  )}
                </div>
              </div>
            </div>
            <div className="pure-u-1-24">
              <div className="vertical-line"></div>
            </div>
            <div className="pure-u-6-24 warehouse">
              <h3 className="h4">
                <span>Warehouse</span>
              </h3>
              <div className="pure-g">
                <div className="pure-u-1-2">
                  <h4 className="h5">Company</h4>
                  {warehouseCompanyName}
                </div>
                <div className="pure-u-1-2">
                  <h4 className="h5">Log in as Warehouse</h4>
                  {warehouse.company.id && (
                    <a
                      target="_blank"
                      href={`${window.warehouserWebUri}/admin/admin_login/${warehouse.company.id}/login_as_admin`}
                    >
                      Warehouse Login Link
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Paper>
      );
    } else {
      return <ErrorMessage>No Reservation Info was returned</ErrorMessage>;
    }
  }

  private handleCreateOrUpdateInventoryGroup = ({ currentTarget }: { currentTarget: HTMLElement }) => {
    const invGroupIdx = parseInt(currentTarget.dataset.invGroupIdx, 10);
    this.createOrUpdateInventoryGroup(invGroupIdx);
  };

  private async createOrUpdateInventoryGroup(invGroupIdx) {
    const companyId = get(this.state, 'reservationDetails.depositor.id');
    try {
      const newOrUpdatedInvGroup = await this.inventoryGroupsService.createOrUpdateInventoryGroup(
        this.state,
        companyId,
        invGroupIdx,
      );
      const oldInvGroup = this.state.pricing.inventoryGroups[invGroupIdx];
      const updatedState = this.inventoryGroupsService.mergeInventoryGroupState(
        this.state,
        invGroupIdx,
        newOrUpdatedInvGroup,
        this.state.pricingTemplate,
      );
      this.setState(updatedState);
    } catch (error) {
      this.setReservationErrorMessage(error.msg);
    }
  }

  private handleCancelEditInventoryGroup = ({ currentTarget }: { currentTarget: HTMLElement }) => {
    const invGroupIdx = parseInt(currentTarget.dataset.invGroupIdx, 10);
    this.inventoryGroupsService.cancelEditInventoryGroup(this.state, invGroupIdx);
  };

  private handleAddInventoryGroup = () => {
    const updatedState = this.inventoryGroupsService.addInventoryGroup(this.state, this.state.pricingTemplate);
    this.setState(updatedState);
  };

  private setReservationErrorMessage = (reservationErrorMessage: JSX.Element | string) => {
    this.setState({ reservationErrorMessage });
    window.scrollTo(0, 0);
  };

  private renderSignedStatus(title: string, signedDate: string) {
    if (signedDate) {
      return (
        <div>
          <h4 className="h5">
            <span>{title}</span>
          </h4>
          <SuccessMessage>
            <span>Signed On {parseDate(signedDate).toLocaleDateString()}</span>
          </SuccessMessage>
        </div>
      );
    } else {
      return (
        <div>
          <h4 className="h5">
            <span>{title}</span>
          </h4>
          <AlertMessage>
            <span>Not Signed</span>
          </AlertMessage>
        </div>
      );
    }
  }

  private renderReviseScopeLink(txnState: ReservationTxnState, scopeTxnState: ScopeTxnState) {
    let editLinkText = 'Revise Scope';
    if (scopeTxnState === 'approved' || scopeTxnState === 'awaiting_approval') {
      editLinkText = 'Replace Scope';
    }
    if (txnState === 'new' || txnState === 'confirmed') {
      if (this.state.disablePricingEdits) {
        return (
          <a className="revise-scope" data-testid="revise-scope" href="#" onClick={this.editScope}>
            <EditIcon color="primary" />
            {editLinkText}
          </a>
        );
      } else {
        return <span className="new-revision">(New Revision)</span>;
      }
    }
  }

  private renderMainButtons(txnState: ReservationTxnState) {
    if (txnState === 'new' || txnState === 'confirmed') {
      return (
        <div className="main-buttons">
          <Button
            color="primary"
            onClick={this.cancelEditScope}
            disabled={this.state.disablePricingEdits || this.state.creatingNewRevision}
            style={{ marginRight: '1rem' }}
          >
            Cancel
          </Button>
          <SubmitButton
            type={RAISED_BTN}
            label="Create New Scope"
            disabled={this.state.disablePricingEdits || this.state.creatingNewRevision}
            onClick={this.createNewScope}
            showSpinner={this.state.creatingNewRevision}
          />
        </div>
      );
    }
  }

  // eslint-disable-next-line complexity -- Disabled pre-existing violation of complexity rule
  private renderTabs(
    reservationDetails: ReservationAndScopeInfo,
    defaultInventoryGroup: InventoryGroup,
    inventoryGroups: InventoryGroup[],
  ) {
    const formErrors = this.state.formErrors;
    const txnState = reservationDetails.txn_state;
    const scopeTxnState = get(reservationDetails, 'reservation_scope.txn_state');
    const blockActivity = get(reservationDetails, 'reservation_scope.block_activity');
    const paymentTerm = get(reservationDetails, 'reservation_scope.payment_term');
    const shipperMinimum = get(reservationDetails, 'reservation_scope.shipper_minimum');
    const warehouseMinimum = get(reservationDetails, 'reservation_scope.warehouse_minimum');
    const shipperMinimumKey = 'reservationDetails.reservation_scope.shipper_minimum';
    const warehouseMinimumKey = 'reservationDetails.reservation_scope.warehouse_minimum';
    const scopeDocuments = reservationDetails.reservation_scope_documents;

    if (!isEmpty(reservationDetails)) {
      const { currentTab } = this.state;
      // @ts-ignore
      return (
        <div>
          <Grid container spacing={3}>
            <Grid item xs={4} />
            <Grid item xs>
              <Paper className="reservation-info">
                <h3 style={{ marginBottom: '10px' }}>SLA Adherence</h3>
                <Button
                  variant="contained"
                  data-testid="configure-sla"
                  onClick={() => this.props.history.push(`/reservations/${this.props.match.params.id}/cutoffs`)}
                >
                  Cutoffs
                </Button>
                <Button variant="contained" data-testid="edit-sla-metrics" onClick={this.showSlaGoalsModal}>
                  Set SLA
                </Button>
              </Paper>
            </Grid>
            <Grid item xs />
          </Grid>

          <h3 className="pricing-heading">Reservation Pricing</h3>
          {this.renderReviseScopeLink(txnState, scopeTxnState)}

          <Tabs className="pricing-tabs" value={currentTab} onChange={this.changeTabs}>
            {!isEmpty(reservationDetails) ? (
              <Tab
                value="setup"
                label={
                  <span className="tab-label">
                    General <small>Settings</small>
                  </span>
                }
                style={currentTab === 'setup' ? styles.setupTabActive : styles.setupTab}
              />
            ) : null}
            {
              <Tab
                value="billing-setup"
                label={
                  <span className="tab-label" data-testid={'billing-tab'}>
                    Billing <small>Settings</small>
                  </span>
                }
                style={currentTab === 'billing-setup' ? styles.setupTabActive : styles.setupTab}
              />
            }
            {this.renderFlatFeeConfigTab()}
            {this.renderWarehouseExpenseConfigTab()}
            {this.renderPatPricingConfigTab()}
            {this.renderDefaultGroupTab(defaultInventoryGroup)}
            {this.renderInventoryGroupTabs(inventoryGroups, 'tab')}
          </Tabs>

          {currentTab === 'setup' && (
            <div className="pure-g" key="general-content">
              <div className="fieldset-wrapper pure-u-1-2">
                <ReservationSetup
                  formData={reservationDetails}
                  scopeDocuments={scopeDocuments}
                  onFieldChange={() => {
                    /* noop */
                  }}
                  onStartOnDateChange={() => {
                    /* noop */
                  }}
                  onIntegerFieldChange={() => {
                    /* noop */
                  }}
                  formErrors={formErrors}
                  disableForm={true}
                  blockActivityBoxEnabled={!this.state.disablePricingEdits}
                />
              </div>
              <div className="fieldset-wrapper pure-u-1-2">
                <ReservationSettings
                  formData={reservationDetails}
                  blockActivity={blockActivity}
                  blockActivityKey="reservationDetails.reservation_scope.block_activity"
                  onFieldChange={this.handleFieldValue}
                  formErrors={formErrors}
                  disableForm={true}
                  blockActivityBoxEnabled={!this.state.disablePricingEdits}
                />
              </div>
            </div>
          )}
          {currentTab === 'billing-setup' && (
            <div className="pure-g" key="general-content">
              <div className="fieldset-wrapper pure-u-1-2">
                <ReservationMinimums
                  formData={reservationDetails}
                  shipperMinimum={shipperMinimum}
                  shipperMinimumKey={shipperMinimumKey}
                  warehouseMinimum={warehouseMinimum}
                  warehouseMinimumKey={warehouseMinimumKey}
                  onFieldChange={this.handleFieldValue}
                  formErrors={formErrors}
                  disableForm={this.state.disablePricingEdits}
                />
                <BillingSettings
                  formData={reservationDetails}
                  paymentTerm={paymentTerm?.toString()}
                  paymentTermKey="reservationDetails.reservation_scope.payment_term"
                  onFieldChange={this.handleFieldValue}
                  formErrors={formErrors}
                  disableForm={this.state.disablePricingEdits}
                />
                <StorageBillingModes
                  formData={this.state.reservationDetails.reservation_scope.storage_billing_mode}
                  storageBillingModeKey={this.storageBillingModeKey}
                  onFieldChange={this.handleFieldValue}
                  formErrors={formErrors}
                  disableForm={this.state.disablePricingEdits}
                />
              </div>
              <div className="fieldset-wrapper pure-u-1-2">
                <PricingOptions
                  formData={reservationDetails.pricingOptions}
                  optionsKey="reservationDetails.pricingOptions"
                  handleToggleChange={this.handleToggleChange}
                  disableForm={this.state.disablePricingEdits}
                />
                {this.state.reservationDetails.pricingOptions.use_inventory_groups ? (
                  <InventoryGroupsManagement
                    onFormUpdate={this.onFormUpdate}
                    inventoryGroups={inventoryGroups}
                    onCancelEditInventoryGroup={this.handleCancelEditInventoryGroup}
                    onCreateOrUpdateInventoryGroup={this.handleCreateOrUpdateInventoryGroup}
                    onAddInventoryGroup={this.handleAddInventoryGroup}
                    disableForm={this.state.disablePricingEdits}
                  />
                ) : null}
              </div>
            </div>
          )}

          {currentTab === 'flatFeeConfig' && (
            <FlatFeeConfigForm
              pricingData={this.state.pricing.flatFeeGroup || {}}
              formErrors={get(this.state, 'pricingFormErrors.default')}
              onFormUpdate={this.onFormUpdate}
              onFormUpdateAndRemove={this.onConfigUpdate}
              disabled={this.state.disablePricingEdits}
            />
          )}

          {currentTab === 'warehouseExpenseConfig' && (
            <WarehouseExpenseConfigForm
              expenseRows={this.expenseRowsFromPricing(this.state.pricing.warehouseExpenseGroup.pricing || {})}
              formErrors={get(this.state, 'pricingFormErrors.default')}
              onAddExpenseRow={this.onAddExpenseRow}
              onExpenseFormShipperRateChange={this.onExpenseFormShipperRateChange}
              onExpenseFormWarehouseRateChange={this.onExpenseFormWarehouseRateChange}
              onExpenseFormDescriptionChange={this.onExpenseFormDescriptionChange}
              onRemoveExpenseRow={this.onRemoveExpenseRow}
              onChangeExpenseEventName={this.onChangeExpenseEventName}
              onChangeExpenseUnit={this.onChangeExpenseUnit}
              disabled={this.state.disablePricingEdits}
              pricingCatalog={this.state.pricingCatalog}
            />
          )}

          {currentTab === 'patPricingConfig' && (
            <PatPricingConfigForm
              patPricing={this.state.patPricing}
              pricingCatalog={this.state.pricingCatalog}
              disabled={this.state.disablePricingEdits}
              onAddPatConfig={this.onAddPatConfig}
              onRemovePatConfig={this.onRemovePatConfig}
              onEditPatConfig={this.onEditPatConfig}
            />
          )}

          {currentTab === 'default' && (
            <InventoryPricingGroup
              idx={0}
              inventoryGroup={defaultInventoryGroup}
              usePackagingGroups={reservationDetails.pricingOptions.use_packaging_groups}
              pricingOptions={this.state.reservationDetails.pricingOptions}
              metaData={this.state.pricingMetadata}
              disableForm={this.state.disablePricingEdits}
              onFormUpdate={this.onFormUpdate}
              formErrors={inventoryGroupHasErrors(get(this.state, 'pricingFormErrors.default'))}
            />
          )}

          {!isNaN(parseInt(currentTab, 10)) && (
            <InventoryPricingGroup
              idx={parseInt(currentTab, 10)}
              inventoryGroup={inventoryGroups[currentTab]}
              usePackagingGroups={reservationDetails.pricingOptions.use_packaging_groups}
              pricingOptions={this.state.reservationDetails.pricingOptions}
              metaData={this.state.pricingMetadata}
              disableForm={this.state.disablePricingEdits}
              onFormUpdate={this.onFormUpdate}
              formErrors={inventoryGroupHasErrors(get(this.state, 'pricingFormErrors.default'))}
            />
          )}

          {this.renderInventoryGroupTabs(inventoryGroups, 'content')}
        </div>
      );
    }
  }

  private expenseRowsFromPricing(pricing: any) {
    const allExpenseRows: WarehouseExpenseRow[] = [];
    Object.keys(pricing).forEach((uom) => {
      Object.keys(pricing[uom]).forEach((category) => {
        const categoryFees = pricing[uom][category];
        for (const categoryFee of categoryFees) {
          if (categoryFee === undefined) {
            continue;
          }
          const warehouseKeys = Object.keys(categoryFee).filter(
            (e) => e.startsWith('warehouse_') && e.endsWith('_fee'),
          );
          warehouseKeys.forEach((key) => {
            const eventName = key.slice(10).slice(0, -4);
            const rownum = categoryFee.rownum;
            const row: WarehouseExpenseRow = {
              id: `${uom}.${category}.${eventName}.${rownum}`,
              category,
              eventName,
              uom,
              warehouseRate: categoryFee[key],
              depositorRate: categoryFee['depositor_' + eventName + '_fee'],
              description: categoryFee[eventName + '_description'],
              databaseId: categoryFee[eventName + '_id'],
              depositorWarning: '',
              warehouseWarning: '',
              rownum,
            };
            allExpenseRows.push(row);
          });
        }
      });
    });
    return allExpenseRows;
  }

  private handleFieldValue = (event) => {
    let errorKey;
    const target = event.target;
    const name = target.name;
    let value = target.value;
    if (target.type === 'checkbox') {
      value = target.checked;
    }
    if (target instanceof HTMLInputElement) {
      errorKey = target.dataset.errorKey;
    }
    this.onFormUpdate(name, value, errorKey);
  };

  private renderPatPricingConfigTab() {
    const formErrors = get(this.state, 'pricingFormErrors.default');
    const hasErrors = warehouseExpenseHasErrors(formErrors);
    return (
      <Tab
        key="patPricingConfig"
        value="patPricingConfig"
        label={
          <span className="tab-label" data-testid={'pat-pricing-tab'}>
            PAT Pricing<small>Config</small>
          </span>
        }
        icon={hasErrors ? <ErrorIcon /> : null}
        style={this.state.currentTab === 'patPricingConfig' ? tabStyles.activeTab : tabStyles.tab}
      />
    );
  }

  private renderWarehouseExpenseConfigTab() {
    const formErrors = get(this.state, 'pricingFormErrors.default');
    const hasErrors = warehouseExpenseHasErrors(formErrors);
    return (
      <Tab
        key="warehouseExpenseConfig"
        value="warehouseExpenseConfig"
        label={
          <span className="tab-label" data-testid={'warehouse-expense-tab'}>
            Warehouse Expenses<small>Config</small>
          </span>
        }
        icon={hasErrors ? <ErrorIcon /> : null}
        style={this.state.currentTab === 'warehouseExpenseConfig' ? tabStyles.activeTab : tabStyles.tab}
      />
    );
  }

  private renderFlatFeeConfigTab() {
    const formErrors = get(this.state, 'pricingFormErrors.default');
    const hasErrors = flatFeeHasErrors(formErrors);
    return (
      <Tab
        key="flatFeeConfig"
        value="flatFeeConfig"
        label={
          <span className="tab-label" data-testid={'flat-fee-tab'}>
            Flat Fee<small>Config</small>
          </span>
        }
        icon={hasErrors ? <ErrorIcon /> : null}
        style={this.state.currentTab === 'flatFeeConfig' ? tabStyles.activeTab : tabStyles.tab}
      />
    );
  }

  private renderDefaultGroupTab(defaultInventoryGroup: InventoryGroup) {
    if (!this.state.reservationDetails.pricingOptions.use_inventory_groups && !isEmpty(defaultInventoryGroup)) {
      // Just show the default group pricing tab
      const defaultInvGroupFormErrors = get(this.state, 'pricingFormErrors.default');
      const hasErrors = inventoryGroupHasErrors(defaultInvGroupFormErrors);
      return (
        <Tab
          key="default"
          value="default"
          label={
            <span className="tab-label">
              Default <small>Pricing</small>
            </span>
          }
          icon={hasErrors ? <ErrorIcon /> : null}
          style={this.state.currentTab === 'default' ? tabStyles.activeTab : tabStyles.tab}
        />
      );
    }
  }

  private renderInventoryGroupTabs(inventoryGroups: InventoryGroup[], returns: 'tab' | 'content') {
    const pricingFormErrors = this.state.pricingFormErrors;
    const useInventoryGroups = this.state.reservationDetails.pricingOptions.use_inventory_groups;
    if (useInventoryGroups && !isEmpty(inventoryGroups)) {
      return inventoryGroups.map((invGroup: InventoryGroup, idx: number) => {
        const invGroupFormErrors = get(pricingFormErrors, invGroup.id);
        const hasErrors = inventoryGroupHasErrors(invGroupFormErrors);
        if (invGroup.enabled) {
          return returns === 'tab' ? (
            <Tab
              key={idx}
              value={idx}
              label={
                <span className="tab-label">
                  {invGroup.description}
                  <small>Pricing</small>
                </span>
              }
              icon={hasErrors ? <ErrorIcon /> : null}
              data-index={idx}
              style={parseInt(this.state.currentTab, 10) === idx ? tabStyles.activeTab : tabStyles.tab}
            />
          ) : this.state.currentTab === String(idx) ? (
            <InventoryPricingGroup
              idx={idx}
              inventoryGroup={invGroup}
              pricingOptions={this.state.reservationDetails.pricingOptions}
              metaData={this.state.pricingMetadata}
              disableForm={this.state.disablePricingEdits}
              onFormUpdate={this.onFormUpdate}
              formErrors={invGroupFormErrors}
            />
          ) : null;
        }
      });
    }
  }

  private renderSubmitScopeApprovalCheck(doc, index) {
    return (
      <li>
        <FormControlLabel
          control={
            <Checkbox
              className="checkbox"
              name={index}
              checked={this.state.scopeDocsChecked[index]}
              onChange={this.handleScopeDocsCheck}
            />
          }
          labelPlacement="end"
          label={
            <p>
              Document&nbsp;
              <a href={`//${window.location.host}/v2/file-storage/content/${doc.fileKey}`} target="_blank">
                {doc.fileName}
              </a>
              {doc.visibleToShipper === true && doc.visibleToWarehouse === false && (
                <span>
                  &nbsp; is visible to <b>Depositor</b>, it does not contain any Warehouse information.
                </span>
              )}
              {doc.visibleToShipper === false && doc.visibleToWarehouse === true && (
                <span>
                  &nbsp; is visible to <b>Warehouse</b>, it does not contain any Depositor information.
                </span>
              )}
              {doc.visibleToShipper === false && doc.visibleToWarehouse === false && (
                <span>
                  &nbsp; is visible only to <b>Flexe Admins</b>.
                </span>
              )}
              {doc.visibleToShipper === true && doc.visibleToWarehouse === true && (
                <span>
                  &nbsp; is visible to <b>Depositor</b> and <b>Warehouse</b>.
                </span>
              )}
            </p>
          }
        />
      </li>
    );
  }

  private renderScopeDocsFinalCheck(scopeDocs) {
    const reservationDetails = this.state.reservationDetails;
    const depositorCompanyName = get(reservationDetails, 'depositor.name');
    const warehouseCompanyName = get(reservationDetails, 'warehouse.company.name');
    return (
      <div>
        <label>
          <b>Scope Documents Final Review</b>
        </label>
        <ul style={styles.documentList}>
          {scopeDocs.map((doc, index) => {
            return this.renderSubmitScopeApprovalCheck(doc, index);
          })}
        </ul>
        <p>
          <i>* Warehouse:</i> <b>{warehouseCompanyName}</b>
        </p>
        <p>
          <i>* Depositor:</i> <b>{depositorCompanyName}</b>
        </p>
        {!this.state.scopeDocsChecked.every((v) => v === true) && (
          <p style={styles.redWarning}>
            <i>* Check all documents before submitting.</i>
          </p>
        )}
        <hr />
      </div>
    );
  }

  private handleScopeDocsCheck = (event) => {
    const target = event.target;
    const index = target.name;
    const value = target.checked;
    const scopeDocsChecked = this.state.scopeDocsChecked;
    scopeDocsChecked[index] = value;
    this.setState({ scopeDocsChecked });
  };

  private hasNegativeMargin(pricingScopeData: PricingScopeData) {
    for (const pricingData of pricingScopeData) {
      for (const key in pricingData.pricing_scopes) {
        if (key.startsWith('flat_fee')) {
          for (const index in pricingData.pricing_scopes[key]) {
            if (pricingData.pricing_scopes[key].hasOwnProperty(key)) {
              for (const eventKey in pricingData.pricing_scopes[key][index]) {
                if (eventKey.startsWith('depositor_') && eventKey.endsWith('_fee')) {
                  const eventName = eventKey.slice(10).slice(0, -4);
                  const val = pricingData.pricing_scopes[key][index];
                  if (parseFloat(val[eventKey]) < parseFloat(val['warehouse_' + eventName + '_fee'])) {
                    this.setState({
                      showNegativeMarginWarning: true,
                    });
                    return true;
                  }
                }
              }
            }
          }
        }
      }
    }
    return false;
  }

  private hasDuplicateFeeTypes(pricingScopeData: any) {
    const hash = {};
    for (const pricingData of pricingScopeData) {
      for (const key in pricingData.pricing_scopes) {
        if (key.startsWith('flat_fee') || warehouseExpenseScopes.includes(key)) {
          for (const index in pricingData.pricing_scopes[key]) {
            if (pricingData.pricing_scopes[key].hasOwnProperty(index)) {
              for (const eventKey in pricingData.pricing_scopes[key][index]) {
                if (eventKey.endsWith('_description')) {
                  const desc = pricingData.pricing_scopes[key][index][eventKey];
                  if (hash[`${key}_${eventKey}`] === desc) {
                    this.setState({
                      showDuplicateFeeTypesError: true,
                    });
                    return true;
                  }
                  hash[`${key}_${eventKey}`] = desc;
                }
              }
            }
          }
        }
      }
    }
    return false;
  }

  private hasInvalidWarehouseExpense(pricingScopeData: PricingScopeData) {
    const errors = WarehouseExpenseValidator.validateWarehouseExpenses(pricingScopeData);
    this.setState({
      showInvalidExpenseError: errors.length > 0,
      invalidExpenseErrors: errors,
    });
    return errors.length > 0;
  }

  private renderApprovalMethodOption() {
    return (
      <div>
        <FormControl>
          <label htmlFor="scope-approval-policy">
            <b>Set Scope Approval Policy:</b>
          </label>
          <Select
            native
            id="scope-approval-policy"
            name="scopeApprovalPolicy"
            value={this.state.scopeApprovalPolicy}
            onChange={this.handleFieldValue}
          >
            <option value={ScopeApprovalPolicy.manager_approval}>Manager approval policy</option>
            <option value={ScopeApprovalPolicy.admin_approval}>Admin approval policy - automatic approval</option>
          </Select>
        </FormControl>
        {this.state.scopeApprovalPolicy === ScopeApprovalPolicy.manager_approval && (
          <div>
            <FormControl>
              <InputLabel htmlFor="scope-manager-approval-email">
                <b>Manager Email:</b>
              </InputLabel>
              <Input
                id="scope-manager-approval-email"
                name="scopeManagerApprovalEmail"
                value={this.state.scopeManagerApprovalEmail}
                onChange={this.handleFieldValue}
              />
            </FormControl>
            <p>
              This will send an email to manager requesting a review of this scope. Are you sure you want to proceed?
            </p>
          </div>
        )}
        {this.state.scopeApprovalPolicy === ScopeApprovalPolicy.admin_approval && (
          <p>This will automatically approve this scope. Are you sure you want to proceed?</p>
        )}
      </div>
    );
  }
}

export default ReservationDetail;
