import * as React from 'react';
import { clone, get } from 'lodash';
import flexeApi from '../../lib/flexeApi';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import PageLoadSpinner from '../../components/PageLoadSpinner/';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import FilterList from '../../components/FilterList';
import { grayLighter } from '../../styles/flexeColors';
import { SORT_ASC, SORT_DESC } from '../../lib/constants';
import { ScopeApprovalPolicy, SortDir } from '../../CommonInterfaces';

const styles = {
  toolbar: {
    backgroundColor: grayLighter,
  },
  sortDirIcon: {
    verticalAlign: 'middle',
  },
  reservationButton: {
    color: 'white',
  },
};

interface Reservation {
  id: number;
  depositor: {
    id: number;
    name: string;
  };
  warehouse: {
    id: number;
    name: string;
    company: {
      id: number;
      name: string;
    };
  };
}

interface ReservationsProps {
  history: any;
}

interface ReservationsState {
  showNewResMenu: boolean;
  anchorEl: HTMLElement;
  filters: string[];
  filteredReservations: Reservation[];
  reservationsLoaded: boolean;
  sortDirections: any;
}

class Reservations extends React.Component<ReservationsProps, ReservationsState> {
  private reservations: Reservation[] = [];

  constructor(props) {
    super(props);

    this.state = {
      showNewResMenu: false,
      anchorEl: null,
      filters: [],
      filteredReservations: [],
      reservationsLoaded: false,
      sortDirections: {
        'id': SORT_DESC,
        'depositor.name': null,
        'warehouse.name': null,
        'reservation_scope.approval_type': null,
        'reservation_scope.approval_info.approval_status': null,
        'txn_state': null,
      },
    };
  }

  public componentDidMount() {
    flexeApi.getReservations().then(({ data }) => {
      if (data && data.length) {
        this.reservations = this.updateSortDirectionsAndSortReservations(data, 'id');
      }
      this.setState({
        reservationsLoaded: true,
        filteredReservations: this.reservations,
      });
    });
  }

  public render() {
    return (
      <div className="page reservations-page">
        <div className="pure-g">
          <div className="pure-u-1-5 side-bar">
            <FilterList filterKey="all" values={this.state.filters} applyFilters={this.applyFilters} />
          </div>
          <div className="pure-u-4-5">
            <h1>Reservations</h1>
            {this.renderReservationsPage()}
          </div>
        </div>
      </div>
    );
  }

  private renderReservationsPage() {
    if (!this.state.reservationsLoaded) {
      return <PageLoadSpinner />;
    } else {
      return (
        <div>
          <List style={styles.toolbar}>
            <ListItem>
              <Button variant="contained" color="primary">
                <a style={styles.reservationButton} href="/reservations/new/#fulfillment">
                  New Reservation
                </a>
              </Button>
            </ListItem>
          </List>

          {this.reservations.length === 0 && <p>There are currently no reservations.</p>}
          {this.reservations.length > 0 && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <a className="sort-link" data-prop="id" onClick={this.handleSortClick}>
                      <span>Res ID</span>
                      {this.getSortDirIcon('id')}
                    </a>
                  </TableCell>
                  <TableCell>
                    <a className="sort-link" data-prop="depositor.name" onClick={this.handleSortClick}>
                      Shipper
                      {this.getSortDirIcon('depositor.name')}
                    </a>
                  </TableCell>
                  <TableCell>
                    <a className="sort-link" data-prop="warehouse.name" onClick={this.handleSortClick}>
                      Warehouse
                      {this.getSortDirIcon('warehouse.name')}
                    </a>
                  </TableCell>
                  <TableCell>
                    <a className="sort-link" data-prop="reservation_scope.approval_type" onClick={this.handleSortClick}>
                      Scope Approval Type
                      {this.getSortDirIcon('reservation_scope.approval_type')}
                    </a>
                  </TableCell>
                  <TableCell>
                    <a
                      className="sort-link"
                      data-prop="reservation_scope.approval_info.approval_status"
                      onClick={this.handleSortClick}
                    >
                      Scope Approval Status
                      {this.getSortDirIcon('reservation_scope.approval_info.approval_status')}
                    </a>
                  </TableCell>
                  <TableCell>
                    <a className="sort-link" data-prop="txn_state" onClick={this.handleSortClick}>
                      Status
                      {this.getSortDirIcon('txn_state')}
                    </a>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.filteredReservations.map((reservation) => {
                  return this.renderReservationRow(reservation);
                })}
              </TableBody>
            </Table>
          )}
        </div>
      );
    }
  }

  private applyFilters = (filterKey: string, filters: string[]) => {
    this.setState({
      filters,
      filteredReservations: this.filterReservations(filters),
    });
  };

  private filterReservations(filters: string[]) {
    if (filters.length === 0) {
      return this.reservations;
    } else {
      return this.reservations.filter((reservation) => {
        return filters.every((filter: string) => {
          filter = filter.toLowerCase();
          return (
            filter === '' ||
            reservation.id.toString().search(filter) !== -1 ||
            reservation.depositor.name.toLowerCase().search(filter) !== -1 ||
            reservation.depositor.id.toString().search(filter) !== -1 ||
            reservation.warehouse.name.toLowerCase().search(filter) !== -1 ||
            reservation.warehouse.id.toString().search(filter) !== -1 ||
            reservation.warehouse.company.name.toLowerCase().search(filter) !== -1 ||
            reservation.warehouse.company.id.toString().search(filter) !== -1
          );
        });
      });
    }
  }

  private handleSortClick = (event: React.MouseEvent<HTMLElement>) => {
    const sortLink = event.currentTarget;
    const filteredReservations = this.updateSortDirectionsAndSortReservations(
      this.state.filteredReservations,
      sortLink.dataset.prop,
    );
    this.setState({ filteredReservations });
  };

  private updateSortDirectionsAndSortReservations(reservations: Reservation[], selectedSortProp: string) {
    const sortDirections = clone(this.state.sortDirections);

    for (const sortProp in sortDirections) {
      if (sortProp === selectedSortProp) {
        if (sortDirections[sortProp] === SORT_ASC) {
          sortDirections[sortProp] = SORT_DESC;
        } else {
          sortDirections[sortProp] = SORT_ASC;
        }
      } else {
        sortDirections[sortProp] = null;
      }
    }

    this.setState({ sortDirections });

    return this.sortReservations(reservations, selectedSortProp, sortDirections[selectedSortProp]);
  }

  private handleCellClick = (resId: number) => {
    this.props.history.push(`/reservations/${resId}`);
  };

  private sortReservations(reservations: Reservation[], sortProp: string, sortDir: SortDir) {
    let sortFunc;
    if (sortProp === 'id') {
      sortFunc = (a: Reservation, b: Reservation) => {
        if (sortDir === SORT_ASC) {
          return b.id - a.id;
        } else {
          return a.id - b.id;
        }
      };
    } else {
      sortFunc = (a: Reservation, b: Reservation) => {
        const aVal: string = get(a, sortProp);
        const bVal: string = get(b, sortProp);
        if (sortDir === SORT_ASC) {
          if (aVal < bVal) {
            return -1;
          }
          if (aVal > bVal) {
            return 1;
          }
        } else {
          if (aVal < bVal) {
            return 1;
          }
          if (aVal > bVal) {
            return -1;
          }
        }
        return 0;
      };
    }

    reservations = clone(reservations);
    return reservations.sort(sortFunc);
  }

  private getSortDirIcon(sortProp: string) {
    switch (this.state.sortDirections[sortProp]) {
      case SORT_ASC:
        return <ArrowDropDown style={styles.sortDirIcon} color="primary" />;
      case SORT_DESC:
        return <ArrowDropUp style={styles.sortDirIcon} color="primary" />;
      default:
        return null;
    }
  }

  private renderReservationRow(reservation: any) {
    return (
      <TableRow key={reservation.id} style={{ cursor: 'pointer' }} onClick={() => this.handleCellClick(reservation.id)}>
        <TableCell>{reservation.id}</TableCell>
        <TableCell>
          #{reservation.depositor.id}: {reservation.depositor.name}
        </TableCell>
        <TableCell>
          #{reservation.warehouse.id}: {reservation.warehouse.name}
        </TableCell>
        <TableCell>{this.get_scope_approval_type(reservation)}</TableCell>
        <TableCell>{this.get_scope_approval_status(reservation)}</TableCell>
        <TableCell>{reservation.txn_state}</TableCell>
      </TableRow>
    );
  }

  private get_scope_approval_status(reservation: any) {
    const scopeTxnState = this.get_scope_txn_state(reservation);
    const scopeApprovalType = this.get_scope_approval_type(reservation);
    const scopeApprovalStatus =
      reservation.reservation_scope.approval_info == null
        ? null
        : reservation.reservation_scope.approval_info.approval_status;
    if (scopeApprovalStatus != null) {
      if (scopeApprovalType === ScopeApprovalPolicy.two_side) {
        const shipperSignedOn = reservation.reservation_scope.approval_info.shipper_side_signed_on;
        const warehouseSignedOn = reservation.reservation_scope.approval_info.warehouser_side_signed_on;
        return scopeApprovalStatus
          ? 'signed'
          : this.get_two_sides_unapproved_scope_signed_info(shipperSignedOn, warehouseSignedOn);
      } else {
        return scopeApprovalStatus ? 'Signed' : 'Unsigned';
      }
    } else if (scopeApprovalStatus === null && scopeApprovalType === ScopeApprovalPolicy.two_side) {
      if (scopeTxnState === 'approved' || scopeTxnState === 'completed') {
        return 'Signed';
      } else {
        const shipperSignedOn = reservation.reservation_scope.shipper_signed_on;
        const warehouseSignedOn = reservation.reservation_scope.warehouse_signed_on;
        return this.get_two_sides_unapproved_scope_signed_info(shipperSignedOn, warehouseSignedOn);
      }
    } else {
      return null;
    }
  }

  private get_two_sides_unapproved_scope_signed_info(shipperSignedOn: any, warehouseSignedOn: any) {
    return 'S: ' + (shipperSignedOn ? 'Yes' : 'No') + '  |  ' + 'W: ' + (warehouseSignedOn ? 'Yes' : 'No');
  }

  private get_scope_txn_state(reservation: any) {
    return reservation.reservation_scope.txn_state;
  }

  private get_scope_approval_type(reservation: any) {
    const scopeTxnState = this.get_scope_txn_state(reservation);
    return reservation.reservation_scope.approval_type || (scopeTxnState !== 'new' ? ScopeApprovalPolicy.two_side : '');
  }
}

export default Reservations;
