import * as React from 'react';
import PricingForm from './PricingForm';
import PricingOptions from './SubForms/PricingOptions';
import Tab from '@material-ui/core/Tab';
import ErrorIcon from '../ErrorIcon/';
import tabStyles from '../../styles/flexeTabs';
import InventoryPricingGroup from './SubForms/InventoryPricingGroup';
import InventoryGroupsManagement from './SubForms/InventoryGroupsManagement';
import { inventoryGroupHasErrors } from '../../lib/helpers';
import { InventoryGroup } from '../../CommonInterfaces';
import './pricingFormStyles.css';
import flexeApi from '../../lib/flexeApi';

class FulfillmentPricingForm extends PricingForm<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      currentTab: 'general',
    };
  }

  public renderTabs() {
    const { formData, pricing, onFormUpdate, formErrors, metaData } = this.props;
    const tabs = [this.renderGeneralSettingsTab()];

    if (!formData.pricingOptions.use_inventory_groups) {
      // Just show the default group pricing tab
      const defaultInvGroupFormErrors = formErrors.default;
      const hasErrors = inventoryGroupHasErrors(defaultInvGroupFormErrors);
      tabs.push(
        <Tab
          key="default"
          value="default"
          label={
            <span className="tab-label">
              Default <small>Pricing</small>
            </span>
          }
          icon={hasErrors ? <ErrorIcon /> : null}
          style={this.state.currentTab === 'default' ? tabStyles.activeTab : tabStyles.tab}
        />,
      );
    } else {
      const inventoryGroupTabs = pricing.inventoryGroups.map((invGroup: InventoryGroup, idx: number) => {
        const invGroupFormErrors = formErrors[invGroup.id];
        const hasErrors = inventoryGroupHasErrors(invGroupFormErrors);
        if (invGroup.enabled) {
          return (
            <Tab
              key={idx}
              value={idx}
              label={
                <span className="tab-label">
                  {invGroup.description}
                  <small>Pricing</small>
                </span>
              }
              icon={hasErrors ? <ErrorIcon /> : null}
              style={this.state.currentTab === idx ? tabStyles.activeTab : tabStyles.tab}
            />
          );
        } else {
          return null;
        }
      });
      tabs.push(...inventoryGroupTabs);
    }

    return tabs;
  }

  public renderPanes() {
    const { formData, pricing, onFormUpdate, formErrors, metaData } = this.props;
    const { currentTab } = this.state;

    if (currentTab === 'general') {
      return this.renderGeneralSettingsPane();
    } else if (currentTab === 'default') {
      return (
        <InventoryPricingGroup
          inventoryGroup={pricing.defaultInventoryGroup}
          usePackagingGroups={formData.use_packaging_groups}
          pricingOptions={formData.pricingOptions}
          supportsReturns={formData.supports_returns}
          metaData={metaData}
          disableForm={false}
          onFormUpdate={onFormUpdate}
          formErrors={formErrors.default}
        />
      );
    } else {
      const invGroup = pricing.inventoryGroups[currentTab];
      const invGroupFormErrors = formErrors[invGroup.id];
      return (
        <InventoryPricingGroup
          idx={currentTab}
          inventoryGroup={invGroup}
          usePackagingGroups={formData.use_packaging_groups}
          pricingOptions={formData.pricingOptions}
          metaData={metaData}
          disableForm={false}
          onFormUpdate={onFormUpdate}
          formErrors={invGroupFormErrors}
        />
      );
    }
  }

  private renderGeneralSettingsTab() {
    const { hasErrors } = this.props;
    return (
      <Tab
        key="general"
        value="general"
        label={
          <span className="tab-label">
            General<small>Settings &amp; Pricing</small>
          </span>
        }
        icon={hasErrors ? <ErrorIcon /> : null}
        style={this.state.currentTab === 'general' ? tabStyles.activeTab : tabStyles.tab}
      />
    );
  }

  private renderGeneralSettingsPane() {
    const {
      formData,
      pricing,
      onFormUpdate,
      cancelEditInventoryGroup,
      createOrUpdateInventoryGroup,
      onAddInventoryGroup,
      flags,
    } = this.props;

    let extraProps = {};
    if (flags !== undefined) {
      extraProps = { flags };
    }

    return (
      <fieldset>
        <h2 className="h4">General Settings &amp; Pricing</h2>
        <div className="pure-g">
          <div className="pure-u-1-2">
            <PricingOptions
              formData={formData.pricingOptions}
              optionsKey="reservation.pricingOptions"
              handleToggleChange={this.updateFieldValue}
              {...extraProps}
            />
          </div>

          {formData.pricingOptions.use_inventory_groups ? (
            <div className="pure-u-1-2">
              <InventoryGroupsManagement
                onFormUpdate={onFormUpdate}
                inventoryGroups={pricing.inventoryGroups}
                onCancelEditInventoryGroup={cancelEditInventoryGroup}
                onCreateOrUpdateInventoryGroup={createOrUpdateInventoryGroup}
                onAddInventoryGroup={onAddInventoryGroup}
                disableForm={false}
              />
            </div>
          ) : null}
        </div>
      </fieldset>
    );
  }
}

export default FulfillmentPricingForm;
