import * as React from 'react';

export interface FormProps {
  formData?: any;
  formErrors?: any;
  disableForm?: boolean;
  onFormUpdate?(targetName: string, value: any, errorKey?: string);
}

class Form<Props, State> extends React.Component<Props & FormProps, State> {
  public static defaultProps = {
    disableForm: false,
  };
  private formConstraints: any;

  constructor(props) {
    super(props);

    this.formConstraints = {};
  }

  protected updateFieldValue = (event) => {
    let errorKey;
    const target = event.target;
    const name = target.name;
    let value = target.value;
    if (target.type === 'checkbox') {
      value = target.checked;
    }
    if (target instanceof HTMLInputElement) {
      errorKey = target.dataset.errorKey;
    }
    this.props.onFormUpdate(name, value, errorKey);
  };

  protected updateIntegerFieldValue = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    if (target instanceof HTMLInputElement) {
      const integerValue = parseInt(value, 10);
      if (isNaN(integerValue)) {
        this.props.onFormUpdate(name, '');
      } else {
        this.props.onFormUpdate(name, integerValue);
      }
    }
  };
}

export default Form;
