import { cloneDeep } from 'lodash';
import { StorageBillingMode } from '../CommonInterfaces';

const PALLET_EQUIV_STORAGE_PRICING_SCOPE = {
  warehouse_rounded_pallet_equivalent_fee: '0.00',
  depositor_rounded_pallet_equivalent_fee: '0.00',
};

const PEAK_LPN_STORAGE_PRICING_SCOPE = {
  warehouse_lpn_daily_maximum_fee: '0.00',
  depositor_lpn_daily_maximum_fee: '0.00',
};

const NONE_STORAGE_PRICING_SCOPE = {
  warehouse_none_fee: '0.00',
  depositor_none_fee: '0.00',
};

const CONTAINER_PRICING_SCOPE_NON_PALLET = {
  warehouse_labor_fee: '0.00',
  warehouse_per_item_labor_fee: '0.00',
  depositor_labor_fee: '0.00',
  depositor_per_item_labor_fee: '0.00',
};

const CONTAINER_PRICING_SCOPE_PALLET = {
  warehouse_labor_fee: '0.00',
  warehouse_per_palletized_item_labor_fee: '0.00',
  warehouse_material_fee: '0.00',
  warehouse_inbound_lpn_complete_fee: '0.00',
  depositor_labor_fee: '0.00',
  depositor_per_palletized_item_labor_fee: '0.00',
  depositor_material_fee: '0.00',
  depositor_inbound_lpn_complete_fee: '0.00',
};

const CONTAINER_PRICING_SCOPE_ALL_FEES = {
  warehouse_labor_fee: '0.00',
  warehouse_per_item_labor_fee: '0.00',
  warehouse_per_palletized_item_labor_fee: '0.00',
  warehouse_material_fee: '0.00',
  warehouse_inbound_lpn_complete_fee: '0.00',
  depositor_labor_fee: '0.00',
  depositor_per_item_labor_fee: '0.00',
  depositor_per_palletized_item_labor_fee: '0.00',
  depositor_material_fee: '0.00',
  depositor_inbound_lpn_complete_fee: '0.00',
};

const CONSUMER_FULFILLMENT_PRICING_SCOPE = {
  warehouse_flat_fee: '0.00',
  warehouse_packing_slip_fee: '0.00',
  warehouse_labor_fee: '0.00',
  depositor_flat_fee: '0.00',
  depositor_packing_slip_fee: '0.00',
  depositor_labor_fee: '0.00',
};

const RETAIL_FULFILLMENT_PRICING_SCOPE_DEFAULT = {
  warehouse_flat_fee: '0.00',
  warehouse_per_unit_pick_fee: '0.00',
  warehouse_per_unit_value_added_service_fee: '0.00',
  warehouse_per_pallet_value_added_service_fee: '0.00',
  warehouse_per_pallet_materials_fee: '0.00',
  warehouse_outbound_lpn_complete_fee: '0.00',
  depositor_flat_fee: '0.00',
  depositor_per_unit_pick_fee: '0.00',
  depositor_per_unit_value_added_service_fee: '0.00',
  depositor_per_pallet_value_added_service_fee: '0.00',
  depositor_per_pallet_materials_fee: '0.00',
  depositor_outbound_lpn_complete_fee: '0.00',
};

const RETAIL_FULFILLMENT_PRICING_SCOPE_NON_PALLET = {
  warehouse_flat_fee: '0.00',
  warehouse_per_unit_pick_fee: '0.00',
  warehouse_per_unit_value_added_service_fee: '0.00',
  depositor_flat_fee: '0.00',
  depositor_per_unit_pick_fee: '0.00',
  depositor_per_unit_value_added_service_fee: '0.00',
};

const RETAIL_FULFILLMENT_PRICING_SCOPE_PALLET_ONLY = {
  warehouse_flat_fee: '0.00',
  warehouse_per_pallet_value_added_service_fee: '0.00',
  warehouse_per_pallet_materials_fee: '0.00',
  warehouse_outbound_lpn_complete_fee: '0.00',
  depositor_flat_fee: '0.00',
  depositor_per_pallet_value_added_service_fee: '0.00',
  depositor_per_pallet_materials_fee: '0.00',
  depositor_outbound_lpn_complete_fee: '0.00',
};

const RETURNS_PRICING_SCOPE = {
  warehouse_flat_fee: '0.00',
  warehouse_refurbish_fee: '0.00',
  warehouse_restock_fee: '0.00',
  warehouse_removal_fee: '0.00',
  depositor_flat_fee: '0.00',
  depositor_refurbish_fee: '0.00',
  depositor_restock_fee: '0.00',
  depositor_removal_fee: '0.00',
};

const SORT_CENTER_PRICING_SCOPE = {
  warehouse_sortation_fee: '0.00',
  depositor_sortation_fee: '0.00',
};

export function fetchStorageScope(storageBillingMode: string) {
  if (storageBillingMode === StorageBillingMode.nightly_rounded_peak_pallet_equivalents) {
    return PALLET_EQUIV_STORAGE_PRICING_SCOPE;
  } else if (storageBillingMode === StorageBillingMode.peak_lpn_pallet) {
    return PEAK_LPN_STORAGE_PRICING_SCOPE;
  } else {
    return NONE_STORAGE_PRICING_SCOPE;
  }
}

export function fetchPricingTemplate(storageBillingMode: string) {
  const template = {
    default: {
      name: 'Default',
      type: 'default',
      storage_pricing_scope: cloneDeep(fetchStorageScope(storageBillingMode)),
      container_pricing_scope: CONTAINER_PRICING_SCOPE_ALL_FEES,
      consumer_fulfillment_pricing_scope: cloneDeep(CONSUMER_FULFILLMENT_PRICING_SCOPE),
      retail_fulfillment_pricing_scope: cloneDeep(RETAIL_FULFILLMENT_PRICING_SCOPE_DEFAULT),
      returns_pricing_scope: cloneDeep(RETURNS_PRICING_SCOPE),
      sort_center_pricing_scope: cloneDeep(SORT_CENTER_PRICING_SCOPE),
    },
    each: {
      name: 'Each',
      type: 'packaging',
      container_pricing_scope: cloneDeep(CONTAINER_PRICING_SCOPE_NON_PALLET),
      consumer_fulfillment_pricing_scope: cloneDeep(CONSUMER_FULFILLMENT_PRICING_SCOPE),
      retail_fulfillment_pricing_scope: cloneDeep(RETAIL_FULFILLMENT_PRICING_SCOPE_NON_PALLET),
      returns_pricing_scope: cloneDeep(RETURNS_PRICING_SCOPE),
    },
    carton: {
      name: 'Carton',
      type: 'packaging',
      container_pricing_scope: cloneDeep(CONTAINER_PRICING_SCOPE_NON_PALLET),
      consumer_fulfillment_pricing_scope: cloneDeep(CONSUMER_FULFILLMENT_PRICING_SCOPE),
      retail_fulfillment_pricing_scope: cloneDeep(RETAIL_FULFILLMENT_PRICING_SCOPE_NON_PALLET),
      returns_pricing_scope: cloneDeep(RETURNS_PRICING_SCOPE),
      sort_center_pricing_scope: cloneDeep(SORT_CENTER_PRICING_SCOPE),
    },
    pallet: {
      name: 'Pallet',
      type: 'packaging',
      storage_pricing_scope: cloneDeep(fetchStorageScope(storageBillingMode)),
      container_pricing_scope: CONTAINER_PRICING_SCOPE_PALLET,
      retail_fulfillment_pricing_scope: cloneDeep(RETAIL_FULFILLMENT_PRICING_SCOPE_PALLET_ONLY),
    },
  };
  return template;
}
