import * as React from 'react';
import * as PropTypes from 'prop-types';
import { PERCENTAGE_HINT_TEXT } from '../../lib/constants';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Tooltip from '@material-ui/core/Tooltip';
import ErrorIcon from '../ErrorIcon';
import { grayDarkest, grayMedLight } from '../../styles/flexeColors';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';

const styles = {
  textFieldStyles: {
    display: 'block',
    fontSize: 14,
    maxWidth: 64,
  },
  errorIconStyles: {
    width: 18,
    height: 18,
    right: 0,
    top: -31,
  },
};

interface PercentageFieldProps {
  'key': any;
  'name': string;
  'value': number;
  'data-error-key': string;
  'errorText': string;
  'style'?: any;
  'labelText'?: string;
  'disabled': boolean;
  onChange(event);
}

interface PercentageFieldState {
  showErrorTooltip: boolean;
}

class PercentageField extends React.Component<PercentageFieldProps, PercentageFieldState> {
  constructor(props) {
    super(props);

    this.state = {
      showErrorTooltip: false,
    };
  }

  public render() {
    const { style, labelText, disabled, errorText, name, value, ...otherProps } = this.props;

    return (
      <div style={{ position: 'relative' }}>
        <FormControl disabled={disabled}>
          <InputLabel htmlFor={name}>{labelText}</InputLabel>
          <TextField
            id={name}
            data-testid={name}
            name={name}
            value={isNaN(value) ? '0' : value.toFixed(0)}
            onChange={otherProps.onChange}
            type="number"
            disabled={disabled}
            InputProps={{
              inputProps: { min: 0, max: 100, step: 1, maxLength: 3 },
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
          <FormHelperText>{PERCENTAGE_HINT_TEXT}</FormHelperText>
          {errorText && <FormHelperText error={true}>{errorText}</FormHelperText>}
        </FormControl>
      </div>
    );
  }
}

export default PercentageField;
