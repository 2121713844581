import * as React from 'react';

class NotFound404 extends React.Component {
  public render() {
    return (
      <div className="page not-found-page">
        <h2>404: Page Not Found</h2>
        <img src="https://http.cat/404" style={{ display: 'block', margin: '0 auto' }} />
      </div>
    );
  }
}

export default NotFound404;
