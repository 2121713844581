// Text
export const textColor = '#404247';

// Primary Blues
export const flexeBlue = '#2280C0';
export const blueDark = '#00577D';
export const blueMed = '#006DB6';
export const blueLight = '#C8DFEF';

// Secondary Orange
export const ctaOrange = '#FF6B00';
export const ctaDark = '#E56000';
export const ctaHighlight = '#FFEFE4';

// Task and Action Colors
export const errorRed = '#A94442';
export const successGreen = '#00AC3B'; // Not in the style guide, but used in comps
export const redHighlight = '#FFE2E2';
export const pickupGreen = '#7CA07A';
export const dropoffLavender = '#826F81';

// Gray Ramp
export const black = '#000';
export const grayDarkest = '#2E2E2F';
export const grayDarker = '#444445';
export const grayDark = '#5C5C5D';
export const grayMedDark = '#707273';
export const grayMedium = '#929496';
export const grayMedLight = '#B5B7B9';
export const grayLight = '#CCCECF';
export const grayLighter = '#E2E5E7';
export const grayLightest = '#F9F9F9';
export const white = '#FFF';
