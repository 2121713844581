export default class Configuration {
  private static instance: Configuration;
  private settings: { [key: string]: string };

  private constructor() {}

  public isReady(): boolean {
    return !!this.settings;
  }

  public async ready(): Promise<Configuration> {
    if (this.isReady()) {
      return this;
    }
    await fetch(`//${window.location.host}/config`)
      .then((response) => response.json())
      .then((data) => {
        this.settings = data;
      });
    return this;
  }

  public static getInstance(): Configuration {
    if (!Configuration.instance) {
      Configuration.instance = new Configuration();
    }

    return Configuration.instance;
  }

  public getSetting(key: string): string {
    if (!this.isReady()) {
      throw Error('cannot fetch settings from an uninitialized configuration!');
    }
    return this.settings[key];
  }
}
